// @flow
import React, { Component, type ElementRef } from "react";
import { Formik } from "formik";

import SettingsForm from "./SettingsForm";
import { ensureDefaults } from "types/constants/settings";
import { settingsValidationSchema } from "api/settingsValidation";

import type { OffersSettings } from "types/flow-types/settings";
import type { Customizations } from "types/flow-types/customizations";

type Props = {
  customizations: Customizations,
  companionAppsInstalled?: boolean,
  hasDiscountScopes: boolean,
  settings: OffersSettings,
  formRef?: { current: ?ElementRef<"form"> } | null,
  onCancel: () => void,
  onSubmit: OffersSettings => void,
  productBoardToken: string
};

export default class SettingsFormWrapped extends Component<Props> {
  render() {
    const {
      customizations,
      settings = {},
      formRef,
      hasDiscountScopes,
      onCancel,
      onSubmit,
      productBoardToken,
      companionAppsInstalled
    } = this.props;

    return (
      <Formik
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={ensureDefaults(settings, customizations)}
        validationSchema={settingsValidationSchema}
      >
        {form => (
          <SettingsForm
            settings={settings}
            ref={formRef}
            form={form}
            hasDiscountScopes={hasDiscountScopes}
            onCancel={onCancel}
            productBoardToken={productBoardToken}
            companionAppsInstalled={companionAppsInstalled}
          />
        )}
      </Formik>
    );
  }
}
