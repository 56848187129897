import React from "react";
import AccountPage from "./AccountPage";
import { SetShopEvent } from "types/shop";

type Props = {
  setEvent: SetShopEvent;
};

function PercentageVolumetricContainer({ setEvent }: Props) {
  return <AccountPage setEvent={setEvent} />;
}

export default PercentageVolumetricContainer;
