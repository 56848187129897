// @flow
import axios from "./authedAxios";
import type {
  ReportParams,
  SummaryReport,
  OfferReport
} from "types/flow-types/reports";
import { API_ORIGIN } from "lib/constants";
import { reportError } from "lib/errorHelpers";

export const ORDERS_API_PATH = "/api/orders/";
export const ORDERS_API_BASE = `${API_ORIGIN}${ORDERS_API_PATH}`;
export const REPORTS_PATHS = {
  summary: `${ORDERS_API_BASE}reports/summary`,
  offers: `${ORDERS_API_BASE}reports/offers`
};

type ApiOfferReport = {
  ...OfferReport,
  id: number
};

export type MaybeSummaryReportResponse =
  | {
      error: Error
    }
  | {
      data: SummaryReport
    };

export type MaybeOffersReportsResponse =
  | {
      error: Error
    }
  | {
      data: Array<ApiOfferReport>
    };

export type FetchReports = (
  params?: ReportParams
) => MaybeSummaryReportResponse;

export async function fetchSummaryReport(
  params: ReportParams = {}
): Promise<MaybeSummaryReportResponse> {
  try {
    const { data } = await axios.get(REPORTS_PATHS.summary, {
      params
    });
    return { data };
  } catch (e) {
    const error = maybeReportError(e);
    return { error };
  }
}

export async function fetchOffersReports(
  params: ReportParams = {}
): Promise<MaybeOffersReportsResponse> {
  try {
    const { data } = await axios.get(REPORTS_PATHS.offers, {
      params
    });
    return { data };
  } catch (e) {
    const error = maybeReportError(e);
    return { error };
  }
}

function maybeReportError(httpError: Object): Error {
  const { response = {} } = httpError;
  const error = new Error(httpError);
  // we're not raising 503s because
  // they are likely to be timeouts.
  if (response.status === 503) {
    return error;
  }
  reportError(error);
  return error;
}
