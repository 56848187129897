export default function getStringsOfObj(obj = {}) {
  if (typeof obj !== "object") {
    throw new Error(
      `getStringsOfObj expects an object, got ${typeof obj} instead`
    );
  }

  const strings = [];
  JSON.stringify(obj, (k, v) => {
    if (typeof v === "string" && !strings.includes(v)) strings.push(v);
    return v;
  });
  return strings;
}
