import axios from "./authedAxios";
import { API_ORIGIN } from "lib/constants";

export const COMPANION_API_PATH = "/api/settings/check-companion-apps";
export const COMPANION_API_BASE = `${API_ORIGIN}${COMPANION_API_PATH}`;
export const checkCompanionAppsUrl = (path = "") =>
  [COMPANION_API_BASE, path].filter(Boolean).join("/");

export const COMPANION_APP_1_ID = "8075153c1dce605b34eb0c4b7226dba6";
export const COMPANION_APP_2_ID = "874834d845b08cc2c537dc7a335af6a7";

export async function getCompanionAppsStatus() {
  try {
    const { data } = await axios.get(checkCompanionAppsUrl());
    return { data };
  } catch (e) {
    const error = new Error(e);
    return { error };
  }
}
