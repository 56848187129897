import React from "react";
import { Card, Layout, Link, TextContainer } from "@shopify/polaris";
import { useShop } from "contexts/Shop";
import copy from "content/copy.json";
import DevPlanCard from "routes/account/percentage-volumetric/DevPlanCard";
import PlanCard from "routes/account/percentage-volumetric/PlanCard";
import PlanCardActive from "routes/account/percentage-volumetric/PlanCardActive";
import TrialCard from "./TrialCard";
import FreePlanCard from "./FreePlanCard";
import { ShopEventPayload } from "types/shop";
import PlanStatusContainer from "components/banners/free-plan/PlanStatusContainer";

interface Props {
  setEvent: (payload: ShopEventPayload) => void;
}
function PlanCardSection({ setEvent }: Props) {
  const { activeUltimatePlan, flags, onDevPlan } = useShop();

  const {
    percentagePlanSectionTitle,
    learnMoreDescription,
    learnMoreLink,
    learnMoreText
  } = copy.account.percentageVolumetric;

  const description = (
    <TextContainer spacing="tight">
      <p>
        {learnMoreDescription}{" "}
        <Link url={learnMoreLink} external>
          {learnMoreText}
        </Link>
      </p>
    </TextContainer>
  );

  return (
    <Layout.AnnotatedSection
      title={percentagePlanSectionTitle}
      description={description}
    >
      <Layout>
        <Layout.Section>
          {onDevPlan ? <DevPlanCard /> : <PlanStatusCard setEvent={setEvent} />}
          {activeUltimatePlan ? <PlanCardActive /> : <PlanCard />}
          {flags.releaseEnableFreePlan && <FreePlanCard />}
        </Layout.Section>
      </Layout>
    </Layout.AnnotatedSection>
  );
}

function PlanStatusCard({ setEvent }: Props) {
  const { activeUltimatePlan, flags, trialDaysRemaining } = useShop();
  if (flags.releaseEnableFreePlan) {
    const banner = PlanStatusContainer({ setEvent });
    return banner && <Card>{banner}</Card>;
  } else {
    return (
      <TrialCard
        activeUltimatePlan={activeUltimatePlan}
        trialDaysRemaining={trialDaysRemaining}
      />
    );
  }
}

export default PlanCardSection;
