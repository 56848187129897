// @flow
import React, { useContext, useEffect, useState } from "react";

import { storeConnect } from "appStore";
import makeRequestProps from "store/makeRequestProps";
import { reportsActions, type OffersReportsRequest } from "store/reportsStore";
import OffersList from "./OffersList";
import { FlagsContext } from "contexts/Flags";
import { offerFilters, getOffersByFilter } from "./offer-filter-helpers";

import type { DateRanges } from "lib/reporting-helpers";
import type { Offer, OfferType } from "types/flow-types/offers";
import { OFFER_TYPES } from "types/constants/offers";

type Props = {
  offers: Array<Offer>,
  moneyFormat: string,
  loadOffersReports: () => void,
  offersReportsRequest: OffersReportsRequest,
  reportingRange: DateRanges
};

const OFFER_TYPE_TITLES = OFFER_TYPES.reduce((a, e) => {
  a[e.type] = e.title;

  return a;
}, {});

// Only searches by offer name
export function getOffersBySearchValue(
  searchValue: string | null,
  offers: Array<Offer>
): Array<Offer> {
  return searchValue
    ? offers.filter(offer => {
        return offer.name
          ? offer.name.toLowerCase().includes(searchValue.toLowerCase())
          : offer.type.toLowerCase().includes(searchValue.toLowerCase());
      })
    : offers;
}

export function OffersListContainer(props: Props) {
  const { disableReporting } = useContext(FlagsContext);

  const [queryValue, setQueryValue] = useState<string | null>(null);
  const [offerType, setOfferType] = useState<OfferType[]>([]);
  const [status, setStatus] = useState<("Enabled" | "Disabled")[]>([]);
  const appliedFilters = [];

  useEffect(() => {
    // @featureFlag ch60386 Reporting kill switch
    if (!disableReporting) {
      props.loadOffersReports();
    }
  }, []);

  const handleQueryChange = (queryValue: string) => {
    setQueryValue(queryValue);
  };

  const handleQueryClear = () => {
    setQueryValue(null);
  };

  const handleOfferTypeChange = (newOfferType: OfferType[]) => {
    setOfferType(newOfferType || []);
  };

  const handleOfferTypeRemove = () => {
    setOfferType([]);
  };

  const handleStatusChange = (status: ("Enabled" | "Disabled")[]) => {
    setStatus(status || []);
  };

  const handleStatusRemove = () => {
    setStatus([]);
  };

  const handleFiltersClearAll = () => {
    handleOfferTypeRemove();
    handleStatusRemove();
  };

  const { offers, moneyFormat, offersReportsRequest, reportingRange } = props;
  const offersFilteredBySearch = getOffersBySearchValue(queryValue, offers);

  if (offerType.length > 0) {
    const key = "offerTypeFilter";
    appliedFilters.push({
      key,
      label: offerType.map(type => OFFER_TYPE_TITLES[type]).join(", "),
      value: offerType,
      onRemove: handleOfferTypeRemove
    });
  }

  if (status.length > 0) {
    const key = "statusFilter";
    appliedFilters.push({
      key,
      label: status.join(", "),
      value: status,
      onRemove: handleStatusRemove
    });
  }

  const offersFilteredBySearchAndFilters = getOffersByFilter(
    appliedFilters,
    offersFilteredBySearch
  );

  return (
    <OffersList
      moneyFormat={moneyFormat}
      offers={offersFilteredBySearchAndFilters}
      offersReportsRequest={offersReportsRequest}
      queryValue={queryValue}
      handleFiltersClearAll={handleFiltersClearAll}
      handleQueryChange={handleQueryChange}
      handleQueryClear={handleQueryClear}
      filters={offerFilters({
        offerType,
        handleOfferTypeChange,
        status,
        handleStatusChange
      })}
      appliedFilters={appliedFilters}
      reportingRange={reportingRange}
    />
  );
}

function mapStateToProps(state) {
  const { shop, reportingRange } = state;

  return {
    offersReportsRequest: makeRequestProps(state, "offersReports"),
    moneyFormat: shop.data.settings.money_format,
    reportingRange
  };
}

export default storeConnect(
  OffersListContainer,
  mapStateToProps,
  reportsActions
);
