import React from "react";
import { Field } from "formik";
import { Card, FormLayout } from "@shopify/polaris";
import PolarisFormikTextField from "components/PolarisFormikTextField";

function NameSection() {
  return (
    <Card.Section>
      <FormLayout>
        <Field
          name="name"
          label="Name"
          placeholder="e.g. Summer bathing suit sale"
          component={PolarisFormikTextField}
        />
      </FormLayout>
    </Card.Section>
  );
}

export default NameSection;
