// @flow
export function numberWithCommas(value: number): string {
  const MAXIMUM_FRACTION_DIGITS = 2;

  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: MAXIMUM_FRACTION_DIGITS,
    style: "decimal"
  }).format(value);
}

export const prettifyNumber = (
  number: number,
  forceDecimals: boolean = false
): string => {
  const charLength = Math.ceil(Math.log(number + 1) / Math.LN10);

  if (charLength < 5) {
    return number % 1 !== 0 && forceDecimals
      ? number.toFixed(2)
      : (Math.round(number * 100) / 100).toLocaleString("en-US");
  }

  const decPlaces = Math.pow(10, 1);
  const abbrev = ["K", "M", "B", "T"];

  let prettifiedNumber = null;

  // Go through the array backwards, so we do the largest first
  for (let i = abbrev.length - 1; i >= 0; i--) {
    // Convert array index to "1000", "1000000", etc
    const size = Math.pow(10, (i + 1) * 3);

    // If the number is bigger or equal do the abbreviation
    if (size <= number) {
      // Here, we multiply by decPlaces, round, and then divide by decPlaces.
      // This gives us nice rounding to a particular decimal place.
      number = Math.round((number * decPlaces) / size) / decPlaces;

      // Handle special case where we round up to the next abbreviation
      if (number === 1000 && i < abbrev.length - 1) {
        number = 1;
        i++;
      }

      // Add the letter for the abbreviation
      prettifiedNumber = number + abbrev[i];

      break;
    }
  }

  return prettifiedNumber || number.toString();
};
