import React from "react";

import { getOfferType } from "lib/offerHelpers";
import AnnotatedSection from "components/AnnotatedSection";
import { helpDocsUrl } from "components/links";

export default function OfferConfigurationSection(props) {
  const { offerTypeSlug } = props;
  if (!offerTypeSlug) return null;

  const defaultTitle = `${getOfferType(offerTypeSlug).title} offer`;
  const defaultDescription = "Configure the specifics of your special offer.";
  const { title = defaultTitle, description = defaultDescription } = props;

  return (
    <AnnotatedSection
      title={title}
      description={description}
      linkUrl={helpDocsUrl(offerTypeSlug)}
      linkText="Learn more"
    >
      {props.children}
    </AnnotatedSection>
  );
}
