// @flow
import React, { Component } from "react";

import { storeConnect } from "appStore";
import { reportsActions } from "store/reportsStore";
import makeRequestProps from "store/makeRequestProps";
import { Card, Stack } from "@shopify/polaris";

import { FlagsContext } from "contexts/Flags";
import { reportError } from "lib/errorHelpers";
import ReportingChartEmpty from "../ReportingChart/ReportingChartEmpty";
import ReportingChartLoading from "../ReportingChart/ReportingChartLoading";
import ReportingChart from "../ReportingChart/ReportingChart";
import makeFormatters from "../reportingValueFormatters";
import ReportingPeriodPicker from "../ReportingPeriodPicker/ReportingPeriodPicker";

import type { DateRanges } from "lib/reporting-helpers";

type ReportingChartContainerProps = {
  updateReportingPeriod: string => void,
  loadSummaryReport: () => void,
  moneyFormat: string,
  reportingRange: DateRanges,
  reportRequest: {
    error: boolean,
    loading: boolean,
    data: Object
  },
  shopDomain: string
};

type ReportingChartContainerState = {
  componentError: ?boolean,
  ChartLineChart: any | null
};

export class ReportingChartContainer extends Component<
  ReportingChartContainerProps,
  ReportingChartContainerState
> {
  static contextType = FlagsContext;

  state = {
    componentError: null,
    ChartLineChart: null
  };

  valueFormatters = makeFormatters(this.props.moneyFormat);

  componentDidCatch(error: Error, info: { componentStack: string }) {
    reportError(error, info);

    this.setState({
      componentError: true
    });
  }

  async componentDidMount() {
    // @featureFlag ch60386 Reporting kill switch
    if (this.context.disableReporting) return;

    this.props.loadSummaryReport();
    const { default: ChartLineChart } = await import(
      "../ChartLineChart/ChartLineChart"
    );
    this.setState({ ChartLineChart });
  }

  renderChart() {
    const { error: apiError, loading, data } = this.props.reportRequest;
    const { ChartLineChart, componentError } = this.state;
    const disableReporting = this.context.disableReporting;

    if (apiError || componentError || disableReporting) {
      return <ReportingChartEmpty valueFormatters={this.valueFormatters} />;
    }

    if (!ChartLineChart || loading) {
      return <ReportingChartLoading />;
    }

    const { offers, summaryReport, offersReports } = data;

    return (
      <ReportingChart
        offers={offers.filter(offer => offer.type)}
        reportingRange={this.props.reportingRange}
        summaryReport={summaryReport}
        offersReports={offersReports}
        valueFormatters={this.valueFormatters}
        ChartLineChart={ChartLineChart}
        shopDomain={this.props.shopDomain}
      />
    );
  }

  render() {
    const { reportingRange, updateReportingPeriod } = this.props;

    return (
      <>
        <Card.Section>
          <Stack distribution="trailing">
            <ReportingPeriodPicker
              reportingRange={reportingRange}
              updateReportingPeriod={updateReportingPeriod}
            />
          </Stack>
        </Card.Section>
        <Card.Section>{this.renderChart()}</Card.Section>
      </>
    );
  }
}

const mapStateToProps = function (state) {
  const { reportingRange, shop } = state;

  return {
    reportingRange,
    moneyFormat: shop.data.settings.money_format,
    shopDomain: shop.data.settings.myshopify_domain,
    reportRequest: makeRequestProps(state, [
      "offers",
      "summaryReport",
      "offersReports"
    ])
  };
};

export default storeConnect(
  ReportingChartContainer,
  mapStateToProps,
  reportsActions
);
