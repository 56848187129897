import React from "react";
import { Card } from "@shopify/polaris";
import copy from "content/copy.json";

const {
  percentagePlanSectionActiveDescription,
  percentagePlanCardTitle,
  planCardActivePrefix
} = copy.account.percentageVolumetric;

const planCardTitle = planCardActivePrefix + percentagePlanCardTitle;

function PlanCardActive() {
  return (
    <Card title={planCardTitle} subdued>
      <Card.Section>{percentagePlanSectionActiveDescription}</Card.Section>
    </Card>
  );
}

export default PlanCardActive;
