import * as Types from "../../types/generated/graphql";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type MainThemeIdOnlyQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type MainThemeIdOnlyQuery = {
  __typename?: "RootQueryType";
  mainTheme: { __typename?: "MainTheme"; id: number };
};

export const MainThemeIdOnlyDocument = gql`
  query MainThemeIdOnly {
    mainTheme {
      id
    }
  }
`;

/**
 * __useMainThemeIdOnlyQuery__
 *
 * To run a query within a React component, call `useMainThemeIdOnlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useMainThemeIdOnlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMainThemeIdOnlyQuery({
 *   variables: {
 *   },
 * });
 */
export function useMainThemeIdOnlyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MainThemeIdOnlyQuery,
    MainThemeIdOnlyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MainThemeIdOnlyQuery, MainThemeIdOnlyQueryVariables>(
    MainThemeIdOnlyDocument,
    options
  );
}
export function useMainThemeIdOnlyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MainThemeIdOnlyQuery,
    MainThemeIdOnlyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MainThemeIdOnlyQuery,
    MainThemeIdOnlyQueryVariables
  >(MainThemeIdOnlyDocument, options);
}
export type MainThemeIdOnlyQueryHookResult = ReturnType<
  typeof useMainThemeIdOnlyQuery
>;
export type MainThemeIdOnlyLazyQueryHookResult = ReturnType<
  typeof useMainThemeIdOnlyLazyQuery
>;
export type MainThemeIdOnlyQueryResult = Apollo.QueryResult<
  MainThemeIdOnlyQuery,
  MainThemeIdOnlyQueryVariables
>;
