export const reportError = function (error, info) {
  if (window.Sentry) {
    if (info) {
      window.Sentry.configureScope(scope => {
        Object.keys(info).forEach(key => {
          scope.setExtra(key, info[key]);
        });
      });
      // this needs to be called in the same scope as the configureScope call
      window.Sentry.captureException(error);
    } else {
      window.Sentry.captureException(error);
    }
  }
};
