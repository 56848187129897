import { flow } from "lodash/fp";
import {
  parseISO as dateParseISO,
  addDays as dateAddDays,
  addHours as dateAddHours,
  format as dateFormat,
  differenceInMilliseconds as dateDifferenceInMilliseconds,
  startOfDay as dateStartOfDay,
  subDays as dateSubDays,
  subHours as dateSubHours
} from "date-fns/fp";

export const formatDateForInput = date => {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();

  return `${year}-${month}-${day}`;
};

export const dateFromString = date =>
  date.constructor === String ? dateParseISO(date) : date;

export const format = (date, format = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") =>
  flow(dateFromString, dateFormat(format))(date);

export const addDays = (date, days) =>
  flow(dateFromString, dateAddDays(days))(date);

/* This function takes a floating number and rounds it up if it is positive
   and rounds it down if it is negative */
const roundToInteger = float => {
  if (float < 0) return Math.floor(float);
  return Math.ceil(float);
};

/* This function returns the rounded up difference between the 2 dates in days.
   If the result is negative the result is rounded down
   example 0.35 is rounded to 1 and -0.35 is rounded to -1 */
export const diffCalendarDays = (dateA, dateB, allowNegative = true) => {
  const millisecondsInADay = 1000 * 60 * 60 * 24;
  dateA = dateFromString(dateA);
  dateB = dateFromString(dateB);

  let diff = dateDifferenceInMilliseconds(dateA, dateB) / millisecondsInADay;

  const roundedDiff = roundToInteger(diff);

  return allowNegative ? roundedDiff : Math.abs(roundedDiff);
};

export const startOfNextDay = date =>
  flow(dateFromString, dateAddDays(1), dateStartOfDay())(date);

export const addHours = (date, hours) =>
  flow(dateFromString, dateAddHours(hours))(date);

export const subDays = (date, days) =>
  flow(dateFromString, dateSubDays(days))(date);

export const subHours = (date, hours) =>
  flow(dateFromString, dateSubHours(hours))(date);
