import { FETCH_STATES } from "lib/constants";

function reduceWhileState(state, stateKeys) {
  const requestPropObject = {
    error: false,
    loading: false,
    status: 0,
    data: {}
  };
  for (const key of stateKeys) {
    const { data, fetchState, status } = state[key];
    const error = fetchState === FETCH_STATES.ERROR;
    const loading =
      fetchState === FETCH_STATES.STARTED || fetchState === FETCH_STATES.NULL;

    requestPropObject.status = status;
    requestPropObject.error = error;
    requestPropObject.loading = loading;
    requestPropObject.data[key] = data;

    if (fetchState !== FETCH_STATES.SUCCESS) {
      break;
    }
  }
  return requestPropObject;
}

export default function makeRequestProps(state, stateKeyOrKeys) {
  let isSingleKey = false;
  let stateKeys = stateKeyOrKeys;
  let stateKey = "";
  if (typeof stateKeyOrKeys === "string") {
    isSingleKey = true;
    stateKey = stateKeyOrKeys;
    stateKeys = [stateKeyOrKeys];
  }

  const requestPropObject = reduceWhileState(state, stateKeys);

  const { error, loading, status, data } = requestPropObject;
  const dataKey = isSingleKey ? stateKey : "data";
  const dataValue = isSingleKey ? data[stateKey] : data;

  return {
    error,
    loading,
    status,
    [dataKey]: dataValue
  };
}
