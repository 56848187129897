import React from "react";
import { Banner, Link } from "@shopify/polaris";
import { SetShopEvent } from "types/shop";
import { useShop } from "contexts/Shop";

interface Props {
  setEvent: SetShopEvent;
}
function AccountPageBanner(props: Props) {
  const shop = useShop();
  const { activeUltimatePlan } = shop;
  const { setEvent } = props;

  const billingTableMessageDismissed =
    shop.events.billing_table_message_dismissed;
  if (shop.events?.billing_error) return <ShopifyChargeReturnErrorMessage />;
  if (activeUltimatePlan && !billingTableMessageDismissed) {
    return <ThankYouMessage setEvent={setEvent} />;
  }
  return null;
}

function ShopifyChargeReturnErrorMessage() {
  return (
    <Banner title="Something went wrong" status="critical">
      <p>
        An error occurred while activating your plan. You can try again, or{" "}
        <Link
          url="https://launch.orbitapps.com/hc/en-us/articles/4402979466003"
          external
        >
          contact support
        </Link>
      </p>
    </Banner>
  );
}

function ThankYouMessage({ setEvent }: { setEvent: SetShopEvent }) {
  return (
    <Banner
      title="Thank you for partnering with Ultimate Special Offers."
      status="success"
      onDismiss={() => setEvent({ billing_table_message_dismissed: true })}
    >
      Your subscription has been activated.
    </Banner>
  );
}

export default AccountPageBanner;
