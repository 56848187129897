// @flow

export const API_ORIGIN = `${window.location.origin}`;

export const FETCH_STATES = {
  NULL: 0, // never been called
  STARTED: 1, // in flight
  SUCCESS: 2, // might as well follow http status codes
  ERROR: 4 // ditto
};

export const STATUSES = {
  READY: 0,
  READING: 1,
  UPDATING: 2,
  DELETING: 3
};

export const DATE_RANGES = {
  TODAY: "today",
  YESTERDAY: "yesterday",
  PAST_7_DAYS: "past7days",
  PAST_30_DAYS: "past30days"
};

export const REPORT_PERIOD_OPTIONS: Array<{ label: string, value: string }> = [
  { label: "Today", value: DATE_RANGES.TODAY },
  { label: "Yesterday", value: DATE_RANGES.YESTERDAY },
  { label: "Past 7 days", value: DATE_RANGES.PAST_7_DAYS },
  { label: "Past 30 days", value: DATE_RANGES.PAST_30_DAYS }
];

export const AJAX_CART_BANNER_STATE = {
  LOADING: "loading",
  THEME_SUPPORTED: null,
  NEW_BANNER: "new",
  LEGACY_BANNER: "legacy"
};

export const BILLING_TABLE_LABELS = {
  GROWTH: "GROWTH: 49¢ per order",
  LITE: "LITE: FREE",
  STARTER: "STARTER: 99¢ per order",
  UNLIMITED: "UNLIMITED: Everything after FREE"
};
