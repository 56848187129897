import { createElement } from "react";

const NestedField = ({ style, children }) =>
  createElement(
    "div",
    {
      style: {
        ...style,
        marginLeft: "2.4rem",
        marginTop: "0.6rem"
      }
    },
    children
  );

export default NestedField;
