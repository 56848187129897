import React, { useContext } from "react";
import { Card, Heading, Layout, Link, TextContainer } from "@shopify/polaris";

import AnnotatedSection from "components/AnnotatedSection";

import NameSection from "./NameSection";
import { NavigationContext } from "contexts/Navigation";
import VerboseOfferTypePicker from "./VerboseOfferTypePicker";
import VisibilitySection from "./VisibilitySection";
import { ShopContext } from "../../contexts/Shop";
import { getIn } from "formik";

export default function OfferTypeSection(props) {
  const { form, onOfferTypeChange, flags } = props;
  const navigation = useContext(NavigationContext);
  const { values } = form;
  const showOfferDetails = values.type != null;
  const shop = useContext(ShopContext);
  const firstOfferCreated = getIn(shop, "events.first_offer_created");

  function navigateToWelcomePage() {
    navigation.goTo("/welcome");
  }

  const postPurchaseInfoLink = (
    <Link
      url="https://launch.orbitapps.com/hc/en-us/articles/4402805635859"
      external={true}
    >
      Learn more
    </Link>
  );

  const layoutDescription = (
    <>
      <p className="Offer-Section-Layout-Description">
        Choose from a variety of easy-to-use offer types to promote your sales.{" "}
        <Link
          url="https://launch.orbitapps.com/hc/en-us/articles/4402812500371"
          external={true}
        >
          Learn more
        </Link>
      </p>
      <p className="Offer-Section-Layout-Description">
        Post-Purchase Upsell is now included in the Ultimate plan.{" "}
        {postPurchaseInfoLink}
      </p>
      <p className="Offer-Section-Layout-Description">
        Watch the Getting Started video{" "}
        <Link onClick={navigateToWelcomePage}>here.</Link>
      </p>
    </>
  );

  return (
    <AnnotatedSection title="Offer" layoutDescription={layoutDescription}>
      <Layout>
        {!showOfferDetails && !firstOfferCreated && (
          <Layout.Section>
            <TextContainer spacing="loose">
              <Heading element="h1">Get started, create an offer!</Heading>
            </TextContainer>
          </Layout.Section>
        )}
        <Layout.Section>
          <VerboseOfferTypePicker
            selectedOfferType={form.values.type}
            onOfferTypeChange={onOfferTypeChange}
            flags={flags}
          />
          {showOfferDetails && (
            <Card>
              <NameSection />
              <VisibilitySection form={form} />
            </Card>
          )}
        </Layout.Section>
      </Layout>
    </AnnotatedSection>
  );
}
