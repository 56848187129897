import React, { useEffect, useState } from "react";
import { getIn } from "formik";
import { Button } from "@shopify/polaris";
import { ResourcePicker } from "@shopify/app-bridge-react";
import { composeGid } from "@shopify/admin-graphql-api-utilities";
import {
  addInventoryItems,
  addInventoryItemsForVariants
} from "lib/formHelpers";

function selectionIdsFromSelection(selection, target, showVariants) {
  if (target === "Product" && showVariants) {
    return selection.map(product => ({
      id: product.id,
      variants: product.variants.map(variant => ({ id: variant.id }))
    }));
  }

  return selection.map(item => ({ id: item.id }));
}

function selectionIdsFromIds(ids, target) {
  return ids.map(id => ({ id: composeGid(target, id) }));
}

function selectionIdsFromProductVariants(productVariants = []) {
  return productVariants.map(product => ({
    id: composeGid("Product", product.product_id),
    variants: product.variants.map(variant => ({
      id: composeGid("ProductVariant", variant.id)
    }))
  }));
}

export default function PolarisFormikInventoryPickerButton({
  allowMultiple = true,
  form,
  handlesFieldName,
  idsFieldName,
  selectionIds,
  setSelectionIds,
  showVariants = false,
  target,
  title,
  titlesFieldName,
  variantsFieldName
}) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (idsFieldName) {
      const ids = getIn(form.values, idsFieldName);
      const initialSelectionIds = selectionIdsFromIds(ids, target);

      setSelectionIds(initialSelectionIds);
      return;
    }

    if (variantsFieldName) {
      const productVariants = getIn(form.values, variantsFieldName);
      const initialSelectionIds =
        selectionIdsFromProductVariants(productVariants);

      setSelectionIds(initialSelectionIds);
      return;
    }
  }, []);

  const onSelection = items => {
    const newSelectionIds = selectionIdsFromSelection(
      items.selection,
      target,
      showVariants
    );

    if (!!idsFieldName && !!titlesFieldName && !!handlesFieldName) {
      addInventoryItems(
        form,
        idsFieldName,
        titlesFieldName,
        handlesFieldName,
        items
      );
    }

    if (target === "Product" && showVariants && !!variantsFieldName) {
      addInventoryItemsForVariants(form, variantsFieldName, items);
    }

    setOpen(false);
    setSelectionIds(newSelectionIds);
  };

  const onCancel = () => {
    setOpen(false);
  };

  const togglePicker = () => {
    setOpen(oldOpen => !oldOpen);
  };

  return (
    <div className="InventoryPicker-Button">
      <Button onClick={togglePicker} loading={open}>
        {title}
      </Button>

      <ResourcePicker
        resourceType={target}
        allowMultiple={allowMultiple}
        open={open}
        onSelection={onSelection}
        onCancel={onCancel}
        showVariants={showVariants}
        initialSelectionIds={selectionIds}
        actionVerb="select"
      />
    </div>
  );
}
