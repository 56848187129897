// @flow
import React from "react";

import MarketingText from "./MarketingText";

export default function ActivatedContent({
  appPlanName
}: {
  appPlanName: string
}) {
  return (
    <div className="Plan-Activation-Section">
      <div className="Description-Text">
        <MarketingText appPlanName={appPlanName} />
      </div>
      <div className="Plan-Activated">Activated</div>
    </div>
  );
}
