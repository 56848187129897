// @flow
import type {
  OfferReport,
  OfferReportItem,
  ReportParams,
  ReportRangeSummary,
  ShopSummary
} from "types/flow-types/reports";

import { parse } from "date-fns";
import { FETCH_STATES } from "lib/constants";
import { getTimeKeyForDate } from "../components/reporting/chart-date-helpers";
import { REPORT_PERIOD_OPTIONS } from "lib/constants";
import { type DateRanges } from "lib/reporting-helpers";
import { trackEvent } from "lib/analytics";
import { fetchSummaryReport, fetchOffersReports } from "api/reports";

export const OFFERS_REPORTS_EMPTY_STATE = {
  data: {},
  fetchState: FETCH_STATES.NULL
};

export const SUMMARY_REPORTS_EMPTY_STATE = {
  data: {},
  fetchState: FETCH_STATES.NULL
};

export const EMPTY_OFFER_REPORT: OfferReportItem = {
  orderCount: 0,
  orderTotal: 0,
  cartCount: 0
};

export type OffersReportsState = {
  data: { [offerId: number]: OfferReport },
  fetchState: string
};

export type NormalizedReportRange = {
  [rangeTimestamp: string]: OfferReportItem
};

export type NormalizedOfferReport = {
  summary: OfferReportItem,
  daily: NormalizedReportRange,
  hourly: NormalizedReportRange
};

export type NormalizedOffersReport = {
  [offerId: number]: NormalizedOfferReport
};

export type NormalizedSummaryReport = {
  summary: OfferReportItem,
  daily: NormalizedReportRange,
  hourly: NormalizedReportRange
};

export type OffersReportsRequest = {
  loading?: boolean,
  error?: Error,
  offersReports: { [offerId: number]: NormalizedOfferReport }
};

export function normalizeReportRange(
  reportRange: Array<ReportRangeSummary>,
  spanKey: "daily" | "hourly"
): NormalizedReportRange {
  const parsePattern = spanKey === "daily" ? "yyyy-MM-dd" : "yyyy-MM-dd HH";
  return reportRange.reduce(
    (normalizedObj, { rangeTimestamp, ...reportStats }) => {
      const dateStamp = parse(rangeTimestamp, parsePattern, new Date());
      const timeKey = getTimeKeyForDate(dateStamp);
      normalizedObj[timeKey] = reportStats;

      return normalizedObj;
    },
    {}
  );
}

export function normalizeSummaryReport(
  reportData: ShopSummary
): NormalizedSummaryReport {
  const { hourly = [], daily = [] } = reportData;

  return {
    summary: reportData.summary,
    daily: normalizeReportRange(daily, "daily"),
    hourly: normalizeReportRange(hourly, "hourly")
  };
}

export function normalizeOffersReports(
  reports: Array<OfferReport>
): NormalizedOffersReport {
  const spanKeys = ["daily", "hourly"];

  return reports.reduce((normalizedObj, { id, ...reportData }) => {
    normalizedObj[id] = {
      summary: reportData.summary
    };

    spanKeys.forEach(span => {
      const dataForSpan = reportData[span] || [];
      normalizedObj[id][span] = normalizeReportRange(dataForSpan, span);
    });

    return normalizedObj;
  }, {});
}

export const reportsActions = (store: Object) => ({
  updateReportingPeriod(state: Object, reportingRange: DateRanges) {
    store.setState({
      reportingRange: reportingRange
    });

    const newSelectedOption = REPORT_PERIOD_OPTIONS.find(
      period => period.value === reportingRange
    );

    trackEvent("Reporting Date Filter Changed", newSelectedOption);
  },

  async loadSummaryReport(
    {
      summaryReport: currentReport
    }: { summaryReport: { data: NormalizedSummaryReport, fetchState: number } },
    params: ReportParams = {}
  ) {
    // Only fetch the report data once
    if (currentReport.fetchState === FETCH_STATES.SUCCESS) return;

    store.setState({
      summaryReport: { ...currentReport, fetchState: FETCH_STATES.STARTED }
    });

    const response = await fetchSummaryReport(params);

    if (response.error) {
      store.setState({
        summaryReport: {
          ...store.getState().summaryReport,
          fetchState: FETCH_STATES.ERROR
        }
      });
    } else {
      store.setState({
        summaryReport: {
          ...store.getState().summaryReport,
          fetchState: FETCH_STATES.SUCCESS,
          data: normalizeSummaryReport(response.data.allOffers)
        }
      });
    }
  },

  async loadOffersReports(
    { offersReports: currentReports }: { offersReports: OffersReportsState },
    params: ReportParams = {}
  ): Promise<void> {
    store.setState({
      offersReports: {
        ...currentReports,
        fetchState: FETCH_STATES.STARTED
      }
    });

    const response = await fetchOffersReports(params);

    if (response.error) {
      store.setState({
        offersReports: {
          ...currentReports,
          fetchState: FETCH_STATES.ERROR
        }
      });
    } else {
      store.setState({
        offersReports: {
          fetchState: FETCH_STATES.SUCCESS,
          data: normalizeOffersReports(response.data)
        }
      });
    }
  }
});
