import React from "react";
import { Field } from "formik";
import { TextField } from "@shopify/polaris";
import { gatherFieldProps, uniqueIdForInput } from "lib/formHelpers";
import objectPath from "lib/objectPath";

function NumberFieldWrapped(props) {
  const fieldProps = gatherFieldProps(props);
  const {
    field,
    form,
    allowFloat = false,
    suppressErrorMessage = false
  } = props;
  const { name, value } = field;
  const parse = allowFloat ? parseFloat : parseInt;
  const id = props.id || uniqueIdForInput(name);

  // TODO: DRY out the error stuff between here and TextField
  const touched = objectPath.get(form.touched, name);
  const error = objectPath.get(form.errors, name);
  let errorMsg = touched && error ? error : null;

  if (suppressErrorMessage) {
    errorMsg = Boolean(errorMsg);
  }

  const handleChange = number => {
    form.setFieldTouched(name);
    const parsedValue = parse(number, 10);
    number = isNaN(parsedValue) ? "" : number;
    form.setFieldValue(name, number);
  };

  const handleBlur = e => {
    const value = e.target.value;
    const parsedValue = parse(value, 10);
    const newValue = isNaN(parsedValue) ? 0 : parsedValue;
    form.setFieldValue(name, newValue);
  };

  return (
    <TextField
      value={value !== undefined && value !== null && value.toString()}
      onChange={handleChange}
      onBlur={handleBlur}
      id={id}
      type="number"
      placeholder=""
      error={errorMsg}
      {...fieldProps}
    />
  );
}

const PolarisFormikNumberField = props => (
  <Field {...props} component={NumberFieldWrapped} />
);

export default PolarisFormikNumberField;
