import React from "react";
import { ChoiceList } from "@shopify/polaris";

export const USE_DEFAULT_CHOICES = [
  { label: "Use default badge", value: true },
  { label: "Create custom badge", value: false }
];

export default function BadgeUseDefaultInput({ onChange, selected }) {
  return (
    <ChoiceList
      choices={USE_DEFAULT_CHOICES}
      name="use-default"
      onChange={onChange}
      selected={selected}
      titleHidden={true}
    />
  );
}
