// @flow
import React, { Component } from "react";
import { Popover, ActionList, Button } from "@shopify/polaris";
import {
  CircleDisableMinor,
  CircleTickOutlineMinor,
  DeleteMinor,
  HorizontalDotsMinor
} from "@shopify/polaris-icons";

import { NavigationContext } from "contexts/Navigation";

import type { Offer } from "types/flow-types/offers";

type OffersActionsMenuProps = {
  offer: Offer,
  setOfferActive: (number, boolean) => void,
  onDelete: () => void,
  itemDeactivated: boolean
};

type OffersActionsMenuState = {
  active: boolean
};

export default class OffersActionsMenu extends Component<
  OffersActionsMenuProps,
  OffersActionsMenuState
> {
  static contextType = NavigationContext;

  state = { active: false };

  editOfferAction = () => {
    this.context.goTo(`/offers/${this.props.offer.id}`);
    this.togglePopover();
  };

  toggleActiveAction = () => {
    const { offer, setOfferActive } = this.props;
    this.togglePopover();
    setOfferActive(offer.id, !offer.active);
  };

  deleteOfferAction = () => {
    this.togglePopover();
    this.props.onDelete();
  };

  togglePopover = () => {
    this.setState(({ active }) => ({
      active: !active
    }));
  };

  render() {
    const { itemDeactivated, offer } = this.props;

    const activator = (
      <Button
        plain
        monochrome
        size="slim"
        onClick={this.togglePopover}
        icon={HorizontalDotsMinor}
        accessibilityLabel="More actions"
      />
    );

    const actionListItems = [
      { content: "Delete", icon: DeleteMinor, onAction: this.deleteOfferAction }
    ];
    if (!itemDeactivated) {
      actionListItems.unshift({
        content: offer.active ? "Disable" : "Enable",
        icon: offer.active ? CircleDisableMinor : CircleTickOutlineMinor,
        onAction: this.toggleActiveAction
      });
    }

    return (
      <Popover
        active={this.state.active}
        activator={activator}
        onClose={this.togglePopover}
      >
        <ActionList items={actionListItems} />
      </Popover>
    );
  }
}
