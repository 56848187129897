import React from "react";
import { Card, Heading, Layout } from "@shopify/polaris";
import AccordionItem from "components/AccordionItem";
import ReportingChartContainer from "./ReportingChartContainer/ReportingChartContainer";

export default function ReportingChartAccordion() {
  return (
    <Layout.Section>
      <Card>
        <AccordionItem
          heading={<Heading>Offer analytics</Heading>}
          id="analytics"
          index={0}
        >
          <ReportingChartContainer />
        </AccordionItem>
      </Card>
    </Layout.Section>
  );
}
