// @flow

import React from "react";
import { Stack, SkeletonBodyText } from "@shopify/polaris";

export default function ChartTabsLoading() {
  return (
    <div className="ChartTabs ChartTabs--loading">
      <Stack>
        {[1, 2, 3].map(i => (
          <div key={i} className="ChartTab__Tab ChartTab__Tab--loading">
            <SkeletonBodyText lines={2} />
          </div>
        ))}
      </Stack>
    </div>
  );
}
