import { NULL_OFFER_TYPE, OFFER_TYPES } from "types/constants/offers";
import { getIn } from "formik";

// The database stores types as "Buy X Get Y" or "Volume Discount", but we'd
// like to have them normalized to "bogo" or "volume" for sanity's sake
// This should only be for UI use and never persisted to the database
// In future we should consider adding a `type_slug` col to the offers
// table and use that, but for now this will do
export function getTypeSlug(type) {
  if (!type) return NULL_OFFER_TYPE.typeSlug;
  const match = OFFER_TYPES.find(obj => obj.type === type);
  if (match === undefined) {
    throw new Error(`Couldn't find an offer type with type ${type}`);
  }
  return match.typeSlug;
}

export function getOfferType(typeSlug) {
  if (!typeSlug) return NULL_OFFER_TYPE;
  const match = OFFER_TYPES.find(obj => obj.typeSlug === typeSlug);
  if (match === undefined) {
    throw new Error(`Couldn't find an offer type with typeSlug ${typeSlug}`);
  }
  return match;
}

export function drawName(name, type) {
  return name ? name : OFFER_TYPES.find(offer => offer.type === type).title;
}

export function offerDefaults(typeSlug) {
  return getOfferType(typeSlug).defaultFields;
}

export function offerDefaultsFromType(type) {
  return offerDefaults(getTypeSlug(type));
}

// We need to store the offer values in the edit form state
export const offerForFormPersistence = (offer = "null") => ({
  details: { ...offer.details },
  notifications: { ...offer.notifications },
  applicability: { ...offer.applicability }
});

export const unavailableOffers = () => {
  return ["Credit"];
};

export const deactivatedPremiumOffers = appPlanName => {
  if (appPlanName === "fixedWithoutPPU") {
    return ["PostPurchaseOffer"];
  }

  return [];
};

const offersExcept = (offers, type) => {
  return offers.filter(offer => offer.type !== type);
};

export const nonPostPurchaseOffers = offers =>
  offersExcept(offers, "PostPurchaseOffer");

export const variantsSelected = form => {
  const buy_from =
    getIn(form, "values.details.buy_from.product_variants", []).length !== 0;
  const get_from =
    getIn(form, "values.details.get_from.product_variants", []).length !== 0;
  return buy_from || get_from;
};
