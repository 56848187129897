import React, { useContext, useEffect } from "react";
import {
  Caption,
  Card,
  FormLayout,
  Subheading,
  TextStyle
} from "@shopify/polaris";
import { getIn } from "formik";

import BadgePreview from "components/badges/BadgePreview";
import BadgeColorInputs from "components/badges/BadgeColorInputs";
import BadgeTextInput from "components/badges/BadgeTextInput";
import defaultGlobalBadge from "types/constants/default-badge";
import { ShopContext } from "contexts/Shop";
import BadgeTypeInput from "components/badges/BadgeTypeInput";
import BadgePositionInput from "components/badges/BadgePositionInput";
import BadgeBorderInput from "components/badges/BadgeBorderInput";

export default function AppearanceBadgeSection({ form }) {
  const shop = useContext(ShopContext);
  const { setFieldValue } = form;
  const defaultBadgeStyles = getIn(
    shop.customizations,
    "css.badge",
    defaultGlobalBadge.css
  );
  const defaultBadgeType = getIn(
    shop,
    "customizations.badge.type",
    defaultGlobalBadge.type
  );
  const defaultBadgePosition = getIn(
    shop,
    "customizations.badge.position",
    defaultGlobalBadge.position
  );
  const defaultBadgeBorder = getIn(
    shop,
    "customizations.badge.border",
    defaultGlobalBadge.border
  );
  const defaultBadgeText = getIn(
    shop.customizations,
    "badge.badge_text",
    defaultGlobalBadge.badge_text
  );
  const badgeText = getIn(
    form,
    "values.customizations.badge.badge_text",
    defaultBadgeText
  );
  const badgeType = getIn(
    form,
    "values.customizations.badge.type",
    defaultBadgeType
  );
  const badgePosition = getIn(
    form,
    "values.customizations.badge.position",
    defaultBadgePosition
  );
  const badgeBorder = getIn(
    form,
    "values.customizations.badge.border",
    defaultBadgeBorder
  );

  const badgeStyles = getIn(
    form,
    "values.customizations.css.badge",
    defaultBadgeStyles
  );

  useEffect(() => {
    setFieldValue("customizations.badge.type", badgeType);
    setFieldValue("customizations.badge.position", badgePosition);
    setFieldValue("customizations.badge.border", badgeBorder);
    setFieldValue("customizations.badge.badge_text", badgeText);
    setFieldValue("customizations.css.badge", badgeStyles);
  }, [setFieldValue]);

  return (
    <Card>
      <div className="AppearancePreview">
        <div className="ImagePreview">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <div style={{ width: 400, padding: 20 }}>
              <BadgePreview
                badgeStyles={badgeStyles}
                badgeText={badgeText}
                badgeType={badgeType}
                badgePosition={badgePosition}
                badgeBorder={badgeBorder}
              />
            </div>
          </div>
        </div>
        <Card.Section>
          <FormLayout>
            <Subheading>Sale Badges</Subheading>
            <FormLayout.Group>
              <BadgeTextInput helpText="" />
              <BadgeTypeInput />
              <BadgeColorInputs form={form} />
              <BadgePositionInput />
              <BadgeBorderInput />
            </FormLayout.Group>
          </FormLayout>
          <TextStyle variation="subdued">
            <Caption>
              *Use these settings to adjust your default sale badge.
            </Caption>
          </TextStyle>
        </Card.Section>
      </div>
    </Card>
  );
}
