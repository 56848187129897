// @flow
import { BILLING_TABLE_LABELS } from "lib/constants";
import { format } from "lib/dateHelpers";

import type {
  UsageTiers,
  CurrentBillingPeriodData,
  DataTableData
} from "types/flow-types/billing";

export const formatCurrency = (amount: number, digits: number = 2) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: digits
  }).format(amount);
};

export const formatUsageDataForTable = (
  billingPeriodData: CurrentBillingPeriodData
): DataTableData => {
  const basePrice = billingPeriodData.basePrice;

  const basePriceRow: Array<Array<string>> = [
    [
      `${formatCurrency(Number(basePrice), 0)}/month`,
      "-",
      formatCurrency(Number(basePrice))
    ]
  ];

  const tableLabels = BILLING_TABLE_LABELS;

  const tierRows = orderTiersByKey(billingPeriodData.tierUsage).map(
    ({ label, max, appliedCount, price }: any) => [
      tableLabels[label],
      max ? `${appliedCount}/${max}` : appliedCount,
      formatCurrency(price)
    ]
  );

  const dataTableRows = basePriceRow.concat(tierRows);
  const dataTableHeadings = ["", "Orders", "Charges"];
  const dataTableTotals = [
    "",
    billingPeriodData.totals.totalOrders,
    formatCurrency(billingPeriodData.totals.totalCharged)
  ];

  return {
    dataTableHeadings,
    dataTableTotals,
    dataTableRows
  };
};

export const orderTiersByKey = (tiers: UsageTiers): Array<any> =>
  Object.entries(tiers)
    .sort((a, b) => Number(a[0]) - Number(b[0]))
    .map(([_key, tier]) => tier);

export const periodDate = (date: string) => format(date, "MMMM d");

export const timestamp = (timestamp: ?string) => {
  if (!timestamp) return null;
  return format(timestamp, "MMMM d, h:mm a");
};
