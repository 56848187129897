import React from "react";
import { Card, Stack, TextStyle, ChoiceList } from "@shopify/polaris";

const visiblityChoices = [
  { label: "Enabled", value: true },
  { label: "Disabled", value: false }
];

export const VisibilitySection = ({ form }) => (
  <Card.Section title="Visibility">
    <Stack vertical spacing="tight">
      <ChoiceList
        title="Visibility"
        titleHidden
        name="active"
        choices={visiblityChoices}
        selected={[form.values.active]}
        onChange={([value]) => {
          form.setFieldValue("active", value);
        }}
      />
      <TextStyle variation="subdued">
        If your offer is disabled, it will be hidden from all customers and will
        not be applied to any orders. You can still preview this offer from your
        dashboard once you save it.
      </TextStyle>
    </Stack>
  </Card.Section>
);

export default VisibilitySection;
