// @flow
import { addDays, diffCalendarDays } from "./dateHelpers";
import { DEFAULT_TRIAL_DAYS } from "types/constants/shop.js";

export function getTrialDaysLeft(
  // This function returns the total number of days left in the trial.
  // it counts partial days as a whole day.
  createdDate: Date,
  trialLength: number
): number {
  const trialEndDate = addDays(createdDate, trialLength);
  return diffCalendarDays(new Date(), trialEndDate);
}

export function getTrialBannerState(trialDays: number, trialDaysLeft: number) {
  const extendedTrial = trialDaysLeft > parseInt(DEFAULT_TRIAL_DAYS);

  const days = trialDaysLeft === 1 ? "day" : "days";
  let status = "info";
  const trialText = extendedTrial
    ? "special extended free trial"
    : "free trial";

  let heading = `${trialDaysLeft} ${days} to go on your ${trialText}`;

  if (trialDaysLeft < 4) {
    status = "warning";
  }

  if (trialDaysLeft <= 0) {
    heading = `Your free trial is over`;
    status = "critical";
  }

  return {
    status,
    heading
  };
}
