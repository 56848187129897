// @flow

import axios from "./authedAxios";
import { API_ORIGIN } from "lib/constants";
import { reportError } from "lib/errorHelpers";

export async function getScopesOAuthURL() {
  try {
    const { data } = await axios.get(`${API_ORIGIN}/api/scopes/oauth-url`);
    return data.url;
  } catch (error) {
    reportError(error);
    return { error };
  }
}
