// @flow

import React from "react";
import {
  Card,
  Stack,
  SkeletonDisplayText,
  SkeletonBodyText
} from "@shopify/polaris";

export default function ChartFooterLoading() {
  return (
    <Card.Section subdued>
      <div style={{ minHeight: "3.6rem", margin: "0.4rem 0" }}>
        <Stack alignment="center">
          <div style={{ width: "11rem" }}>
            <SkeletonDisplayText size="small" />
          </div>
          <div style={{ width: "11rem" }}>
            <SkeletonBodyText lines={1} />
          </div>
        </Stack>
      </div>
    </Card.Section>
  );
}
