import React from "react";
import { Card, TextStyle, FormLayout } from "@shopify/polaris";

import { variantsSelected } from "lib/offerHelpers";
import offerPlaceholders from "content/offerPlaceholders";
import OptionalNumberField from "components/OptionalNumberField";
import PolarisFormikInventoryPicker from "components/PolarisFormikInventoryPicker";
import PolarisFormikDiscountSection from "components/PolarisFormikDiscountSection";
import OfferMessageSection from "components/OfferMessageSection";
import OfferSummarySection from "components/OfferSummarySection";
import OfferConfigurationSection from "components/OfferConfigurationSection";
import { HelpDocsLink } from "components/links";

export default function BulkSection(props) {
  const {
    form,
    moneyFormat,
    offerTypeSlug,
    multicurrencyWarningCard,
    redirectToAppearance
  } = props;

  const variantPresence = variantsSelected(form);

  const placeholderType = variantPresence ? "variant" : "nonVariant";

  return [
    <OfferConfigurationSection
      key="offer-configuration"
      offerTypeSlug={offerTypeSlug}
    >
      <Card>
        <Card.Section>
          <PolarisFormikInventoryPicker
            label="Choose items to discount"
            name="details.buy_from"
            qtyName="details.buy_qty"
            form={form}
          />
        </Card.Section>
        <Card.Section>
          <FormLayout>
            <PolarisFormikDiscountSection
              name="details.price"
              form={form}
              moneyFormat={moneyFormat}
              multicurrencyWarningCard={multicurrencyWarningCard}
            />
            <OptionalNumberField
              form={form}
              label="Apply an offer limit"
              name="details.use_max"
              min={1}
            />

            <TextStyle variation="subdued">
              <b>Tip:</b> if the offer is only for some specific products, also
              add an Upsell offer for the same products, so customers can Add to
              cart quicker. <HelpDocsLink helpDocsType={offerTypeSlug} />
            </TextStyle>
          </FormLayout>
          <OfferSummarySection form={form} moneyFormat={moneyFormat} />
        </Card.Section>
      </Card>
    </OfferConfigurationSection>,
    <OfferMessageSection
      key="offer-message"
      offerTypeSlug={offerTypeSlug}
      redirectToAppearance={redirectToAppearance}
      variantPresence={variantPresence}
      fields={[
        {
          label: "Initial message",
          name: "notifications.message",
          placeholder: offerPlaceholders.bulk.initial[placeholderType]
        },
        {
          label: "Success message",
          name: "notifications.message_after",
          placeholder: offerPlaceholders.bulk.success
        }
      ]}
    />
  ];
}
