import React from "react";
import { Card, TextContainer, FormLayout } from "@shopify/polaris";

import { variantsSelected } from "lib/offerHelpers";
import PolarisFormikInventoryPicker from "components/PolarisFormikInventoryPicker";
import PolarisFormikDiscountSection from "components/PolarisFormikDiscountSection";
import OfferMessageSection from "components/OfferMessageSection";
import OfferSummarySection from "components/OfferSummarySection";
import OfferShippingSection from "components/OfferShippingSection";
import OfferConfigurationSection from "components/OfferConfigurationSection";
import { HelpDocsLink } from "components/links";
import OptionalNumberField from "components/OptionalNumberField";
import copy from "content/copy.json";

export default function PostPurchaseOfferSection(props) {
  const {
    form,
    moneyFormat,
    offerTypeSlug,
    multicurrencyWarningCard,
    redirectToAppearance
  } = props;
  const variantPresence = variantsSelected(form);
  const { options, placeholder } = copy.offers.ppu.offerMessaging;
  const ppuOfferMessagingOptions = Object.keys(options).map(key => ({
    label: options[key],
    value: options[key]
  }));

  return [
    <OfferConfigurationSection
      key="offer-configuration"
      offerTypeSlug={offerTypeSlug}
    >
      <Card>
        <Card.Section title="Trigger">
          <FormLayout>
            <p>
              Choose products or collections that trigger this offer when
              included in the original purchase.
            </p>

            <PolarisFormikInventoryPicker form={form} name="details.buy_from" />
          </FormLayout>
        </Card.Section>

        <Card.Section title="Offer">
          <TextContainer>
            <p>
              Choose products to promote post-purchase when trigger items are
              included in the original purchase. For improved conversion rates,
              choose products complementary to your selected trigger products.{" "}
              <HelpDocsLink helpDocsType={offerTypeSlug} />
            </p>
            <PolarisFormikInventoryPicker form={form} name="details.get_from" />
          </TextContainer>
        </Card.Section>

        <Card.Section>
          <FormLayout>
            <PolarisFormikDiscountSection
              name="details.price"
              form={form}
              moneyFormat={moneyFormat}
              multicurrencyWarningCard={multicurrencyWarningCard}
            />

            <OptionalNumberField
              form={form}
              label="Apply an offer limit"
              name="details.use_max"
              min={1}
            />
          </FormLayout>

          <OfferSummarySection form={form} moneyFormat={moneyFormat} />
        </Card.Section>
      </Card>
    </OfferConfigurationSection>,
    <OfferShippingSection
      key="offer-shipping"
      offerTypeSlug={offerTypeSlug}
      form={form}
      moneyFormat={moneyFormat}
    />,
    <OfferMessageSection
      key="offer-message"
      offerTypeSlug={offerTypeSlug}
      redirectToAppearance={redirectToAppearance}
      variantPresence={variantPresence}
      fields={[
        {
          label: "Initial message",
          name: "notifications.message",
          placeholder: placeholder,
          options: ppuOfferMessagingOptions
        }
      ]}
    />
  ];
}
