import { useEffect, useState } from "react";
import { fetchCurrentUsageChargesForPercentageVolumetricPlan } from "api/billing";
import { CurrentUsageChargedData } from "types/percentage-volumetric-billing";

const useFetchUsageChargesData = () => {
  const [currentUsageCharges, setCurrentUsageCharges] =
    useState<CurrentUsageChargedData>({} as CurrentUsageChargedData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    async function fetchUsageChargesData() {
      const { data, error } =
        await fetchCurrentUsageChargesForPercentageVolumetricPlan();
      setCurrentUsageCharges(data);
      setError(error);
      setLoading(false);
    }

    fetchUsageChargesData();
  }, []);

  return { currentUsageCharges, error, loading };
};

export default useFetchUsageChargesData;
