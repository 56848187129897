import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { NavigationContext } from "contexts/Navigation";
import copy from "content/copy.json";

const { accountCallToActionContent } = copy.freePlanBanners;

export default function bannerAction() {
  const { pathname } = useLocation();
  const accountCallToAction = !pathname.startsWith("/account");
  if (accountCallToAction) {
    const navigation = useContext(NavigationContext);

    return {
      content: accountCallToActionContent,
      onAction: () => navigation.goTo("/account")
    };
  }
}
