import { useState } from "react";
import { composeGid } from "@shopify/admin-graphql-api-utilities";

const useInventoryPickerSelections = () => {
  const [collectionSelectionIds, setCollectionSelectionIds] = useState([]);
  const [productSelectionIds, setProductSelectionIds] = useState([]);
  const [variantSelectionIds, setVariantSelectionIds] = useState([]);

  const allSelections = {
    Collection: {
      ids: collectionSelectionIds,
      setIds: setCollectionSelectionIds
    },
    Product: { ids: productSelectionIds, setIds: setProductSelectionIds },
    Variant: { ids: variantSelectionIds, setIds: setVariantSelectionIds }
  };

  const removeSelectionIds = (item, type) => {
    if (type === "Variant") {
      const gid = composeGid("Product", item.product_id);

      allSelections[type].setIds(oldVariantSelectionIds => {
        const newVariantSelectionIds = oldVariantSelectionIds.filter(
          product => product.id !== gid
        );

        return newVariantSelectionIds;
      });
    } else {
      const gid = composeGid(type, item.id);
      const index = allSelections[type].ids.findIndex(item => item.id === gid);

      if (index < 0) {
        return;
      }

      allSelections[type].setIds(oldSelectionIds => {
        const newSelectionIds = [...oldSelectionIds];
        newSelectionIds.splice(index, 1);

        return newSelectionIds;
      });
    }
  };

  const handleAllCollectionFetch = collections => {
    const newCollectionSelectionIds = collections.map(c => ({
      id: composeGid("Collection", c.id)
    }));

    setCollectionSelectionIds(newCollectionSelectionIds);
  };

  return {
    collectionSelectionIds,
    handleAllCollectionFetch,
    productSelectionIds,
    removeSelectionIds,
    setCollectionSelectionIds,
    setProductSelectionIds,
    setVariantSelectionIds,
    variantSelectionIds
  };
};

export default useInventoryPickerSelections;
