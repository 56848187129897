// @flow
import axios from "./authedAxios";

import { reportError } from "lib/errorHelpers";
import { prepareFormData } from "./offersSanitization";
import { API_ORIGIN } from "lib/constants";

import type { Offer } from "types/flow-types/offers";

export const OFFERS_API_PATH = "/api/offers";
export const OFFERS_API_BASE = `${API_ORIGIN}${OFFERS_API_PATH}`;
export const offersUrl = (path: ?number) =>
  [OFFERS_API_BASE, path].filter(Boolean).join("/");

export type MaybeOfferResponse = {
  data?: Offer,
  error?: Error
};

export type MaybeFetchOffersResponse = {
  data?: Array<Offer>,
  error?: Error
};

export type MaybeDeleteOfferResponse = {
  data?: { rowCount: 1 },
  error?: Error
};

export async function createOffer(): Promise<MaybeOfferResponse> {
  try {
    const { data } = await axios.post(offersUrl());
    return { data };
  } catch (e) {
    const error = new Error(e);
    reportError(error);
    return { error };
  }
}

export async function fetchOffer(id: number): Promise<MaybeOfferResponse> {
  try {
    const { data } = await axios.get(offersUrl(id));
    return { data: data[0] };
  } catch (e) {
    const error = new Error(e);
    reportError(error);
    return { error };
  }
}

export async function fetchOffers(): Promise<MaybeFetchOffersResponse> {
  try {
    const { data } = await axios.get(offersUrl());
    return { data };
  } catch (e) {
    const error = new Error(e);
    reportError(error);
    return { error };
  }
}

export async function deleteOffer(
  id: number
): Promise<MaybeDeleteOfferResponse> {
  try {
    const { data } = await axios.delete(offersUrl(id));
    return { data };
  } catch (e) {
    const error = new Error(e);
    reportError(error);
    return { error };
  }
}

export async function patchOffer(
  id: number,
  formData: Object,
  moneyFormat: string = "{{amount}}",
  appPlan: string
): Promise<MaybeOfferResponse> {
  const preparedData = prepareFormData(formData, moneyFormat);

  try {
    const { data } = await axios.patch(offersUrl(id), {
      id,
      appPlan,
      ...preparedData
    });

    return { data };
  } catch (e) {
    const error = new Error(e);
    reportError(error);
    return { error };
  }
}
