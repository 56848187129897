import React from "react";
import PropTypes from "prop-types";
import { Popover, ColorPicker } from "@shopify/polaris";
import { hexToHsba, hsbaToHex } from "lib/colorHelpers";

import "./InlineColorPicker.css";

const InlineColorPicker = props => {
  // update value down from color picker
  const handleColorChange = color => {
    props.onChange(hsbaToHex(color));
  };

  const {
    popoverOpen,
    inputField,
    togglePopover,
    field: { value },
    allowAlpha
  } = props;

  return (
    <div className="InlineColorPicker__control">
      <Popover
        active={popoverOpen}
        activator={inputField}
        onClose={togglePopover}
        sectioned
      >
        <ColorPicker
          onChange={handleColorChange}
          color={hexToHsba(value)}
          allowAlpha={allowAlpha}
        />
      </Popover>
    </div>
  );
};

// TODO: Flow can/should do this
InlineColorPicker.propTypes = {
  onChange: PropTypes.func.isRequired
};

InlineColorPicker.displayName = "InlineColorPicker";

export default InlineColorPicker;
