import {
  fetchOffers,
  patchOffer,
  deleteOffer,
  createOffer
} from "api/offersApi";

import { FETCH_STATES, STATUSES } from "lib/constants";
import { offerDefaults } from "lib/offerHelpers";
import { trackEvent } from "lib/analytics";
import { setCookie } from "lib/cookie";

export const OFFERS_EMPTY_STATE = {
  data: [],
  fetchState: FETCH_STATES.NULL,
  status: STATUSES.READY
};

export const CURRENT_OFFER_EMPTY_STATE = {
  data: null,
  fetchState: FETCH_STATES.NULL,
  status: STATUSES.READY
};

const setUpdating = (store, state, status) => {
  store.setState({
    offers: {
      ...state.offers,
      fetchState: FETCH_STATES.STARTED,
      status: status
    }
  });
};

const handleError = (store, stateKey, { message }) => {
  const currentState = store.getState()[stateKey];
  return {
    [stateKey]: {
      ...currentState,
      fetchState: FETCH_STATES.ERROR
    },
    toast: message && { content: message, error: true }
  };
};

export const offerActions = store => ({
  async fetchOffers(state) {
    setUpdating(store, state, STATUSES.READING);
    const { error, data } = await fetchOffers();
    if (error) {
      const message = "We're sorry, there was an error fetching your offers.";
      return handleError(store, "offers", { message });
    }
    return {
      offers: {
        ...store.getState().offers,
        data,
        fetchState: FETCH_STATES.SUCCESS
      }
    };
  },

  async setOfferActive(state, offerID, isActive) {
    setUpdating(store, state, STATUSES.UPDATING);

    const { error } = await patchOffer(offerID, {
      id: offerID,
      active: isActive
    });

    if (error) {
      const message = "We're sorry, there was an error fetching your offers.";
      return handleError(store, "offers", { message });
    }
    const offers = store.getState().offers.data.map(offer => {
      return offer.id === offerID ? { ...offer, active: isActive } : offer;
    });

    return {
      offers: {
        ...store.getState().offers,
        data: offers,
        fetchState: FETCH_STATES.SUCCESS
      }
    };
  },

  async patchOffer(state, offerID, offer, onSuccess) {
    const moneyFormat = state.shop.data.settings.money_format;

    setUpdating(store, state, STATUSES.UPDATING);
    const { error, data } = await patchOffer(
      offerID,
      offer,
      moneyFormat,
      state.shop.data.app_plan?.name
    );
    if (error) {
      const message = "We're sorry, there was a problem saving your offer";
      return handleError(store, "offers", { message });
    }
    const offers = store
      .getState()
      .offers.data.map(offer => (offer.id === offerID ? data : offer));

    store.setState({
      offers: {
        ...store.getState().offers,
        data: offers,
        fetchState: FETCH_STATES.SUCCESS
      },
      toast: {
        content: "Saved successfully"
      }
    });

    if (onSuccess) onSuccess();
  },

  async createOffer(state, values, onSuccess) {
    const moneyFormat = state.shop.data.settings.money_format;
    const firstOffer = state.shop.data.events.first_offer_created !== true;

    setUpdating(store, state, STATUSES.UPDATING);

    store.setState({
      shop: {
        data: {
          ...state.shop.data,
          events: {
            ...state.shop.data.events,
            first_offer_created: true
          }
        }
      }
    });

    // createOffer API call can only create empty offers
    const { error, data } = await createOffer();

    const message = "We're sorry, there was a problem creating your offer";

    if (error) {
      return handleError(store, "offers", { message });
    }

    const { error: patchError } = await patchOffer(
      data.id,
      values,
      moneyFormat,
      state.shop.data.app_plan?.name
    );

    if (patchError) {
      return handleError(store, "offers", { message });
    }

    trackEvent("Offer Created", { values });
    store.setState({ toast: { content: "Saved successfully" } });

    if (firstOffer) {
      setCookie("firstOfferCreated", true, 1440);
    }

    if (onSuccess) onSuccess(data.id);
  },

  async deleteOffer(state, offerId) {
    setUpdating(store, state, STATUSES.DELETING);
    let { error } = await deleteOffer(offerId);

    if (error) {
      const message = "We're sorry, there was a problem creating your offer";
      return handleError(store, "offers", { message });
    }

    const offers = store
      .getState()
      .offers.data.filter(offer => offer.id !== offerId);

    store.setState({
      offers: {
        ...store.getState().offers,
        data: offers,
        fetchState: FETCH_STATES.SUCCESS
      },
      toast: {
        content: "Offer deleted"
      }
    });
  },

  async setCurrentOffer(state, offerId) {
    store.setState({ currentOffer: CURRENT_OFFER_EMPTY_STATE });

    // set current offer to empty/default state
    if (!offerId) {
      return {
        currentOffer: {
          ...state.currentOffer,
          fetchState: FETCH_STATES.SUCCESS,
          data: { ...offerDefaults() }
        }
      };
    }

    const offer = state.offers.data.find(offer => offer.id === offerId);

    // requested current found in the store
    if (offer) {
      return {
        currentOffer: {
          ...store.getState().currentOffer,
          fetchState: FETCH_STATES.SUCCESS,
          data: offer
        }
      };
    }

    // if the offer isn't in the store, fetch from DB
    store.setState({
      currentOffer: {
        ...store.getState().currentOffer,
        fetchState: FETCH_STATES.STARTED,
        status: STATUSES.READING
      }
    });

    const { error, data } = await fetchOffers();

    const message = "We're sorry, there was a problem finding that offer";

    // Error fetching from DB
    if (error) {
      return handleError(store, "currentOffer", { message });
    }

    const fetchedOffer = data.find(offer => offer.id === offerId);

    // Offer not found in DB
    if (!fetchedOffer || fetchedOffer == null) {
      return handleError(store, "currentOffer", { message });
    }

    // if the offer was found in the DB
    return {
      offers: {
        data
      },
      currentOffer: {
        ...store.getState().currentOffer,
        data: fetchedOffer,
        fetchState: FETCH_STATES.SUCCESS
      }
    };
  }
});
