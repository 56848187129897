import React from "react";
import { storeConnect } from "appStore";
import { shopActions } from "store/shopStore";
import { ShopProvider } from "contexts/Shop";
import { Shop, ShopStateData, SetShopEvent } from "types/shop";
import FixedAndVolumetricContainer from "./fixed-and-volumetric/FixedAndVolumetricContainer";
import { BillingType } from "types/app-plan";
import PercentageVolumetricContainer from "./percentage-volumetric/PercentageVolumetricContainer";

type Props = {
  setEvent: SetShopEvent;
  shop: Shop;
};

const AccountRoute = ({ shop, setEvent }: Props) => {
  const billingType = shop.app_plan.billingType;
  return (
    <>
      {billingType === BillingType.PercentageVolumetric ||
      billingType === BillingType.Free ? (
        <ShopProvider value={shop}>
          <PercentageVolumetricContainer setEvent={setEvent} />
        </ShopProvider>
      ) : (
        <FixedAndVolumetricContainer shop={shop} setEvent={setEvent} />
      )}
    </>
  );
};

const mapStateToProps = ({ shop }: ShopStateData) => {
  return {
    shop: shop.data
  };
};

export default storeConnect(AccountRoute, mapStateToProps, shopActions);
