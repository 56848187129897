// @flow

import React, { useContext } from "react";
import { TitleBar } from "@shopify/app-bridge-react";
import { NavigationContext } from "contexts/Navigation";

const CommonTitleBar = () => {
  const navigation = useContext(NavigationContext);
  const navigateToNewOffer = () => navigation.goTo("/offers/new");

  const actionGroups = [
    {
      content: "Settings",
      onAction: () => navigation.goTo("/settings")
    },
    {
      content: "Appearance",
      onAction: () => navigation.goTo("/appearance")
    },
    {
      content: "Feature Requests",
      onAction: () => navigation.goTo("/feature-requests")
    },
    {
      content: "Account",
      onAction: () => navigation.goTo("/account")
    }
  ];

  return (
    <TitleBar
      primaryAction={{
        content: "Create special offer",
        onAction: navigateToNewOffer
      }}
      actionGroups={[
        {
          title: "Menu",
          actions: actionGroups
        }
      ]}
    />
  );
};

export default CommonTitleBar;
