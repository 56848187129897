import React, { useContext, useEffect, useState } from "react";
import { Banner, Layout } from "@shopify/polaris";
import { IndexSkeletonPage } from "components/skeletons";
import { NavigationContext } from "contexts/Navigation";
import { storeConnect } from "appStore";
import { createRecurringCharge } from "api/recurring-charges";
import { Shop } from "types/shop";
import { BillingType } from "types/app-plan";

import PROMOS from "types/constants/promos";

type RecurringChargesResponse = {
  data: { redirectUrl: string };
  error: Error;
};

interface Props {
  shop: Shop;
}

function ThirtyDaysFreePromo({ shop }: Props) {
  const navigation = useContext(NavigationContext);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (
      shop.subscription_activated_date &&
      shop.app_plan.billingType !== BillingType.Free
    ) {
      navigation.goTo("/");
      return;
    }

    async function createCharge() {
      const response = (await createRecurringCharge({
        promoCode: PROMOS.THIRTY_DAYS_FREE.CODE
      })) as RecurringChargesResponse;
      if (response.error) {
        setError(true);
      } else {
        navigation.redirect(response.data.redirectUrl);
      }
    }

    createCharge();
  }, []);

  return error ? <Error /> : <IndexSkeletonPage />;
}

function Error() {
  return (
    <Layout>
      <Banner
        status="critical"
        title="We're sorry, an error has occurred. Please refresh the page to try again."
      />
    </Layout>
  );
}

function mapStateToProps({ shop }: { shop: { data: Shop } }) {
  return {
    shop: shop.data
  };
}

export default storeConnect(ThirtyDaysFreePromo, mapStateToProps);
