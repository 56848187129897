import React, { useContext } from "react";
import { getIn, useFormikContext } from "formik";
import { Card, Stack, ChoiceList, TextStyle } from "@shopify/polaris";

import BadgePickerCustomStyles from "routes/offers/BadgePickerCustomStyles";
import BadgePickerDefaultStyles from "routes/offers/BadgePickerDefaultStyles";
import { ShopContext } from "contexts/Shop";
import defaultGlobalBadge from "types/constants/default-badge";
import { HelpDocsLink } from "components/links";

const ENABLE_CHOICES = [
  { label: "Enabled", value: true },
  { label: "Disabled", value: false }
];

export const BadgesSection = () => {
  const form = useFormikContext();
  const { values, setFieldValue } = form;

  const shop = useContext(ShopContext);

  // ch123217 Badges Per Offer
  // using getIn here so all of our offerTypeSection tests don't fail
  const releaseEnableBadgesPerOffer = getIn(
    shop,
    "flags.releaseEnableBadgesPerOffer",
    false
  );

  const badgesEnabled = getIn(values.customizations.badge, "enabled") || false;
  const useDefault = getIn(values.customizations.badge, "use_default");
  const customOfferBadgeType = getIn(values.customizations.badge, "type");

  // TODO: refactor with getDefaultBadgeStyles in getOfferBadgeStyles.js
  const globalDefaultBadge = defaultGlobalBadge;
  const shopCustomizations = shop.customizations;
  const defaultBadgeType = getIn(
    shopCustomizations,
    "badge.type",
    globalDefaultBadge.type
  );
  const defaultBadgePosition = getIn(
    shopCustomizations,
    "badge.position",
    globalDefaultBadge.position
  );
  const defaultBadgeCSS = getIn(
    shopCustomizations,
    "css.badge",
    globalDefaultBadge.css
  );
  const defaultBadgeText = getIn(
    shopCustomizations,
    "badge.badge_text",
    globalDefaultBadge.badge_text
  );
  const defaultBadgeBorder = getIn(
    shopCustomizations,
    "badge.border",
    globalDefaultBadge.border
  );

  const handleUseDefaultToggle = useDefault => {
    if (!useDefault && !customOfferBadgeType) {
      initializeCustomBadgeFields();
    }

    setFieldValue("customizations.badge.use_default", useDefault);
  };

  const initializeCustomBadgeFields = () => {
    setFieldValue("customizations.badge.type", defaultBadgeType);
    setFieldValue("customizations.badge.position", defaultBadgePosition);
    setFieldValue("customizations.badge.border", defaultBadgeBorder);
    setFieldValue("customizations.css.badge", defaultBadgeCSS);

    if (!getIn(values.customizations.badge, "badge_text")) {
      setFieldValue("customizations.badge.badge_text", defaultBadgeText);
    }
  };

  const onUseDefaultChange = ([value]) => {
    handleUseDefaultToggle(value);
  };

  return (
    <>
      <Card.Section title="Sale Badges">
        <Stack vertical spacing="tight">
          <ChoiceList
            title="Sale Badges"
            titleHidden
            name="badges"
            choices={ENABLE_CHOICES}
            selected={[badgesEnabled]}
            onChange={([value]) => {
              setFieldValue("customizations.badge.enabled", value);
            }}
          />

          <p>
            <TextStyle variation="subdued">
              Sale badges are promotional icons that appear in the corner of
              product images. <HelpDocsLink helpDocsType="salesBadges" />
            </TextStyle>
          </p>
        </Stack>
      </Card.Section>

      {badgesEnabled && releaseEnableBadgesPerOffer && (
        <Card.Section>
          {useDefault ? (
            <BadgePickerDefaultStyles
              defaultBadgeStyles={defaultBadgeCSS}
              defaultBadgeType={defaultBadgeType}
              defaultBadgePosition={defaultBadgePosition}
              defaultBadgeBorder={defaultBadgeBorder}
              form={form}
              onUseDefaultChange={onUseDefaultChange}
            />
          ) : (
            <BadgePickerCustomStyles
              form={form}
              onUseDefaultChange={onUseDefaultChange}
            />
          )}
        </Card.Section>
      )}
    </>
  );
};

export default BadgesSection;
