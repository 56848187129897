// All offers share this general structure
const DEFAULT_OFFER_FIELDS = {
  name: "",
  active: true,
  type: "Buy X Get Y", // Default to BOGO
  title: "",
  example: "",
  outcome: "",
  outcomeIcon: "",
  details: {}, // Differs between offer types
  notifications: {}, // Differs between offer types
  applicability: {
    channels: ["online", "pos"],
    beginning_at: false,
    ending_at: false,
    only_customers_signed_in: false,
    only_customers_tagged_with: false,
    only_customers_tagged_with_tags: "",
    exclude_customers_tagged_with: false,
    exclude_customers_tagged_with_tags: "",
    only_special_link: false
  },
  customizations: {
    badge: {
      enabled: false,
      use_default: true
    }
  }
};

const OFFER_PRICE_DISCOUNT_TYPES = {
  FLAT: "flat",
  PERCENT: "percent",
  SUBTRACT: "subtract"
};

// location of the price object within the offers that include one.
// note that this does not include volume discounts.
const OFFER_PRICE_MAP = {
  bogo: "price",
  bulk: "price",
  bundle: "price",
  credit: "price",
  discount: "discount",
  goal: "discount"
};

// Common field groups
const DEFAULT_OFFER_FIELD_GROUPS = {
  price: { type: OFFER_PRICE_DISCOUNT_TYPES.SUBTRACT, amount: "", title: "" },
  inventory: {
    product_ids: [],
    product_titles: [],
    product_handles: [],
    collection_ids: [],
    collection_titles: [],
    collection_handles: [],
    product_variants: [],
    title: ""
  }
};

const OFFER_TYPE_KEYS = {
  BOGO: "Buy X Get Y",
  BULK: "Buy X For $",
  BUNDLE: "Bundle",
  CREDIT: "Credit",
  DISCOUNT: "Discount",
  GOAL: "Spend Amount",
  GIFT: "Free Gift",
  POST_PURCHASE_OFFER: "Post Purchase Offer",
  UPSELL: "Upsell",
  VOLUME: "Volume Discount"
};

// These are an array so that they show up in proper order in the edit form
const OFFER_TYPES = [
  {
    title: "Post-Purchase Upsell",
    type: "PostPurchaseOffer",
    typeSlug: "postPurchaseOffer",
    description:
      "Increase your average order value without compromising conversion",
    example: "You've unlocked a 50% discount!",
    outcome: "One-click product upsells",
    outcomeIcon: "AddProduct",
    color: "#AF1E84",
    defaultFields: Object.assign({}, DEFAULT_OFFER_FIELDS, {
      type: "PostPurchaseOffer",
      details: {
        use_max: 1,
        discount: Object.assign({}, DEFAULT_OFFER_FIELD_GROUPS.price, {}),
        buy_from: Object.assign({}, DEFAULT_OFFER_FIELD_GROUPS.inventory, {}),
        get_from: Object.assign({}, DEFAULT_OFFER_FIELD_GROUPS.inventory, {}),
        price: Object.assign({}, DEFAULT_OFFER_FIELD_GROUPS.price, {
          amount: 0
        }),
        shipping: { default: 0 }
      },
      notifications: { message: "" }
    })
  },
  {
    title: "Buy X Get Y (BOGO)",
    type: "Buy X Get Y", // The value in the database
    typeSlug: "bogo", // A more sane type to work with in the UI
    description: "Boost order value by promoting BOGO sales",
    example: "Buy jeans, get t-shirts for 50% off!",
    outcome: "Generate more revenue",
    outcomeIcon: "CartDown",
    color: "#FFB0B0",
    defaultFields: Object.assign({}, DEFAULT_OFFER_FIELDS, {
      type: "Buy X Get Y",
      details: {
        buy_qty: 0,
        get_qty: 0,
        use_max: null,
        show_upsell: true,
        buy_from: Object.assign({}, DEFAULT_OFFER_FIELD_GROUPS.inventory, {}),
        get_from: Object.assign({}, DEFAULT_OFFER_FIELD_GROUPS.inventory, {}),
        price: Object.assign({}, DEFAULT_OFFER_FIELD_GROUPS.price, {})
      },
      notifications: { message: "", message_gety: "", message_after: "" }
    })
  },
  {
    title: "Volume",
    type: "Volume Discount",
    typeSlug: "volume",
    description: "Vary pricing by number of items purchased",
    example: "Buy 3, get 10% off. Buy 4, get 20% off. Buy 5, get 30% off!",
    outcome: "Increase average order volume",
    outcomeIcon: "AddProduct",
    color: "#4BBBAF",
    defaultFields: Object.assign({}, DEFAULT_OFFER_FIELDS, {
      type: "Volume Discount",
      details: {
        tiers: [
          {
            quantity: 0,
            price: Object.assign({}, DEFAULT_OFFER_FIELD_GROUPS.price, {})
          }
        ],
        type: "variant",
        buy_from: Object.assign({}, DEFAULT_OFFER_FIELD_GROUPS.inventory, {})
      },
      notifications: {
        product_message: "",
        upsell_message:
          "Order at least {{minimum}} to get it at {{discounted_price}}",
        message_after: ""
      }
    })
  },
  {
    title: "Bundle",
    type: "Bundle",
    typeSlug: "bundle",
    description: "Give discount when certain products are purchased together",
    example: "Buy shoes, socks, and a belt together for $100!",
    outcome: "Highlight complementary products",
    outcomeIcon: "Confetti",
    color: "#CEAAFF",
    defaultFields: Object.assign({}, DEFAULT_OFFER_FIELDS, {
      type: "Bundle",
      details: {
        bundles: [
          {
            buy_from: Object.assign({}, DEFAULT_OFFER_FIELD_GROUPS.inventory, {
              quantity: 1
            })
          }
        ],
        show_upsell: true,
        price: Object.assign({}, DEFAULT_OFFER_FIELD_GROUPS.price, {})
      },
      notifications: { message: "", message_after: "" }
    })
  },
  {
    title: "Discount",
    type: "Discount",
    typeSlug: "discount",
    description: "Put any product or collection on sale",
    example: "Get shoes for 20% off!",
    outcome: "Create urgency",
    outcomeIcon: "Clock",
    color: "#077187",
    defaultFields: Object.assign({}, DEFAULT_OFFER_FIELDS, {
      type: "Discount",
      details: {
        discount: Object.assign({}, DEFAULT_OFFER_FIELD_GROUPS.price, {}),
        buy_from: Object.assign({}, DEFAULT_OFFER_FIELD_GROUPS.inventory, {})
      },
      notifications: { message: "", message_after: "" }
    })
  },
  {
    title: "Bulk",
    type: "Buy X For $",
    typeSlug: "bulk",
    description: "Discount items when customers meet a quantity threshold",
    example: "Buy 5 pairs of socks to get 10% off!",
    outcome: "Boost bulk order sales",
    outcomeIcon: "AddProduct",
    color: "#FF8F00",
    defaultFields: Object.assign({}, DEFAULT_OFFER_FIELDS, {
      type: "Buy X For $",
      details: {
        buy_qty: 0,
        use_max: null,
        buy_from: Object.assign({}, DEFAULT_OFFER_FIELD_GROUPS.inventory, {}),
        price: {
          type: OFFER_PRICE_DISCOUNT_TYPES.FLAT,
          amount: "",
          title: ""
        }
      },
      notifications: { message: "", message_after: "" }
    })
  },
  {
    title: "Upsell / Cross-sell",
    type: "Upsell",
    typeSlug: "upsell",
    description: "Promote additional or complementary products",
    example: "You got shoes, would you like socks too?",
    outcome: "Generate more revenue",
    outcomeIcon: "Confetti",
    color: "#FFC100",
    defaultFields: Object.assign({}, DEFAULT_OFFER_FIELDS, {
      type: "Upsell",
      details: {
        buy_from: Object.assign({}, DEFAULT_OFFER_FIELD_GROUPS.inventory, {}),
        get_from: Object.assign({}, DEFAULT_OFFER_FIELD_GROUPS.inventory, {})
      },
      notifications: { message: "" }
    })
  },
  {
    title: "Goal",
    type: "Spend Amount",
    typeSlug: "goal",
    description: "Discount purchase when customers reach a certain order value",
    example: "Spend $100, get 10% off!",
    outcome: "Increase order value",
    outcomeIcon: "CartDown",
    color: "#67C0FF",
    defaultFields: Object.assign({}, DEFAULT_OFFER_FIELDS, {
      type: "Spend Amount",
      details: {
        amount_greater_than: { amount: 0, title: "" },
        buy_from: Object.assign({}, DEFAULT_OFFER_FIELD_GROUPS.inventory, {}),
        discount: {
          type: OFFER_PRICE_DISCOUNT_TYPES.FLAT,
          amount: "",
          title: ""
        }
      },
      notifications: {
        message: "Spend {{amount}}, get {{discount}} off",
        upsell_message: "Spend {{difference}} more and get {{discount}} off"
      }
    })
  },
  {
    title: "Gift",
    type: "Free Gift",
    typeSlug: "gift",
    description: "Reward customers for purchases above a certain amount",
    example: "Spend $100, get a free pair of socks!",
    outcome: "Increase loyalty",
    outcomeIcon: "CartDown",
    color: "#8364FF",
    defaultFields: Object.assign({}, DEFAULT_OFFER_FIELDS, {
      type: "Free Gift",
      details: {
        cart_amount_greater_than: { amount: 0, title: "" },
        multiple: false,
        buy_from: Object.assign({}, DEFAULT_OFFER_FIELD_GROUPS.inventory, {})
      },
      notifications: { message_before: "", message_after: "" }
    })
  },
  {
    title: "Credit",
    type: "Credit",
    typeSlug: "credit",
    description: "Give store credit to incentivize future purchases.",
    example: "",
    outcome: "",
    outcomeIcon: "",
    color: "#43477B",
    defaultFields: Object.assign({}, DEFAULT_OFFER_FIELDS, {
      type: "Credit",
      details: {
        buy_qty: 0,
        buy_from: Object.assign({}, DEFAULT_OFFER_FIELD_GROUPS.inventory, {}),
        price: Object.assign({}, DEFAULT_OFFER_FIELD_GROUPS.price, {})
      },
      notifications: {}
    })
  }
];

// Primarily for dealing with freshly-created offers from the database
const NULL_OFFER_TYPE = {
  title: "No name",
  type: null,
  typeSlug: null,
  description: "Edit this offer",
  color: "#888",
  defaultFields: Object.assign({}, DEFAULT_OFFER_FIELDS, {
    title: "No name",
    type: null,
    typeSlug: null,
    description: "Edit this offer",
    color: "#888"
  })
};

module.exports = {
  DEFAULT_OFFER_FIELD_GROUPS: DEFAULT_OFFER_FIELD_GROUPS,
  DEFAULT_OFFER_FIELDS: DEFAULT_OFFER_FIELDS,
  NULL_OFFER_TYPE: NULL_OFFER_TYPE,
  OFFER_PRICE_DISCOUNT_TYPES: OFFER_PRICE_DISCOUNT_TYPES,
  OFFER_PRICE_MAP: OFFER_PRICE_MAP,
  OFFER_TYPE_KEYS: OFFER_TYPE_KEYS,
  OFFER_TYPES: OFFER_TYPES
};
