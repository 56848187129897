import React from "react";
import { Card, Banner, FormLayout } from "@shopify/polaris";
import AnnotatedSection from "components/AnnotatedSection";
import { helpDocsUrl } from "components/links";
import PolarisFormikNumberField from "components/PolarisFormikNumberField";
import { getCurrencySymbol } from "lib/currencyHelpers";
import { Field } from "formik";

export default function OfferShippingSection(props) {
  const { offerTypeSlug, form, moneyFormat } = props;

  return (
    <AnnotatedSection
      title="Shipping"
      linkUrl={helpDocsUrl(offerTypeSlug)}
      linkText="Learn more"
    >
      <Card>
        <Card.Section>
          <FormLayout>
            <Field
              allowFloat={true}
              component={PolarisFormikNumberField}
              form={form}
              label="Additional shipping charge"
              min={0}
              name="details.shipping.default"
              prefix={getCurrencySymbol(moneyFormat)}
            />

            <Banner status="info">
              At this time, it is only possible to include a fixed charge for
              shipping products that are added to the original order on the
              post-purchase page.
            </Banner>
          </FormLayout>
        </Card.Section>
      </Card>
    </AnnotatedSection>
  );
}
