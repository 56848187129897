// @flow
import React from "react";
import { Spinner } from "@shopify/polaris";

export default function ChartLineChartLoading() {
  return (
    <div className="Chart__LoadingContainer">
      <Spinner color="inkLightest" />
    </div>
  );
}
