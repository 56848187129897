import React from "react";
import { Stack, ButtonGroup } from "@shopify/polaris";

export default function GroupedPageActions({ children }) {
  return (
    <div className="Polaris-PageActions">
      <Stack distribution="trailing">
        <ButtonGroup>{children}</ButtonGroup>
      </Stack>
    </div>
  );
}
