// @flow

import React, { useContext } from "react";
import { Filters, ResourceList, ResourceItem } from "@shopify/polaris";

import { NavigationContext } from "contexts/Navigation";
import OffersListItemContainer from "components/OffersListItem/OffersListItemContainer";
import OffersListHeader from "components/OffersList/OffersListHeader";
import { numberWithCommas } from "../../lib/numberHelpers";
import { formatCurrency } from "../../lib/currencyHelpers";
import { REPORT_PERIOD_OPTIONS } from "lib/constants";
import makeOfferReportingProp from "./makeOfferReportingProp";
import { getRangeForSpan } from "components/reporting/chart-date-helpers";
import makeFormatters from "../reporting/reportingValueFormatters";
import offerIcon from "components/offerIcon/offerIcon";
import { getTypeSlug } from "lib/offerHelpers";

import type { DateRanges } from "lib/reporting-helpers";
import type { OffersReportsRequest } from "store/reportsStore";
import type { Offer, OfferType } from "types/flow-types/offers";

import "./OffersList.scss";

export const resourceName = {
  singular: "offer",
  plural: "offers"
};

export type ReportingColumnItem = {
  key: string,
  title: string,
  titleTooltip: () => string,
  valueTooltip: (value: number) => string,
  formatValue: (value: number) => string
};

export function makeReportingColumns({
  timespan,
  moneyFormat
}: {
  timespan: string,
  moneyFormat: string
}): Array<ReportingColumnItem> {
  const valueFormatters = makeFormatters(moneyFormat);
  return [
    {
      key: "orderTotal",
      title: "Sales",
      titleTooltip: () =>
        `Total value of orders completed with one or more special offers applied ${
          timespan.includes("past") ? "in " : ""
        }${timespan}`,
      formatValue: valueFormatters.orderTotal,
      valueTooltip: value => {
        const orderTotal = formatCurrency(numberWithCommas(value), moneyFormat);
        return `${orderTotal} in orders ${
          timespan.includes("past") ? "in " : ""
        }${timespan}.`;
      }
    },
    {
      key: "orderCount",
      title: "Orders",
      titleTooltip: () =>
        `Total number of orders completed with one or more special offers applied ${
          timespan.includes("past") ? "in " : ""
        }${timespan}`,
      valueTooltip: value =>
        `${value} orders ${timespan.includes("past") ? "in " : ""}${timespan}.`,
      formatValue: valueFormatters.orderCount
    },
    {
      key: "conversionRate",
      title: "Conv.",
      titleTooltip: () =>
        `Percentage of carts with special offers that have converted to sales, ${
          timespan.includes("past") ? "in " : ""
        }${timespan}.`,
      valueTooltip: value =>
        `${valueFormatters.conversionRate(value)} of carts converted to sales ${
          timespan.includes("past") ? "in " : ""
        }${timespan}.`,
      formatValue: valueFormatters.conversionRate
    }
  ];
}

type OffersListProps = {
  offers: Array<Offer>,
  filters: Array<{}>,
  appliedFilters: Array<{}>,
  moneyFormat: string,
  queryValue: string | null,
  handleQueryChange: (value: string) => void,
  handleQueryClear: () => void,
  handleFiltersClearAll: () => void,
  offersReportsRequest: OffersReportsRequest,
  reportingRange: DateRanges
};

const OffersList = ({
  offers = [],
  moneyFormat,
  queryValue,
  handleQueryChange,
  handleQueryClear,
  filters,
  appliedFilters,
  handleFiltersClearAll,
  offersReportsRequest,
  reportingRange
}: OffersListProps) => {
  const navigation = useContext(NavigationContext);

  const selectedDateRange = REPORT_PERIOD_OPTIONS.find(
    option => option.value === reportingRange
  );

  const timespan = selectedDateRange
    ? selectedDateRange.label.toLowerCase()
    : "past 30 days";

  const reportingColumns = makeReportingColumns({
    timespan,
    moneyFormat
  });

  const buildCustomValueTooltip = (offerType: OfferType) => {
    return (columnKey: string) => {
      if (offerType !== "PostPurchaseOffer") {
        return undefined;
      }

      if (columnKey !== "conversionRate") {
        return undefined;
      }

      return (value: number) => {
        return `${value}% of customers added the suggested upsell to their order`;
      };
    };
  };

  const { spanKey, axisTicks } = getRangeForSpan(reportingRange);

  const showHeader = reportingColumns.length > 0 && offers.length > 0;

  const filterControl = (
    <React.Fragment>
      <Filters
        filters={filters}
        appliedFilters={appliedFilters}
        queryValue={queryValue}
        onQueryChange={handleQueryChange}
        onQueryClear={handleQueryClear}
        onClearAll={handleFiltersClearAll}
      />
      {showHeader && <OffersListHeader reportingColumns={reportingColumns} />}
    </React.Fragment>
  );

  return (
    <div style={{ borderTop: "1px solid #dfe3e8" }}>
      <ResourceList
        items={offers}
        renderItem={offer => (
          <ResourceItem
            id={offer.id}
            onClick={() => navigation.goTo(`/offers/${offer.id}`)}
            media={offerIcon(getTypeSlug(offer.type), {
              size: 40,
              faded: !offer.active
            })}
            accessibilityLabel={`Edit ${offer.name}`}
          >
            <OffersListItemContainer
              buildCustomValueTooltip={buildCustomValueTooltip(offer.type)}
              offer={offer}
              reporting={makeOfferReportingProp(
                offer.id,
                offersReportsRequest,
                spanKey,
                axisTicks
              )}
              reportingColumns={reportingColumns}
            />
          </ResourceItem>
        )}
        resourceName={resourceName}
        filterControl={filterControl}
      />
    </div>
  );
};

export default OffersList;
