import React, { Component } from "react";
import { InlineError } from "@shopify/polaris";
import { Field } from "formik";
import classnames from "classnames";

import objectPath from "lib/objectPath";

class InputWithPopover extends Component {
  state = {
    popoverOpen: false
  };

  togglePopover = () => {
    this.setState({ popoverOpen: !this.state.popoverOpen });
  };

  handleChange = value => {
    const { form, name } = this.props;
    form.setFieldTouched(name);
    form.setFieldValue(name, value);
  };

  handleInputChange = event => {
    this.handleChange(event.target.value);
  };

  render() {
    const {
      form,
      placeholder,
      label,
      labelHidden,
      name,
      popoverComponent,
      disabled
    } = this.props;
    const value = objectPath.get(form.values, name) || "";
    const id = `Popover${name}`;
    const error = objectPath.get(form.errors, name);

    const containerClass = classnames({
      "Polaris-TextField": true,
      "Polaris-TextField--error": error,
      "Polaris-TextField--disabled": disabled
    });

    const inputField = (
      <div className={containerClass}>
        <input
          onFocus={this.togglePopover}
          onChange={this.handleInputChange}
          placeholder={placeholder}
          id={id}
          className="Polaris-TextField__Input"
          aria-label={label}
          aria-labelledby={`${id}Label`}
          aria-invalid={!!error}
          value={value}
          disabled={disabled ? "disabled" : ""}
        />
        <div className="Polaris-TextField__Backdrop" />
        {popoverComponent.displayName === "InlineColorPicker" && value && (
          <div
            className="InlineColorPicker__swatch"
            style={{ backgroundColor: value }}
          />
        )}
      </div>
    );

    const inputLabel = labelHidden ? null : (
      <div className="Polaris-Labelled__LabelWrapper">
        <div className="Polaris-Label">
          <label id={`${id}Label`} htmlFor={id} className="Polaris-Label__Text">
            {label}
          </label>
        </div>
      </div>
    );

    return (
      <>
        {inputLabel}
        <Field
          name={name}
          component={popoverComponent}
          onChange={value => {
            this.handleChange(value);
          }}
          togglePopover={this.togglePopover}
          popoverOpen={this.state.popoverOpen}
          inputField={inputField}
        />
        {error && (
          <div style={{ marginTop: "0.4rem" }}>
            <InlineError message={error} fieldID={id} />
          </div>
        )}
      </>
    );
  }
}

export default InputWithPopover;
