// @flow

export function getCookieByKey(key: string): string {
  const match = document.cookie.match(new RegExp("(^| )" + key + "=([^;]+)"));
  if (match) return match[2];

  return "";
}

export function expireCookieByKey(key: string): boolean {
  const cookieValue = getCookieByKey(key);
  if (cookieValue) {
    document.cookie = `${key}=${cookieValue}; expires=${new Date().toUTCString()} SameSite=None; Secure`;

    return true;
  }

  return false;
}

export function setCookie(
  key: string,
  value: string,
  minutes: number
): boolean {
  const d = new Date();
  d.setTime(d.getTime() + minutes * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  document.cookie = `${key}=${value};${expires};SameSite=None;path=/;Secure`;

  return true;
}
