import React from "react";
import { Card, FormLayout } from "@shopify/polaris";

import PolarisFormikInventoryPicker from "components/PolarisFormikInventoryPicker";
import PolarisFormikDiscountSection from "components/PolarisFormikDiscountSection";
import OfferSummarySection from "components/OfferSummarySection";
import OfferConfigurationSection from "components/OfferConfigurationSection";

export default function CreditSection(props) {
  const { form, moneyFormat, offerTypeSlug, multicurrencyWarningCard } = props;

  return (
    <OfferConfigurationSection offerTypeSlug={offerTypeSlug}>
      <Card>
        <Card.Section>
          <FormLayout>
            <p>Choose items to trigger cart credit</p>
            <PolarisFormikInventoryPicker
              form={form}
              name="details.buy_from"
              qtyName="details.buy_qty"
            />
          </FormLayout>
        </Card.Section>
        <Card.Section>
          <PolarisFormikDiscountSection
            label="Choose cart discount"
            name="details.price"
            form={form}
            moneyFormat={moneyFormat}
            multicurrencyWarningCard={multicurrencyWarningCard}
          />
          <OfferSummarySection form={form} moneyFormat={moneyFormat} />
        </Card.Section>
      </Card>
    </OfferConfigurationSection>
  );
}
