import React from "react";
import { Card, TextContainer, FormLayout } from "@shopify/polaris";

import { variantsSelected } from "lib/offerHelpers";
import offerPlaceholders from "content/offerPlaceholders";
import OptionalNumberField from "components/OptionalNumberField";
import PolarisFormikCheckbox from "components/PolarisFormikCheckbox";
import PolarisFormikInventoryPicker from "components/PolarisFormikInventoryPicker";
import PolarisFormikDiscountSection from "components/PolarisFormikDiscountSection";
import OfferMessageSection from "components/OfferMessageSection";
import OfferSummarySection from "components/OfferSummarySection";
import OfferConfigurationSection from "components/OfferConfigurationSection";
import { HelpDocsLink } from "components/links";

export default function BogoSection(props) {
  const {
    form,
    moneyFormat,
    offerTypeSlug,
    multicurrencyWarningCard,
    redirectToAppearance
  } = props;

  const variantPresence = variantsSelected(form);

  const placeholderType = variantPresence ? "variant" : "nonVariant";

  return [
    <OfferConfigurationSection
      key="offer-configuration"
      offerTypeSlug={offerTypeSlug}
    >
      <Card>
        <Card.Section title="Trigger">
          <FormLayout>
            <p>
              Choose products or collections that trigger this offer when added
              to a cart.
            </p>

            <PolarisFormikInventoryPicker
              form={form}
              qtyName="details.buy_qty"
              name="details.buy_from"
            />
          </FormLayout>
        </Card.Section>

        <Card.Section title="Offer">
          <TextContainer>
            <p>
              BOGO lets you create an offer that activates when a specific
              criteria is met. You can either add a single product to the cart
              at a discounted price, or display a popup with a list of multiple
              items or collections, each showing the discounted pricing.{" "}
              <HelpDocsLink helpDocsType={offerTypeSlug} />
            </p>
            <PolarisFormikInventoryPicker
              form={form}
              qtyName="details.get_qty"
              name="details.get_from"
            />
          </TextContainer>
        </Card.Section>

        <Card.Section>
          <FormLayout>
            <PolarisFormikDiscountSection
              name="details.price"
              form={form}
              moneyFormat={moneyFormat}
              multicurrencyWarningCard={multicurrencyWarningCard}
            />

            <div>
              <OptionalNumberField
                form={form}
                label="Apply an offer limit"
                name="details.use_max"
                min={1}
              />
              <PolarisFormikCheckbox
                name="details.show_upsell"
                label={
                  <span>
                    Show an upsell popup with sale products.{" "}
                    <HelpDocsLink helpDocsType={offerTypeSlug} />{" "}
                  </span>
                }
              />
            </div>
          </FormLayout>

          <OfferSummarySection form={form} moneyFormat={moneyFormat} />
        </Card.Section>
      </Card>
    </OfferConfigurationSection>,
    <OfferMessageSection
      key="offer-message"
      offerTypeSlug={offerTypeSlug}
      redirectToAppearance={redirectToAppearance}
      variantPresence={variantPresence}
      fields={[
        {
          label: "Initial message",
          name: "notifications.message",
          placeholder: offerPlaceholders.bogo.initial[placeholderType]
        },
        {
          label: "Progress message",
          name: "notifications.message_gety",
          placeholder: offerPlaceholders.bogo.progress[placeholderType]
        },
        {
          label: "Success message",
          name: "notifications.message_after",
          placeholder: offerPlaceholders.bogo.success
        }
      ]}
    />
  ];
}
