// @flow
import React, { useContext, useEffect } from "react";
import { Page } from "@shopify/polaris";

import CommonTitleBar from "components/CommonTitleBar";
import { offerActions } from "store/offersStore";
import { storeConnect } from "appStore";
import makeRequestProps from "store/makeRequestProps";
import { NavigationContext } from "contexts/Navigation";

import DashboardLayout from "./DashboardLayout";

import type { OffersRequest, ShopRequest } from "appStore";

type Props = {
  fetchOffers: () => void,
  setEvent: () => void,
  shopRequest: ShopRequest,
  offersRequest: OffersRequest
};

export const HomeRoute = (props: Props) => {
  const navigationContext = useContext(NavigationContext);

  useEffect(() => {
    props.fetchOffers();
  }, []);

  const { shopRequest, offersRequest } = props;
  const { shop } = shopRequest;
  const navigateToNewOffer = () => navigationContext.goTo("/offers/new");

  return (
    <Page fullWidth>
      <CommonTitleBar />
      <DashboardLayout
        shop={shop}
        createOffer={navigateToNewOffer}
        fetchOffers={props.fetchOffers}
        offersRequest={offersRequest}
      />
    </Page>
  );
};

const mapStateToProps = state => {
  return {
    shopRequest: makeRequestProps(state, "shop"),
    offersRequest: makeRequestProps(state, "offers")
  };
};

export default storeConnect(HomeRoute, mapStateToProps, offerActions);
