// @flow

import React, { useContext } from "react";
import { Banner, Layout, Page, Button, ButtonGroup } from "@shopify/polaris";

import errorMessages from "content/errorMessages";
import { NavigationContext } from "contexts/Navigation";

type Props = {
  stateKey?: string,
  status?: number
};
export default function ErrorPage({ stateKey, status }: Props) {
  const navigation = useContext(NavigationContext);

  function handleRefreshClick() {
    navigation
      ? navigation.goTo("/")
      : (window.location.href = `/?host=${window.host}`);
  }

  const message =
    stateKey && status
      ? errorMessages[stateKey][status]
      : errorMessages.default;
  return (
    <Page fullWidth={true}>
      <Layout>
        <Layout.Section>
          <Banner status="critical" title="Oops, something went wrong">
            <p style={{ marginBottom: "2rem" }}>{message}</p>
            <ButtonGroup>
              <Button outline onClick={handleRefreshClick}>
                Refresh app
              </Button>
              <Button
                url="https://launch.orbitapps.com/hc/en-us/articles/4402979466003"
                external
              >
                Contact support
              </Button>
            </ButtonGroup>
          </Banner>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
