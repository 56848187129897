// @flow
import React, { useContext } from "react";
import { Page } from "@shopify/polaris";

import CommonTitleBar from "components/CommonTitleBar";
import { NavigationContext } from "contexts/Navigation";

import WelcomeLayout from "./WelcomeLayout";

export const WelcomeRoute = () => {
  const navigation = useContext(NavigationContext);

  return (
    <Page fullWidth>
      <CommonTitleBar />
      <WelcomeLayout onCreateOffer={() => navigation.goTo("/offers/new")} />
    </Page>
  );
};

export default WelcomeRoute;
