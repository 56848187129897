import React, { Component } from "react";

import { get } from "lib/objectPath";
import { Checkbox } from "@shopify/polaris";

import { gatherFieldProps } from "lib/formHelpers";
import PolarisFormikNumberField from "components/PolarisFormikNumberField";
import NestedField from "components/NestedField";

/**
 * Renders a toggleable number input. If the input is toggled off or left blank,
 * its value will be null.
 */
class OptionalNumberField extends Component {
  constructor(props) {
    super(props);
    this.defaultInitialValue = 1;
    const initialValue = get(props.form.values, props.name);

    this.state = {
      value: initialValue,
      checked: initialValue !== null
    };
  }

  onCheckboxChange = checked => {
    const { form, name } = this.props;

    const newState = {
      checked
    };

    if (checked && this.state.value === null) {
      newState.value = this.defaultInitialValue;
      form.setFieldValue(name, this.defaultInitialValue);
    }

    if (!checked) {
      form.setFieldValue(name, null);
      newState.value = null;
    }

    this.setState(newState);
  };

  render() {
    const { label, name } = this.props;
    const fieldProps = gatherFieldProps(this.props);

    return (
      <div>
        <Checkbox
          label={label}
          checked={this.state.checked}
          onChange={this.onCheckboxChange}
        />
        {this.state.checked && (
          <NestedField
            style={{
              maxWidth: "100px"
            }}
          >
            <PolarisFormikNumberField {...fieldProps} name={name} labelHidden />
          </NestedField>
        )}
      </div>
    );
  }
}

export default OptionalNumberField;
