import * as yup from "yup";
import {
  OFFER_TYPES,
  OFFER_PRICE_DISCOUNT_TYPES
} from "types/constants/offers";
import { getTypeSlug } from "lib/offerHelpers";
import hasCommon from "lib/hasCommon";
import objectPath from "lib/objectPath";
import { MAX_BADGE_TEXT_LENGTH } from "./settingsValidation";
import { yupHexColorTest } from "lib/validationHelpers";

const dateRegex = /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/;
const dateFormatMessage = fieldName =>
  `${fieldName} must match format: YYYY-MM-DD`;
const dateYupSchema = (dependentField, fieldName) =>
  yup.string().when(dependentField, {
    is: val => val && val.length > 0,
    then: yup
      .string()
      .required(`${fieldName} is required`)
      .matches(dateRegex, dateFormatMessage(fieldName)),
    otherwise: yup
      .string()
      .transform(value => (!value ? null : value)) // make nullable work with empty strings
      .nullable()
      .matches(dateRegex, dateFormatMessage(fieldName))
  });

// empty is passing nullable
const timeYupSchema = fieldName =>
  yup
    .string()
    .transform(value => (!value ? null : value))
    .nullable()
    .matches(
      /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
      `${fieldName} must match format: HH:MM`
    );

export const applicabilityYupSchema = yup.object().shape({
  beginning_at_time: timeYupSchema("Start time"),
  beginning_at_date: dateYupSchema("beginning_at_time", "Start date"),
  ending_at_time: timeYupSchema("End time"),
  ending_at_date: dateYupSchema("ending_at_time", "End date"),
  exclude_customers_tagged_with: yup.boolean(),
  exclude_customers_tagged_with_tags: yup
    .string()
    .when("exclude_customers_tagged_with", {
      is: true,
      then: yup
        .string()
        .required("Please enter a list of customer tags to exclude.")
    }),
  channels: yup
    .array()
    .min(
      1,
      "You must make your offer available to either your online store or POS (or both)"
    )
});

export const customizationsYupSchema = yup
  .object()
  .nullable()
  .shape({
    badge: yup
      .object()
      .nullable()
      .shape({
        enabled: yup.boolean(),
        use_default: yup.boolean(),
        type: yup.string().when("use_default", {
          is: false,
          then: yup.string().required("A badge type must be selected")
        }),
        badge_text: yup
          .string()
          .max(
            MAX_BADGE_TEXT_LENGTH,
            ({ max }) => `Badge text must be at most ${max} characters`
          ),
        position: yup.string().when("use_default", {
          is: false,
          then: yup.string().required("A badge position must be selected")
        })
      }),
    css: yup
      .object()
      .nullable()
      .when("badge.use_default", {
        is: false,
        then: yup.object().shape({
          badge: yup
            .object()
            .nullable()
            .shape({
              background: yupHexColorTest(
                "Sale badges background colour",
                false
              ),
              color: yupHexColorTest("Sale badges text colour", false)
            })
        })
      })
  });

export const baseYupSchema = {
  type: yup
    .string()
    .required("An offer type must be selected")
    .oneOf(OFFER_TYPES.map(o => o.type)),
  applicability: applicabilityYupSchema,
  name: yup.string().required("Offer name required"),
  customizations: customizationsYupSchema
};

export const inventoryYupSchema = (section = "") =>
  yup
    .object()
    .test(
      "validInventory",
      `At least one ${section} collection, product, or variant must be selected`,
      obj =>
        obj.product_ids.length > 0 ||
        obj.collection_ids.length > 0 ||
        (obj.product_variants && obj.product_variants.length > 0)
    )
    .test(
      "duplicatedProduct",
      "The same product cannot be targeted in both product and variant selectors",
      obj =>
        !hasCommon(
          obj.product_ids,
          (obj.product_variants || []).map(
            productVariant => productVariant.product_id
          )
        )
    );

export const priceYupSchema = () =>
  yup.object().shape({
    type: yup
      .string()
      .required("A discount type must be selected")
      .oneOf([
        OFFER_PRICE_DISCOUNT_TYPES.FLAT,
        OFFER_PRICE_DISCOUNT_TYPES.PERCENT,
        OFFER_PRICE_DISCOUNT_TYPES.SUBTRACT
      ]),
    amount: yup
      .number()
      .typeError("Discount amount must be a number")
      .required("Discount amount cannot be empty")
      .when("type", {
        is: OFFER_PRICE_DISCOUNT_TYPES.PERCENT,
        then: yup
          .number()
          .max(100, "Discount percentage can't be greater than 100")
      })
  });

export const qtyMinimumYupSchema = (target = "The") => {
  const message = `${target} quantity must be more than 0`;
  return yup.number().typeError(message).min(1, message).required(message);
};

export const messageYupSchema = (target = "The") =>
  yup.string().required(`${target} message cannot be blank`);

export const notificationsYupSchema = () => yup.object();

export function yupSchemaForOfferType(type) {
  const slug = getTypeSlug(type);

  return yup.object().shape(
    {
      bogo: {
        ...baseYupSchema,
        details: yup
          .object()
          .required()
          .shape({
            use_max: yup.mixed().nullable(),
            buy_qty: qtyMinimumYupSchema("Target"),
            get_qty: qtyMinimumYupSchema("Offer"),
            buy_from: inventoryYupSchema("trigger"),
            get_from: inventoryYupSchema("offer"),
            price: priceYupSchema()
          }),
        notifications: notificationsYupSchema({
          message: "Initial",
          message_gety: "Progress",
          message_after: "Success"
        })
      },
      bulk: {
        ...baseYupSchema,
        details: yup
          .object()
          .required()
          .shape({
            buy_qty: qtyMinimumYupSchema("Item"),
            buy_from: inventoryYupSchema(),
            use_max: yup.mixed().nullable(),
            price: priceYupSchema()
          }),
        notifications: notificationsYupSchema({
          message: "Initial",
          message_after: "Success"
        })
      },
      bundle: {
        ...baseYupSchema,
        details: yup
          .object()
          .required()
          .shape({
            bundles: yup
              .array()
              .min(1, "At least one item must be added")
              .of(
                yup.object().shape({
                  quantity: qtyMinimumYupSchema("Items"),
                  buy_from: inventoryYupSchema()
                })
              ),
            price: priceYupSchema()
          }),
        notifications: notificationsYupSchema({
          message: "Initial",
          message_after: "Success"
        })
      },
      credit: {
        ...baseYupSchema,
        details: yup
          .object()
          .required()
          .shape({
            buy_qty: qtyMinimumYupSchema("Item"),
            buy_from: inventoryYupSchema(),
            price: priceYupSchema()
          })
      },
      discount: {
        ...baseYupSchema,
        details: yup.object().required().shape({
          discount: priceYupSchema(),
          buy_from: inventoryYupSchema()
        }),
        notifications: notificationsYupSchema({
          message: "Notification",
          message_after: "Success"
        })
      },
      gift: {
        ...baseYupSchema,
        details: yup
          .object()
          .required()
          .shape({
            buy_from: inventoryYupSchema(),
            cart_amount_greater_than: yup.object().shape({
              amount: yup
                .number()
                .required("A spend goal is required")
                .min(1, "Please enter a goal amount greater than zero")
                .typeError("Spend Goal must be a number")
            })
          }),
        notifications: notificationsYupSchema({
          message_before: "Initial",
          message_after: "Success"
        })
      },
      goal: {
        ...baseYupSchema,
        details: yup
          .object()
          .required()
          .shape({
            buy_from: inventoryYupSchema(),
            amount_greater_than: yup.object().shape({
              amount: yup
                .number()
                .required("A spend goal is required")
                .min(1, "Please enter a goal amount greater than zero")
                .typeError("Spend Goal must be a number")
            }),
            discount: priceYupSchema()
          }),
        notifications: notificationsYupSchema({
          message: "Initial",
          upsell_message: "Progress",
          message_after: "Success"
        })
      },
      postPurchaseOffer: {
        ...baseYupSchema,
        details: yup
          .object()
          .required()
          .shape({
            use_max: yup.mixed().nullable(),
            buy_from: inventoryYupSchema("trigger"),
            get_from: inventoryYupSchema("offer"),
            price: priceYupSchema(),
            shipping: yup
              .object()
              .required()
              .shape({
                default: yup
                  .number()
                  .required("A shipping charge is required")
                  .min(
                    0,
                    "Please enter a shipping charge greater than or equal to zero"
                  )
                  .typeError("Shipping charge must be a number")
              })
          }),
        notifications: yup
          .object()
          .required()
          .shape({
            message: yup.string().required("A message is required")
          })
      },
      upsell: {
        ...baseYupSchema,
        details: yup
          .object()
          .required()
          .shape({
            buy_from: inventoryYupSchema("target"),
            get_from: inventoryYupSchema("promoted")
          }),
        notifications: notificationsYupSchema({
          message: "Upsell"
        })
      },
      volume: {
        ...baseYupSchema,
        details: yup
          .object()
          .required()
          .shape({
            type: yup.string().required("Please select a type"),
            buy_from: inventoryYupSchema(),
            tiers: yup
              .array()
              .min(1, "At least one price tier must be added")
              .of(
                yup.object().shape({
                  quantity: qtyMinimumYupSchema("Item"),
                  price: priceYupSchema()
                })
              )
          })
      }
    }[slug]
  );
}

export function validateOffer(values) {
  return validate(yupSchemaForOfferType(values.type), values);
}

function validate(schema, obj) {
  try {
    schema.validateSync(obj, { abortEarly: false });
    return [true, {}];
  } catch (e) {
    const errors = e.inner.reduce((ret, error) => {
      objectPath.set(ret, error.path, error.message);
      return ret;
    }, {});
    return [false, errors];
  }
}
