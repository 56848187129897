import React, { ReactNode } from "react";
import { DataTable } from "@shopify/polaris";
import copy from "content/copy.json";

type Props = {
  chargesRowContent: ReactNode;
  discountOrdersRowContent: ReactNode;
  footerContent: ReactNode;
};

function UsageFeesDataTable({
  chargesRowContent,
  discountOrdersRowContent,
  footerContent
}: Props) {
  const {
    usageTableColumnHeading1,
    usageTableColumnHeading2,
    usageTableColumnHeading3
  } = copy.account.percentageVolumetric.usageFeesSection;

  return (
    <DataTable
      columnContentTypes={["text", "numeric", "numeric"]}
      headings={[
        usageTableColumnHeading1,
        usageTableColumnHeading2,
        usageTableColumnHeading3
      ]}
      rows={[["Totals", discountOrdersRowContent, chargesRowContent]]}
      showTotalsInFooter
      footerContent={footerContent}
    />
  );
}

export default UsageFeesDataTable;
