import React from "react";
import {
  FormLayout,
  Card,
  Banner,
  Icon,
  Badge,
  Stack,
  Subheading
} from "@shopify/polaris";
import { AlertMinor } from "@shopify/polaris-icons";
import { Field } from "formik";

import { getOfferType } from "lib/offerHelpers";

import AnnotatedSection from "components/AnnotatedSection";
import BadgesSection from "routes/offers/BadgesSection";
import { AppearanceLink, HelpDocsLink, helpDocsUrl } from "components/links";
import PolarisFormikSelect from "components/PolarisFormikSelect";
import PolarisFormikTextField from "components/PolarisFormikTextField";
import copy from "content/copy.json";

function getDefaultDescription(offerTypeSlug) {
  if (offerTypeSlug === "postPurchaseOffer") {
    const { description } = copy.offers.ppu.offerMessaging;
    return description;
  }

  return "Enable or disable sales badges on your product listings. Write succinct and enticing offer messaging to your customers. Leave these fields empty if you don’t want to display a message";
}

const VariantsDisclaimer = ({ offerType }) => {
  return (
    <Banner icon={() => <Icon source={AlertMinor} />}>
      <span className="VariantsDisclaimer">
        Your Offer Includes product variants. Don&apos;t forget to mention them
        in your messaging. <HelpDocsLink helpDocsType={offerType} />
      </span>
    </Banner>
  );
};

export default function OfferMessageSection(props) {
  const { offerTypeSlug, fields } = props;
  if (!offerTypeSlug) return null;

  const sectionName = "presentation";

  const defaultTitle = `${getOfferType(offerTypeSlug).title} ${sectionName}`;
  const defaultDescription = getDefaultDescription(offerTypeSlug);

  const {
    title = defaultTitle,
    description = defaultDescription,
    redirectToAppearance,
    variantPresence
  } = props;

  const { badgeTextNew, titleOfferMessaging } = copy.offers.ppu.offerMessaging;

  const cardSectionTitle = (
    <Stack>
      <Stack.Item fill>
        <Subheading element="h3">{titleOfferMessaging}</Subheading>
      </Stack.Item>
      {offerTypeSlug === "postPurchaseOffer" && (
        <Stack.Item>
          <Badge status="attention">{badgeTextNew}</Badge>
        </Stack.Item>
      )}
    </Stack>
  );

  return (
    <AnnotatedSection
      title={title}
      description={description}
      linkUrl={helpDocsUrl(offerTypeSlug)}
      linkText="Learn more"
    >
      <Card>
        {offerTypeSlug !== "postPurchaseOffer" && <BadgesSection />}
        <Card.Section title={cardSectionTitle}>
          <FormLayout>
            {variantPresence && (
              <VariantsDisclaimer offerType={offerTypeSlug} />
            )}
            {fields.map((field, i) => (
              <Field
                component={
                  offerTypeSlug !== "postPurchaseOffer"
                    ? PolarisFormikTextField
                    : PolarisFormikSelect
                }
                {...field}
                key={i}
              />
            ))}
            <AppearanceLink
              redirectToAppearance={redirectToAppearance}
              sectionName={sectionName}
              type={offerTypeSlug}
            />
          </FormLayout>
        </Card.Section>
      </Card>
    </AnnotatedSection>
  );
}
