//@flow

import React from "react";
import { storeConnect } from "appStore";
import makeRequestProps from "store/makeRequestProps";
import { shopActions } from "store/shopStore";
import CartWarningBannerSection from "./CartWarningBannerSection";

import type { ShopRequest } from "appStore";
import type { ShopEventPayload } from "types/flow-types/shop";
import { useMainThemeHasAjaxCartInstallQuery } from "graphql/queries/main-theme-has-ajax-cart-install.generated";

type Props = {
  shopRequest: ShopRequest,
  setEvent: ShopEventPayload => void
};

// TODO tests
function CartWarningBannerContainer(props: Props) {
  const { shopRequest, setEvent } = props;
  const { shop } = shopRequest;
  const { data, error, loading } = useMainThemeHasAjaxCartInstallQuery({
    skip: shop.events.cart_banner_dismissed
  });

  if (
    shop.events.cart_banner_dismissed ||
    data?.mainTheme.hasAjaxCartInstall ||
    error
  ) {
    return null;
  }

  return (
    <CartWarningBannerSection
      setEvent={setEvent}
      bannerExpanded={!loading}
      shopDomain={shop.settings.domain}
    />
  );
}

const mapStateToProps = function (state) {
  return {
    shopRequest: makeRequestProps(state, "shop")
  };
};

export default storeConnect(
  CartWarningBannerContainer,
  mapStateToProps,
  shopActions
);
