// @flow
import React from "react";
import { Button } from "@shopify/polaris";
import MarketingText from "./MarketingText";

type SwitchPlanFeaturesContentProps = {
  appPlanName: string,
  onActivateClick: () => void
};

export default function SwitchPlanFeaturesContent({
  appPlanName,
  onActivateClick
}: SwitchPlanFeaturesContentProps) {
  return (
    <div className="Plan-Activation-Section">
      <div className="Description-Text">
        <MarketingText appPlanName={appPlanName} />
      </div>
      <Button primary onClick={() => onActivateClick()}>
        ACTIVATE
      </Button>
    </div>
  );
}
