import React from "react";
import { Banner } from "@shopify/polaris";
import { useI18n } from "@shopify/react-i18n";
import AnimatedBanner from "components/banners/AnimatedBanner";
import { useMainThemeHasAjaxCartInstallQuery } from "graphql/queries/main-theme-has-ajax-cart-install.generated";

function WarningBanner({ renderBanner }: { renderBanner: boolean }) {
  const [i18n] = useI18n();

  if (!renderBanner) return null;

  return (
    <div style={{ padding: "10px 0 10px 0" }}>
      <Banner
        title={i18n.translate("settings.discountCodeUnsupportedBanner.title")}
      >
        <p>{i18n.translate("settings.discountCodeUnsupportedBanner.body")}</p>
      </Banner>
    </div>
  );
}

export default function DiscountCodeUnsupportedBanner() {
  const { data } = useMainThemeHasAjaxCartInstallQuery();

  const renderBanner = !!data?.mainTheme.hasAjaxCartInstall;

  return (
    <AnimatedBanner expanded={renderBanner}>
      <WarningBanner renderBanner={renderBanner} />
    </AnimatedBanner>
  );
}
