import React from "react";
import { useI18n } from "@shopify/react-i18n";
import copy from "content/copy.json";

export default function I18nRoot({ children }: { children: React.ReactNode }) {
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const [i18n, ShareTranslations] = useI18n({
    id: "root",
    translations() {
      return copy;
    }
  });
  return <ShareTranslations>{children}</ShareTranslations>;
}
