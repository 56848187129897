import React from "react";
import { Banner } from "@shopify/polaris";
import copy from "content/copy.json";
import bannerAction from "./banner-action";
import { useShop } from "contexts/Shop";
import singularOrPluralText from "lib/singular-or-plural-text";

const {
  trialOngoingTitle,
  trialOngoingText,
  trialDaySingularText,
  trialDayPluralText
} = copy.freePlanBanners;

export default function TrialOngoing() {
  const shop = useShop();
  const trialDays = shop.trialDaysRemaining;
  const dayOrDays = singularOrPluralText(
    trialDays,
    trialDaySingularText,
    trialDayPluralText
  );

  return (
    <Banner
      status="info"
      title={`${trialDays} ${dayOrDays} ${trialOngoingTitle}`}
      action={bannerAction()}
    >
      <p>{trialOngoingText}</p>
    </Banner>
  );
}
