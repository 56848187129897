import React, { useState } from "react";
import { Button, Card, Modal, Stack, TextContainer } from "@shopify/polaris";
import { BillingType } from "types/app-plan";
import copy from "content/copy.json";
import { useShop } from "contexts/Shop";
import { shopActions } from "store/shopStore";
import { storeConnect } from "appStore";

const {
  freePlanCardContent,
  freePlanCardModalCancel,
  freePlanCardModalConfirm,
  freePlanCardModalContent,
  freePlanCardModalTitle,
  freePlanCardTitle,
  freePlanOnTrialText,
  onFreePlanCardTitle
} = copy.account.freePlan;

function FreePlanCard() {
  const { app_plan, onTrial } = useShop();
  const onFreePlan = app_plan.billingType === BillingType.Free;
  const title =
    onFreePlan && !onTrial ? onFreePlanCardTitle : freePlanCardTitle;
  const showOnTrialMessage = onFreePlan && onTrial;

  return (
    <Card title={title}>
      <Card.Section>
        <Stack distribution="fill">
          <TextContainer>
            <p>{freePlanCardContent}</p>
          </TextContainer>
          {!onFreePlan && <ConnectedModal />}
          {showOnTrialMessage && (
            <Stack distribution="trailing">
              <Button disabled plain>
                {freePlanOnTrialText}
              </Button>
            </Stack>
          )}
        </Stack>
      </Card.Section>
    </Card>
  );
}

interface ActivateFreePlanModalProps {
  loading: boolean;
  deleteRecurringCharge: (payload: () => void) => void;
}

function ActivateFreePlanModal({
  deleteRecurringCharge
}: ActivateFreePlanModalProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => setModalOpen(false);
  const onError = () => {
    setLoading(false);
    handleClose();
  };
  async function confirmFreePlan() {
    setLoading(true);
    deleteRecurringCharge(onError);
  }

  const activatePlanButton = (
    <Stack distribution="trailing">
      <Button plain onClick={() => setModalOpen(true)}>
        Activate plan
      </Button>
    </Stack>
  );
  return (
    <Modal
      activator={activatePlanButton}
      open={modalOpen}
      title={freePlanCardModalTitle}
      onClose={handleClose}
      loading={loading}
      primaryAction={{
        content: freePlanCardModalConfirm,
        onAction: confirmFreePlan
      }}
      secondaryActions={[
        {
          content: freePlanCardModalCancel,
          onAction: handleClose
        }
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>{freePlanCardModalContent}</p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  );
}

const ConnectedModal = storeConnect(ActivateFreePlanModal, [], shopActions);

export default FreePlanCard;
