// @flow
import axios from "./authedAxios";
import { API_ORIGIN } from "lib/constants";
import { reportError } from "lib/errorHelpers";

import type { CollectionItem } from "types/flow-types/offers";
const { DEFAULT_API_VERSION } = require("types/constants/shopify");

export const SHOPIFY_COLLECTIONS_API_PATH = `/shopify-api-wrapper/admin/api/${DEFAULT_API_VERSION}`;
export const SHOPIFY_COLLECTIONS_API_BASE = `${API_ORIGIN}${SHOPIFY_COLLECTIONS_API_PATH}`;

export type MaybeCollectionResponse = {
  error?: Error,
  data?: Array<CollectionItem>
};

export const collectionsUrl = (path?: string): string =>
  [SHOPIFY_COLLECTIONS_API_BASE, path].filter(Boolean).join("/");

export async function fetchCustomCollections(): Promise<Array<CollectionItem>> {
  return (await axios.get(collectionsUrl("custom_collections.json"))).data;
}

export async function fetchSmartCollections(): Promise<Array<CollectionItem>> {
  return (await axios.get(collectionsUrl("smart_collections.json"))).data;
}

export async function fetchCollections(): Promise<MaybeCollectionResponse> {
  try {
    const [smartCollections, customCollections] = await Promise.all([
      fetchSmartCollections(),
      fetchCustomCollections()
    ]);

    return { data: [...smartCollections, ...customCollections] };
  } catch (e) {
    const error = new Error(e);
    reportError(error);
    return { error };
  }
}
