export type AppPlanName =
  | "fixedWithoutPPU"
  | "fixedWithPPU"
  | "freeMay2023"
  | "ultimate"
  | "ultimateFeb2023"
  | "volumetricWithoutPPU"
  | "volumetricWithPPU";

export enum BillingType {
  Fixed = "fixed",
  Free = "free",
  PercentageVolumetric = "percentage_volumetric",
  Volumetric = "volumetric"
}

export type AppPlan = {
  billingType: BillingType;
  description: string;
  freeOrders: number;
  legacy: boolean;
  name: AppPlanName;
  ppuEnabled: boolean;
};
