import React from "react";
import getStringsOfObj from "lib/getStringsOfObj";
import isEmptyObj from "lib/isEmptyObj";
import { Layout, Banner, List } from "@shopify/polaris";

const ErrorsBannerSection = React.forwardRef((props, ref) => {
  const { errors = {}, touched = {} } = props;
  const errorStrings = getStringsOfObj(errors);
  const numErrors = errorStrings.length;
  const numErrorsStr = numErrors > 1 ? `are ${numErrors} errors` : `is 1 error`;

  if (errorStrings.length === 0 || isEmptyObj(touched)) return null;

  return (
    <div ref={ref} style={{ width: "100%" }} data-testid="ErrorBanner">
      <Layout.Section>
        <Banner
          status="critical"
          title={`There ${numErrorsStr} with your offer`}
        >
          <List type="bullet">
            {errorStrings.map(str => (
              <List.Item key={str}>{str}</List.Item>
            ))}
          </List>
        </Banner>
      </Layout.Section>
    </div>
  );
});

ErrorsBannerSection.displayName = "ErrorsBannerSection";

export default ErrorsBannerSection;
