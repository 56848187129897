// @flow

import React from "react";
import { Card } from "@shopify/polaris";

import ChartTabsLoading from "../ChartTabs/ChartTabsLoading";
import ChartFooterLoading from "../ChartFooter/ChartFooterLoading";
import ChartLineChartLoading from "../ChartLineChart/ChartLineChartLoading";
import ChartWrapper from "../ChartWrapper";

export default function ChartLoading() {
  return (
    <Card>
      <ChartTabsLoading />
      <Card.Section>
        <ChartWrapper>
          <ChartLineChartLoading />
        </ChartWrapper>
      </Card.Section>
      <ChartFooterLoading />
    </Card>
  );
}
