import React, { useContext, useState } from "react";
import { Modal, TitleBar } from "@shopify/app-bridge-react";
import { Page } from "@shopify/polaris";
import { ExternalMinor } from "@shopify/polaris-icons";
import { Formik } from "formik";

import { settingsValidationSchema } from "api/settingsValidation";
import { shopActions } from "store/shopStore";
import { storeConnect } from "appStore";
import makeRequestProps from "store/makeRequestProps";
import { ensureDefaults } from "types/constants/settings";
import AppearanceForm from "./AppearanceForm";

import { NavigationContext } from "contexts/Navigation";
import { FlagsContext } from "contexts/Flags";
import { ShopProvider } from "contexts/Shop";

function AppearanceRoute(props) {
  const [cancelAlertOpen, setCancelAlertOpen] = useState(false);
  const navigation = useContext(NavigationContext);
  const flags = useContext(FlagsContext);

  const handleSubmit = values => {
    props.putCustomizations(values, () => navigation.goTo("/"));
  };

  const handleCancel = dirty => {
    dirty ? setCancelAlertOpen(true) : navigation.goTo("/");
  };

  const {
    shopRequest: {
      shop: { app_settings: settings, customizations }
    }
  } = props;

  const linkUrl =
    "https://launch.orbitapps.com/hc/en-us/articles/4402806410643";

  return (
    <ShopProvider value={props.shopRequest.shop}>
      <Page
        title="Appearance"
        subtitle="Customize the style and positioning of your offers."
        secondaryActions={[
          {
            content: "Learn more",
            external: true,
            icon: ExternalMinor,
            url: linkUrl
          }
        ]}
      >
        <Formik
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={ensureDefaults(settings, customizations)}
          validationSchema={() =>
            settingsValidationSchema({
              releaseEnableBadgesPerOffer: flags.releaseEnableBadgesPerOffer
            })
          }
        >
          {form => (
            <>
              <TitleBar
                title="Appearance"
                primaryAction={{ content: "Save", onAction: form.submitForm }}
                secondaryActions={[
                  {
                    content: "Cancel",
                    onAction: () => handleCancel(form.dirty)
                  }
                ]}
              />

              <AppearanceForm
                handleCancel={handleCancel}
                handleSubmit={handleSubmit}
                setEvent={props.setEvent}
              />

              <Modal
                title="You have unsaved changes"
                open={cancelAlertOpen}
                message="If you leave this page, all your unsaved changes will be lost. Are you sure you want to discard these changes and leave this page?"
                primaryAction={{
                  content: "Leave page",
                  destructive: true,
                  onAction: () => {
                    setCancelAlertOpen(false);
                    navigation.goTo("/");
                  }
                }}
                secondaryActions={[
                  {
                    content: "Cancel",
                    onAction: () => setCancelAlertOpen(false)
                  }
                ]}
              />
            </>
          )}
        </Formik>
      </Page>
    </ShopProvider>
  );
}

const mapStateToProps = state => {
  return {
    shopRequest: makeRequestProps(state, "shop")
  };
};

export default storeConnect(AppearanceRoute, mapStateToProps, shopActions);
