import { OFFER_PRICE_DISCOUNT_TYPES as PRICE_TYPES } from "types/constants/offers";
import { formatCurrency } from "lib/currencyHelpers";
import variantProductTitleWithCount from "./variantProductTitleWithCount";
/**
 * Format an array into an english-readable list.
 *
 * @param {array} array of strings to format
 * @param {string} finalConnector string to join last two elements. 'or' or 'and'.
 */
const commaSeparated = (array, finalConnector = "and") => {
  if (array.length === 0) {
    return "";
  }

  if (array.length === 1) {
    return array[0];
  }

  const lastItem = array.pop();
  return `${array.join(", ")} ${finalConnector} ${lastItem}`;
};

const capitalizeFirstChar = message => {
  return message.charAt(0).toUpperCase() + message.slice(1);
};

/**
 * Ensure a summary message array is valid by checking for empty strings
 *
 * @param {array} wordArr
 */
const validateMessage = wordArr => {
  return wordArr.indexOf("") >= 0 ? "No summary yet." : wordArr.join(" ");
};

/**
 * build a buy_from or get_from inventory message given an array of titles.
 * e.g.
 *   - "any product from Collection: Boots"
 *   - "3 of Product: Striped Shirt"
 *
 * @param {object} inventory - inventory object (buy_from/get_from) from offer.details
 * @param {number} quantity - quantity, if applicable, from offer details
 * @param {boolean} includeQuantities whether or not to include the quantity language in the output
 *  - e.g. "any 1 product from" or "4 of"
 */
const inventoryMessage = function (
  inventory,
  quantity = 1,
  includeQuantities = true
) {
  quantity = parseInt(quantity, 10);

  if (quantity < 1) {
    return "";
  }

  const productString = quantity > 1 ? "products" : "product";
  const {
    product_titles: products,
    collection_titles: collections,
    product_variants: productVariants = []
  } = inventory;
  const variantProductTitlesWithCounts = productVariants.map(productVariant =>
    variantProductTitleWithCount(
      productVariant.title,
      productVariant.variants.length
    )
  );
  const productsAndProductVariants = [
    ...products,
    ...variantProductTitlesWithCounts
  ];

  const includesProductsOrProductVariants =
    productsAndProductVariants.length > 0;
  const includesCollections = collections.length > 0;

  const output = [];

  if (includesProductsOrProductVariants) {
    if (quantity > 1 && includeQuantities) {
      output.push(`${quantity} of`);
    }
    output.push(`Product:`);
    output.push(productsAndProductVariants.join(", "));

    if (includesCollections) {
      output.push("or");
    }
  }

  if (includesCollections) {
    if (includeQuantities) {
      const quantityString = quantity > 1 ? ` ${quantity}` : "";
      output.push(`any${quantityString} ${productString} from`);
    }
    output.push("Collection:", collections.join(", "));
  }

  return output.join(" ");
};

/**
 * Generate a price discount message
 *
 * @param {object} price - the price object from offer.details
 * @param {*} moneyFormat - {{amount}} string from shop's settings
 */
const priceMessage = function ({ type, amount }, moneyFormat) {
  if (parseInt(amount, 10) <= 0) {
    return "";
  }

  switch (type) {
    case PRICE_TYPES.SUBTRACT:
      return `for ${formatCurrency(amount, moneyFormat)} off`;
    case PRICE_TYPES.PERCENT:
      return `at ${amount}% off`;
    case PRICE_TYPES.FLAT:
      return `for ${formatCurrency(amount, moneyFormat)}`;
    default:
      return "";
  }
};

/**
 * Summary message templates, one per type
 */

const bogoTemplate = (details, moneyFormat) => {
  const { buy_from, buy_qty, get_from, get_qty, price } = details;

  return [
    "Buy",
    inventoryMessage(buy_from, buy_qty),
    "and get",
    inventoryMessage(get_from, get_qty),
    priceMessage(price, moneyFormat)
  ];
};

const bulkTemplate = (details, moneyFormat) => {
  const { buy_from, buy_qty, price } = details;
  return [
    "Buy",
    inventoryMessage(buy_from, buy_qty),
    priceMessage(price, moneyFormat)
  ];
};

const bundleTemplate = (details, moneyFormat) => {
  const { bundles, price } = details;

  const bundleMessages = bundles.map(({ buy_from, quantity }) =>
    inventoryMessage(buy_from, quantity)
  );

  return [
    "Buy",
    commaSeparated(bundleMessages),
    priceMessage(price, moneyFormat)
  ];
};

const creditTemplate = (details, moneyFormat) => {
  const { buy_from, buy_qty, price } = details;

  return [
    "Buy",
    inventoryMessage(buy_from, buy_qty),
    "and get a cart discount",
    priceMessage(price, moneyFormat)
  ];
};

const discountTemplate = (details, moneyFormat) => {
  const { buy_from, discount } = details;

  return [
    "Get",
    inventoryMessage(buy_from),
    priceMessage(discount, moneyFormat)
  ];
};

const giftTemplate = (details, moneyFormat) => {
  const { buy_from, cart_amount_greater_than } = details;

  return [
    "Spend",
    formatCurrency(cart_amount_greater_than.amount, moneyFormat),
    "get",
    inventoryMessage(buy_from),
    "for free"
  ];
};

const goalTemplate = (details, moneyFormat) => {
  const { amount_greater_than, discount, buy_from } = details;

  return [
    "Spend",
    formatCurrency(amount_greater_than.amount, moneyFormat),
    "on",
    inventoryMessage(buy_from),
    "and get a discount",
    priceMessage(discount, moneyFormat)
  ];
};

const upsellTemplate = details => {
  const { buy_from, get_from } = details;

  return [
    "When buying",
    `${inventoryMessage(buy_from)},`,
    "tell about",
    inventoryMessage(get_from, 1, false)
  ];
};

const volumeTemplate = (details, moneyFormat) => {
  const { buy_from, tiers } = details;

  const tierMessage = tiers
    .filter(({ quantity, price }) => {
      return parseInt(quantity, 10) > 0 && parseInt(price.amount, 10) > 0;
    })
    .map(({ quantity, price }) => {
      return `get ${quantity} ${priceMessage(price, moneyFormat)}`;
    });

  let buyFromMessage = inventoryMessage(buy_from);
  if (buyFromMessage !== "") {
    buyFromMessage = `${buyFromMessage}:`;
  }

  return [buyFromMessage, commaSeparated(tierMessage)];
};

const postPurchaseOfferTemplate = (details, moneyFormat) => {
  const { buy_from, buy_qty, get_from, get_qty, price } = details;

  const texts = [
    "Buy",
    inventoryMessage(buy_from, buy_qty),
    "and get",
    inventoryMessage(get_from, get_qty)
  ];

  if (Number(price.amount) > 0) {
    texts.push(priceMessage(price, moneyFormat));
  }

  return texts;
};

export function buildOfferSummary(type, details, moneyFormat) {
  const template = {
    bogo: bogoTemplate,
    bulk: bulkTemplate,
    bundle: bundleTemplate,
    credit: creditTemplate,
    discount: discountTemplate,
    gift: giftTemplate,
    goal: goalTemplate,
    postPurchaseOffer: postPurchaseOfferTemplate,
    upsell: upsellTemplate,
    volume: volumeTemplate
  }[type];

  return template
    ? capitalizeFirstChar(validateMessage(template(details, moneyFormat)))
    : "";
}

export function buildAvailabilitySummary(applicability) {
  const output = [];

  if (applicability.channels.length < 2) {
    output.push(`only on channel ${applicability.channels[0]}`);
  }

  if (applicability.beginning_at) {
    output.push(`beginning at ${applicability.beginning_at_date}`);
  }

  if (applicability.ending_at) {
    output.push(`ending at ${applicability.ending_at_date}`);
  }

  if (applicability.exclude_customers_tagged_with) {
    output.push(
      `excluding customers tagged with ${applicability.exclude_customers_tagged_with_tags}`
    );
  }

  if (applicability.only_customers_signed_in) {
    output.push("only for signed in customers");
  }

  if (applicability.only_customers_tagged_with) {
    output.push(
      `only for signed in customers tagged with ${applicability.only_customers_tagged_with_tags}`
    );
  }

  if (applicability.only_special_link) {
    output.push("only for visitors with special link");
  }

  return output.length > 0 ? capitalizeFirstChar(commaSeparated(output)) : "";
}
