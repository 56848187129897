import React, { Component } from "react";
import { Layout, Button, TextContainer } from "@shopify/polaris";

import {
  getTypeSlug,
  offerDefaultsFromType,
  offerForFormPersistence
} from "lib/offerHelpers";

import OfferFormMessageContainer from "./OfferFormMessageContainer";
import GroupedPageActions from "components/GroupedPageActions";
import OfferUpperSection from "./OfferUpperSection";
import AvailabilitySection from "./AvailabilitySection";
import typeSections from "./types/index";

export const OfferDetailsSection = props => {
  const typeSlug = getTypeSlug(props.type);

  if (!typeSections[typeSlug]) return null;

  const Section = typeSections[typeSlug];
  return <Section offerTypeSlug={typeSlug} {...props} />;
};

class EditOfferForm extends Component {
  constructor(props) {
    super(props);
    const { values } = this.props.form;
    this.errorsSectionRef = React.createRef();
    this.state = {
      offerValues: {
        [getTypeSlug(values.type)]: offerForFormPersistence({ ...values })
      }
    };
  }

  onOfferTypeChange = (_, offerType = "") => {
    const { values } = this.props.form;
    const offerTypeSlug = getTypeSlug(offerType);
    const offerFromState = this.state.offerValues[offerTypeSlug];
    const currentOfferValues =
      offerFromState ||
      offerForFormPersistence(offerDefaultsFromType(offerType));

    const nextValues = {
      ...values,
      ...currentOfferValues,
      type: offerType
    };

    this.setState(state => {
      const newState = { ...state };
      newState.offerValues[getTypeSlug(values.type)] =
        offerForFormPersistence(values);
      return newState;
    });

    this.props.form.resetForm({ values: nextValues });
  };

  componentDidUpdate(prevProps) {
    const wasSubmitting = prevProps.form.isSubmitting;
    const { isSubmitting, isValid } = this.props.form;
    if (wasSubmitting && !isSubmitting && !isValid) {
      this.errorsSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  render() {
    const {
      appPlan,
      dismissPPUInfoMessage,
      dismissPPUSettingsMessage,
      domain,
      flags,
      form,
      isNewOffer,
      ppuInfoMessageDismissed,
      ppuSettingsMessageDismissed,
      redirectToAppearance
    } = this.props;
    const { values, handleSubmit, errors, touched } = form;

    const showOfferDetails = values.type != null;

    return (
      <form onSubmit={handleSubmit}>
        <Layout>
          <OfferFormMessageContainer
            appPlan={appPlan}
            dismissPPUInfoMessage={dismissPPUInfoMessage}
            dismissPPUSettingsMessage={dismissPPUSettingsMessage}
            errors={errors}
            errorsRef={this.errorsSectionRef}
            isNewOffer={isNewOffer}
            offerType={values.type}
            ppuInfoMessageDismissed={ppuInfoMessageDismissed}
            ppuSettingsMessageDismissed={ppuSettingsMessageDismissed}
            touched={touched}
          />
          <OfferUpperSection
            isNewOffer={isNewOffer}
            form={form}
            onOfferTypeChange={this.onOfferTypeChange}
            flags={flags}
            redirectToAppearance={redirectToAppearance}
          />
          {showOfferDetails && (
            <>
              <OfferDetailsSection type={values.type} {...this.props} />
              <AvailabilitySection {...this.props} domain={domain} />
              <Layout.Section>
                <GroupedPageActions>
                  <Button
                    primary
                    onClick={form.submitForm}
                    disabled={!this.props.isDirty}
                  >
                    Save
                  </Button>
                </GroupedPageActions>
              </Layout.Section>
            </>
          )}
          <TextContainer>
            <p>&nbsp;</p>
          </TextContainer>
        </Layout>
      </form>
    );
  }
}

export default EditOfferForm;
