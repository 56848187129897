import React from "react";
import { Card, SkeletonBodyText, SkeletonDisplayText } from "@shopify/polaris";
import UsageFeesDataTable from "./UsageFeesDataTable";

function UsageFeesLoadingCard() {
  const usageCardSkeleton = <SkeletonBodyText lines={1} />;

  return (
    <Card title={<SkeletonDisplayText size="small" />}>
      <UsageFeesDataTable
        chargesRowContent={usageCardSkeleton}
        discountOrdersRowContent={usageCardSkeleton}
        footerContent={usageCardSkeleton}
      />
    </Card>
  );
}

export default UsageFeesLoadingCard;
