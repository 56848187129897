import * as Types from "../../types/generated/graphql";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type MainThemeQueryVariables = Types.Exact<{ [key: string]: never }>;

export type MainThemeQuery = {
  __typename?: "RootQueryType";
  mainTheme: {
    __typename?: "MainTheme";
    hasAjaxCartInstall: boolean;
    hasEmbeddedInstall: boolean;
    id: number;
  };
};

export const MainThemeDocument = gql`
  query MainTheme {
    mainTheme {
      hasAjaxCartInstall
      hasEmbeddedInstall
      id
    }
  }
`;

/**
 * __useMainThemeQuery__
 *
 * To run a query within a React component, call `useMainThemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMainThemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMainThemeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMainThemeQuery(
  baseOptions?: Apollo.QueryHookOptions<MainThemeQuery, MainThemeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MainThemeQuery, MainThemeQueryVariables>(
    MainThemeDocument,
    options
  );
}
export function useMainThemeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MainThemeQuery,
    MainThemeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MainThemeQuery, MainThemeQueryVariables>(
    MainThemeDocument,
    options
  );
}
export type MainThemeQueryHookResult = ReturnType<typeof useMainThemeQuery>;
export type MainThemeLazyQueryHookResult = ReturnType<
  typeof useMainThemeLazyQuery
>;
export type MainThemeQueryResult = Apollo.QueryResult<
  MainThemeQuery,
  MainThemeQueryVariables
>;
