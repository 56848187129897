import { getCookieByKey, expireCookieByKey } from "lib/cookie";
import { CLIENT_EVENTS, EVENT_COOKIE_KEY } from "types/constants/analytics";

export const trackCookieEvent = () => {
  const cookieEvent = getCookieByKey(EVENT_COOKIE_KEY);

  if (!cookieEvent || !CLIENT_EVENTS[cookieEvent]) return;

  trackEvent(CLIENT_EVENTS[cookieEvent]);

  expireCookieByKey(EVENT_COOKIE_KEY);
};

export const identifyUser = settings => {
  const { myshopify_domain, email } = settings;

  if (window.analytics) {
    window.analytics.identify(myshopify_domain, {
      email,
      ...attributionParams()
    });
  }

  if (window.userflow) {
    window.userflow.identify(myshopify_domain);
  }
};

export const trackNavigation = () => {
  if (!window.analytics) return;

  window.analytics.page();
};

export const trackEvent = (
  event,
  properties = {},
  options = {},
  callback = {}
) => {
  if (!window.analytics) return;

  const preparedProperties = { ...properties, app: "USO" };
  window.analytics.track(event, preparedProperties, options, callback);
};

// parse first and last attribution utm params from cookie
// as set by https://orbit-attribution.s3.ca-central-1.amazonaws.com/attribution.js
const attributionParams = () => {
  try {
    const params = {};
    const cookie = JSON.parse(getCookieByKey("gd"));

    if (cookie.firstAttribution && cookie.firstAttribution.params) {
      for (const [key, value] of new URLSearchParams(
        cookie.firstAttribution.params
      ).entries()) {
        if (key.includes("utm")) {
          params[key] = value;
        }
      }
    }

    if (cookie.lastAttribution && cookie.lastAttribution.params) {
      for (const [key, value] of new URLSearchParams(
        cookie.lastAttribution.params
      ).entries()) {
        if (key.includes("utm")) {
          params["last_" + key] = value;
        }
      }
    }
    return params;
  } catch {
    return {};
  }
};
