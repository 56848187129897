import React, { createContext, ReactNode, useContext } from "react";
import { Shop } from "types/shop";
import { getTrialDaysLeft } from "lib/planHelpers";
import onShopifyDevPlan from "lib/shop/on-shopify-dev-plan";
import { BillingType } from "types/app-plan";

export const ShopContext = createContext({});
export function ShopProvider({
  children,
  value: shop
}: {
  children: ReactNode;
  value: Shop;
}) {
  const activeUltimatePlan =
    shop.subscription_activated_date !== null &&
    shop.app_plan.billingType === BillingType.PercentageVolumetric;
  const onDevPlan = onShopifyDevPlan(shop.settings.plan_name);
  const trialDaysRemaining = getTrialDaysLeft(
    shop.created_date,
    shop.trial_days
  );
  const onTrial = trialDaysRemaining > 0;
  const billingPeriodActive = activeUltimatePlan && !onTrial;
  const shopValue = {
    ...shop,
    activeUltimatePlan,
    onDevPlan,
    onTrial,
    trialDaysRemaining,
    billingPeriodActive
  };
  return (
    <ShopContext.Provider value={shopValue}>{children}</ShopContext.Provider>
  );
}

export function useShop() {
  return useContext(ShopContext) as Shop;
}
