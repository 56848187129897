import React, { useContext } from "react";

import { deactivatedPremiumOffers } from "lib/offerHelpers";
import OfferDetails from "./OfferDetails";
import { ShopContext } from "../../contexts/Shop";

export default function OfferCard({
  offer,
  onOfferTypeChange,
  selectedOfferType
}) {
  const shop = useContext(ShopContext);
  const handleClick = (type, isSelected) => {
    if (isSelected) return;
    onOfferTypeChange(true, type);
    // TODO workaround for unable to focus on name
    window.scrollTo(0, 0);
  };
  const offerType = offer.type;
  const checked = selectedOfferType === offerType;
  const cardDeactivated = deactivatedPremiumOffers(
    shop?.app_plan?.name
  ).includes(offerType);
  let className = "VerboseOfferTypeCard";
  if (checked) {
    className += " VerboseOfferCard--Checked";
  }
  if (cardDeactivated) {
    className += " VerboseOfferCard--Deactivated";
  }

  return (
    <>
      <div className={className}>
        <OfferDetails
          offer={offer}
          checked={checked}
          showChangeButton={checked}
          onClick={() => handleClick(offerType, checked)}
          onCancel={() => onOfferTypeChange(true, null)}
          alwaysColor={true}
        />
      </div>
    </>
  );
}
