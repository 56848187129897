import React from "react";

import PolarisFormikInventoryPickerButton from "./PolarisFormikInventoryPickerButton";

export default function CollectionPickerButton({
  form,
  name,
  selectionIds,
  setSelectionIds,
  type
}) {
  if (type === "PostPurchaseOffer" && name === "details.get_from") {
    return null;
  }

  return (
    <PolarisFormikInventoryPickerButton
      idsFieldName={`${name}.collection_ids`}
      titlesFieldName={`${name}.collection_titles`}
      handlesFieldName={`${name}.collection_handles`}
      target="Collection"
      form={form}
      title="Choose collections"
      selectionIds={selectionIds}
      setSelectionIds={setSelectionIds}
    />
  );
}
