import React from "react";
import { Layout, FooterHelp, Link } from "@shopify/polaris";

export default function FooterSection() {
  return (
    <Layout.Section>
      <FooterHelp>
        Learn more about{" "}
        <Link url="https://orbitapps.com" external="true">
          Orbit
        </Link>
      </FooterHelp>
    </Layout.Section>
  );
}
