import React, { useContext } from "react";
import { getIn } from "formik";
import { TextStyle, Caption } from "@shopify/polaris";

import defaultBadge from "types/constants/default-badge";
import perOfferStaticBadgeStyles from "storefront/src/lib/badges/per-offer-static-badge-styles";
import perOfferDynamicBadgeStyles from "storefront/src/lib/badges/per-offer-dynamic-badge-styles";
import badgeTag from "storefront/src/lib/badges/per-offer-badge-tag";
import {
  pennant,
  swallowtail
} from "storefront/src/lib/badges/per-offer-badges-svg";
import placeholderProduct from "./placeholderProduct.svg";

import "./BadgePreview.css";
import { ShopContext } from "../../contexts/Shop";

const SVGS = {
  pennant,
  swallowtail
};
const Z_INDEX = 100;

const badge_style_tags = ({ badgeStyle }) => `
${perOfferStaticBadgeStyles}
<style>
${perOfferDynamicBadgeStyles({ uuid: "default", badgeStyle })}
</style>
`;

const BadgePreview = ({
  badgeType,
  badgeText,
  badgeStyles,
  badgePosition,
  badgeBorder
}) => {
  const shop = useContext(ShopContext);

  const defaultBadgeText =
    getIn(shop, "customizations.badge.badge_text") || defaultBadge.badge_text;
  const previewBadgeText = badgeText || defaultBadgeText;

  return (
    <>
      <div className="BadgePreview">
        <div
          dangerouslySetInnerHTML={{
            __html: badge_style_tags({ badgeStyle: badgeStyles })
          }}
        />

        <div className="BadgePreviewContainer">
          <img src={placeholderProduct} />

          <div
            dangerouslySetInnerHTML={{
              __html: badgeTag({
                badgeType: badgeType,
                zIndex: Z_INDEX,
                badgeText: previewBadgeText,
                badgeSvg: SVGS[badgeType],
                handleOfferUUID: "default",
                badgeUseDefault: true,
                badgePosition: badgePosition,
                badgeBorder: badgeBorder
              })
            }}
          />
        </div>
      </div>
      <div className="BadgePreviewDisclaimer">
        <TextStyle variation="subdued">
          <Caption>* Storefront may appear different than preview.</Caption>
        </TextStyle>
      </div>
    </>
  );
};

export default BadgePreview;
