import React from "react";
import {
  Card,
  ChoiceList,
  FormLayout,
  TextContainer,
  TextStyle,
  Link,
  Stack
} from "@shopify/polaris";
import { Field, getIn } from "formik";

import PolarisFormikTextField from "components/PolarisFormikTextField";
import InputWithPopover from "components/InputWithPopover";
import InlineDatePicker from "components/InlineDatePicker";
import AnnotatedSection from "components/AnnotatedSection";

import "./AvailabilitySection.css";

const AvailabilitySection = props => {
  const { domain, form } = props;
  // Everything here is scoped to values.applicability
  const values = form.values.applicability;

  if (form.values.type === "PostPurchaseOffer") return null;

  const linkCode = values.only_special_link_code;
  const linkCodeLink = linkCode ? `https://${domain}/?sol=${linkCode}` : "";
  const channelsError = getIn(form.errors, "applicability.channels");

  const startDate = (
    <InputWithPopover
      name="applicability.beginning_at_date"
      placeholder="Start date"
      popoverComponent={InlineDatePicker}
      form={form}
      labelHidden
    />
  );

  const endDate = (
    <InputWithPopover
      name="applicability.ending_at_date"
      placeholder="End date"
      popoverComponent={InlineDatePicker}
      form={form}
      labelHidden
    />
  );

  const availabilityChoices = [
    {
      label: "Offer to online store",
      value: "online"
    },
    {
      label: "Offer to point of sale (POS)",
      value: "pos"
    }
  ];

  const customerTagOptions = [
    "only_customers_signed_in",
    "exclude_customers_tagged_with"
  ];

  return (
    <AnnotatedSection
      title="Availability"
      description="Select when, where, and to whom your offer appears."
      linkUrl="https://launch.orbitapps.com/hc/en-us/articles/4416895373843"
    >
      <Card>
        <Card.Section>
          <FormLayout>
            <ChoiceList
              allowMultiple
              name="applicability.channels"
              choices={availabilityChoices}
              selected={values.channels}
              onChange={values => {
                form.setFieldValue("applicability.channels", values);
              }}
              error={channelsError}
            />
            <div className="ApplicabilityDate__Row">
              <Stack>
                <Field
                  name="applicability.beginning_at_time"
                  label="Optional start date"
                  placeholder="Time"
                  component={PolarisFormikTextField}
                  connectedLeft={startDate}
                />
                <Field
                  name="applicability.ending_at_time"
                  label="Optional end date"
                  placeholder="Time"
                  component={PolarisFormikTextField}
                  connectedLeft={endDate}
                />
              </Stack>
              <div className="Polaris-Labelled__HelpText">
                The time is set to your browser&lsquo;s timezone. It uses a
                24-hour clock, i.e. 15:30 is equivalent to 3:30pm.
              </div>
            </div>
            <ChoiceList
              allowMultiple
              selected={customerTagOptions.filter(option => values[option])}
              onChange={values => {
                customerTagOptions.forEach(option => {
                  form.setFieldValue(
                    `applicability.${option}`,
                    values.includes(option)
                  );
                });
              }}
              choices={[
                {
                  label: "Only show to signed in customers",
                  value: "only_customers_signed_in",
                  renderChildren: selected => {
                    if (selected) {
                      return (
                        <div style={{ marginTop: "0.6rem" }}>
                          <Field
                            name="applicability.only_customers_tagged_with_tags"
                            label="Choose customers with specific tags"
                            placeholder="Comma separated list of tags"
                            component={PolarisFormikTextField}
                          />
                        </div>
                      );
                    }
                  }
                },
                {
                  label: "Exclude signed in customers with specific tags",
                  value: "exclude_customers_tagged_with",
                  renderChildren: selected => {
                    if (selected) {
                      return (
                        <div style={{ marginTop: "0.6rem" }}>
                          <Field
                            name="applicability.exclude_customers_tagged_with_tags"
                            label="Don't show to signed in customers with specific tags"
                            labelHidden
                            placeholder="Comma separated list of tags"
                            component={PolarisFormikTextField}
                          />
                        </div>
                      );
                    }
                  }
                }
              ]}
            />
            <Field
              name="applicability.only_special_link_code"
              label="Require specific link access"
              component={PolarisFormikTextField}
              placeholder="Link code"
            />
            {linkCodeLink && (
              <div>
                <b>Link:</b>{" "}
                <Link external url={linkCodeLink}>
                  {linkCodeLink}
                </Link>
              </div>
            )}
            <TextContainer>
              <TextStyle variation="subdued">
                Works shop-wide, append the final part (&quot;?sol=...&quot;) to
                any URL. This is not a Shopify discount code, just a link to be
                included in newsletters, ads or other postings.
              </TextStyle>
            </TextContainer>
          </FormLayout>
        </Card.Section>
      </Card>
    </AnnotatedSection>
  );
};

export default AvailabilitySection;
