import React from "react";
import { Tooltip, Link, Icon } from "@shopify/polaris";
import { ViewMinor } from "@shopify/polaris-icons";
import useDiscountsAppEmbedNeedsEnabling from "hooks/use-discounts-app-embed-needs-enabling";

const PreviewLink = ({ onClick, offerType }) => {
  if (offerType === "PostPurchaseOffer") return null;

  const {
    loading: discountsAppEmbedNeedsEnablingLoading,
    needsEnabling: discountsAppEmbedNeedsEnabling
  } = useDiscountsAppEmbedNeedsEnabling();

  if (discountsAppEmbedNeedsEnablingLoading || discountsAppEmbedNeedsEnabling) {
    return null;
  }

  const tooltipText = "Preview in Online Store";

  return (
    <span
      className="OffersListItem__PreviewLink"
      onClick={e => e.stopPropagation()}
    >
      <Tooltip content={tooltipText} preferredPosition="above">
        <Link onClick={onClick}>
          <Icon source={ViewMinor} />
        </Link>
      </Tooltip>
    </span>
  );
};

export default PreviewLink;
