const defaultBadge = {
  type: "circle",
  position: "topLeft",
  css: {
    background: "#00000021",
    color: "#000000"
  },
  badge_text: "SALE",
  border: false
};

module.exports = defaultBadge;
