import React from "react";
import { Banner } from "@shopify/polaris";
import copy from "content/copy.json";
import bannerAction from "./banner-action";
import { Shop } from "types/shop";
import singularOrPluralText from "lib/singular-or-plural-text";

const { trialStartTitle, trialStartTextEnd, trialStartTextStart } =
  copy.freePlanBanners;
const { trialDaySingularText, trialDayPluralText } = copy.common;

interface Props {
  trialDaysRemaining: Shop["trialDaysRemaining"];
}

export default function TrialStart({ trialDaysRemaining }: Props) {
  const dayOrDays = singularOrPluralText(
    trialDaysRemaining,
    trialDaySingularText,
    trialDayPluralText
  );
  return (
    <Banner status="info" title={trialStartTitle} action={bannerAction()}>
      <p>
        {trialStartTextStart} {trialDaysRemaining} {dayOrDays}{" "}
        {trialStartTextEnd}
      </p>
    </Banner>
  );
}
