import React from "react";
import { Banner } from "@shopify/polaris";
import copy from "content/copy.json";
import bannerAction from "./banner-action";

const { maxUsageText, maxUsageTitle } = copy.freePlanBanners;

export default function MaxUsage() {
  return (
    <Banner status="warning" title={maxUsageTitle} action={bannerAction()}>
      <p>{maxUsageText}</p>
    </Banner>
  );
}
