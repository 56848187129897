// @flow

import React from "react";
import { storeConnect } from "appStore";
import makeRequestProps from "store/makeRequestProps";
import { shopActions } from "store/shopStore";

import { isMulticurrencyEnabled } from "lib/multicurrency";
import MulticurrencyWarningBanners from "./MulticurrencyWarningBanners";

import type { ShopEventPayload } from "types/flow-types/shop";
import type { ShopRequest } from "appStore";
import { hasDiscountScopes } from "lib/scopesHelpers";

type Props = {
  companionAppsInstalled?: Boolean,
  page: "offer" | "dashboard",
  setEvent: ShopEventPayload => void,
  shopRequest: ShopRequest
};

export const MulticurrencyWarningBannerContainer = (props: Props) => {
  const {
    companionAppsInstalled,
    page,
    setEvent,
    shopRequest: { shop }
  } = props;

  const shopHasDiscountScopes = hasDiscountScopes(shop.shopify_scopes);
  const persistBannerDismissal = () => {
    setEvent({ default_multicurrency_warning_banner_dismissed: true });
  };

  const storefrontMultiCurrencyEnabled = isMulticurrencyEnabled(
    shop.settings.enabled_presentment_currencies
  );
  const appMultiCurrencyEnabled = shop.app_settings.enableMultiCurrency;
  const usingDiscountCodes =
    shop.app_settings.offers_method === "discount_code";

  if (!companionAppsInstalled && !shopHasDiscountScopes) return null;
  if (!storefrontMultiCurrencyEnabled) return null;
  if (appMultiCurrencyEnabled && usingDiscountCodes) return null;
  if (!appMultiCurrencyEnabled && page === "offer") return null;
  if (appMultiCurrencyEnabled && !usingDiscountCodes) {
    return <MulticurrencyWarningBanners.DiscountCodesRequired />;
  }
  if (shop.events.default_multicurrency_warning_banner_dismissed) return null;
  return (
    <MulticurrencyWarningBanners.Default onDismiss={persistBannerDismissal} />
  );
};

const mapStateToProps = function (state) {
  return {
    companionAppsInstalled: state.companionAppsInstalled,
    shopRequest: makeRequestProps(state, "shop")
  };
};

export default storeConnect(
  MulticurrencyWarningBannerContainer,
  mapStateToProps,
  shopActions
);
