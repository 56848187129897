// @flow

import React, { useContext, useState } from "react";
import { NavigationContext } from "contexts/Navigation";
import { hasRequiredScopes } from "lib/scopesHelpers";
import { createRecurringCharge } from "api/recurring-charges";
import NewScopesContent from "./PlanCardContentComponents/NewScopesContent";
import ActivatedContent from "./PlanCardContentComponents/ActivatedContent";
import SwitchPlanFeaturesContent from "./PlanCardContentComponents/SwitchPlanFeaturesContent";

import type { Shop } from "types/flow-types/shop";

type PlanStateProps = {
  planCardactivated: boolean,
  setError: (errorMessage: string) => void,
  setEvent: any,
  shop: Shop
};

export default function PlanCardContent({
  planCardactivated,
  setEvent,
  setError,
  shop
}: PlanStateProps) {
  const [scopesUpdatingRecurringCharges, setScopesUpdatingRecurringCharges] =
    useState(shop.events.scopes_recurring_charge_update_needed);
  const navigation = useContext(NavigationContext);
  const updateScopes = !hasRequiredScopes(shop.shopify_scopes);

  async function activateRecurringCharge(
    navigation: any,
    setError: (errorMessage: string) => void
  ) {
    const response = await createRecurringCharge();
    if (response.error) {
      setError("We're sorry, an error has occurred. Please try again.");
    } else {
      navigation.redirect(response.data.redirectUrl);
    }
  }

  const onActivateClick = () => {
    if (updateScopes) {
      setEvent({ scopes_recurring_charge_update_needed: true });
      setScopesUpdatingRecurringCharges(true);
    } else {
      activateRecurringCharge(navigation, setError);
    }
  };

  // Activated Card, Content: ACTIVATED text
  if (planCardactivated) {
    return <ActivatedContent appPlanName={shop.app_plan.name} />;
  }

  /* Card is not activated, Content: update scopes and/or recurring charges links */
  if (scopesUpdatingRecurringCharges) {
    return (
      <NewScopesContent
        scopes={shop.shopify_scopes}
        updateScopes={updateScopes}
        setError={setError}
        activateRecurringCharge={() =>
          activateRecurringCharge(navigation, setError)
        }
      />
    );
  }

  /* Card is not activated, Content: ACTIVATE Button
    features and scopes are OK *OR*
    scope/recurringCharge update process hasn't begun yet */
  return (
    <SwitchPlanFeaturesContent
      onActivateClick={onActivateClick}
      appPlanName={shop.app_plan.name}
    />
  );
}
