import { useContext, useEffect, useState } from "react";
import { Context as ShopifyContext } from "@shopify/app-bridge-react";
import { ContextualSaveBar } from "@shopify/app-bridge/actions";

function useContextualSaveBar(save, discard, dependencies) {
  const [shouldSave, setShouldSave] = useState(false);
  const [shouldDiscard, setShouldDiscard] = useState(false);

  useEffect(() => {
    if (shouldSave) {
      save(dependencies);
      setShouldSave(false);
    }
  }, [shouldSave, save, dependencies]);

  useEffect(() => {
    if (shouldDiscard) {
      discard(dependencies);
      setShouldDiscard(false);
    }
  }, [shouldDiscard, discard, dependencies]);

  return [() => setShouldSave(true), () => setShouldDiscard(true)];
}

const options = {
  saveAction: {
    disabled: false,
    loading: false
  },
  discardAction: {
    disabled: false,
    loading: false,
    discardConfirmationModal: true
  }
};

const SaveBar = ({ dependencies, onDiscard, onSave, saveLoading, visible }) => {
  const app = useContext(ShopifyContext);
  const [saveBar] = useState(ContextualSaveBar.create(app, options));

  // this is required to get around the callback functions getting stale props
  // (from a stale closure) inside the first useEffect in the ContextualSaveBar
  // actions subscriptions
  // https://dmitripavlutin.com/react-hooks-stale-closures/
  const [save, discard] = useContextualSaveBar(onSave, onDiscard, dependencies);

  useEffect(() => {
    const saveUnsub = saveBar.subscribe(ContextualSaveBar.Action.SAVE, save);

    const discardUnsub = saveBar.subscribe(
      ContextualSaveBar.Action.DISCARD,
      discard
    );

    return () => {
      saveUnsub();
      discardUnsub();
    };
  }, []);

  useEffect(() => {
    visible
      ? saveBar.dispatch(ContextualSaveBar.Action.SHOW)
      : saveBar.dispatch(ContextualSaveBar.Action.HIDE);
  }, [visible]);

  useEffect(() => {
    visible && saveBar.set({ saveAction: { loading: saveLoading } });
  }, [saveLoading]);

  return null;
};

export default SaveBar;
