// @flow

import axios from "./authedAxios";
import { API_ORIGIN } from "lib/constants";
import { reportError } from "lib/errorHelpers";

// TODO: update flow types with final data shape
// import type { CurrentBillingPeriodData } from "types/flow-types/billing";

export const BILLING_API_PATH = "/api/billing";
export const BILLING_API_BASE = `${API_ORIGIN}${BILLING_API_PATH}`;

export const billingUrl = (path?: string): string =>
  [BILLING_API_BASE, path].filter(Boolean).join("/");

export async function fetchCurrentBillingData(): Promise<any> {
  try {
    const { data } = await axios.get(billingUrl("/current"));
    return { data };
  } catch (e) {
    const error = new Error(e);
    reportError(error);
    return { error };
  }
}

export async function fetchCurrentUsageChargesForPercentageVolumetricPlan(): Promise<any> {
  try {
    const { data } = await axios.get(
      billingUrl("/percentage-volumetric/current")
    );
    return { data, error: false };
  } catch (e) {
    const error = new Error(e);
    reportError(error);
    return { error, data: {} };
  }
}
