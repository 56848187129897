// @flow
import React, { useContext, useEffect, useRef, useState } from "react";
import { Layout, Page } from "@shopify/polaris";
import { Modal, TitleBar } from "@shopify/app-bridge-react";
import { ViewMinor } from "@shopify/polaris-icons";

import { offerActions } from "store/offersStore";
import { shopActions } from "store/shopStore";
import { storeConnect } from "appStore";
import makeRequestProps from "store/makeRequestProps";

import { NavigationContext } from "contexts/Navigation";
import { ShopProvider } from "contexts/Shop";

import useDiscountsAppEmbedNeedsEnabling from "hooks/use-discounts-app-embed-needs-enabling";

import EditOfferFormWrapped from "./EditOfferFormWrapped";
import { OfferFormSkeletonPage } from "components/skeletons";
import MulticurrencyWarningInCard from "components/banners/MulticurrencyWarningInCard";
import MulticurrencyWarningBannerContainer from "components/banners/MulticurrencyWarningBannerContainer";
import { openPreviewTab } from "components/links";
import ContextualSaveBar from "components/ContextualSaveBar";

import type { Offer, CreateOffer, PatchOffer } from "types/flow-types/offers";
import type { CurrentOfferRequest } from "appStore";
import type { ShopifyMultiCurrencyCode } from "types/flow-types/shopify";
import type { Flags } from "types/flow-types/flags";
import type { Shop, ShopEventPayload } from "types/flow-types/shop";

type Props = {
  appMultiCurrencyEnabled: boolean,
  createOffer: CreateOffer,
  currency: string,
  currentOfferRequest: CurrentOfferRequest,
  domain: string,
  enabledPresentmentCurrencies: ShopifyMultiCurrencyCode[],
  flags: Flags,
  match: {
    params: {
      offerId: number
    }
  },
  moneyFormat: string,
  offer: Offer,
  offerId: number,
  offersMethod: string,
  patchOffer: PatchOffer,
  setCurrentOffer: (?number) => void,
  setEvent: ShopEventPayload => void,
  shop: Shop
};

function handleDiscard({ form, setRedirecting, navigation }) {
  if (form.values.id) {
    form.resetForm();
  } else {
    setRedirecting(true);
    navigation.goTo("/");
  }
}

function handleSave({ form }) {
  form.submitForm();
}

function OffersRoute(props: Props) {
  const navigation = useContext(NavigationContext);
  const formRef = useRef();
  const {
    loading: discountsAppEmbedNeedsEnablingLoading,
    needsEnabling: discountsAppEmbedNeedsEnabling
  } = useDiscountsAppEmbedNeedsEnabling();

  const [cancelAlertOpen, setCancelAlertOpen] = useState(false);
  const [modalRedirectLocation, setModalRedirectLocation] = useState("");
  const [isDirty, setIsDirty] = useState(false);
  const [saving, setSaving] = useState(false);
  const [redirecting, setRedirecting] = useState(false);

  useEffect(() => {
    const { setCurrentOffer, offerId } = props;
    setCurrentOffer(offerId);
  }, []);

  function handleSubmit(values) {
    const { setCurrentOffer, createOffer, patchOffer, currentOfferRequest } =
      props;

    setSaving(true);

    /* If the current offer doesn't have an ID then this is a new offer
       and it needs to be created in the DB */
    if (currentOfferRequest.currentOffer.id) {
      patchOffer(values.id, values, () => {
        setCurrentOffer(values.id);
        setSaving(false);
        setIsDirty(false);
      });
    } else {
      createOffer(values, id => {
        navigation.replaceURL(`/offers/${id}`);
        setCurrentOffer(id);
        setSaving(false);
        setIsDirty(false);
      });
    }

    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }

  function updateDirtyState(currentDirtyState) {
    /* We can't use the formRef here because we need to trigger a page update for the formRef to reflect the changes */
    currentDirtyState !== isDirty && setIsDirty(currentDirtyState);
  }

  function onRedirectClick(redirectLocation: string) {
    if (isDirty) {
      setCancelAlertOpen(true);
      setModalRedirectLocation(redirectLocation);
    } else {
      navigation.goTo(redirectLocation);
    }
  }

  /* onModalClose will be run by Shopify every time the modal is closed
     as well as when the user closes the modal with the 'X'. We need to make
     sure that we don't do a set state when the modal is redirecting */
  function onModalClose() {
    if (redirecting) setCancelAlertOpen(false);
  }

  function onModalDiscard() {
    setCancelAlertOpen(false);
    setRedirecting(true);
    navigation.goTo(modalRedirectLocation);
  }

  const {
    appMultiCurrencyEnabled,
    currency,
    currentOfferRequest,
    domain,
    enabledPresentmentCurrencies,
    flags,
    moneyFormat,
    offersMethod,
    setEvent,
    shop
  } = props;

  const { currentOffer: offer, loading } = currentOfferRequest;

  const isNewOffer = !offer?.id;
  const title = offer?.name || "";

  const multicurrencyWarningCard = (
    <MulticurrencyWarningInCard
      appMultiCurrencyEnabled={appMultiCurrencyEnabled}
      enabledPresentmentCurrencies={enabledPresentmentCurrencies}
      offersMethod={offersMethod}
    />
  );

  const previewAction = {
    id: "preview-action",
    content: "Preview",
    icon: ViewMinor,
    onAction: () => {
      openPreviewTab(offer, domain);
    }
  };

  const secondaryActions =
    loading ||
    discountsAppEmbedNeedsEnablingLoading ||
    isNewOffer ||
    offer.type === "PostPurchaseOffer" ||
    discountsAppEmbedNeedsEnabling
      ? []
      : [previewAction];

  const ppuSettingsMessageDismissed =
    shop.events.ppu_settings_message_dismissed;

  const dismissPPUSettingsMessage = () => {
    setEvent({ ppu_settings_message_dismissed: true });
  };

  const ppuInfoMessageDismissed = shop.events.ppu_info_message_dismissed;
  const dismissPPUInfoMessage = () => {
    setEvent({ ppu_info_message_dismissed: true });
  };

  const appPlan = shop.app_plan?.name;

  const saveBarVisible = redirecting ? false : isDirty;

  const saveBarDeps = {
    form: formRef.current?.props?.form,
    navigation,
    setRedirecting
  };

  return (
    <>
      <ContextualSaveBar
        dependencies={saveBarDeps}
        onDiscard={handleDiscard}
        onSave={handleSave}
        saveLoading={saving}
        visible={saveBarVisible}
      />
      <TitleBar title="" />
      <Page
        title={title}
        breadcrumbs={[
          {
            content: "Dashboard",
            onAction: () => onRedirectClick("/")
          }
        ]}
        secondaryActions={secondaryActions}
      >
        {loading ? (
          <OfferFormSkeletonPage />
        ) : (
          <>
            <Layout>
              <MulticurrencyWarningBannerContainer page="offer" />
              <Layout.Section>
                <ShopProvider value={shop}>
                  <EditOfferFormWrapped
                    appPlan={appPlan}
                    offer={offer}
                    currency={currency}
                    dismissPPUInfoMessage={dismissPPUInfoMessage}
                    dismissPPUSettingsMessage={dismissPPUSettingsMessage}
                    domain={domain}
                    moneyFormat={moneyFormat}
                    formRef={formRef}
                    isNewOffer={isNewOffer}
                    onSubmit={handleSubmit}
                    ppuInfoMessageDismissed={ppuInfoMessageDismissed}
                    ppuSettingsMessageDismissed={ppuSettingsMessageDismissed}
                    updateDirty={isDirty => updateDirtyState(isDirty)}
                    flags={flags}
                    multicurrencyWarningCard={multicurrencyWarningCard}
                    redirectToAppearance={event => {
                      event.preventDefault();
                      onRedirectClick("/appearance");
                    }}
                  />
                </ShopProvider>
              </Layout.Section>
            </Layout>
          </>
        )}
        <Modal
          title="Discard all unsaved changes"
          open={cancelAlertOpen}
          onClose={onModalClose}
          message="You have unsaved changes. If you leave this page, you’ll delete any edits you made since you last saved."
          primaryAction={{
            content: "Discard Changes",
            destructive: true,
            onAction: onModalDiscard
          }}
          secondaryActions={[
            {
              content: "Continue editing",
              onAction: () => setCancelAlertOpen(false)
            }
          ]}
        />
      </Page>
    </>
  );
}

const mapStateToProps = state => {
  const { app_settings, settings, flags } = state.shop.data;

  const { domain, money_format, currency, enabled_presentment_currencies } =
    settings;

  return {
    appMultiCurrencyEnabled: app_settings.enableMultiCurrency,
    currency,
    currentOfferRequest: makeRequestProps(state, "currentOffer"),
    domain,
    enabledPresentmentCurrencies: enabled_presentment_currencies,
    flags,
    moneyFormat: money_format,
    offersMethod: app_settings.offers_method,
    shop: state.shop.data
  };
};

export default storeConnect(
  OffersRoute,
  mapStateToProps,
  offerActions,
  shopActions
);
