// @flow

import React, { useEffect, useState } from "react";
import { Banner } from "@shopify/polaris";

import BillingPeriodDetails from "./BillingPeriodDetails";
import BillingTable from "./BillingTable";
import BillingTableLoading from "./BillingTableLoading";
import MaxChargeDisclaimer from "./MaxChargeDisclaimer";

import { fetchCurrentBillingData } from "api/billing";

function ErrorBanner() {
  return <Banner status="critical" title="Oops, something went wrong" />;
}

export default function BillingTableContainer() {
  const [currentBillingPeriodRequest, setCurrentBillingPeriodRequest] =
    useState({ data: undefined, loading: true, error: undefined });

  useEffect(() => {
    async function fetchBillingData() {
      const response = await fetchCurrentBillingData();
      setCurrentBillingPeriodRequest(response);
    }

    fetchBillingData();
  }, []);

  if (currentBillingPeriodRequest.error) {
    return <ErrorBanner />;
  }

  if (currentBillingPeriodRequest.loading) {
    return <BillingTableLoading />;
  }

  return (
    <>
      <BillingPeriodDetails billingData={currentBillingPeriodRequest.data} />
      <BillingTable billingPeriodDetails={currentBillingPeriodRequest.data} />
      <MaxChargeDisclaimer />
    </>
  );
}
