import * as yup from "yup";
import { yupHexColorTest, yupSchemaPercentage } from "lib/validationHelpers";

export const MAX_BADGE_TEXT_LENGTH = 40;

export const settingsValidationSchema = ({
  // ch123217 Badges Per Offer
  releaseEnableBadgesPerOffer
} = {}) =>
  yup
    .object()
    // If we don't have _any_ data at all, then something is super wonky
    .required("Something went wrong, please refresh the page and try again")
    .shape({
      notifications: yup.object().shape({
        opacity: yupSchemaPercentage("Opacity"),
        background_color: yupHexColorTest("Background colour"),
        text_color: yupHexColorTest("Text colour")
      }),
      tax_percent: yupSchemaPercentage("Tax percentage"),
      customizations: yup
        .object()
        .shape({
          ...(releaseEnableBadgesPerOffer && {
            badge: yup.object().shape({
              badge_text: yup
                .string()
                .max(
                  MAX_BADGE_TEXT_LENGTH,
                  ({ max }) => `Badge text must be at most ${max} characters`
                ),
              type: yup.string().required("A badge type must be selected"),
              position: yup
                .string()
                .required("A badge position must be selected")
            })
          }),
          css: yup.object().shape({
            badge: yup.object().shape({
              background: yupHexColorTest(
                "Sale badges background colour",
                !releaseEnableBadgesPerOffer
              ),
              color: yupHexColorTest(
                "Sale badges text colour",
                !releaseEnableBadgesPerOffer
              )
            }),
            text: yup.object().shape({
              heading: yup.object().shape({
                color: yupHexColorTest("Text headings colour", true)
              }),
              body: yup.object().shape({
                color: yupHexColorTest("Body text colour", true)
              }),
              sale: yup.object().shape({
                color: yupHexColorTest("Sale text colour", true)
              }),
              inline: yup.object().shape({
                color: yupHexColorTest("Cart Item text colour", true)
              })
            }),
            button: yup.object().shape({
              primary: yup.object().shape({
                background: yupHexColorTest(
                  "Primary button background colour",
                  true
                ),
                color: yupHexColorTest("Primary button text colour", true)
              })
            }),
            table: yup.object().shape({
              even: yup.object().shape({
                color: yupHexColorTest("Even table row colour", true)
              }),
              odd: yup.object().shape({
                color: yupHexColorTest("Odd table row colour", true)
              })
            }),
            popup: yup.object().shape({
              background: yupHexColorTest("Popup background colour", true)
            })
          })
        })
        .nullable()
    });
