import React from "react";
import { Card, TextContainer } from "@shopify/polaris";

export default function DevPlanCard() {
  return (
    <Card title="Developer plan" sectioned subdued>
      <TextContainer>
        <p>You are currently on the Developer plan for $0/month.</p>
      </TextContainer>
    </Card>
  );
}
