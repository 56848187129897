export const saleTag = `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102 172">
    <path d="M80 0l21 32v139H1V32L23 0h57zM51 14a11 11 0 100 22 11 11 0 000-22z"/>
  </svg>
`;

export const pennant = `
  <svg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 100'>
    <polygon vector-effect="non-scaling-stroke" points='0,0 90,0 100,50 90,100 0,100' opacity='1'></polygon>
  </svg>
`;

export const swallowtail = `
  <svg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 100'>
    <polygon vector-effect="non-scaling-stroke" points='0,0 100,0 90,50 100,100 0,100' opacity='1'></polygon>
  </svg>
`;
