import React from "react";
import { Banner } from "@shopify/polaris";
import copy from "content/copy.json";
import singularOrPluralText from "lib/singular-or-plural-text";
import { Shop } from "types/shop";

interface Props {
  trialDaysRemaining: Shop["trialDaysRemaining"];
}
const { textStart, textEnd } = copy.common.earlyActivationBanner;
const { trialDayPluralText, trialDaySingularText } = copy.common;

export default function EarlyActivation({ trialDaysRemaining }: Props) {
  const dayOrDays = singularOrPluralText(
    trialDaysRemaining,
    trialDaySingularText,
    trialDayPluralText
  );
  return (
    <Banner>
      {textStart} {trialDaysRemaining} {dayOrDays} {textEnd}
    </Banner>
  );
}
