import React from "react";
import {
  Page,
  SkeletonDisplayText,
  SkeletonBodyText,
  Layout,
  Card,
  TextContainer,
  TextField
} from "@shopify/polaris";

export const IndexSkeletonPage = () => (
  <Page fullWidth={true}>
    <Layout>
      <Layout.Section>
        <OffersListSkeletonPage />
      </Layout.Section>
    </Layout>
  </Page>
);

export const OffersListSkeletonPage = () => {
  const cardSections = [];
  for (let i = 0; i < 10; i++) {
    cardSections.push(
      <Card.Section key={i}>
        <SkeletonBodyText lines={2} />
      </Card.Section>
    );
  }

  return (
    <Layout>
      <Layout.Section>
        <Card>
          <Card.Section>
            <TextContainer>
              <SkeletonDisplayText />
              <div style={{ width: "30%" }}>
                <SkeletonBodyText lines={1} />
              </div>
              <SkeletonBodyText lines={2} />
            </TextContainer>
          </Card.Section>
          {cardSections}
        </Card>
      </Layout.Section>
    </Layout>
  );
};

export const OfferFormSkeletonPage = () => (
  <Layout>
    <Layout.AnnotatedSection
      title={<SkeletonDisplayText />}
      description={<SkeletonBodyText lines={1} />}
    >
      <Card>
        <Card.Section>
          <TextContainer>
            <div style={{ width: "30%" }}>
              <SkeletonBodyText lines={1} />
            </div>
            <TextField label="" autoComplete="off" onChange={() => null} />
          </TextContainer>
        </Card.Section>
        <Card.Section>
          <TextContainer spacing="loose">
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText lines={1} />
            <SkeletonBodyText lines={1} />
            <SkeletonBodyText lines={1} />
            <SkeletonBodyText lines={1} />
            <SkeletonBodyText lines={1} />
            <SkeletonBodyText lines={1} />
          </TextContainer>
        </Card.Section>
      </Card>
    </Layout.AnnotatedSection>
    <Layout.AnnotatedSection
      title={<SkeletonDisplayText />}
      description={<SkeletonBodyText lines={1} />}
    >
      <Card>
        <Card.Section>
          <TextContainer>
            <div style={{ width: "30%" }}>
              <SkeletonBodyText lines={1} />
            </div>
            <TextField label="" autoComplete="off" onChange={() => null} />
          </TextContainer>
        </Card.Section>
        <Card.Section>
          <TextContainer>
            <SkeletonDisplayText />
            <SkeletonBodyText lines={2} />
          </TextContainer>
        </Card.Section>
      </Card>
    </Layout.AnnotatedSection>
  </Layout>
);

export const PlanCardSectionSkeleton = () => (
  <div style={{ width: "550px", marginTop: "30px", marginBottom: "30px" }}>
    <Layout>
      <Layout.Section>
        <Card>
          <Card.Section>
            <TextContainer>
              <SkeletonBodyText lines={5} />
            </TextContainer>
          </Card.Section>
          <Card.Section>
            <TextContainer>
              <SkeletonBodyText lines={8} />
            </TextContainer>
          </Card.Section>
          <Card.Section>
            <TextContainer spacing="loose">
              <SkeletonBodyText lines={1} />
            </TextContainer>
          </Card.Section>
          <Card.Section>
            <TextContainer spacing="loose">
              <SkeletonBodyText lines={1} />
            </TextContainer>
          </Card.Section>
          <Card.Section>
            <TextContainer spacing="loose">
              <SkeletonBodyText lines={1} />
            </TextContainer>
          </Card.Section>
        </Card>
      </Layout.Section>
    </Layout>
  </div>
);

export function FaqSkeleton() {
  return (
    <>
      {[...Array(6)].map((_, index) => (
        <div className="AccordionItem__row" key={index}>
          <Card.Section>
            <SkeletonBodyText lines={1} />
          </Card.Section>
        </div>
      ))}
    </>
  );
}
