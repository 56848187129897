import axios from "axios";
import { getSessionToken } from "@shopify/app-bridge/utilities";
import { reportError } from "lib/errorHelpers";

const instance = axios.create();
// intercept all requests on this axios instance
instance.interceptors.request.use(function (config) {
  if (process.env.STORYBOOK) return config;

  if (!window.app) {
    reportError("window.app is not set");

    throw new Error("There was an error while handling your request.");
  }

  return getSessionToken(window.app) // requires an App Bridge instance
    .then(token => {
      // append your request headers with an authenticated token
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
});
// export your axios instance to use within your app
export default instance;
