import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import redirectUnlessIframe from "lib/redirectUnlessIframe";
import { unregister } from "./registerServiceWorker";

function render(Component) {
  ReactDOM.render(Component, document.getElementById("root"));
}

if (!redirectUnlessIframe()) {
  unregister();
  render(<App />);
}
