import React from "react";
import { FormLayout, Banner, Card } from "@shopify/polaris";

import { ThemeAssetLink } from "components/links";
import CopyHelperTextField from "components/CopyHelperTextField";

const AdvancedPlacementSnippets = function () {
  // the helptext for three of the four inputs here is duplicated, somewhat silly
  const productLiquidHelpText = (
    <span>
      This code is usually pasted in your theme&lsquo;s{" "}
      <ThemeAssetLink asset="templates/product.liquid">
        product.liquid
      </ThemeAssetLink>{" "}
      or a product details snippet included in that template.
    </span>
  );

  const cartLiquidHelpText = (
    <span>
      This code is usually pasted in your theme&lsquo;s{" "}
      <ThemeAssetLink asset="templates/cart.liquid">cart.liquid</ThemeAssetLink>{" "}
      or a cart snippet included in that template.
    </span>
  );

  const inputs = [
    {
      id: "notification",
      label: "Customize the placement of the notification bar:",
      value: `<div id="saso-notifications" style="padding-top: 0px; "></div>`,
      helpText:
        "This code can be pasted wherever you like, usually in the header of your theme.",
      bannerText:
        "If you use this snippet to customize the position of the notification bar in your theme, note that the default position setting will be ignored."
    },
    {
      id: "snippet-embed-offers",
      label: "To display offers in the cart (instead of in a popup):",
      value: `<div class="saso-cart-offers"></div>`,
      helpText: cartLiquidHelpText
    },
    {
      id: "snippet-volume-tiers",
      label: "Customize where to display the Volume Tiers grid:",
      value: `<div class="saso-volumes"></div>`,
      helpText: productLiquidHelpText
    },
    {
      id: "snippet-bundle-offer",
      label: "Customize where to display the Bundle offer:",
      value: `<div class="saso-bundle"></div>`,
      helpText: productLiquidHelpText
    },
    {
      id: "snippet-cart-discounts",
      label: "Customize where to display the cart discounts summary:",
      value: `<div class="saso-summary"></div>`,
      helpText: cartLiquidHelpText
    },
    {
      id: "snippet-offer-badge",
      label: "Manually place your sales badges using this snippet:",
      value: `<div class="saso-offer-badge-container" data-saso-handle="{{ product.handle }}"></div>`,
      helpText: " ", // empty string needed for padding 🤦
      bannerText:
        "The specific placement of this snippet will vary by theme, and placement in multiple templates may be required."
    }
  ];

  return (
    <Card.Section>
      <FormLayout>
        {inputs.map(input => (
          <React.Fragment key={input.id}>
            <CopyHelperTextField {...input} />
            <CopyBanner bannerText={input.bannerText} />
          </React.Fragment>
        ))}
      </FormLayout>
    </Card.Section>
  );
};

const CopyBanner = ({ bannerText }) => {
  if (!bannerText) return null;

  return <Banner>{bannerText}</Banner>;
};

export default AdvancedPlacementSnippets;
