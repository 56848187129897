const config = {
  text: {
    title: "Text",
    // White background preferred because of scaling and card background color
    defaultImage: "https://uso-images.s3.amazonaws.com/text_blank.jpg",
    fields: [
      {
        name: "customizations.css.text.heading.color",
        label: "Heading",
        image: "https://uso-images.s3.amazonaws.com/text_header.jpg"
      },
      {
        name: "customizations.css.text.body.color",
        label: "Body",
        image: "https://uso-images.s3.amazonaws.com/text_body.jpg"
      },
      {
        name: "customizations.css.text.sale.color",
        label: "Sale",
        image: "https://uso-images.s3.amazonaws.com/text_sale.jpg"
      }
    ]
  },
  buttons: {
    title: "Buttons",
    defaultImage: "https://uso-images.s3.amazonaws.com/button_blank.jpg",
    fields: [
      {
        name: "customizations.css.button.primary.background",
        label: "Background",
        image: "https://uso-images.s3.amazonaws.com/button_background.jpg"
      },
      {
        name: "customizations.css.button.primary.color",
        label: "Text",
        image: "https://uso-images.s3.amazonaws.com/button_text.jpg"
      }
    ]
  },
  volumeTable: {
    title: "Volume Table",
    defaultImage: "https://uso-images.s3.amazonaws.com/volume_blank.jpg",
    fields: [
      {
        name: "customizations.css.table.odd.background",
        label: "Odd Rows",
        image: "https://uso-images.s3.amazonaws.com/volume_odd.jpg"
      },
      {
        name: "customizations.css.table.even.background",
        label: "Even Rows",
        image: "https://uso-images.s3.amazonaws.com/volume_even.jpg"
      }
    ]
  },
  notifications: {
    title: "Notifications",
    defaultImage:
      "https://uso-images.s3.amazonaws.com/notification_bar_blank.jpg",
    fields: [
      {
        type: "select",
        options: [
          { label: "At the top of my store", value: "top" },
          { label: "At the bottom of my store", value: "bottom" }
        ],
        name: "notifications.position",
        label: "Position",
        image: "https://uso-images.s3.amazonaws.com/notification_bar_blank.jpg"
      },
      {
        name: "notifications.background_color",
        label: "Background",
        image:
          "https://uso-images.s3.amazonaws.com/notification_bar_background.jpg"
      },
      {
        name: "notifications.text_color",
        label: "Text color",
        image: "https://uso-images.s3.amazonaws.com/notification_bar_text.jpg"
      },
      {
        type: "number",
        options: {
          min: 0,
          max: 100
        },
        name: "notifications.opacity",
        label: "Opacity",
        image: "https://uso-images.s3.amazonaws.com/notification_bar_blank.jpg"
      }
    ]
  },
  popup: {
    title: "Cart Popup",
    defaultImage: "https://uso-images.s3.amazonaws.com/popup_blank.jpg",
    fields: [
      {
        name: "customizations.css.popup.background",
        label: "Background",
        image: "https://uso-images.s3.amazonaws.com/popup_popup.jpg"
      }
    ]
  },
  bundle: {
    title: "Product Bundle",
    defaultImage: "https://uso-images.s3.amazonaws.com/bundle_blank.jpg",
    fields: [
      {
        name: "customizations.css.bundle.background",
        label: "Background",
        image: "https://uso-images.s3.amazonaws.com/bundle_background.jpg"
      }
    ]
  },
  badges: {
    title: "Sale Badges",
    defaultImage: "",
    selectImages: {
      circleOutline:
        "https://uso-images.s3.amazonaws.com/circleOutline_example.jpg",
      ribbon: "https://uso-images.s3.amazonaws.com/ribbon_example.jpg",
      cornerText: "https://uso-images.s3.amazonaws.com/cornerText_example.jpg",
      saleTag: "https://uso-images.s3.amazonaws.com/saleTag_example.jpg",
      bubble: "https://uso-images.s3.amazonaws.com/bubble_example.jpg"
    },
    fields: [
      {
        type: "select",
        updateImage: true,
        options: [
          {
            label: "Circle Outline",
            value: "circleOutline"
          },
          {
            label: "Ribbon",
            value: "ribbon"
          },
          {
            label: "Corner Text",
            value: "cornerText"
          },
          {
            label: "Sale Tag",
            value: "saleTag"
          },
          {
            label: "Bubble",
            value: "bubble"
          }
        ],
        name: "customizations.badge.type",
        label: "Type",
        image: ""
      },
      {
        name: "customizations.css.badge.background",
        label: "Background",
        image: ""
      },
      {
        name: "customizations.css.badge.color",
        label: "Text Color",
        image: ""
      }
    ]
  }
};

export default config;
