import React from "react";
import { Formik } from "formik";
import EditOfferForm from "./EditOfferForm";
import { validateOffer } from "api/offersValidation";

export default function EditOfferFormWrapped(props) {
  const {
    appPlan,
    currency,
    dismissPPUInfoMessage,
    dismissPPUSettingsMessage,
    domain,
    flags,
    formRef,
    isNewOffer,
    offer,
    moneyFormat,
    multicurrencyWarningCard,
    onSubmit,
    ppuInfoMessageDismissed,
    ppuSettingsMessageDismissed,
    updateDirty,
    redirectToAppearance
  } = props;

  return (
    <Formik
      offerId={offer.id}
      onSubmit={onSubmit}
      initialValues={offer}
      validateOnBlur={false}
      validateOnChange={false}
      validate={values => {
        // eslint-disable-next-line no-unused-vars
        const [_, errors] = validateOffer(values);

        return errors;
      }}
    >
      {form => {
        updateDirty(form.dirty);
        return (
          <EditOfferForm
            appPlan={appPlan}
            currency={currency}
            dismissPPUInfoMessage={dismissPPUInfoMessage}
            dismissPPUSettingsMessage={dismissPPUSettingsMessage}
            domain={domain}
            flags={flags}
            form={form}
            isNewOffer={isNewOffer}
            moneyFormat={moneyFormat}
            multicurrencyWarningCard={multicurrencyWarningCard}
            offerId={offer.ID}
            ppuSettingsMessageDismissed={ppuSettingsMessageDismissed}
            ppuInfoMessageDismissed={ppuInfoMessageDismissed}
            ref={formRef}
            isDirty={form.dirty}
            redirectToAppearance={redirectToAppearance}
          />
        );
      }}
    </Formik>
  );
}
