import React from "react";
import { Banner } from "@shopify/polaris";
import bannerAction from "./banner-action";
import { SetShopEvent } from "types/shop";
import { useI18n } from "@shopify/react-i18n";

interface Props {
  setEvent: SetShopEvent;
}

export default function TrialEnded({ setEvent }: Props) {
  const [i18n] = useI18n();
  const trialEndedText = i18n.translate("freePlanBanners.trialEndedText");
  const trialEndedTitle = i18n.translate("freePlanBanners.trialEndedTitle");

  const handleDismiss = () => {
    setEvent({ free_plan_trial_ended_banner_dismissed: true });
  };

  return (
    <Banner
      status="warning"
      title={trialEndedTitle}
      action={bannerAction()}
      onDismiss={handleDismiss}
    >
      <p>{trialEndedText}</p>
    </Banner>
  );
}
