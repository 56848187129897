// @flow
import React, { Component } from "react";
import { Modal } from "@shopify/app-bridge-react";
import OffersListItem from "./OffersListItem";

import { offerActions } from "store/offersStore";
import { storeConnect } from "appStore";

import { deactivatedPremiumOffers } from "lib/offerHelpers";
import type { ReportingColumnItem } from "../OffersList/OffersList";
import type { OfferReportProp } from "../OffersList/makeOfferReportingProp";

import type { Offer } from "types/flow-types/offers";
import type { AppPlan } from "types/flow-types/shop";

type Props = {
  appPlan: AppPlan,
  offer: Offer,
  domain: string,
  moneyFormat: string,
  reportingColumns: Array<ReportingColumnItem>,
  reporting: OfferReportProp,
  setOfferActive: (number, boolean) => void,
  deleteOffer: (offerId: number) => void,
  buildCustomValueTooltip: (columnKey: string) => ?(value: number) => string
};

type State = {
  deleteAlertOpen: boolean
};

class OffersListItemContainer extends Component<Props, State> {
  state = {
    deleteAlertOpen: false
  };

  getOfferStatus = (deactivated: boolean, active: boolean) => {
    if (deactivated) return "Deactivated";
    else return active ? "Enabled" : "Disabled";
  };

  handleDeleteClick = () => {
    this.setState({
      deleteAlertOpen: true
    });
  };

  toggleOfferActive = deactivated => {
    const { setOfferActive, offer } = this.props;
    !deactivated && setOfferActive(offer.id, !offer.active);
  };

  deleteOffer = () => {
    this.props.deleteOffer(this.props.offer.id);
    this.hideDeleteAlert();
  };

  hideDeleteAlert = () => {
    this.setState({ deleteAlertOpen: false });
  };

  render() {
    const { appPlan, offer } = this.props;
    const offerDeactivated = deactivatedPremiumOffers(appPlan.name).includes(
      offer.type
    );
    const offerStatus = this.getOfferStatus(offerDeactivated, offer.active);

    return (
      <React.Fragment>
        <OffersListItem
          {...this.props}
          onDelete={this.handleDeleteClick}
          setOfferActive={() => this.toggleOfferActive(offerDeactivated)}
          offerStatus={offerStatus}
        />
        <Modal
          title="Delete Offer"
          open={this.state.deleteAlertOpen}
          message="Are you sure you want to delete this offer? This cannot be undone."
          primaryAction={{
            content: "Delete Offer",
            destructive: true,
            onAction: this.deleteOffer
          }}
          secondaryActions={[
            {
              content: "Nevermind",
              onAction: this.hideDeleteAlert
            }
          ]}
        />
      </React.Fragment>
    );
  }
}

export default storeConnect(
  OffersListItemContainer,
  ({ shop }) => {
    return {
      domain: shop.data.settings.domain,
      moneyFormat: shop.data.settings.money_format,
      appPlan: shop.data.app_plan
    };
  },
  offerActions
);
