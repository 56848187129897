import React, { Component } from "react";
import PropTypes from "prop-types";
import { Popover, DatePicker } from "@shopify/polaris";

import { formatDateForInput } from "lib/dateHelpers";

class InlineDatePicker extends Component {
  constructor(props) {
    super(props);

    const value = this.props.field.value;

    const selectedDate = value ? this.getDateFromString(value) : new Date();

    this.state = {
      month: selectedDate.getMonth(),
      year: selectedDate.getFullYear(),
      selectedDate
    };
  }

  componentDidUpdate(prevProps) {
    const value = this.props.field.value;
    if (value && value !== prevProps.field.value) {
      this.setState({ selectedDate: this.getDateFromString(value) });
    }
  }

  getDateFromString(dateString) {
    return new Date(dateString.split("-"));
  }

  // update value from date picker
  handleDateChange = value => {
    const { onChange, togglePopover } = this.props;

    onChange(formatDateForInput(value.start));
    togglePopover();
  };

  handleMonthChange = (month, year) => {
    this.setState({ month, year });
  };

  render() {
    const { popoverOpen, inputField, togglePopover } = this.props;

    return (
      <Popover
        active={popoverOpen}
        activator={inputField}
        onClose={togglePopover}
        sectioned
      >
        <DatePicker
          allowRange={false}
          month={this.state.month}
          year={this.state.year}
          selected={this.state.selectedDate}
          onChange={this.handleDateChange}
          onMonthChange={this.handleMonthChange}
        />
      </Popover>
    );
  }
}

// TODO: Flow can/should do this
InlineDatePicker.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default InlineDatePicker;
