// @flow

import React, { Component } from "react";
import { Card } from "@shopify/polaris";

import {
  makeOfferOptions,
  getChartView,
  setChosenOffersInOrder
} from "../chart-helpers";
import ChartTabs from "../ChartTabs/ChartTabs";
import ChartFooter from "../ChartFooter/ChartFooter";
import ChartWrapper from "../ChartWrapper";
import { reconcileOfferColors } from "../chart-theme";
import { getRangeForSpan } from "../chart-date-helpers";

import type { ValueFormatMap } from "../reportingValueFormatters";
import type { Offer } from "types/flow-types/offers";
import type {
  NormalizedOffersReport,
  NormalizedSummaryReport
} from "store/reportsStore";
import type { LineData, ChartColorMap } from "../chart-types";
import type { DateRanges } from "lib/reporting-helpers";

import "./ReportingChart.scss";
import { nonPostPurchaseOffers as getNonPostPurchaseOffers } from "lib/offerHelpers";

type ReportingChartProps = {
  ChartLineChart: any,
  reportingRange: DateRanges,
  offers: Array<Offer>,
  offersReports: NormalizedOffersReport,
  summaryReport: NormalizedSummaryReport,
  valueFormatters: ValueFormatMap,
  shopDomain: string
};

type ReportingChartState = {
  currentStatIndex: number,
  selectedOfferIds: number[],
  offerColors: ChartColorMap
};

class ReportingChart extends Component<
  ReportingChartProps,
  ReportingChartState
> {
  tabsMap = ["orderTotal", "orderCount", "conversionRate"];
  maxOffers = 3;
  state = {
    currentStatIndex: 0,
    selectedOfferIds: [],
    offerColors: {}
  };

  handleStatChange = (currentStatIndex: number) => {
    this.setState({
      currentStatIndex
    });
  };

  handleOfferSelectionChange = (freshOfferIds: number[]) => {
    // preserve selection order, putting new ones at the end of the list
    const selectedOfferIds = setChosenOffersInOrder(
      this.state.selectedOfferIds,
      freshOfferIds
    );
    // store a reference of which color is tied to which offer
    const offerColors = reconcileOfferColors(
      selectedOfferIds,
      this.state.offerColors
    );
    this.setState({
      selectedOfferIds,
      offerColors
    });
  };

  applyLineColors = (lineData: LineData): LineData => {
    const { selectedOfferIds, offerColors } = this.state;

    if (selectedOfferIds.length > this.maxOffers) return lineData;

    for (let index = 0; index < selectedOfferIds.length; index++) {
      const offerId = selectedOfferIds[index];
      lineData[offerId].color = offerColors[offerId];
    }

    return lineData;
  };

  render() {
    const {
      ChartLineChart,
      offers,
      offersReports,
      summaryReport,
      valueFormatters,
      reportingRange
    } = this.props;
    const { currentStatIndex, selectedOfferIds } = this.state;

    const nonPostPurchaseOffers = getNonPostPurchaseOffers(offers);
    const hasPostPurchaseOffers = offers.length > nonPostPurchaseOffers.length;
    const offerOptions = makeOfferOptions(nonPostPurchaseOffers);

    const selectedOffers = selectedOfferIds
      .map(id => nonPostPurchaseOffers.find(offer => id === offer.id))
      .filter(Boolean);

    const { spanKey, axisTicks } = getRangeForSpan(reportingRange);
    const { lineMode, lineData } = getChartView(
      selectedOffers,
      offersReports,
      summaryReport,
      spanKey,
      axisTicks,
      nonPostPurchaseOffers.length,
      this.maxOffers
    );

    const lineDataWithColors = this.applyLineColors(lineData);

    const currentStat = this.tabsMap[currentStatIndex];

    return (
      <Card>
        <ChartTabs
          selectedOffers={selectedOfferIds}
          offersReports={offersReports}
          summaryReport={summaryReport}
          onTabChange={this.handleStatChange}
          currentTab={currentStatIndex}
          valueFormatters={valueFormatters}
          axisTicks={axisTicks}
          spanKey={spanKey}
          shopDomain={this.props.shopDomain}
        />
        <Card.Section>
          <ChartWrapper>
            <ChartLineChart
              activeOffers={selectedOfferIds}
              lineMode={lineMode}
              lineData={lineDataWithColors}
              currentStat={currentStat}
              axisTicks={axisTicks}
              spanKey={spanKey}
              valueFormatters={valueFormatters}
            />
          </ChartWrapper>
        </Card.Section>
        <ChartFooter
          offerOptions={offerOptions}
          lineData={lineDataWithColors}
          selectedOffers={selectedOffers}
          onOfferSelectionChange={this.handleOfferSelectionChange}
          lineMode={lineMode}
          hasPostPurchaseOffers={hasPostPurchaseOffers}
        />
      </Card>
    );
  }
}

export default ReportingChart;
