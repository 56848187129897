// @flow
import React from "react";
import { ChoiceList } from "@shopify/polaris";
import { OFFER_TYPES } from "types/constants/offers";
import type { Offer, OfferType } from "types/flow-types/offers";

export const offerFilters = ({
  offerType,
  handleOfferTypeChange,
  status,
  handleStatusChange
}: {
  offerType: OfferType[],
  handleOfferTypeChange: (offerType: OfferType[]) => void,
  status: ("Enabled" | "Disabled")[],
  handleStatusChange: (status: ("Enabled" | "Disabled")[]) => void
}) => [
  {
    key: "offerTypeFilter",
    label: "Offer type",
    filter: (
      <ChoiceList
        title="Offer type"
        titleHidden={true}
        choices={OFFER_TYPES.map(offer => ({
          label: offer.title,
          value: offer.type
        }))}
        selected={offerType}
        onChange={handleOfferTypeChange}
        allowMultiple={true}
      />
    ),
    shortcut: true
  },
  {
    key: "statusFilter",
    label: "Status",
    filter: (
      <ChoiceList
        title="Status"
        titleHidden={true}
        choices={[
          { label: "Enabled", value: "Enabled" },
          { label: "Disabled", value: "Disabled" }
        ]}
        selected={status}
        onChange={handleStatusChange}
        allowMultiple={false}
      />
    ),
    shortcut: true
  }
];

export function getOffersByFilter(
  appliedFilters: Array<Object>,
  offers: Array<Offer>
): Array<Offer> {
  if (appliedFilters.length === 0) {
    return offers;
  }

  const offerTypeSet = new Set();
  const statusSet = new Set();
  const filteredOffers = [];

  for (const filter of appliedFilters) {
    if (filter.key === "offerTypeFilter") {
      filter.value.forEach(offerTypeSet.add, offerTypeSet);
    }

    if (filter.key === "statusFilter") {
      filter.value.forEach(statusSet.add, statusSet);
    }
  }

  for (const offer of offers) {
    if (
      !(statusSet.has("Enabled") && statusSet.has("Disabled")) &&
      ((statusSet.has("Enabled") && !offer.active) ||
        (statusSet.has("Disabled") && offer.active))
    ) {
      continue;
    }

    if (offerTypeSet.size > 0 && !offerTypeSet.has(offer.type)) {
      continue;
    }

    filteredOffers.push(offer);
  }

  return filteredOffers;
}
