import React, { useContext } from "react";
import { Card, Layout, Link, Page, TextStyle } from "@shopify/polaris";
import { TitleBar } from "@shopify/app-bridge-react";

import { storeConnect } from "appStore";
import { NavigationContext } from "contexts/Navigation";

function FeatureRequestsRoute({ token }) {
  const navigation = useContext(NavigationContext);

  return (
    <>
      <TitleBar title="Feature Requests" />
      <Page
        title="Feature Requests"
        subtitle="What new features would you like to see in Ultimate Special
          Offers?"
        breadcrumbs={[
          {
            content: "Dashboard",
            onAction: () => navigation.goTo("/")
          }
        ]}
      >
        <Layout>
          <Layout.Section>
            <TextStyle variation="subdued">
              If you require support assistance, please{" "}
              <Link
                url="https://launch.orbitapps.com/hc/en-us/articles/4402979466003"
                external
              >
                contact support
              </Link>
              . This channel is not monitored for support inquires.
            </TextStyle>
          </Layout.Section>
          <Layout.Section>
            <Card sectioned>
              <iframe
                title="Product Board Portal"
                src={`https://portal.productboard.com/f1ckcfg9dehk3vpjsyhktbcn?hide_header=1&token=${token}`}
                frameBorder={0}
                width="100%"
                height={550}
              />
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    </>
  );
}

const mapStateToProps = state => {
  const {
    shop: {
      data: { productBoardToken }
    }
  } = state;

  return {
    token: productBoardToken
  };
};

export default storeConnect(FeatureRequestsRoute, mapStateToProps);
