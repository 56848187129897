// @flow
import React from "react";
import { Banner, Link } from "@shopify/polaris";
import type { ShopEventPayload } from "types/flow-types/shop";

import type { Shop } from "types/flow-types/shop";

type SetEvent = ShopEventPayload => void;

const THANK_YOU_TITLE =
  "Thank you for partnering with Ultimate Special Offers.";

export default function AccountPageMessage({
  appPlanName,
  setEvent,
  shop,
  shopifyChargeReturnError,
  trialDaysRemaining
}: {
  appPlanName: string,
  setEvent: SetEvent,
  shop: Shop,
  shopifyChargeReturnError: boolean,
  trialDaysRemaining: number
}) {
  if (shopifyChargeReturnError) return <ShopifyChargeReturnErrorMessage />;

  const planActivated = !!shop.subscription_activated_date;
  const PPUInfoMessageDismissed = shop.events.ppu_account_banner_dismissed;
  const thankYouMessageDismissed = shop.events.billing_table_message_dismissed;
  const displayPPUInfoMessage = shouldDisplayPPUInfoMessage(appPlanName);

  if (!planActivated && trialDaysRemaining > 0) {
    return <TrialMessage trialDaysRemaining={trialDaysRemaining} />;
  }
  if (!planActivated && trialDaysRemaining <= 0) {
    return <TrialExpiredMessage />;
  }

  if (planActivated && trialDaysRemaining > 0) {
    return <EarlyActivationMessage trialDaysRemaining={trialDaysRemaining} />;
  }

  if (planActivated && trialDaysRemaining <= 0) {
    if (displayPPUInfoMessage && !PPUInfoMessageDismissed) {
      return <PPUInfoMessage setEvent={setEvent} />;
    }
    if (thankYouMessageDismissed) return null;

    return <ThankYouMessage setEvent={setEvent} />;
  }
  return null;
}

function ShopifyChargeReturnErrorMessage() {
  return (
    <Banner title="Something went wrong" status="critical">
      <p>
        An error occurred while activating your plan. You can try again, or{" "}
        <Link
          url="https://launch.orbitapps.com/hc/en-us/articles/4402979466003"
          external
        >
          contact support
        </Link>
      </p>
    </Banner>
  );
}

function TrialMessage({ trialDaysRemaining }: { trialDaysRemaining: number }) {
  return (
    <Banner title={`${trialDaysRemaining} days to go on your free trial`}>
      Select and activate your subscription now to continue running offers after
      your trial has ended. You will not be charged for the remainder of your
      trial.
    </Banner>
  );
}

function TrialExpiredMessage() {
  return (
    <Banner title="Your free trial is over" status="critical">
      Activate your Ultimate Special Offers subscription to make your offers
      available to your customers.
    </Banner>
  );
}

function EarlyActivationMessage({
  trialDaysRemaining
}: {
  trialDaysRemaining: number
}) {
  return (
    <Banner status="success" title={THANK_YOU_TITLE}>
      Your subscription has been activated. Charges will begin in{" "}
      {trialDaysRemaining} days, after your free trial has ended.
    </Banner>
  );
}

function PPUInfoMessage({ setEvent }: { setEvent: SetEvent }) {
  const action = {
    content: "Learn more about Post-Purchase Upsells",
    url: "https://launch.orbitapps.com/hc/en-us/articles/4402805635859",
    external: true
  };

  return (
    <Banner
      title="Try out the power of Post-Purchase Upsell"
      status="info"
      action={action}
      onDismiss={() => setEvent({ ppu_account_banner_dismissed: true })}
    >
      Post-Purchase Upsell offer lets you promote additional or complementary
      products immediately after checkout with an optional discount.
    </Banner>
  );
}

function ThankYouMessage({ setEvent }: { setEvent: SetEvent }) {
  return (
    <Banner
      title={THANK_YOU_TITLE}
      status="success"
      onDismiss={() => setEvent({ billing_table_message_dismissed: true })}
    >
      Your subscription has been activated.
    </Banner>
  );
}

function shouldDisplayPPUInfoMessage(appPlanName: string) {
  return ["fixedWithoutPPU", "volumetricWithoutPPU"].includes(appPlanName);
}
