import React, { useContext, useEffect, useRef } from "react";
import { useFormikContext } from "formik";
import { Button, Layout } from "@shopify/polaris";

import ErrorsBannerSection from "components/banners/ErrorsBannerSection";
import { FlagsContext } from "contexts/Flags";
import GroupedPageActions from "components/GroupedPageActions";
import AppearanceSection from "./AppearanceSection";
import AdvancedSection from "./AdvancedSection";
import AppearanceBadgeSection from "./components/AppearanceBadgeSection";

import appearanceConfig from "content/appearanceConfig";
import BadgeUpdateBannerSection from "components/banners/BadgeUpdateBannerSection";

export default function AppearanceForm(props) {
  const flags = useContext(FlagsContext);
  const form = useFormikContext();
  const { dirty, submitForm, errors, touched, isSubmitting, isValid } = form;

  const wasSubmitting = useRef();
  const errorsSectionRef = useRef();

  useEffect(() => {
    if (wasSubmitting.current && !isSubmitting && !isValid) {
      errorsSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
    wasSubmitting.current = form.isSubmitting;
  });

  return (
    <Layout>
      <ErrorsBannerSection
        errors={errors}
        touched={touched}
        ref={errorsSectionRef}
      />

      <BadgeUpdateBannerSection setEvent={props.setEvent} />

      <Layout.Section>
        <form onSubmit={() => props.handleSubmit(form.values)}>
          <AppearanceSection section={appearanceConfig.text} form={form} />
          <AppearanceSection section={appearanceConfig.buttons} form={form} />
          <AppearanceSection
            section={appearanceConfig.notifications}
            form={form}
          />
          <AppearanceSection
            section={appearanceConfig.volumeTable}
            form={form}
          />
          <AppearanceSection section={appearanceConfig.popup} form={form} />
          <AppearanceSection section={appearanceConfig.bundle} form={form} />
          {/* ch123217 Badges Per Offer*/}
          {flags.releaseEnableBadgesPerOffer ? (
            <AppearanceBadgeSection form={form} />
          ) : (
            <AppearanceSection section={appearanceConfig.badges} form={form} />
          )}
          <AdvancedSection />
          <Layout.Section>
            <GroupedPageActions>
              <Button onClick={() => props.handleCancel(dirty)}>Cancel</Button>
              <Button primary onClick={submitForm}>
                Save
              </Button>
            </GroupedPageActions>
          </Layout.Section>
        </form>
      </Layout.Section>
    </Layout>
  );
}
