import React, { ReactNode } from "react";
import { TextStyle, Link } from "@shopify/polaris";

import { SettingsPageLink, ThemeAssetLink } from "components/links";
import { MainTheme } from "types/generated/graphql";

const Code = ({ children }: { children: ReactNode }) => (
  <TextStyle variation="code">{children}</TextStyle>
);

const ContactSupport = ({ capitalize = false }) => (
  <Link
    url="https://launch.orbitapps.com/hc/en-us/articles/4402979466003"
    external
  >
    {capitalize ? "C" : "c"}
    ontact our support team
  </Link>
);

export type FaqItem = {
  question: string;
  answer: ReactNode;
};
export function buildFaqItems({
  hasAjaxCartInstall,
  hasEmbeddedInstall
}: MainTheme): FaqItem[] {
  const baseFaqs = [
    {
      question:
        "Can I allow customers to use a discount code on top of an offer?",
      answer: hasAjaxCartInstall ? (
        <div>
          <p>
            Yes. You can enable this in the Multiple discounts section of the{" "}
            <SettingsPageLink>settings page</SettingsPageLink>. There you will
            also be able to specify whether to apply discount codes before or
            after your offers.
          </p>
          <strong>Notes:</strong>
          <br></br> - Discount codes that are limited to one use{" "}
          <em>per customer</em> do not work with Ultimate Special Offers.
          Discount codes that are limited to one use <em>in total</em> will be
          updated to indicate use by Ultimate Special Offers. Due to platform
          limitations they will not be marked as used. The original discount
          code will be updated to prevent repeat use. <br></br>- Popup carts do
          not support discount code use. Use a page cart instead.
        </div>
      ) : (
        <p>
          Yes. You can enable this in the Multiple discounts section of the{" "}
          <SettingsPageLink>settings page</SettingsPageLink>. There you will
          also be able to specify whether to apply discount codes before or
          after your offers. <strong>Note:</strong> Discount codes that are
          limited to one use <em>per customer</em> do not work with Ultimate
          Special Offers. Discount codes that are limited to one use{" "}
          <em>in total</em> will be updated to indicate use by Ultimate Special
          Offers. Due to platform limitations they will not be marked as used.
          The original discount code will be updated to prevent repeat use.
        </p>
      )
    },
    {
      question:
        "My customers aren't receiving discounts when they check out with PayPal. Why is that?",
      answer: (
        <p>
          Ultimate Special Offers relies on customer movement from the cart to
          the checkout page. When a customer clicks a PayPal Express button on
          the cart page, it bypasses the checkout, and discounts aren&rsquo;t
          properly applied. This can be easily fixed by moving these buttons
          from cart to checkout. <ContactSupport capitalize /> for help.
        </p>
      )
    },
    {
      question:
        "My theme uses an Ajax/popup cart. Is this compatible with your app?",
      answer: hasAjaxCartInstall ? (
        <p>Your theme is supported.</p>
      ) : (
        <p>
          Ultimate Special Offers is not currently compatible with Ajax, popup,
          or drawer carts due to the way discounts are applied.{" "}
          <ContactSupport capitalize /> for a simple solution.
        </p>
      )
    },
    {
      question: "How do I offer multiple free gifts?",
      answer: (
        <p>
          There is a section for Gift offer on the{" "}
          <SettingsPageLink>settings page</SettingsPageLink>. Select the
          &ldquo;Apply all gift offers&rdquo; option.
        </p>
      )
    },
    {
      question: "Why don't my summary stats and per-offer stats match?",
      answer: (
        <p>
          This is likely because you have recently deleted an offer. The stats
          for the deleted offer immediately disappear from your offer list but
          are still included in the summary, so long as they were active during
          the defined reporting period.
        </p>
      )
    }
  ];

  // USO app extension does not expose the necessary capabilities to allow popups, notifications
  // and other USO elements to be modified. So we're hiding the translation FAQ for themes with
  // an app extension install.
  if (hasEmbeddedInstall) {
    baseFaqs.push({
      question: "Can I translate the popups and notifications?",
      answer: (
        <p>
          Yes. First, you need to access your current theme&rsquo;s code in the
          Shopify admin (Online Store &rsaquo; Themes &rsaquo; Actions &rsaquo;
          Edit code). Open{" "}
          <ThemeAssetLink asset="snippets/special-offers-templates.liquid">
            special-offers-templates.liquid
          </ThemeAssetLink>{" "}
          and edit the text between the HTML tags to your language of choice.
          Then, find{" "}
          <ThemeAssetLink asset="snippets/special-offers.liquid">
            special-offers.liquid
          </ThemeAssetLink>
          , copy the highlighted section at the top of the file and paste it
          into theme.liquid, just above{" "}
          <Code>{`{% include special-offers %}`}</Code>. You can then change the
          text to whatever you like. <ContactSupport capitalize /> if you need a
          hand.
        </p>
      )
    });
  }

  return baseFaqs;
}

export default buildFaqItems;
