// @flow

import {
  colorSky,
  colorInkLightest,
  colorPurple,
  colorOrange,
  colorIndigo,
  colorTeal,
  fontStackBase
} from "@shopify/polaris-tokens";
import type { ChartColorMap } from "./chart-types";

// colors for individual lines. There is a max of 3 right now
export const LINE_COLORS = [colorTeal, colorOrange, colorPurple];

// color for aggregate/summary line
export const BULK_COLOR = colorIndigo;

export const theme = {
  fontFamily: fontStackBase,
  axis: {
    domain: {
      line: {
        stroke: "transparent"
      }
    },
    ticks: {
      line: {
        stroke: colorSky
      },
      text: {
        fill: colorInkLightest,
        fontSize: 12
      }
    }
  },
  markers: {
    lineColor: "red"
  },
  grid: {
    line: {
      stroke: colorSky
    }
  }
};

/**
 * Keep track of which of the offer colours are being used in the chart.
 * If a new line is added, give it a color that isn't in use.
 *
 * If more than the max # offers are chosen, some offer colors will  be undefined
 * until we get back down to three, but it doesn't matter cause they don't show up.
 */
export const reconcileOfferColors = (
  offerIds: number[],
  currentColors: ChartColorMap
): ChartColorMap => {
  const usedColors = offerIds.map(id => currentColors[id]).filter(Boolean);
  const availableColors = LINE_COLORS.filter(
    color => !usedColors.includes(color)
  );
  const newColorState: ChartColorMap = {};

  for (let index = 0; index < offerIds.length; index++) {
    const offerId = offerIds[index];
    const colorForOffer = currentColors[offerId];
    newColorState[offerId] = colorForOffer || availableColors.shift();
  }

  return newColorState;
};
