import React from "react";
import { Card, TextStyle } from "@shopify/polaris";

import { buildOfferSummary } from "lib/offerDetails";
import { getTypeSlug } from "lib/offerHelpers";

import "./OfferSummarySection.css";

const OfferSummarySection = function (props) {
  const { moneyFormat, form } = props;
  const { type, details } = form.values;
  const slug = getTypeSlug(type);

  return (
    <div className="OfferSummarySection">
      <Card subdued>
        <Card.Section title="Summary">
          <TextStyle variation="subdued">
            {buildOfferSummary(slug, details, moneyFormat)}
          </TextStyle>
        </Card.Section>
      </Card>
    </div>
  );
};

export default OfferSummarySection;
