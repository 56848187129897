import React from "react";
import { Select } from "@shopify/polaris";

export default function PolarisFormikSelect(props) {
  const {
    field,
    form,
    options,
    label,
    onChangeCallback,
    disabled,
    placeholder
  } = props;
  const { name, value } = field;
  return (
    <Select
      disabled={disabled}
      label={label}
      options={options}
      value={value}
      onChange={val => {
        form.setFieldValue(name, val);
        onChangeCallback && onChangeCallback(val);
      }}
      placeholder={placeholder}
    />
  );
}
