import React from "react";
import { Card, Subheading } from "@shopify/polaris";

import AccordionItem from "components/AccordionItem";
import { ThemeAssetLink } from "components/links";
import AdvancedPlacementSnippets from "./AdvancedPlacementSnippets";
import { useMainThemeHasEmbeddedInstallQuery } from "graphql/queries/main-theme-has-embedded-install.generated";

export default function AdvancedSection() {
  const { data } = useMainThemeHasEmbeddedInstallQuery();
  return (
    <Card>
      <AccordionItem heading={<Subheading>Advanced</Subheading>} index={1}>
        {data?.mainTheme.hasEmbeddedInstall && (
          <SpecialOffersAdvancedMessaging />
        )}
        <AdvancedPlacementSnippets />
      </AccordionItem>
    </Card>
  );
}

function SpecialOffersAdvancedMessaging() {
  return (
    <>
      <p className="advanced-liquid-links">
        Change the style of your offers by editing your{" "}
        <ThemeAssetLink asset="assets/special-offers.scss.liquid">
          special-offers.scss.liquid
        </ThemeAssetLink>{" "}
        file.
      </p>
      <p className="advanced-liquid-links">
        Change your offer templates by editing your{" "}
        <ThemeAssetLink asset="snippets/special-offers-templates.liquid">
          special-offers-templates.liquid
        </ThemeAssetLink>{" "}
        file.
      </p>
    </>
  );
}
