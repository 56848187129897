// @flow
import React, { useState } from "react";
import { Card, Icon } from "@shopify/polaris";
import { CircleTickOutlineMinor } from "@shopify/polaris-icons";
import { Toast } from "@shopify/app-bridge-react";

import { formatCurrency } from "./account-helpers";

import PlanCardContent from "./PlanCardContent";

import "./PlanCard.scss";

const billingPlanPrice = 9;
const tiers = {
  LITE: "First 10 orders FREE",
  STARTER: "Next 100 orders 99¢ each",
  GROWTH: "Next 1000 orders 49¢ each",
  UNLIMITED: "Everything after FREE"
};

const renderTiers = tiers => {
  return Object.keys(tiers).map(tier => (
    <div key={tier} className="Plan-Card-Tier">
      {`${tier}: ${tiers[tier]}`}
    </div>
  ));
};

const ErrorToast = ({ error, setError }) => {
  if (!error) return null;

  return <Toast content={error} error onDismiss={() => setError(null)} />;
};

export default function PlanCard({
  planActivated,
  setEvent,
  shop
}: {
  planActivated: boolean,
  setEvent: any,
  shop: any
}) {
  const [error, setError] = useState(null);

  return (
    <>
      {planActivated && (
        <div className="Checkmark-Container">
          <Icon
            source={CircleTickOutlineMinor}
            color="success"
            backdrop={true}
          />
        </div>
      )}
      <div className="Ultimate-Plan-Card">
        <Card>
          <div className="Plan-Header-Wrapper">
            <div className="Plan-Name">
              <p className="basic-package">Ultimate plan</p>
            </div>
            <div className="Monthly-Cost">
              <span>{formatCurrency(billingPlanPrice, 0)}</span>
              <div className="Monthly-Subtitle">per month + usage fees</div>
            </div>
          </div>
          <PlanCardContent
            planCardactivated={planActivated}
            setError={setError}
            setEvent={setEvent}
            shop={shop}
          />
          <div className="Plan-Tiers">{renderTiers(tiers)}</div>
        </Card>
      </div>
      <ErrorToast error={error} setError={setError} />
    </>
  );
}
