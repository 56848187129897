import React, { useContext } from "react";
import { Layout, Page } from "@shopify/polaris";
import { TitleBar } from "@shopify/app-bridge-react";
import { NavigationContext } from "contexts/Navigation";
import { SetShopEvent } from "types/shop";
import AccountPageBanner from "routes/account/percentage-volumetric/AccountPageBanner";
import PlanCardSection from "routes/account/percentage-volumetric/PlanCardSection";
import UsageFeesSection from "routes/account/percentage-volumetric/UsageFeesSection";

interface Props {
  setEvent: SetShopEvent;
}

function AccountPage(props: Props) {
  const { setEvent } = props;
  const navigation = useContext(NavigationContext);

  return (
    <Page
      title="Account"
      breadcrumbs={[
        {
          content: "Dashboard",
          onAction: () => navigation.goTo("/")
        }
      ]}
    >
      <TitleBar title="Account" />
      <Layout>
        <Layout.Section>
          <AccountPageBanner setEvent={setEvent} />
        </Layout.Section>
        <PlanCardSection setEvent={setEvent} />
        <UsageFeesSection />
      </Layout>
    </Page>
  );
}

export default AccountPage;
