import React, { useContext } from "react";
import { Field, getIn } from "formik";
import PolarisFormikTextField from "components/PolarisFormikTextField";

import defaultBadge from "types/constants/default-badge";
import { ShopContext } from "../../contexts/Shop";

export default function BadgeTextInput({
  helpText = "Leave blank to use default"
}) {
  function isWhitespace(str) {
    return /\s+/.test(str);
  }
  const shop = useContext(ShopContext);
  const defaultBadgeText =
    getIn(shop, "customizations.badge.badge_text") || defaultBadge.badge_text;
  const placeHolderText = isWhitespace(defaultBadgeText)
    ? "Default badge text is blank"
    : defaultBadgeText;

  return (
    <Field
      component={PolarisFormikTextField}
      helpText={helpText}
      label="Badge text"
      name="customizations.badge.badge_text"
      placeholder={placeHolderText}
    />
  );
}
