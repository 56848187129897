// @flow
import type { Shop } from "types/flow-types/shop";
import { requiresDiscountScopesUpdate } from "./scopesHelpers";

export function initializeBeamer(shop: Shop): void {
  const beamerKey = process.env.REACT_APP_BEAMER_KEY;

  if (!beamerKey) return;

  const scriptSrc = "https://app.getbeamer.com/js/beamer-embed.js";
  const { email, myshopify_domain, name } = shop.settings;

  const filter = getFilter(shop);

  // https://www.getbeamer.com/docs/#params
  window.beamer_config = {
    product_id: process.env.REACT_APP_BEAMER_KEY,
    user_firstname: name,
    user_id: myshopify_domain,
    user_email: email,
    button: false,
    filter
  };

  const beamerScript = document.createElement("script");
  beamerScript.setAttribute("src", scriptSrc);
  beamerScript.setAttribute("defer", "true");

  //$FlowDisableNextLine
  document.body.appendChild(beamerScript);
}

// Beamer accepts a semi-colon-separated string list of filters that can be used
// to target segments https://www.getbeamer.com/help/how-to-use-segmentation
function getFilter(shop) {
  return requiresDiscountScopesUpdate(shop)
    ? "requiresDiscountCheckoutScopes"
    : "";
}
