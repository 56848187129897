import React, { useContext } from "react";
import { Layout, Banner, Button, Stack } from "@shopify/polaris";
import { NavigationContext } from "contexts/Navigation";

export default function PPUSettingsBanner({ dismissPPUSettingsMessage }) {
  const navigation = useContext(NavigationContext);
  return (
    <div style={{ width: "100%" }} data-testid="ppuSettingsBanner">
      <Layout.Section>
        <Banner
          status="info"
          title="Post-Purchase Upsell activation"
          onDismiss={() => dismissPPUSettingsMessage()}
        >
          <Stack vertical>
            <p>
              You must manually select Ultimate Special Offers in your Shopify
              checkout settings to use Post-Purchase Upsells.
            </p>
            <Stack>
              <Button
                onClick={() =>
                  navigation.redirectAdmin("/settings/checkout#post-purchase")
                }
              >
                Enable Post-Purchase Upsells
              </Button>
              <Button outline onClick={dismissPPUSettingsMessage}>
                I have enabled Post-Purchase Upsells
              </Button>
            </Stack>
          </Stack>
        </Banner>
      </Layout.Section>
    </div>
  );
}
