import React from "react";
import { TextStyle } from "@shopify/polaris";

interface ChartLabelProps {
  label: string;
  color: string;
}

export default function ChartLabel({ label, color }: ChartLabelProps) {
  return (
    <span className="ChartLabel__Item">
      <span className="ChartLabel__Pip" style={{ backgroundColor: color }} />
      <span className="ChartLabel__Text">
        <TextStyle variation="subdued">{label}</TextStyle>
      </span>
    </span>
  );
}
