import React, { Component, createContext } from "react";
import history from "lib/history";
import { trackNavigation } from "lib/analytics";

import { History, Redirect } from "@shopify/app-bridge/actions";
import { Context } from "@shopify/app-bridge-react";

export const NavigationContext = createContext();

export default class Navigation extends Component {
  static contextType = Context;

  componentDidMount = () => {
    this.context.subscribe(History.Action.PUSH, historyData => {
      history.push(historyData.path);
    });
    this.appRedirect = Redirect.create(this.context);
    this.appHistory = History.create(this.context);
  };

  goTo = path => {
    this.appHistory.dispatch(History.Action.PUSH, path);
    trackNavigation();
  };

  redirect = url => {
    this.appRedirect.dispatch(Redirect.Action.REMOTE, url);
    trackNavigation();
  };

  redirectAdmin = path => {
    this.appRedirect.dispatch(Redirect.Action.ADMIN_PATH, path);
    trackNavigation();
  };

  replaceURL = path => {
    this.appHistory.dispatch(History.Action.REPLACE, path);
    trackNavigation();
  };

  render() {
    const contextObject = {
      goTo: this.goTo,
      redirect: this.redirect,
      redirectAdmin: this.redirectAdmin,
      replaceURL: this.replaceURL,
      history: history
    };

    return (
      <NavigationContext.Provider value={contextObject}>
        {this.props.children}
      </NavigationContext.Provider>
    );
  }
}
