import React from "react";
import {
  Card,
  TextContainer,
  TextStyle,
  FormLayout,
  Button
} from "@shopify/polaris";
import { colorSky } from "@shopify/polaris-tokens";
import { CancelSmallMinor } from "@shopify/polaris-icons";
import { useI18n } from "@shopify/react-i18n";

import { FieldArray, getIn } from "formik";

import offerPlaceholders from "content/offerPlaceholders";
import PolarisFormikInventoryPicker from "components/PolarisFormikInventoryPicker";
import PolarisFormikDiscountSection from "components/PolarisFormikDiscountSection";
import PolarisFormikCheckbox from "components/PolarisFormikCheckbox";
import OfferMessageSection from "components/OfferMessageSection";
import OfferSummarySection from "components/OfferSummarySection";
import OfferConfigurationSection from "components/OfferConfigurationSection";
import { DEFAULT_OFFER_FIELD_GROUPS } from "types/constants/offers";

import { useMainThemeHasAjaxCartInstallQuery } from "graphql/queries/main-theme-has-ajax-cart-install.generated";

// Check each bundle in the form to see if it contains any variants
function variantsSelected(form) {
  return getIn(form, "values.details.bundles", []).reduce((acc, bundle) => {
    if (!bundle.buy_from.product_variants) return acc;
    return acc || bundle.buy_from.product_variants.length !== 0;
  }, false);
}

export default function BundleSection(props) {
  const {
    form,
    moneyFormat,
    offerTypeSlug,
    multicurrencyWarningCard,
    redirectToAppearance
  } = props;
  const { values } = form;

  const hasBundles = values.details.bundles.length > 0;

  const buttonRowStyle = {
    marginTop: "2rem",
    paddingTop: "2rem",
    borderTop: `1px solid ${colorSky}`
  };

  const variantPresence = variantsSelected(form);

  const placeholderType = variantPresence ? "variant" : "nonVariant";

  return [
    <OfferConfigurationSection
      key="offer-configuration"
      offerTypeSlug={offerTypeSlug}
    >
      <Card>
        <Card.Section title="Bundle Items">
          <FormLayout>
            <TextContainer>
              Choose complementary products to sell together at a discounted
              price.
            </TextContainer>
            <FieldArray name="details.bundles" validateOnChange={false}>
              {arrayHelpers => (
                <div>
                  {values.details.bundles.map((bundle, index) => (
                    <PolarisFormikInventoryPicker
                      key={index}
                      name={`details.bundles[${index}].buy_from`}
                      qtyName={`details.bundles[${index}].quantity`}
                      form={form}
                      extras={
                        <Button
                          icon={CancelSmallMinor}
                          onClick={() => {
                            arrayHelpers.remove(index);
                          }}
                        />
                      }
                    />
                  ))}
                  <div style={hasBundles ? buttonRowStyle : {}}>
                    <button
                      type="button"
                      className="Polaris-Link"
                      onClick={() =>
                        arrayHelpers.push({
                          buy_from: { ...DEFAULT_OFFER_FIELD_GROUPS.inventory },
                          quantity: 1
                        })
                      }
                    >
                      {hasBundles ? "+ Add another item" : "+ Add an item"}
                    </button>
                  </div>
                </div>
              )}
            </FieldArray>
          </FormLayout>
        </Card.Section>
        <Card.Section title="Bundle discount">
          <PolarisFormikDiscountSection
            name="details.price"
            form={form}
            moneyFormat={moneyFormat}
            multicurrencyWarningCard={multicurrencyWarningCard}
          />
          <FormLayout>
            <div style={{ marginTop: "30px" }}>
              <PolarisFormikCheckbox
                name="details.show_upsell"
                label={<span>Show an upsell popup with sale products.</span>}
              />
              <TextContainer>
                <TextStyle variation="subdued">
                  <UpsellNoteText />
                </TextStyle>
              </TextContainer>
            </div>
          </FormLayout>
          <OfferSummarySection form={form} moneyFormat={moneyFormat} />
        </Card.Section>
      </Card>
    </OfferConfigurationSection>,
    <OfferMessageSection
      key="offer-message"
      offerTypeSlug={offerTypeSlug}
      redirectToAppearance={redirectToAppearance}
      variantPresence={variantPresence}
      fields={[
        {
          label: "Initial message",
          name: "notifications.message",
          placeholder: offerPlaceholders.bundle.initial[placeholderType]
        },
        {
          label: "Success message",
          name: "notifications.message_after",
          placeholder: offerPlaceholders.bundle.success
        }
      ]}
    />
  ];
}

function UpsellNoteText() {
  const { data, error, loading } = useMainThemeHasAjaxCartInstallQuery();
  const [i18n] = useI18n();

  if (loading || error) return null;

  if (data?.mainTheme.hasAjaxCartInstall) {
    return i18n.translate("offers.bundle.upsellNoteHasAjaxCartInstall");
  }

  return i18n.translate("offers.bundle.upsellNote");
}
