import * as Types from "../../types/generated/graphql";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type DiscountsAppEmbedStatusQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type DiscountsAppEmbedStatusQuery = {
  __typename?: "RootQueryType";
  discountsAppEmbedStatus: {
    __typename?: "DiscountsAppEmbedStatus";
    enabled: boolean;
  };
};

export const DiscountsAppEmbedStatusDocument = gql`
  query DiscountsAppEmbedStatus {
    discountsAppEmbedStatus {
      enabled
    }
  }
`;

/**
 * __useDiscountsAppEmbedStatusQuery__
 *
 * To run a query within a React component, call `useDiscountsAppEmbedStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscountsAppEmbedStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscountsAppEmbedStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useDiscountsAppEmbedStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DiscountsAppEmbedStatusQuery,
    DiscountsAppEmbedStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DiscountsAppEmbedStatusQuery,
    DiscountsAppEmbedStatusQueryVariables
  >(DiscountsAppEmbedStatusDocument, options);
}
export function useDiscountsAppEmbedStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DiscountsAppEmbedStatusQuery,
    DiscountsAppEmbedStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DiscountsAppEmbedStatusQuery,
    DiscountsAppEmbedStatusQueryVariables
  >(DiscountsAppEmbedStatusDocument, options);
}
export type DiscountsAppEmbedStatusQueryHookResult = ReturnType<
  typeof useDiscountsAppEmbedStatusQuery
>;
export type DiscountsAppEmbedStatusLazyQueryHookResult = ReturnType<
  typeof useDiscountsAppEmbedStatusLazyQuery
>;
export type DiscountsAppEmbedStatusQueryResult = Apollo.QueryResult<
  DiscountsAppEmbedStatusQuery,
  DiscountsAppEmbedStatusQueryVariables
>;
