const perOfferStaticBadgeStyles = `<style>
/* COMMON */
.saso-offer-badge {
  --inset: 20px;
  font-weight: 800;
  text-align: center;
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: 17px;
  box-sizing: border-box;
}

.saso-offer-badge:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.saso-offer-badge-content {
  position: absolute;
  width: 100%;
  height: 100%;
  padding-top: 30%;
  color: var(--color);
  background: var(--background);
  min-height: 2.215em;
}

.saso-offer-badge-text {
  padding: 0.25em;
}

.saso-offer-badge-position-topLeft {
    top: 0;
    bottom: auto;
    left: 0;
    right: auto;
}
.saso-offer-badge-position-topRight {
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
}
.saso-offer-badge-position-bottomLeft {
    bottom: 0;
    left: 0;
    right: auto;
    top: auto;
}
.saso-offer-badge-position-bottomRight {
    bottom: 0;
    right: 0;
    left: auto;
    top: auto;
}

.saso-offer-badge-position-topLeft .saso-offer-badge-content {
    top: var(--inset);
    left: var(--inset);
    bottom: auto;
    right: auto;
}
.saso-offer-badge-position-topRight .saso-offer-badge-content {
    top: var(--inset);
    right: var(--inset);
    bottom: auto;
    left: auto;
}
.saso-offer-badge-position-bottomLeft .saso-offer-badge-content {
    bottom: var(--inset);
    left: var(--inset);
    top: auto;
    right: auto;
}
.saso-offer-badge-position-bottomRight .saso-offer-badge-content {
    bottom: var(--inset);
    right: var(--inset);
    top: auto;
    left: auto;
}

/* RIBBON & CORNER TEXT */
.saso-offer-badge-type-ribbon {
  width: 100%;
  height: 100%;
  border-width: 0;
  background: none !important;
}

.saso-offer-badge-type-ribbon .saso-offer-badge-content {
  --inset: 40px;
  padding: 0;
  height: auto;
  width: 100%;
  min-height: 1.7em;
  border-left: none!important;
  border-right: none!important;
}

.saso-offer-badge-type-ribbon .saso-offer-badge-text {
  width: 8em;
  margin: 0 auto;
}

.saso-offer-badge-type-corner {
  --inset: 0;
  background: none;
}

.saso-offer-badge-type-corner .saso-offer-badge-content {
  height: auto;
  width: 100%;
  padding: 0;
  background: var(--background);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border-left: none!important;
  border-right: none!important;
  border-top: none!important;
}

.saso-offer-badge-type-corner .saso-offer-badge-text {
  width: 6em;
  padding-top: 40px;
}

.saso-offer-badge-type-ribbon.saso-offer-badge-position-topLeft .saso-offer-badge-content,
 .saso-offer-badge-type-corner.saso-offer-badge-position-topLeft .saso-offer-badge-content {
  transform: rotate(-45deg) translate(-50%);
  transform-origin: 0 0;
}

.saso-offer-badge-type-ribbon.saso-offer-badge-position-topRight .saso-offer-badge-content,
 .saso-offer-badge-type-corner.saso-offer-badge-position-topRight .saso-offer-badge-content {
  transform: rotate(45deg) translate(50%);
  transform-origin: 100% 0%;
}

.saso-offer-badge-type-ribbon.saso-offer-badge-position-bottomLeft .saso-offer-badge-content,
 .saso-offer-badge-type-corner.saso-offer-badge-position-bottomLeft .saso-offer-badge-content  {
  transform: rotate(45deg) translate(-50%);
  transform-origin: 0% 100%;
}

.saso-offer-badge-type-ribbon.saso-offer-badge-position-bottomRight .saso-offer-badge-content,
 .saso-offer-badge-type-corner.saso-offer-badge-position-bottomRight .saso-offer-badge-content {
  transform: rotate(-45deg) translate(50%);
  transform-origin: 100% 100%;
}

.saso-offer-badge-type-corner.saso-offer-badge-position-bottomLeft .saso-offer-badge-content,
  .saso-offer-badge-type-corner.saso-offer-badge-position-bottomRight .saso-offer-badge-content {
  justify-content: flex-start;
}

.saso-offer-badge-type-corner.saso-offer-badge-position-bottomLeft .saso-offer-badge-text,
  .saso-offer-badge-type-corner.saso-offer-badge-position-bottomRight .saso-offer-badge-text {
  padding-bottom: 40px;
  padding-top: 0.5em;
}

/* CIRCLE */
.saso-offer-badge-type-circle .saso-offer-badge-content {
  padding: 0 0 calc(5em - 4px) 0;
  width: 5em;
  height: auto;
  border-width: 2px;
  border-color: transparent;
  border-radius: 50%;
  border-style: solid;
}

.saso-offer-badge-type-circle .saso-offer-badge-text {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
}

/* BANNER */
.saso-offer-badge-type-banner {
  --inset: 0;
  width: 100%;
}

.saso-offer-badge-type-banner .saso-offer-badge-content {
  width: 100%;
  height: auto;
  background: var(--background);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-left: none!important;
  border-right: none!important;
}
.saso-offer-badge-type-banner.saso-offer-badge-position-topLeft .saso-offer-badge-content,
.saso-offer-badge-type-banner.saso-offer-badge-position-topRight .saso-offer-badge-content {
 border-top: none!important;
}
.saso-offer-badge-type-banner.saso-offer-badge-position-bottomLeft .saso-offer-badge-content,
.saso-offer-badge-type-banner.saso-offer-badge-position-bottomRight .saso-offer-badge-content {
 border-bottom: none!important;
}

/* RECTANGLE, OVAL */
.saso-offer-badge-type-rectangle .saso-offer-badge-content,
.saso-offer-badge-type-oval .saso-offer-badge-content {
  height: auto;
  width: auto;
  background: var(--background);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25em 0.5em;
  min-width: 25%;
  max-width: 66%;
}

.saso-offer-badge-type-rectangle .saso-offer-badge-text,
.saso-offer-badge-type-oval .saso-offer-badge-text {
  width: auto;
  overflow: hidden;
}

.saso-offer-badge-type-oval .saso-offer-badge-content {
  border-radius: 100px;
}

/* PENNANT & SWALLOWTAIL */
.saso-offer-badge-type-pennant, .saso-offer-badge-type-swallowtail {
  width: auto;
  height: auto;
  border-radius: 0;
  border: none;
  min-width: 33%;
  max-width: 66%;
}

.saso-offer-badge-type-pennant.saso-offer-badge-position-topLeft, .saso-offer-badge-type-swallowtail.saso-offer-badge-position-topLeft {
    top: var(--inset);
    left: var(--inset);
    bottom: auto;
    right: auto;
    text-align: left;
}
.saso-offer-badge-type-pennant.saso-offer-badge-position-topRight, .saso-offer-badge-type-swallowtail.saso-offer-badge-position-topRight {
    top: var(--inset);
    right: var(--inset);
    bottom: auto;
    left: auto;
    text-align: right;
}
.saso-offer-badge-type-pennant.saso-offer-badge-position-bottomLeft, .saso-offer-badge-type-swallowtail.saso-offer-badge-position-bottomLeft {
    bottom: var(--inset);
    left: var(--inset);
    top: auto;
    right: auto;
    text-align: left;
}
.saso-offer-badge-type-pennant.saso-offer-badge-position-bottomRight, .saso-offer-badge-type-swallowtail.saso-offer-badge-position-bottomRight {
    bottom: var(--inset);
    right: var(--inset);
    top: auto;
    left: auto;
    text-align: right;
}

.saso-offer-badge-type-pennant.saso-offer-badge-position-topLeft .saso-offer-badge-content,
.saso-offer-badge-type-pennant.saso-offer-badge-position-topRight .saso-offer-badge-content,
.saso-offer-badge-type-pennant.saso-offer-badge-position-bottomLeft .saso-offer-badge-content,
.saso-offer-badge-type-pennant.saso-offer-badge-position-bottomRight .saso-offer-badge-content,
.saso-offer-badge-type-swallowtail.saso-offer-badge-position-topLeft .saso-offer-badge-content,
.saso-offer-badge-type-swallowtail.saso-offer-badge-position-topRight .saso-offer-badge-content,
.saso-offer-badge-type-swallowtail.saso-offer-badge-position-bottomLeft .saso-offer-badge-content,
.saso-offer-badge-type-swallowtail.saso-offer-badge-position-bottomRight .saso-offer-badge-content {
    top: auto;
    left: auto;
    bottom: auto;
    right: auto;
    border: none!important;
}
.saso-offer-badge-type-pennant.saso-offer-badge-position-topRight .saso-offer-badge-content,
 .saso-offer-badge-type-pennant.saso-offer-badge-position-bottomRight .saso-offer-badge-content,
 .saso-offer-badge-type-swallowtail.saso-offer-badge-position-topRight .saso-offer-badge-content,
 .saso-offer-badge-type-swallowtail.saso-offer-badge-position-bottomRight .saso-offer-badge-content {
    text-align: right;
    margin: 0 0 0 auto;
}

.saso-offer-badge-type-pennant:after, .saso-offer-badge-type-swallowtail:after {
    display: none;
}

.saso-offer-badge-type-pennant svg, .saso-offer-badge-type-swallowtail svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  fill: var(--background);
}
.saso-offer-badge-type-pennant.saso-offer-badge-position-topRight svg,
 .saso-offer-badge-type-pennant.saso-offer-badge-position-bottomRight svg,
 .saso-offer-badge-type-swallowtail.saso-offer-badge-position-topRight svg,
 .saso-offer-badge-type-swallowtail.saso-offer-badge-position-bottomRight svg {
    transform: rotate(180deg);
}

.saso-offer-badge-type-pennant .saso-offer-badge-content, .saso-offer-badge-type-swallowtail .saso-offer-badge-content {
  height: auto;
  width: 90%;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: none !important;
  position: relative;
}
.saso-offer-badge-type-pennant .saso-offer-badge-text, .saso-offer-badge-type-swallowtail .saso-offer-badge-text {
  padding: 0.5em;
}

/* Borders */
.saso-offer-badge-border .saso-offer-badge-content {
    border-style: solid;
    border-color: var(--color);
}
.saso-offer-badge-border svg {
    stroke: var(--color);
    stroke-width: 2px;
}
</style>`;

export default perOfferStaticBadgeStyles;
