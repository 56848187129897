import React from "react";
import { Icon, Tooltip } from "@shopify/polaris";
import { QuestionMarkMinor } from "@shopify/polaris-icons";

export default function ThreeButtonsTooltip() {
  const explainer = (
    <div>
      Use the <strong>Choose products</strong> button to target all variants for
      a product, and use the <strong>Choose variants</strong> button to target
      specific variants.
    </div>
  );

  return (
    <Tooltip
      light
      preferredPosition="mostSpace"
      content={explainer}
      overlayDetails="450px"
    >
      <Icon source={QuestionMarkMinor} />
    </Tooltip>
  );
}
