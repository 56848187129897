// @flow
import { initShop } from "api/shop";
import { putSettings } from "api/settings";
import { putCustomizations } from "api/customizations";
import { getCompanionAppsStatus } from "api/checkCompanionApps";
import { patchEvents } from "api/events";
import { identifyUser, trackNavigation, trackCookieEvent } from "lib/analytics";
import { initializeBeamer } from "lib/beamer";
import isEmptyObj from "lib/isEmptyObj";
import { FETCH_STATES, STATUSES } from "lib/constants";
import copy from "content/copy.json";
import { deleteRecurringCharge } from "api/recurring-charges";

import type { ShopEventPayload } from "types/flow-types/shop";

const { freePlanCardModalToastError, freePlanCardModalToastSuccess } =
  copy.account.freePlan;

// Any values we need to pull from shop settings must be defined from the
// beginning so that state updating works.
export const DEFAULT_SHOP_STATE = {
  slug: "",
  access_token: "",
  settings: {
    email: "",
    currency: "",
    domain: "",
    money_format: "",
    plan_name: ""
  },
  app_settings: {
    offers_method: null
  },
  events: {
    first_offer_created: null,
    cart_banner_dismissed: null,
    badge_update_banner_dismissed: null,
    multicurrency_warning_banner_dismissed: null
  },
  subscription_activated_date: null,
  trial_days: 0,
  plan_status: null,
  flags: {}
};

export const SHOP_EMPTY_STATE = {
  data: DEFAULT_SHOP_STATE,
  fetchState: FETCH_STATES.NULL
};

export const PLAN_EMPTY_STATE = {
  data: null,
  fetchState: null
};

export const shopActions = (store: Object) => ({
  clearToast: () => ({ toast: null }),

  async initShop(state: Object) {
    const { shop } = state;
    store.setState({
      shop: {
        ...shop,
        fetchState: FETCH_STATES.STARTED,
        status: STATUSES.READING
      }
    });

    const response = await initShop();

    if (response.error) {
      store.setState({
        shop: {
          ...store.getState().shop,
          fetchState: FETCH_STATES.ERROR
        }
      });
    } else {
      const { shop } = response.data;

      identifyUser(shop.settings);
      initializeBeamer(shop);
      trackNavigation();
      trackCookieEvent();

      store.setState({
        shop: {
          data: shop,
          fetchState: FETCH_STATES.SUCCESS
        }
      });
    }
  },
  async deleteRecurringCharge(state: Object, onError?: () => void) {
    const response = await deleteRecurringCharge();
    if (response.error) {
      store.setState({
        toast: {
          content: freePlanCardModalToastError,
          error: true
        }
      });
      if (onError) onError();
    } else {
      const { appPlan } = response.data;
      store.setState({
        shop: {
          ...state.shop,
          data: {
            ...state.shop.data,
            app_plan: appPlan
          }
        },
        toast: {
          content: freePlanCardModalToastSuccess
        }
      });
    }
  },

  // TODO putSettings should only save settings, putCustomizations the customizations
  // Need to separate logic in storefront first
  async putSettings(state: Object, settings: Object, onSuccess?: () => void) {
    store.setState({
      shop: {
        ...state.shop,
        status: STATUSES.UPDATING
      }
    });

    const { error, data } = await putSettings(settings);

    if (error) {
      store.setState({
        shop: {
          ...store.getState().shop
        },
        toast: {
          content: "We're sorry, there was a problem saving your settings",
          error: true
        }
      });
    } else {
      store.setState({
        shop: {
          ...state.shop,
          data: {
            ...state.shop.data,
            app_settings: data,
            // TODO: not this - get customizations back from api?
            customizations: settings.customizations
          }
        },
        toast: {
          content: "Settings saved"
        }
      });

      if (onSuccess) onSuccess();
    }
  },

  async putCustomizations(
    state: Object,
    settings: Object,
    onSuccess?: () => void
  ) {
    store.setState({
      shop: {
        ...state.shop,
        status: STATUSES.UPDATING
      }
    });

    const { error, data } = await putCustomizations(settings);

    if (error) {
      store.setState({
        shop: {
          ...store.getState().shop
        },
        toast: {
          content: "We're sorry, there was a problem saving your settings",
          error: true
        }
      });
    } else {
      store.setState({
        shop: {
          ...state.shop,
          data: {
            ...state.shop.data,
            app_settings: data,
            // TODO: not this - get customizations back from api?
            customizations: settings.customizations
          }
        },
        toast: {
          content: "Settings saved"
        }
      });

      if (onSuccess) onSuccess();
    }
  },

  // Same with compantion app fetch. Tracking errors but not reporting out
  // errors unless there's a UI case to do so.
  async checkCompanionApps() {
    const { error, data } = await getCompanionAppsStatus();

    if (error) {
      // TODO: report error
    }

    const companionAppsInstalled = data && isEmptyObj(data);

    store.setState({ companionAppsInstalled });
  },

  setEvent(state: Object, payload: ShopEventPayload) {
    const { shop } = state;

    patchEvents(payload);

    store.setState({
      shop: {
        data: {
          ...shop.data,
          events: {
            ...shop.data.events,
            ...payload
          }
        }
      }
    });
  }
});
