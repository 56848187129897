import React, { useContext } from "react";
import { Banner, Layout } from "@shopify/polaris";
import { HelpDocsLink } from "components/links";

import { ShopContext } from "contexts/Shop";

const BadgeUpdateBannerSection = props => {
  const shop = useContext(ShopContext);

  // ch123217 Badges Per Offer
  if (
    !shop.flags.releaseEnableBadgesPerOffer ||
    shop.events.badge_update_banner_dismissed
  ) {
    return null;
  }

  const dismissBanner = () => {
    props.setEvent({
      badge_update_banner_dismissed: true
    });
  };

  return (
    <Layout.Section>
      <Banner status="info" onDismiss={dismissBanner}>
        <p>
          Sale badges have been updated to include customizable text along with
          more appearance options! Take a look through your settings and{" "}
          <HelpDocsLink helpDocsType="salesBadges" title="click here" />
          for more information on customizing sale badges.
        </p>
      </Banner>
    </Layout.Section>
  );
};

export default BadgeUpdateBannerSection;
