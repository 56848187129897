// @flow
import React from "react";
import { Card, TextContainer, Heading } from "@shopify/polaris";

type AdProps = {
  ad: {
    title: string,
    description: string,
    imageSource: string,
    url: string
  }
};

export default function AdCard(props: AdProps) {
  const { url, imageSource, title, description } = props.ad;

  const openAdLink = () => {
    window.open(url, "_blank");
  };

  return (
    <div style={{ cursor: "pointer" }} onClick={openAdLink}>
      <Card sectioned>
        <div style={{ display: "flex" }}>
          <img
            src={imageSource}
            alt={title}
            style={{
              borderRadius: "1rem",
              marginRight: "2rem",
              width: "64px",
              height: "64px"
            }}
          />
          <TextContainer>
            <Heading>{title}</Heading>
            <p style={{ marginTop: "0.4rem" }}>{description}</p>
          </TextContainer>
        </div>
      </Card>
    </div>
  );
}
