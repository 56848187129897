// @flow
import React from "react";
import { DataTable, Card } from "@shopify/polaris";
import { timestamp } from "./account-helpers";
import { formatUsageDataForTable } from "./account-helpers";

import type { CurrentBillingPeriodData } from "types/flow-types/billing";

import "./BillingTable.scss";

function BillingTable({
  billingPeriodDetails
}: {
  billingPeriodDetails: CurrentBillingPeriodData
}) {
  const lastUpdate = billingPeriodDetails.lastUpdate;
  const tableData = formatUsageDataForTable(billingPeriodDetails);

  const { dataTableRows, dataTableHeadings, dataTableTotals } = tableData;

  return (
    <div className="Billing-Table">
      <Card>
        <DataTable
          columnContentTypes={["text", "numeric", "numeric"]}
          headings={dataTableHeadings}
          rows={dataTableRows}
          totals={dataTableTotals}
        />
      </Card>
      {lastUpdate && (
        <div className="Billing-Disclaimer">
          Last Updated: {timestamp(lastUpdate)}
        </div>
      )}

      <div className="Billing-Disclaimer">
        *Recent charges seen here may not yet be visible on your Shopify bill.
        <br />
        *Total charges displayed may not accurately reflect account changes made
        during the billing cycle because of charge proration.
      </div>
    </div>
  );
}

export default BillingTable;
