// @flow

import axios from "./authedAxios";
import { API_ORIGIN } from "lib/constants";

import { reportError } from "lib/errorHelpers";

import type { Shop } from "types/flow-types/shop";

export const SHOP_API_PATH = "/api/shop";
export const SHOP_API_BASE = `${API_ORIGIN}${SHOP_API_PATH}`;

export const shopUrl = (path?: string): string =>
  [SHOP_API_BASE, path].filter(Boolean).join("/");

export type MaybeInitShopResponse =
  | {
      error: Error
    }
  | {
      data: {
        shop: Shop
      }
    };

export async function initShop(): Promise<MaybeInitShopResponse> {
  try {
    const { data } = await axios.get(shopUrl(), {
      params: {
        includePlan: true
      }
    });

    return { data: { shop: data } };
  } catch (e) {
    const error = new Error(e);
    reportError(error);
    return { error };
  }
}
