import { rgbToHsb, hsbToHex } from "@shopify/polaris";

export const isHex = s =>
  s.toLowerCase().padStart(8, "0") ===
  parseInt(s, 16).toString(16).padStart(8, "0");

// allowing length 5 and 9 supports hex with alpha
export const isValidHexColor = (s, nullable = false) => {
  if (!s && nullable) return true;
  if (!s) return false;

  return (
    s.charAt(0) === "#" &&
    isHex(s.slice(1)) &&
    (s.length === 4 || s.length === 5 || s.length === 7 || s.length === 9)
  );
};

/**
 * https://stackoverflow.com/a/11508164/1493544
 * https://jsperf.com/2014-09-16-hex-to-rgb
 */
export const hexToRgb = function (hex) {
  hex = hex.substr(1);
  hex = hex.length === 3 ? `${hex}${hex}` : hex;
  const bigint = parseInt(hex, 16);
  const red = (bigint >> 16) & 255;
  const green = (bigint >> 8) & 255;
  const blue = bigint & 255;

  return { red, green, blue };
};

/**
 * For HSB to hex and back again, we rely partly on homebaked functions and
 * partly on some utilities provided out of Polaris that god forbid don't get
 * moved or go away
 *
 * https://github.com/Shopify/polaris/issues/267#issuecomment-363432201
 */

export const hexToHsb = function (hex = "") {
  return rgbToHsb(hexToRgb(hex));
};

const hsbaToHex = color => {
  const alpha = color.alpha === undefined ? 1 : color.alpha;
  const alphaHex = Math.round(alpha * 255)
    .toString(16)
    .padStart(2, "0");

  if (alphaHex === "ff") {
    return hsbToHex(color);
  }

  return `${hsbToHex(color)}${alphaHex}`;
};

const hexShorthandToHsba = hex => {
  if (hex.length === 4) {
    return hexToHsb(hex);
  }

  const alphaHex = hex[4];
  const hexBeforeAlpha = hex.slice(0, 4);
  const alpha = parseInt(`${alphaHex}${alphaHex}`, 16) / 255;

  return { ...hexToHsb(hexBeforeAlpha), alpha };
};

const hexToHsba = (hex = "") => {
  if (hex.length === 4 || hex.length === 5) {
    return hexShorthandToHsba(hex);
  }

  const alphaHex = hex.slice(7, 9);

  if (!alphaHex) {
    return hexToHsb(hex);
  }

  const alpha = parseInt(alphaHex, 16) / 255;
  const hexBeforeAlpha = hex.slice(0, 7);

  return { ...hexToHsb(hexBeforeAlpha), alpha };
};

export { hsbToHex, hsbaToHex, hexToHsba };
