// @flow

import React, { useEffect } from "react";
import { Tabs } from "@shopify/polaris";

import chartTabsFactory from "./chartTabsFactory";

import type {
  NormalizedOffersReport,
  NormalizedSummaryReport
} from "store/reportsStore";
import type { ValueFormatMap } from "../reportingValueFormatters";
import type { SpanKey } from "../chart-types";
import { sendSurvicateDataToSegment } from "lib/reporting-helpers";

import "./ChartTabs.scss";

type Props = {
  selectedOffers: number[],
  offersReports: NormalizedOffersReport,
  summaryReport: NormalizedSummaryReport,
  valueFormatters: ValueFormatMap,
  currentTab: number,
  onTabChange: (tabIndex: number) => void,
  axisTicks: Date[],
  spanKey: SpanKey,
  shopDomain: string
};

const ChartTabs = function ({
  selectedOffers,
  offersReports,
  summaryReport,
  valueFormatters,
  currentTab,
  onTabChange,
  axisTicks,
  spanKey,
  shopDomain
}: Props) {
  const makeChartTabs = chartTabsFactory(valueFormatters);
  const tabs = makeChartTabs(
    selectedOffers,
    offersReports,
    summaryReport,
    axisTicks,
    spanKey
  );

  // Send survicate data to sentry on load only
  useEffect(() => {
    sendSurvicateDataToSegment({
      axisTicks,
      shopDomain,
      spanKey,
      summaryReport
    });
  }, []);

  return (
    <div className="ChartTabs">
      <Tabs tabs={tabs} selected={currentTab} onSelect={onTabChange} />
    </div>
  );
};

export default ChartTabs;
