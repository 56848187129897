import React from "react";
import { Banner, Card, Layout } from "@shopify/polaris";
import { useI18n } from "@shopify/react-i18n";

import AccordionItem from "components/AccordionItem";
import { HelpDocsLink } from "components/links";
import { FaqSkeleton } from "components/skeletons";
import { useMainThemeQuery } from "graphql/queries/main-theme.generated";
import { buildFaqItems, FaqItem } from "content/faq";

export default function FaqSection() {
  const [i18n] = useI18n();
  const link = (
    <HelpDocsLink
      helpDocsType="helpCenter"
      title={i18n.translate("common.helpCenter")}
    />
  );
  return (
    <Layout.Section>
      <Card title="Quick answers">
        <Card.Section>
          <p>{i18n.translate("dashboard.faq.subheading", { link })}</p>
        </Card.Section>
        <FaqBody />
      </Card>
    </Layout.Section>
  );
}

function FaqBody() {
  const { error, data, refetch } = useMainThemeQuery({
    notifyOnNetworkStatusChange: true
  });

  if (error) return <FaqError onAction={() => refetch()} />;

  if (data) {
    const items = buildFaqItems(data.mainTheme);

    return <FaqItems items={items} />;
  }

  return <FaqSkeleton />;
}

function FaqError({ onAction }: { onAction: () => void }) {
  const [i18n] = useI18n();
  const action = {
    content: i18n.translate("common.error.tryAgain"),
    onAction
  };
  const secondaryAction = {
    content: i18n.translate("common.contactSupport"),
    url: "https://launch.orbitapps.com/hc/en-us/articles/4402979466003",
    taget: "_blank"
  };

  return (
    <Card.Section>
      <Banner
        action={action}
        secondaryAction={secondaryAction}
        status="critical"
        title={i18n.translate("common.error.heading")}
      />
    </Card.Section>
  );
}

function FaqItems({ items }: { items: FaqItem[] }) {
  return (
    <>
      {items.map((faq, index) => (
        <AccordionItem
          id="faq"
          key={faq.question}
          index={index}
          heading={faq.question}
        >
          <div className="AccordionItem__content">{faq.answer}</div>
        </AccordionItem>
      ))}
    </>
  );
}
