import React from "react";
import { FormLayout, Stack } from "@shopify/polaris";
import { getIn } from "formik";

import BadgeColorInputs from "components/badges/BadgeColorInputs";
import BadgePreview from "components/badges/BadgePreview";
import BadgeTextInput from "components/badges/BadgeTextInput";
import BadgeTypeInput from "components/badges/BadgeTypeInput";
import BadgeUseDefaultInput from "components/badges/BadgeUseDefaultInput";
import BadgePositionInput from "components/badges/BadgePositionInput";
import BadgeBorderInput from "components/badges/BadgeBorderInput";

export default function BadgePickerCustomStyles({ form, onUseDefaultChange }) {
  const badgeType = getIn(form, "values.customizations.badge.type");
  const badgeText = getIn(form, "values.customizations.badge.badge_text");
  const useDefault = getIn(form, "values.customizations.badge.use_default");
  const badgePosition = getIn(form, "values.customizations.badge.position");
  const badgeStyles = getIn(form, "values.customizations.css.badge");
  const badgeBorder = getIn(form, "values.customizations.badge.border");

  return (
    <Stack distribution="fillEvenly">
      <BadgePreview
        badgeText={badgeText}
        badgeType={badgeType}
        badgeStyles={badgeStyles}
        badgePosition={badgePosition}
        badgeBorder={badgeBorder}
      />
      <FormLayout>
        <BadgeTextInput />
        <BadgeUseDefaultInput
          onChange={onUseDefaultChange}
          selected={[useDefault]}
        />
        <BadgeTypeInput />
        <BadgeColorInputs form={form} />
        <BadgePositionInput />
        <BadgeBorderInput />
      </FormLayout>
    </Stack>
  );
}
