import React from "react";
import { Stack, TextStyle, Heading, Button } from "@shopify/polaris";

import offerIcon from "components/offerIcon/offerIcon";
import { getTypeSlug } from "lib/offerHelpers";

export default function OfferDetails(props) {
  const { offer, checked, onCancel, onClick, showChangeButton, alwaysColor } =
    props;
  const icon = offerIcon(getTypeSlug(offer.type), {
    size: 40,
    faded: !checked && !alwaysColor,
    style: {
      marginRight: "2rem"
    }
  });

  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start"
      }}
      onClick={onClick}
    >
      {icon}
      <div style={{ flex: 1 }}>
        <Stack distribution="equalSpacing">
          <Heading>{offer.title}</Heading>

          {showChangeButton && (
            <Button plain onClick={onCancel}>
              Change type
            </Button>
          )}
        </Stack>
        <p>{offer.description}</p>
        <div className="VerboseOfferType__Example">
          <span className="VerboseOfferType__Quote">{offer.example}</span>
        </div>
        <div className="VerboseOfferType__Tag">
          <Stack spacing="tight" alignment="center">
            <TextStyle>{offer.outcome}</TextStyle>
          </Stack>
        </div>
      </div>
    </div>
  );
}
