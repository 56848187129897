// @flow

const BASIC = "Basic";
const SHOPIFY = "Shopify";
const ADVANCED = "Advanced";
const PLUS = "Plus";
const AFFILIATE = "Affiliate";

const APP_PLANS = {
  BASIC,
  SHOPIFY,
  ADVANCED,
  PLUS,
  AFFILIATE
};

// Using a `REACT_APP_` env var so that it can be read by react scripts
const DEFAULT_TRIAL_DAYS = process.env.REACT_APP_TRIAL_DAYS || 10;

const USO_PLANS_MAP = {
  Basic: BASIC,
  BasicPOS: BASIC,
  BasicUpsell: BASIC,
  Shopify: SHOPIFY,
  ShopifyUpsell: SHOPIFY,
  ShopifyPOS: SHOPIFY,
  Advanced: ADVANCED,
  AdvancedUpsell: ADVANCED,
  Plus: PLUS
};

module.exports = {
  APP_PLANS,
  USO_PLANS_MAP,
  DEFAULT_TRIAL_DAYS
};
