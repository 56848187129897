// @flow

import React from "react";
import { Banner, Layout, TextContainer, TextStyle } from "@shopify/polaris";

import { SettingsPageLink } from "components/links";

import type { ShopEventPayload } from "types/flow-types/shop";

type Props = {
  onDismiss: ShopEventPayload => void
};

const DefaultBanner = ({ onDismiss }: Props) => (
  <Layout.Section>
    <Banner status="warning" onDismiss={onDismiss}>
      <TextContainer spacing="tight">
        <p>
          <TextStyle variation="strong">
            You have enabled multi-currency in your store.
          </TextStyle>{" "}
        </p>
        <p>
          Only customers in your default currency are currently eligible to
          receive offers.{" "}
          <SettingsPageLink>
            Go to Ultimate Special Offers Settings
          </SettingsPageLink>{" "}
          to enable multi-currency offers.
        </p>
      </TextContainer>
    </Banner>
  </Layout.Section>
);

const DiscountCodesRequiredBanner = () => (
  <Layout.Section>
    <Banner status="warning">
      <p>
        <TextStyle variation="strong">
          You have enabled multi-currency in your store and in Ultimate Special
          Offers.
        </TextStyle>{" "}
        The pre-filled discount code method must be selected in{" "}
        <SettingsPageLink>app settings</SettingsPageLink>.
      </p>
    </Banner>
  </Layout.Section>
);

export default {
  Default: DefaultBanner,
  DiscountCodesRequired: DiscountCodesRequiredBanner
};
