//@flow

import React from "react";
import { EmptyState } from "@shopify/polaris";

import "./WelcomeLayout.scss";

type Props = {
  onCreateOffer: () => void
};

const VideoEmbed = () => (
  <div className="WelcomePage__IframeWrap">
    <iframe
      title="Ultimate Special Offers introductory video"
      width="100%"
      src="https://www.youtube-nocookie.com/embed/L1fIcd9_EA8?rel=0&minimalbranding=1"
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </div>
);

const WelcomeLayout = ({ onCreateOffer }: Props) => {
  return (
    <div className="WelcomePage">
      <EmptyState
        heading="Welcome to Ultimate Special Offers"
        action={{
          content: "Create special offer",
          onAction: onCreateOffer
        }}
        fullWidth
      >
        <VideoEmbed />
        <p>
          Get ready to run promotions, discounts, and other customizable offers
          within a single Shopify app.
        </p>
      </EmptyState>
    </div>
  );
};

export default WelcomeLayout;
