import React from "react";
import { Layout, Card, ChoiceList } from "@shopify/polaris";

const EXCLUDE = "exclude";
const TRIM = "trim";
const FULL = "full";

const getSelectedOption = function (values) {
  const excludeDetails = values.exclude_order_discount_details;
  const trimDetails = values.trim_order_discount_details;

  if (excludeDetails) return [EXCLUDE];
  return [trimDetails ? TRIM : FULL];
};

export default function OrderDiscountDetailsSection(props) {
  const { values, setFieldValue } = props.form;

  // There are two boolean fields in settings:
  //  exclude_order_discount_details
  //  trim_order_discount_details
  // these are combined into a single radio button with 3 options
  // If exclude is true, then the other field doesn't matter (we always set to false)
  // Else, the value of the other field sets the state of the radio button

  const updateDiscountDetails = function ([value]) {
    const shouldExclude = value === EXCLUDE;
    const shouldTrim = shouldExclude ? false : value === TRIM;

    setFieldValue("exclude_order_discount_details", shouldExclude);
    setFieldValue("trim_order_discount_details", shouldTrim);
  };

  return (
    <Layout.AnnotatedSection title="Order discount details">
      <Card>
        <Card.Section>
          <ChoiceList
            selected={getSelectedOption(values)}
            choices={[
              {
                label: "Show discount details on Shopify admin order pages",
                value: FULL
              },
              {
                label:
                  "Show truncated discount details on Shopify admin order pages",
                value: TRIM
              },
              {
                label:
                  "Don't show any discount details on Shopify admin order pages",
                value: EXCLUDE
              }
            ]}
            onChange={updateDiscountDetails}
          />
        </Card.Section>
      </Card>
    </Layout.AnnotatedSection>
  );
}
