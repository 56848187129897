import objectPath from "object-path";

// object-path uses object dot notation for arrays
// "graph.users[10].posts[2]" => "graph.users.10.posts.2"
export const sqToDot = path => path.replace(/\[(\d+)\]/g, ".$1");

export const get = (obj, path) => objectPath.get(obj, sqToDot(path));
export const set = (obj, path, val) => objectPath.set(obj, sqToDot(path), val);

// Normally we'd prefer just doing named exports and `import * as objectPath`,
// but this matches the API of object-path
export default { get, set };
