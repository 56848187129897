// @flow

import React from "react";

import { formatCurrency } from "./account-helpers";

export default function MaxChargeDisclaimer() {
  const maximumMonthlyCharge = formatCurrency(Number(499), 0);

  return (
    <div className="Billing-Disclaimer">
      *Maximum usage charge of {maximumMonthlyCharge} per app billing cycle.
    </div>
  );
}
