import React from "react";
import { Card, FormLayout, TextStyle } from "@shopify/polaris";

import { variantsSelected } from "lib/offerHelpers";
import offerPlaceholders from "content/offerPlaceholders";
import PolarisFormikInventoryPicker from "components/PolarisFormikInventoryPicker";
import PolarisFormikDiscountSection from "components/PolarisFormikDiscountSection";
import PolarisFormikNumberField from "components/PolarisFormikNumberField";
import OfferMessageSection from "components/OfferMessageSection";
import OfferSummarySection from "components/OfferSummarySection";
import OfferConfigurationSection from "components/OfferConfigurationSection";

export default function GoalSection(props) {
  const {
    form,
    currency,
    moneyFormat,
    offerTypeSlug,
    multicurrencyWarningCard,
    redirectToAppearance
  } = props;

  const variantPresence = variantsSelected(form);

  const placeholderType = variantPresence ? "variant" : "nonVariant";

  return [
    <OfferConfigurationSection
      key="offer-configuration"
      offerTypeSlug={offerTypeSlug}
    >
      <Card>
        <Card.Section title="Spend Goal">
          <FormLayout>
            <FormLayout.Group>
              <PolarisFormikNumberField
                suffix={currency}
                allowFloat
                min={0}
                name="details.amount_greater_than.amount"
              />
              <div />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
        <Card.Section title="Offer">
          <PolarisFormikInventoryPicker form={form} name="details.buy_from" />
        </Card.Section>
        <Card.Section>
          <FormLayout>
            <PolarisFormikDiscountSection
              name="details.discount"
              form={form}
              moneyFormat={moneyFormat}
              multicurrencyWarningCard={multicurrencyWarningCard}
            />
            <TextStyle variation="subdued">
              Spend a certain amount of money on selected products and/or
              collections and get a discount off the total price of those items.
            </TextStyle>
          </FormLayout>
          <OfferSummarySection form={form} moneyFormat={moneyFormat} />
        </Card.Section>
      </Card>
    </OfferConfigurationSection>,
    <OfferMessageSection
      key="offer-message"
      offerTypeSlug={offerTypeSlug}
      redirectToAppearance={redirectToAppearance}
      variantPresence={variantPresence}
      fields={[
        {
          label: "Initial message",
          name: "notifications.message",
          placeholder: offerPlaceholders.goal.initial[placeholderType]
        },
        {
          label: "Progress message (after necessary products added to cart)",
          name: "notifications.upsell_message",
          placeholder: offerPlaceholders.goal.progress[placeholderType]
        },
        {
          label: "Success message",
          name: "notifications.message_after",
          placeholder: offerPlaceholders.goal.success
        }
      ]}
    />
  ];
}
