import React from "react";

import { Card } from "@shopify/polaris";
import { OFFER_TYPES } from "types/constants/offers";
import { unavailableOffers } from "lib/offerHelpers";

import "./VerboseOfferTypePicker.scss";
import OfferCard from "./OfferCard";

export default function VerboseOfferTypePicker({
  selectedOfferType,
  onOfferTypeChange
}) {
  const availableOffers = OFFER_TYPES.filter(
    opt => !unavailableOffers().includes(opt.type)
  );

  const filteredOffers =
    selectedOfferType != null
      ? availableOffers.filter(({ type }) => type === selectedOfferType)
      : availableOffers;

  const displayOffersSection =
    selectedOfferType === null ||
    !unavailableOffers().includes(selectedOfferType);

  return (
    <div className="VerboseOfferTypeWrapper">
      {displayOffersSection && (
        <div className="VerboseOfferTypeSection">
          <Card>
            {filteredOffers.map(offer => {
              return (
                <OfferCard
                  offer={offer}
                  selectedOfferType={selectedOfferType}
                  onOfferTypeChange={onOfferTypeChange}
                  key={offer.type}
                />
              );
            })}
          </Card>
        </div>
      )}
    </div>
  );
}
