// @flow
import React from "react";
import { Button } from "@shopify/polaris";

export default function ActivateRecurringChargeLink({
  activateRecurringCharge,
  updateScopes
}: {
  activateRecurringCharge: () => Promise<void>,
  updateScopes: boolean
}) {
  return (
    <Button
      disabled={updateScopes}
      plain
      onClick={() => activateRecurringCharge()}
    >
      2. Confirm new monthly charge
    </Button>
  );
}
