export default function getAppAuth(): { host: string; apiKey: string } {
  const search = window.location.search;
  const urlParams = new URLSearchParams(search);
  const host = urlParams.get("host");

  if (!host) {
    throw new Error("getAppAuth - url params do not contain host");
  }

  const apiKey = process.env.REACT_APP_SHOPIFY_API_KEY as string;
  return { host, apiKey };
}
