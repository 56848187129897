// @flow
import React from "react";
import { Layout, Banner } from "@shopify/polaris";
import AnimatedBanner from "./AnimatedBanner";

import type { ShopEventPayload } from "types/flow-types/shop";

function WarningBanner({ setEvent }: { setEvent: ShopEventPayload => void }) {
  return (
    <Banner
      status="warning"
      onDismiss={() => {
        setEvent({ cart_banner_dismissed: true });
      }}
      title="Offers don’t work with cart popups"
    >
      <p>
        If you’re using a mini cart that slides or pops out in your theme,
        you&apos;ll need to go to{" "}
        <em>Online Store &gt; Themes &gt; Customize</em> and set your cart type
        to &ldquo;Page&rdquo; (or ask your theme developer to do this).
      </p>
    </Banner>
  );
}

type Props = {
  bannerExpanded?: boolean,
  setEvent: ShopEventPayload => void
};

export default function CartWarningBannerSection(props: Props) {
  const { bannerExpanded, setEvent } = props;

  return (
    <Layout.Section>
      <AnimatedBanner expanded={bannerExpanded}>
        <WarningBanner setEvent={setEvent} />
      </AnimatedBanner>
    </Layout.Section>
  );
}
