import React from "react";
import { Banner, Card } from "@shopify/polaris";

function UsageFeesErrorCard() {
  return (
    <Card>
      <Banner status="critical" title="Oops, something went wrong" />
    </Card>
  );
}

export default UsageFeesErrorCard;
