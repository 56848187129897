const hasCommon = (baseArray, compareArray) => {
  const baseElements = new Set(baseArray);

  for (const element of compareArray) {
    if (baseElements.has(element)) {
      return true;
    }
  }

  return false;
};

export default hasCommon;
