// Taken from:
// https://github.com/orbit-apps/alliance-pro/pull/379
import { I18nContext, I18nManager } from "@shopify/react-i18n";
import React, { ReactNode } from "react";
import I18nRoot from "I18nRoot";

function I18nProvider({ children }: { children: ReactNode }) {
  const params = new URLSearchParams(document.location.search);
  const locale = params.get("locale") || "en";
  const i18nManager = new I18nManager({
    locale
  });

  return (
    <I18nContext.Provider value={i18nManager}>
      <I18nRoot>{children}</I18nRoot>
    </I18nContext.Provider>
  );
}

export { I18nProvider };
