import React, { useState, useRef } from "react";
import {
  Card,
  FormLayout,
  Icon,
  Subheading,
  TextStyle,
  Caption
} from "@shopify/polaris";
import { QuestionMarkMinor } from "@shopify/polaris-icons";
import { Field, getIn } from "formik";

import InputWithPopover from "components/InputWithPopover";
import InlineColorPicker from "components/InlineColorPicker";
import PolarisFormikSelect from "components/PolarisFormikSelect";
import PolarisFormikNumberField from "components/PolarisFormikNumberField";

import "./AppearancePreview.css";

const ImagePreview = React.forwardRef(({ currentImage }, ref) => {
  return (
    <div className="ImagePreview" ref={ref}>
      <img
        src={currentImage}
        // Proportional image scaling
        width="100%"
        height="100%"
        alt=""
        style={{
          objectFit: "contain",
          objectPosition: "center"
        }}
      />
    </div>
  );
});

ImagePreview.displayName = "ImagePreview";

const updateImage = (val, selectImages, setCurrentImage) => {
  setCurrentImage(selectImages[val]);
};

const Input = ({ form, field, setCurrentImage, selectImages }) => {
  switch (field.type) {
    case "select":
      return (
        <Field
          component={PolarisFormikSelect}
          name={field.name}
          label={field.label}
          options={field.options}
          onChangeCallback={
            field.updateImage
              ? val => {
                  updateImage(val, selectImages, setCurrentImage);
                }
              : null
          }
        />
      );

    case "number":
      return (
        <PolarisFormikNumberField
          name={field.name}
          label={field.label}
          min={field.options.min}
          max={field.options.max}
        />
      );

    case "color":
    default:
      return (
        <InputWithPopover
          name={field.name}
          label={field.label}
          placeholder="Color"
          popoverComponent={InlineColorPicker}
          // TODO other components above do not have form, we should not use form
          form={form}
        />
      );
  }
};

const getSelectedBadgeImage = (images, form) => {
  const value = getIn(
    form,
    "values.customizations.badge.type",
    "circleOutline"
  );
  return images[value];
};

const AppearanceSection = ({ section, form }) => {
  const { fields, defaultImage, title, selectImages } = section;

  const [currentImage, setCurrentImage] = useState(
    defaultImage || getSelectedBadgeImage(section.selectImages, form)
  );

  const imageRef = useRef();

  const detailsClickHandler = (e, image) => {
    e.preventDefault();
    image && setCurrentImage(image);
    imageRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const detailsButtonClass = type => {
    return type === "select" || type === "number"
      ? "DetailsButton ExtraPadding"
      : "DetailsButton";
  };

  return (
    <Card>
      <div className="AppearancePreview">
        <ImagePreview currentImage={currentImage} ref={imageRef} />

        <Card.Section>
          <FormLayout>
            <div className="Polaris-Subheading__container">
              <Subheading>{title}</Subheading>
            </div>
            <FormLayout.Group>
              {fields.map(field => (
                <div
                  onMouseOver={() =>
                    field.image && setCurrentImage(field.image)
                  }
                  onMouseOut={() =>
                    field.image && setCurrentImage(defaultImage)
                  }
                  key={field.name}
                  className="InputWrapper"
                >
                  <Input
                    form={form}
                    field={field}
                    setCurrentImage={setCurrentImage}
                    selectImages={selectImages}
                  />
                  <div
                    className={detailsButtonClass(field.type)}
                    onClick={e => detailsClickHandler(e, field.image)}
                  >
                    <Icon source={QuestionMarkMinor} color="base" />
                  </div>
                </div>
              ))}
            </FormLayout.Group>
          </FormLayout>
          <TextStyle variation="subdued">
            <Caption>
              *Visit your storefront to see these changes in action. Example
              images only indicate which elements are customizable.
            </Caption>
          </TextStyle>
        </Card.Section>
      </div>
    </Card>
  );
};

export default AppearanceSection;
