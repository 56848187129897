// @flow
import React from "react";
import { Select, Stack, TextStyle } from "@shopify/polaris";
import { REPORT_PERIOD_OPTIONS } from "lib/constants";

type Props = {
  reportingRange: string,
  updateReportingPeriod: string => void
};

const ReportingPeriodPicker = ({
  reportingRange,
  updateReportingPeriod
}: Props) => {
  return (
    <div className="ReportingSection_FilterRow">
      <Stack alignment="center">
        <TextStyle variation="subdued">Show data for</TextStyle>
        <Select
          options={REPORT_PERIOD_OPTIONS}
          onChange={updateReportingPeriod}
          value={reportingRange}
        />
      </Stack>
    </div>
  );
};

export default ReportingPeriodPicker;
