import { STATUSES } from "lib/constants";

const errorMessages = {
  default: "",
  appConfig: {
    [STATUSES.READING]: "Sorry, we couldn't load the app."
  }
};

export default errorMessages;
