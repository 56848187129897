// @flow

import axios from "./authedAxios";
import { shopUrl } from "api/shop";
import { reportError } from "lib/errorHelpers";
import type { ShopEventPayload } from "types/flow-types/shop";

export type MaybeEventsResponse = {
  data?: ShopEventPayload,
  error?: Error
};

export const eventsUrl = shopUrl("events");

export async function patchEvents(
  payload: ShopEventPayload
): Promise<MaybeEventsResponse> {
  try {
    const { data } = await axios.patch(eventsUrl, payload);
    return { data };
  } catch (e) {
    const error = new Error(e);
    reportError(error);
    return { error };
  }
}
