// @flow
import React, { Fragment, useContext, type Node } from "react";
import { TextStyle, DisplayText, Stack } from "@shopify/polaris";

import { FlagsContext } from "contexts/Flags";

export default function ChartLineChartEmpty(): Node {
  // @featureFlag ch60386 Reporting kill switch
  const flags = useContext(FlagsContext);
  const disabled = flags.disableReporting;

  const headerText = disabled
    ? "Reporting is currently unavailable due to increased activity"
    : "We couldn’t load any data at this time";
  const bodyText = disabled ? (
    <Fragment>
      Your campaigns are still active and running. Please try again later.
    </Fragment>
  ) : (
    "Please try refreshing the page and contacting support if it persists."
  );

  return (
    <div className="ReportingLineChart--Empty">
      <Stack vertical spacing="tight">
        <DisplayText size="small">{headerText}</DisplayText>
        <TextStyle variation="subdued">{bodyText}</TextStyle>
      </Stack>
    </div>
  );
}
