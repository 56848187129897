// @flow
import React, { Fragment } from "react";
import { Banner, Card } from "@shopify/polaris";

import { OffersListSkeletonPage } from "components/skeletons";
import OffersListContainer from "components/OffersList/OffersListContainer";
import OffersListEmpty from "components/OffersList/OffersListEmpty";

import { STATUSES } from "lib/constants";
import { unavailableOffers } from "lib/offerHelpers";

import type { OffersRequest } from "appStore";

export const Error = () => (
  <Banner title="Something went wrong" status="critical">
    Oh no! An error occurred retrieving your offers. Please try reload the page,
    or contact support.
  </Banner>
);

type Props = {
  onCreateOffer: () => void,
  offersRequest: OffersRequest
};

export default function OffersSection({ onCreateOffer, offersRequest }: Props) {
  const { error, loading, offers, status } = offersRequest;

  const filteredOffers = offers.filter(
    offer => offer.type && !unavailableOffers().includes(offer.type)
  );

  if (error) {
    return <Error />;
  }
  if (loading && status === STATUSES.READING) {
    return <OffersListSkeletonPage />;
  }
  return (
    <Card title="Special offers">
      {filteredOffers.length > 0 ? (
        <Fragment>
          <Card.Section>
            <p>Your offers will be applied in the order shown below.</p>
          </Card.Section>
          <OffersListContainer offers={filteredOffers} />
        </Fragment>
      ) : (
        <OffersListEmpty onCreateOffer={onCreateOffer} />
      )}
    </Card>
  );
}
