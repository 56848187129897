// @flow

import { formatCurrency } from "lib/currencyHelpers";
import { prettifyNumber } from "lib/numberHelpers";

export type ValueFormatMap = {
  [statKey: string]: (value: number) => string
};

export default function makeFormatters(moneyFormat: string): ValueFormatMap {
  return {
    orderTotal(value: number) {
      const prettyValue = prettifyNumber(value);
      return formatCurrency(prettyValue, moneyFormat);
    },
    orderCount(value: number) {
      return prettifyNumber(value);
    },
    conversionRate(value: number) {
      const prettyValue = prettifyNumber(value);
      return `${prettyValue}%`;
    }
  };
}
