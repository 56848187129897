import React from "react";
import { Card, FormLayout } from "@shopify/polaris";

import { variantsSelected } from "lib/offerHelpers";
import offerPlaceholders from "content/offerPlaceholders";
import PolarisFormikInventoryPicker from "components/PolarisFormikInventoryPicker";
import OfferMessageSection from "components/OfferMessageSection";
import OfferSummarySection from "components/OfferSummarySection";
import OfferConfigurationSection from "components/OfferConfigurationSection";

export default function UpsellSection(props) {
  const { form, moneyFormat, offerTypeSlug, redirectToAppearance } = props;

  const variantPresence = variantsSelected(form);

  const placeholderType = variantPresence ? "variant" : "nonVariant";

  return [
    <OfferConfigurationSection
      key="offer-configuration"
      offerTypeSlug={offerTypeSlug}
    >
      <Card>
        <Card.Section title="Trigger">
          <FormLayout>
            <p>
              Choose products or collections that trigger this offer when added
              to cart.
            </p>
            <PolarisFormikInventoryPicker form={form} name="details.buy_from" />
          </FormLayout>
        </Card.Section>
        <Card.Section title="Promote">
          <FormLayout>
            <p>
              Choose products or collections to promote when trigger items added
              to cart.
            </p>
            <PolarisFormikInventoryPicker form={form} name="details.get_from" />
          </FormLayout>
          <OfferSummarySection form={form} moneyFormat={moneyFormat} />
        </Card.Section>
      </Card>
    </OfferConfigurationSection>,
    <OfferMessageSection
      key="offer-message"
      offerTypeSlug={offerTypeSlug}
      redirectToAppearance={redirectToAppearance}
      variantPresence={variantPresence}
      fields={[
        {
          label: "Upsell message",
          name: "notifications.message",
          placeholder: offerPlaceholders.upsell.upsell[placeholderType]
        }
      ]}
    />
  ];
}
