import React from "react";
import { Card, FormLayout } from "@shopify/polaris";

import { variantsSelected } from "lib/offerHelpers";
import offerPlaceholders from "content/offerPlaceholders";
import PolarisFormikInventoryPicker from "components/PolarisFormikInventoryPicker";
import PolarisFormikCheckbox from "components/PolarisFormikCheckbox";
import PolarisFormikNumberField from "components/PolarisFormikNumberField";
import OfferMessageSection from "components/OfferMessageSection";
import OfferSummarySection from "components/OfferSummarySection";
import OfferConfigurationSection from "components/OfferConfigurationSection";

export default function GiftSection(props) {
  const { form, currency, moneyFormat, offerTypeSlug, redirectToAppearance } =
    props;
  const checkboxLabel =
    "Get multiple quantities of free gifts, for every multiple of selected amount spent";

  const variantPresence = variantsSelected(form);

  const placeholderType = variantPresence ? "variant" : "nonVariant";

  return [
    <OfferConfigurationSection
      key="offer-configuration"
      offerTypeSlug={offerTypeSlug}
    >
      <Card>
        <Card.Section title="Spend Goal">
          <FormLayout>
            <FormLayout.Group>
              <PolarisFormikNumberField
                type="number"
                suffix={currency}
                min={0}
                name="details.cart_amount_greater_than.amount"
              />
              <div />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
        <Card.Section title="Free Gift">
          <PolarisFormikInventoryPicker form={form} name="details.buy_from" />
        </Card.Section>
        <Card.Section>
          <FormLayout>
            <PolarisFormikCheckbox
              name="details.multiple"
              label={checkboxLabel}
              helpText={
                <p>
                  The gift product should always have a price set. App will make
                  it free only when applicable.
                </p>
              }
            />
          </FormLayout>
          <OfferSummarySection form={form} moneyFormat={moneyFormat} />
        </Card.Section>
      </Card>
    </OfferConfigurationSection>,
    <OfferMessageSection
      key="offer-message"
      offerTypeSlug={offerTypeSlug}
      redirectToAppearance={redirectToAppearance}
      variantPresence={variantPresence}
      fields={[
        {
          label: "Initial message",
          name: "notifications.message_before",
          placeholder: offerPlaceholders.gift.initial[placeholderType]
        },
        {
          label: "Success message",
          name: "notifications.message_after",
          placeholder: offerPlaceholders.gift.success
        }
      ]}
    />
  ];
}
