// @flow
import React, { useContext } from "react";
import { Badge, TextStyle, Tooltip } from "@shopify/polaris";
import { FlagsContext } from "contexts/Flags";
import { buildOfferSummary, buildAvailabilitySummary } from "lib/offerDetails";
import OffersActionsMenu from "./OfferActionsMenu";
import PreviewLink from "./PreviewLink";
import { drawName, getOfferType } from "../../lib/offerHelpers";

import type { Offer, OfferType } from "types/flow-types/offers";
import type { ReportingColumnItem } from "../OffersList/OffersList";
import type { OfferReportProp } from "../OffersList/makeOfferReportingProp";

export const SummaryColumn = ({
  offer,
  slug,
  moneyFormat,
  onPreviewClick
}: {
  offer: Offer,
  slug: string,
  moneyFormat: string,
  onPreviewClick: () => Promise<void>
}) => {
  const { type, name, details, applicability } = offer;
  const offerName = drawName(name, type);

  return (
    <div className="OffersListItem__Details">
      <h3 className="OffersListItem__Name">
        <TextStyle variation="strong">{offerName}</TextStyle>
        <PreviewLink onClick={onPreviewClick} offerType={type} />
      </h3>
      <div className="OffersListItem__Summary Polaris-Caption">
        {buildOfferSummary(slug, details, moneyFormat)}
      </div>
      <div className="OffersListItem__Availability Polaris-Caption">
        {buildAvailabilitySummary(applicability)}
      </div>
    </div>
  );
};

export const StatusColumn = ({ status }: { status: string }) => {
  return status === "Enabled" ? (
    <Badge status="success">Enabled</Badge>
  ) : (
    <Badge>Disabled</Badge>
  );
};

export const ReportingColumn = ({
  reporting,
  column,
  customValueTooltip,
  offerType
}: {
  reporting: OfferReportProp,
  column: ReportingColumnItem,
  customValueTooltip: ?(value: number) => string,
  offerType: ?OfferType
}) => {
  const { error, loading, data: offerReport } = reporting;
  const { key: reportProperty, formatValue, valueTooltip, title } = column;

  // @featureFlag ch60386 Reporting kill switch
  const { disableReporting } = useContext(FlagsContext);
  const isPostPurchaseOrderTotal =
    offerType === "PostPurchaseOffer" && reportProperty === "orderTotal";

  if (error || disableReporting || isPostPurchaseOrderTotal) {
    const content = disableReporting
      ? "Reporting is currently unavailable."
      : isPostPurchaseOrderTotal
      ? `Sales totals are not currently available for ${
          getOfferType("postPurchaseOffer").title
        } offers`
      : "Unable to load offer analytics.";

    return (
      <Tooltip
        preferredPosition="above"
        content={<TextStyle variation="negative">{content}</TextStyle>}
      >
        <span data-testid="error">
          <TextStyle variation="subdued">&ndash;</TextStyle>
        </span>
      </Tooltip>
    );
  }

  if (loading) return null;

  // $FlowDisableNextLine
  const value = offerReport[reportProperty];

  return (
    <Tooltip
      preferredPosition="above"
      content={
        customValueTooltip ? customValueTooltip(value) : valueTooltip(value)
      }
    >
      <span data-testid="offer-column-tooltip">
        {formatValue(value)}
        <span className="ReportingColumn__InlineLabel">
          {" "}
          {title.toLocaleLowerCase()}
        </span>
      </span>
    </Tooltip>
  );
};

export const ActionsColumn = ({
  itemDeactivated,
  offer,
  onDelete,
  setOfferActive
}: {
  itemDeactivated: boolean,
  offer: Offer,
  onDelete: () => void,
  setOfferActive: (number, boolean) => void
}) => {
  return (
    <div
      className="OffersListItem__Actions"
      onClick={e => {
        // keep button click from bubbling up to a resource item click
        e.stopPropagation();
      }}
    >
      <OffersActionsMenu
        offer={offer}
        setOfferActive={setOfferActive}
        onDelete={onDelete}
        itemDeactivated={itemDeactivated}
      />
    </div>
  );
};
