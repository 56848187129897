// @flow
import React from "react";

import { getTypeSlug } from "lib/offerHelpers";
import type { Offer, OfferStatus } from "types/flow-types/offers";
import type { ReportingColumnItem } from "../OffersList/OffersList";
import type { OfferReportProp } from "../OffersList/makeOfferReportingProp";
import { openPreviewTab } from "components/links";
import {
  SummaryColumn,
  StatusColumn,
  ReportingColumn,
  ActionsColumn
} from "./offer-columns";
import "./OffersListItem.scss";

type Props = {
  buildCustomValueTooltip: (columnKey: string) => ?(value: number) => string,
  deleteOffer: number => void,
  domain: string,
  moneyFormat: string,
  offer: Offer,
  offerStatus: OfferStatus,
  onDelete: () => void,
  reporting: OfferReportProp,
  reportingColumns: Array<ReportingColumnItem>,
  setOfferActive: (number, boolean) => void
};

const OffersListItem = (props: Props) => {
  const {
    domain,
    moneyFormat,
    offer,
    offerStatus,
    onDelete,
    reporting,
    reportingColumns,
    setOfferActive,
    buildCustomValueTooltip
  } = props;
  const { type, active } = offer;
  const typeSlug = getTypeSlug(type);

  const offerDeactivated = offerStatus === "Deactivated";

  return (
    <div className={`OffersListItem ${offerStatus}`}>
      <div
        className={`OffersList__Row OffersList__Row--${
          active ? "enabled" : "disabled"
        }`}
      >
        <div className="OffersList__Column OffersList__Column--main">
          <SummaryColumn
            offer={offer}
            slug={typeSlug}
            moneyFormat={moneyFormat}
            onPreviewClick={() => openPreviewTab(offer, domain)}
          />
        </div>
        <div className="OffersList__Column OffersList__Column--status">
          <StatusColumn status={offerStatus} />
        </div>
        {reportingColumns.map(column => (
          <div
            key={column.key}
            className="OffersList__Column OffersList__Column--reporting"
          >
            <ReportingColumn
              column={column}
              offerType={type}
              reporting={reporting}
              customValueTooltip={buildCustomValueTooltip(column.key)}
            />
          </div>
        ))}
        <div className="OffersList__Column OffersList__Column--actions">
          <ActionsColumn
            offer={offer}
            setOfferActive={setOfferActive}
            onDelete={onDelete}
            itemDeactivated={offerDeactivated}
          />
        </div>
      </div>
    </div>
  );
};

export default OffersListItem;
