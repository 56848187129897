import React from "react";
import { Card, TextContainer } from "@shopify/polaris";
import copy from "content/copy.json";

export default function DevPlanCard() {
  const { devPlanCardDescription } = copy.account.percentageVolumetric;
  return (
    <Card title="Developer plan" sectioned subdued>
      <TextContainer>
        <p>{devPlanCardDescription}</p>
      </TextContainer>
    </Card>
  );
}
