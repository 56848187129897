// @flow
import React, { type ComponentType } from "react";
import createStore from "unistore";
import { Provider, connect } from "unistore/react";

import { PLAN_EMPTY_STATE, SHOP_EMPTY_STATE } from "store/shopStore";
import {
  OFFERS_REPORTS_EMPTY_STATE,
  SUMMARY_REPORTS_EMPTY_STATE
} from "store/reportsStore";
import {
  OFFERS_EMPTY_STATE,
  CURRENT_OFFER_EMPTY_STATE
} from "store/offersStore";

import { DATE_RANGES } from "lib/constants";

import type { Offer } from "types/flow-types/offers";
import type { Shop } from "types/flow-types/shop";

export const APP_EMPTY_STATE = {
  companionAppsInstalled: null,
  currentOffer: CURRENT_OFFER_EMPTY_STATE,
  offers: OFFERS_EMPTY_STATE,
  offersReports: OFFERS_REPORTS_EMPTY_STATE,
  plan: PLAN_EMPTY_STATE,
  reportingRange: DATE_RANGES.PAST_30_DAYS,
  shop: SHOP_EMPTY_STATE,
  summaryReport: SUMMARY_REPORTS_EMPTY_STATE,
  toast: null
};

export type RequestDefaults = {
  error: boolean,
  loading: boolean,
  status: number
};

export type OffersRequest = {
  ...RequestDefaults,
  offers: Array<Offer>
};

export type CurrentOfferRequest = {
  ...RequestDefaults,
  currentOffer: Offer
};

export type ShopRequest = {
  ...RequestDefaults,
  shop: Shop
};

export type ReportsRequest = {
  ...RequestDefaults,
  reports: Object
};

export type PlanRequest = {
  ...RequestDefaults,
  plan: {
    confirmationUrl: string
  }
};

export const appStore = () => createStore(APP_EMPTY_STATE);

// Creates a function that is a composition of all store functions passed to it
// with the proper store value passed in
export const composeActions =
  (...actionFns: Array<Function>) =>
  (store: Object) =>
    actionFns.reduce((ret, fn) => ({ ...ret, ...fn(store) }), {});

// Avoids some boilerplate code of connecting components to the store, and
// allows us in future to specify an actions param that functions in the same
// way as storeKeys to allow components to specify just the actions they're
// interested in instead of getting every single action in our store as a prop
export const storeConnect = (
  Component: ComponentType<any>,
  mapStateToProps: Function | Array<string> | string,
  ...actionFns: Array<Function>
) => {
  const ConnectedComponent = connect(
    mapStateToProps,
    composeActions.apply(null, actionFns)
  )(Component);

  return ConnectedComponent;
};

// This should only be used on the top level component (in this case, App)
// Wraps the component in a unistore provider
export const storeProvide = (
  Component: ComponentType<Object>,
  store: Object = appStore()
) => {
  const ProvidedComponent = (props: Object) => (
    <Provider store={store}>
      <Component {...props} />
    </Provider>
  );

  return ProvidedComponent;
};
