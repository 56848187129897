/**
 * Format a currency string
 *
 * @param {number} value - numeric money amount
 * @param {string} moneyFormat - shop's currency format from their settings.
 */
export const formatCurrency = function (value, moneyFormat) {
  return stripTags(
    moneyFormat
      .replace(/{{\s+(.*)\s+}}/, "{{$1}}") // remove whitespace before/after curly braces
      .replace(
        /{{amount}}|{{amount_no_decimals}}|{{amount_with_comma_separator}}|{{amount_no_decimals_with_comma_separator}}|{{amount_with_apostrophe_separator}}/,
        value.toString()
      )
  );
};

export function stripTags(content) {
  const frag = document.createDocumentFragment();
  const innerEl = document.createElement("div");
  frag.appendChild(innerEl);
  innerEl.innerHTML = content;
  // Jest's jsdom environment doesn't
  // support innerTextlib
  return frag.firstChild.textContent;
}

export function getCurrencySymbol(moneyFormat) {
  const symbol = stripTags(moneyFormat)
    .split(/{{\s*[\w]+\s*}}/)
    .join("");
  return symbol;
}
