import { useDiscountsAppEmbedStatusQuery } from "graphql/queries/discounts-app-embed-status.generated";
import { useMainThemeHasEmbeddedInstallQuery } from "graphql/queries/main-theme-has-embedded-install.generated";

export default function useDiscountsAppEmbedNeedsEnabling() {
  const {
    data: embedStatusQueryData,
    error: embedStatusQueryError,
    loading: embedStatusQueryLoading
  } = useDiscountsAppEmbedStatusQuery();

  const {
    data: mainThemeQueryData,
    error: mainThemeQueryError,
    loading: mainThemeQueryLoading
  } = useMainThemeHasEmbeddedInstallQuery();

  if (embedStatusQueryLoading || mainThemeQueryLoading) {
    return { loading: true };
  }
  if (
    embedStatusQueryError ||
    mainThemeQueryError ||
    mainThemeQueryData?.mainTheme.hasEmbeddedInstall
  ) {
    return { needsEnabling: false };
  }

  return {
    needsEnabling: !embedStatusQueryData?.discountsAppEmbedStatus.enabled
  };
}
