import React from "react";
import "./AnimatedBanner.css";

export default function AnimatedBanner({ children, expanded }) {
  const bannerClass = expanded ? "expanded" : "collapsed";
  return (
    <div id="expand-container">
      <div id="animated-banner" className={bannerClass}>
        {children}
      </div>
    </div>
  );
}
