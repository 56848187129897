import React from "react";
import { Banner, Layout } from "@shopify/polaris";

import copy from "content/copy.json";
import { ShopEventPayload } from "types/shop";

export default function TrialOngoingDevShop({
  dismissed,
  setEvent
}: {
  dismissed?: boolean;
  setEvent: (payload: ShopEventPayload) => void;
}) {
  if (dismissed) return null;
  return (
    <Layout.Section>
      <Banner
        status="success"
        title={copy.common.devPlanBanner.title}
        onDismiss={() => {
          setEvent({ dev_shop_trial_banner_dismissed: true });
        }}
      >
        <p>{copy.common.devPlanBanner.body}</p>
      </Banner>
    </Layout.Section>
  );
}
