// @flow

import React, { useContext } from "react";
import { Layout, Banner, Button, Stack } from "@shopify/polaris";
import { HelpDocsLink, helpDocsUrl } from "components/links";
import { NavigationContext } from "contexts/Navigation";

function UpdatePlanButton() {
  const navigation = useContext(NavigationContext);
  return (
    <Button
      onClick={() => {
        navigation.goTo("/account");
      }}
    >
      Update plan
    </Button>
  );
}

function PPULearnMoreButton() {
  return (
    <Button url={helpDocsUrl("postPurchaseOffer")} external>
      Learn more about Post-Purchase Upsell Offers
    </Button>
  );
}

type supportedAppPlans =
  | "fixedWithPPU"
  | "fixedWithoutPPU"
  | "volumetricWithPPU"
  | "volumetricWithoutPPU";

export default function PPUInfoBanner({
  appPlan,
  dismissMessage
}: {
  appPlan: supportedAppPlans,
  dismissMessage: () => void
}) {
  const bannerMessaging = {
    fixedWithPPU: {
      title:
        "Move to the Ultimate plan and get Post-Purchase Upsells for free!",
      message: `The Post-Purchase Upsell offer is now available
      for free with our Ultimate plan starting at $9/ month. Start 
      with a lower base fee and pay for orders as you go.`
    },
    fixedWithoutPPU: {
      title: "Great News!",
      message: `The Post-Purchase Upsell offer is now available
      for free with our Ultimate plan starting at $9/ month. Take advantage 
      of one-click product upsells in your checkout and increas average order 
      value. `
    },
    volumetricWithPPU: {
      title: "Great News!",
      message: `The premium Post-Purchase Upsell offer is now available to all 
      merchants using Ultimate Special Offers. Switch now and lower your monthly costs.`
    },
    volumetricWithoutPPU: {
      title: "Great News!",
      message: `You can now take advantage of the Post-Purchase Upsell
      offer included in our Ultimate plan. This premium offer is now 
      included at no additional cost.`
    }
  };

  if (!bannerMessaging[appPlan]) return null;
  const updatePlan = appPlan !== "volumetricWithoutPPU";

  return (
    <div style={{ width: "100%" }} data-testid="ppuInfoBanner">
      <Layout.Section>
        <Banner
          status="info"
          title={bannerMessaging[appPlan].title}
          onDismiss={() => dismissMessage()}
        >
          <Stack vertical>
            <p data-testid={appPlan}>
              {bannerMessaging[appPlan].message}
              {appPlan === "fixedWithoutPPU" && (
                <HelpDocsLink helpDocsType="postPurchaseOffer" />
              )}
            </p>
            {updatePlan ? (
              <UpdatePlanButton onClick={dismissMessage} />
            ) : (
              <PPULearnMoreButton />
            )}
          </Stack>
        </Banner>
      </Layout.Section>
    </div>
  );
}
