//@flow
import {
  OFFER_TYPE_KEYS,
  OFFER_PRICE_DISCOUNT_TYPES
} from "types/constants/offers";

import type { OfferType, OfferDetails } from "types/flow-types/offers";
import type { ShopifyMultiCurrencyCode } from "types/flow-types/shopify";

// returning true means the offer will work in a multicurrency setting
type percentageDiscountCheck = (details: OfferDetails) => ?boolean;

const { PERCENT } = OFFER_PRICE_DISCOUNT_TYPES;
const { BOGO, BULK, BUNDLE, CREDIT, DISCOUNT, GOAL, GIFT, UPSELL, VOLUME } =
  OFFER_TYPE_KEYS;

// the majority of offer types have the discount at details.price
export const defaultPriceCheck: percentageDiscountCheck = ({ price }) =>
  price && price.type === PERCENT;

export const percentageDiscountChecks: {
  [offerType: OfferType]: percentageDiscountCheck
} = {
  // no visibility at all for these two
  // "spend goal" is always tied to shop currency
  [GIFT]: () => false,
  [GOAL]: () => false,

  // TODO: these work in the cart but popup prices display incorrectly
  [UPSELL]: () => true,
  [BUNDLE]: defaultPriceCheck,

  [BOGO]: defaultPriceCheck,
  [BULK]: defaultPriceCheck,
  [CREDIT]: defaultPriceCheck,

  [DISCOUNT]: ({ discount }) => discount && discount.type === PERCENT,

  [VOLUME]: ({ tiers }) =>
    tiers && tiers.every(tier => tier.price && tier.price.type === PERCENT)
};

export function isMulticurrencyEnabled(
  presentmentCurrencies: ?(ShopifyMultiCurrencyCode[])
): boolean {
  return (
    Array.isArray(presentmentCurrencies) && presentmentCurrencies.length > 1
  );
}
