import React from "react";
import { Tag, Scrollable } from "@shopify/polaris";
import { getIn } from "formik";
import {
  removeInventoryItem,
  removeInventoryItemForVariant
} from "lib/formHelpers";
import variantProductTitleWithCount from "lib/variantProductTitleWithCount";

import "./InventoryTags.css";

// It's easier working with a single array of {id, title} objects instead of
// separate product_ids[], product_titles[]
export const makeInventoryItemArray = (ids, titles, handles) =>
  ids.map((id, index) => ({
    id,
    title: titles[index],
    handle: handles && handles[index]
  }));

const buildTagGroups = (form, name, removeSelectionIds) => [
  {
    items: makeInventoryItemArray(
      getIn(form.values, `${name}.product_ids`),
      getIn(form.values, `${name}.product_titles`),
      getIn(form.values, `${name}.product_handles`)
    ),
    onRemove: item => {
      removeSelectionIds(item, "Product");
      removeInventoryItem(
        form,
        `${name}.product_ids`,
        `${name}.product_titles`,
        `${name}.product_handles`,
        item
      );
    }
  },
  {
    items: makeInventoryItemArray(
      getIn(form.values, `${name}.collection_ids`),
      getIn(form.values, `${name}.collection_titles`),
      getIn(form.values, `${name}.collection_handles`)
    ),
    onRemove: item => {
      removeSelectionIds(item, "Collection");
      removeInventoryItem(
        form,
        `${name}.collection_ids`,
        `${name}.collection_titles`,
        `${name}.collection_handles`,
        item
      );
    }
  },
  {
    items: (getIn(form.values, `${name}.product_variants`) || []).map(
      product => ({
        ...product,
        title: variantProductTitleWithCount(
          product.title,
          product.variants.length
        )
      })
    ),
    onRemove: item => {
      removeSelectionIds(item, "Variant");
      removeInventoryItemForVariant(form, `${name}.product_variants`, item);
    }
  }
];

export default function InventoryTags({ form, name, removeSelectionIds }) {
  // We want to be able to have products and collections together, so this
  // component can either take a single items and onRemove prop, or a groups
  // array of { items, onRemove } objects
  const groups = buildTagGroups(form, name, removeSelectionIds);

  const tagElements = groups.reduce(
    (accumulator, group, groupIndex) =>
      accumulator.concat(
        // For each group, we map over the items and return an array of Tag
        // elements, then concatenate it to the resulting array
        group.items.map((item, itemIndex) => (
          <Tag
            onRemove={() => group.onRemove(item)}
            key={`${groupIndex}-${itemIndex}`}
          >
            {item.title}
          </Tag>
        ))
      ),
    [] // Start with an empty array as accumulator
  );

  if (tagElements.length === 0) return null;

  return (
    <div className="InventoryTags">
      <Scrollable
        hint
        shadow
        style={tagElements.length > 20 ? { height: "200px" } : {}}
      >
        {tagElements}
      </Scrollable>
    </div>
  );
}
