import React from "react";
import { Layout, TextContainer } from "@shopify/polaris";
import { useShop } from "contexts/Shop";
import AnnotatedSection from "components/AnnotatedSection";
import copy from "content/copy.json";
import UsageFeesActiveCard from "./usage-fees-card/UsageFeesActiveCard";
import UsageFeesInactiveCard from "./usage-fees-card/UsageFeesInactiveCard";

function UsageFeesSection() {
  const { billingPeriodActive } = useShop();
  const { recentChargesVisibilityOnShopifyBill } =
    copy.account.percentageVolumetric.usageFeesSection;

  const layoutDescription = (
    <TextContainer spacing="tight">
      <p>{recentChargesVisibilityOnShopifyBill}</p>
    </TextContainer>
  );

  return (
    <AnnotatedSection title="Usage" layoutDescription={layoutDescription}>
      <Layout>
        <Layout.Section>
          <div className="usageFeesTable">
            {billingPeriodActive ? (
              <UsageFeesActiveCard />
            ) : (
              <UsageFeesInactiveCard />
            )}
          </div>
        </Layout.Section>
      </Layout>
    </AnnotatedSection>
  );
}

export default UsageFeesSection;
