import React from "react";
import { Card, FormLayout } from "@shopify/polaris";

import { variantsSelected } from "lib/offerHelpers";
import offerPlaceholders from "content/offerPlaceholders";
import PolarisFormikInventoryPicker from "components/PolarisFormikInventoryPicker";
import PolarisFormikDiscountSection from "components/PolarisFormikDiscountSection";
import OfferMessageSection from "components/OfferMessageSection";
import OfferSummarySection from "components/OfferSummarySection";
import OfferConfigurationSection from "components/OfferConfigurationSection";

export default function DiscountSection(props) {
  const {
    form,
    moneyFormat,
    offerTypeSlug,
    multicurrencyWarningCard,
    redirectToAppearance
  } = props;

  const variantPresence = variantsSelected(form);

  const placeholderType = variantPresence ? "variant" : "nonVariant";

  return [
    <OfferConfigurationSection
      key="offer-specifics"
      offerTypeSlug={offerTypeSlug}
    >
      <Card>
        <Card.Section title="Offer">
          <FormLayout>
            <p>
              Choose products or collections that trigger this offer when added
              to your cart.
            </p>
            <PolarisFormikInventoryPicker form={form} name="details.buy_from" />
          </FormLayout>
        </Card.Section>
        <Card.Section>
          <PolarisFormikDiscountSection
            name="details.discount"
            form={form}
            moneyFormat={moneyFormat}
            multicurrencyWarningCard={multicurrencyWarningCard}
          />
          <OfferSummarySection form={form} moneyFormat={moneyFormat} />
        </Card.Section>
      </Card>
    </OfferConfigurationSection>,
    <OfferMessageSection
      key="offer-messages"
      offerTypeSlug={offerTypeSlug}
      redirectToAppearance={redirectToAppearance}
      variantPresence={variantPresence}
      fields={[
        {
          label: "Notification message",
          name: "notifications.message",
          placeholder: offerPlaceholders.discount.notification[placeholderType]
        },
        {
          label: "Success message",
          name: "notifications.message_after",
          placeholder: offerPlaceholders.discount.success
        }
      ]}
    />
  ];
}
