import React from "react";
import InlineColorPicker from "components/InlineColorPicker";
import InputWithPopover from "components/InputWithPopover";

const InlineBadgeColorPicker = props => (
  <InlineColorPicker {...props} allowAlpha={true} />
);
InlineBadgeColorPicker.displayName = "InlineColorPicker";

// Breaking these inputs into a separate component breaks the classes
// being correctly applied to each child input - we've manually added a margin
// on the second input to fix :'(
export default function BadgeColorInputs({ form }) {
  return (
    <>
      <InputWithPopover
        form={form}
        key="badge-background"
        label="Background color"
        name="customizations.css.badge.background"
        placeholder="Color"
        popoverComponent={InlineBadgeColorPicker}
      />

      <div style={{ marginTop: "1.6rem" }}>
        <InputWithPopover
          form={form}
          key="badge-color"
          label="Text and border color"
          name="customizations.css.badge.color"
          placeholder="Color"
          popoverComponent={InlineBadgeColorPicker}
        />
      </div>
    </>
  );
}
