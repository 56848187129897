import * as yup from "yup";
import { isValidHexColor } from "lib/colorHelpers";

export const yupHexColorTest = (name, nullable = false) =>
  yup.string().test({
    name,
    test: string => isValidHexColor(string, nullable),
    message: `${name} must be a valid hexadecimal color`
  });

export const yupSchemaNumberBetween = (min, max, name) =>
  yup
    .number(`${name} must be a number`)
    .min(min, `${name} must be >= ${min}`)
    .max(max, `${name} must be <= ${max}`);

export const yupSchemaPercentage = yupSchemaNumberBetween.bind(null, 0, 100);
