import * as Types from "../../types/generated/graphql";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type MainThemeHasAjaxCartInstallQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type MainThemeHasAjaxCartInstallQuery = {
  __typename?: "RootQueryType";
  mainTheme: {
    __typename?: "MainTheme";
    hasAjaxCartInstall: boolean;
    id: number;
  };
};

export const MainThemeHasAjaxCartInstallDocument = gql`
  query MainThemeHasAjaxCartInstall {
    mainTheme {
      hasAjaxCartInstall
      id
    }
  }
`;

/**
 * __useMainThemeHasAjaxCartInstallQuery__
 *
 * To run a query within a React component, call `useMainThemeHasAjaxCartInstallQuery` and pass it any options that fit your needs.
 * When your component renders, `useMainThemeHasAjaxCartInstallQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMainThemeHasAjaxCartInstallQuery({
 *   variables: {
 *   },
 * });
 */
export function useMainThemeHasAjaxCartInstallQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MainThemeHasAjaxCartInstallQuery,
    MainThemeHasAjaxCartInstallQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MainThemeHasAjaxCartInstallQuery,
    MainThemeHasAjaxCartInstallQueryVariables
  >(MainThemeHasAjaxCartInstallDocument, options);
}
export function useMainThemeHasAjaxCartInstallLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MainThemeHasAjaxCartInstallQuery,
    MainThemeHasAjaxCartInstallQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MainThemeHasAjaxCartInstallQuery,
    MainThemeHasAjaxCartInstallQueryVariables
  >(MainThemeHasAjaxCartInstallDocument, options);
}
export type MainThemeHasAjaxCartInstallQueryHookResult = ReturnType<
  typeof useMainThemeHasAjaxCartInstallQuery
>;
export type MainThemeHasAjaxCartInstallLazyQueryHookResult = ReturnType<
  typeof useMainThemeHasAjaxCartInstallLazyQuery
>;
export type MainThemeHasAjaxCartInstallQueryResult = Apollo.QueryResult<
  MainThemeHasAjaxCartInstallQuery,
  MainThemeHasAjaxCartInstallQueryVariables
>;
