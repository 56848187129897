import {
  DEFAULT_OFFER_FIELDS,
  OFFER_PRICE_MAP,
  OFFER_PRICE_DISCOUNT_TYPES as PRICE_TYPES
} from "types/constants/offers";
import { formatCurrency } from "lib/currencyHelpers";
import { getTypeSlug } from "lib/offerHelpers";

export function getUTCStr(dateInput, timeInput = "") {
  // split input from yyyy-mm-dd to work in Safari and IE
  const [yearInput, monthInput, dayInput] = dateInput.split("-");
  const [hourInput, minuteInput] = timeInput.split(":");
  const d = new Date(
    yearInput,
    monthInput - 1,
    dayInput,
    hourInput,
    minuteInput
  );
  const year = d.getUTCFullYear();
  const month = (d.getUTCMonth() + 1).toString().padStart(2, "0");
  const date = d.getUTCDate().toString().padStart(2, "0");
  const hours = d.getUTCHours().toString().padStart(2, "0");
  const minutes = d.getUTCMinutes().toString().padStart(2, "0");
  const seconds = d.getUTCSeconds().toString().padStart(2, "0");
  return `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;
}

export const prepareApplicabilityParams = formSource => {
  const applicability = { ...DEFAULT_OFFER_FIELDS.applicability };

  if (formSource) {
    if (formSource.beginning_at_date) {
      applicability.beginning_at = true;
      applicability.beginning_at_date = formSource.beginning_at_date;
      applicability.beginning_at_time = formSource.beginning_at_time || "00:01";
      applicability.beginning_at_utc = getUTCStr(
        applicability.beginning_at_date,
        applicability.beginning_at_time
      );
    }

    if (formSource.ending_at_date) {
      applicability.ending_at = true;
      applicability.ending_at_date = formSource.ending_at_date;
      applicability.ending_at_time = formSource.ending_at_time || "23:59";
      applicability.ending_at_utc = getUTCStr(
        applicability.ending_at_date,
        applicability.ending_at_time
      );
    }

    if (formSource.only_customers_signed_in) {
      applicability.only_customers_signed_in = true;
      if (formSource.only_customers_tagged_with_tags) {
        applicability.only_customers_tagged_with = true;
        applicability.only_customers_tagged_with_tags =
          formSource.only_customers_tagged_with_tags;
      }
    }

    if (formSource.exclude_customers_tagged_with) {
      applicability.exclude_customers_tagged_with = true;
      applicability.exclude_customers_tagged_with_tags =
        formSource.exclude_customers_tagged_with_tags;
    }

    if (formSource.channels) {
      applicability.channels = formSource.channels;
    }

    if (formSource.only_special_link_code) {
      applicability.only_special_link = true;
      applicability.only_special_link_code = formSource.only_special_link_code;
    }
  }

  return applicability;
};

// TODO: this does not include money formatting.
// Need to pull shop's money format from state and pass it to these functions
export const buildTitleForPrice = ({ type, amount }, moneyFormat) => {
  let title;

  switch (type) {
    case PRICE_TYPES.SUBTRACT:
      title = `${formatCurrency(amount, moneyFormat)} off`;
      break;
    case PRICE_TYPES.FLAT:
      title = `${formatCurrency(amount, moneyFormat)}`;
      break;
    case PRICE_TYPES.PERCENT:
      title = `${amount}% Off`;
      break;
    default:
      title = "";
  }

  return {
    type,
    amount,
    title: `<span class="saso-price">${title}</span>`
  };
};

const buildTitlesForVolumeOffer = (offerDetails, moneyFormat) => {
  return {
    ...offerDetails,
    tiers: offerDetails.tiers.map(tier => {
      return {
        ...tier,
        price: buildTitleForPrice(tier.price, moneyFormat)
      };
    })
  };
};

export const appendPriceTitles = (offerDetails, offerType, moneyFormat) => {
  // volume gets special treatment because prices are in a tiers array
  if (offerType === "volume") {
    return buildTitlesForVolumeOffer(offerDetails, moneyFormat);
  }

  const priceField = OFFER_PRICE_MAP[offerType];
  if (!priceField) return offerDetails;

  const price = buildTitleForPrice(offerDetails[priceField], moneyFormat);

  // Client-side code installed in themes removes "Off" for goal offers.
  // This is the quickest way to maintain compatibility.
  if (offerType === "goal") {
    price.title = price.title.replace("off", "Off");
  }

  return {
    ...offerDetails,
    [priceField]: price
  };
};

export const addSingleCollectionLink = offerDetails => {
  if (
    offerDetails.get_from &&
    offerDetails.get_from.collection_ids.length === 1 &&
    offerDetails.get_from.collection_handles
  ) {
    offerDetails.get_from.click_here = `/collections/${offerDetails.get_from.collection_handles[0]}`;
  }
  return offerDetails;
};

export const getPriority = (offerDetails, offerType) => {
  const priorityFloat = getPriorityFloat(offerDetails, offerType);
  return Math.round(priorityFloat);
};

const getPriorityFloat = (offerDetails, offerType) => {
  /**
   * all logic copied from:
   * https://github.com/pixelunion/supple-specialoffers/blob/master/public/admin/js/views/OfferDetailsView.js#L604-L887
   */
  switch (offerType) {
    case "discount":
      if (offerDetails.discount.type === PRICE_TYPES.PERCENT) {
        // largest percentages first
        return 100 - offerDetails.discount.amount;
      } else {
        /**
         * flat price, all go after percentages, in the order of smallest price.
         * Multiply with 100 to make sure they're after percentages.
         * Should fixed prices get priority?
         */
        return 100 * offerDetails.discount.amount;
      }
    case "volume":
      // eslint-disable-next-line no-case-declarations
      const price = offerDetails.tiers[0].price;
      if (price.type === PRICE_TYPES.PERCENT) {
        // largest percentages first
        return 100 - price.amount;
      } else {
        /**
         * flat price, all go after percentages, in the order of smallest price.
         * Multiply with 100 to make sure they're after percentages
         */
        return 100 * price.amount;
      }
    case "goal":
      if (offerDetails.discount.type === PRICE_TYPES.PERCENT) {
        // largest percentages first
        return 100 - offerDetails.discount.amount;
      } else {
        /**
         * flat price, all go after percentages, in the order of LARGEST amount.
         * Multiply with 100 to make sure they're after percentages
         */
        return 999999988 - 100 * offerDetails.discount.amount;
      }
    case "bulk":
      //reverse qty
      return 10000 - offerDetails.buy_qty;
    case "bogo":
      /**
       * reverse total qty
       * differentiate between offers with same qty, but different $ off, % or
       * fixed amount
       */
      return (
        1000000 -
        (offerDetails.buy_qty + offerDetails.get_qty) * 10000 -
        offerDetails.price.amount
      );
    case "bundle":
      //priority = reverse qty. eg Buy 3 polos + 1 belt at; Buy 2 polos + 1 belt at;
      return 100000 - offerDetails.bundles.length * 1000;
    case "credit":
      //reverse qty
      return 10000 - offerDetails.buy_qty;
    case "gift":
      return offerDetails.cart_amount_greater_than.amount;
    case "upsell":
      return 1;
    default:
      break;
  }
  return 1;
};

const parseIfPresent = (value, parser) => {
  const parsedValue = parser(value);
  return isNaN(parsedValue) ? value : parsedValue;
};

const parseRecursively = (obj, valueToParse, parser) => {
  if (typeof obj === "object") {
    for (const key in obj) {
      if (key === valueToParse) {
        obj[key] = parseIfPresent(obj[key], parser);
      } else if (typeof obj[key] === "object") {
        parseRecursively(obj[key], valueToParse, parser);
      }
    }
  }
};

export const parseOfferInputsToNumerics = (offerDetails, offerType) => {
  //start with properties that are on the top level of the offer details
  const propertiesToParse = ["buy_qty", "get_qty", "use_max"];
  for (const prop of propertiesToParse) {
    if (offerDetails && offerDetails[prop]) {
      offerDetails[prop] = parseIfPresent(offerDetails[prop], parseInt);
    }
  }

  //parse the 'amount' values
  const priceField = OFFER_PRICE_MAP[offerType];
  if (priceField) {
    offerDetails[priceField].amount = parseIfPresent(
      offerDetails[priceField].amount,
      parseFloat
    );
  } else if (offerDetails.price) {
    offerDetails.price.amount = parseIfPresent(
      offerDetails.price.amount,
      parseFloat
    );
  } else if (offerDetails.tiers) {
    offerDetails.tiers.forEach(tier => {
      tier.price.amount = parseIfPresent(tier.price.amount, parseFloat);
    });
  }

  //some offers have unique properties to be parsed individually
  switch (offerType) {
    case "postPurchaseOffer":
      offerDetails.shipping.default = parseIfPresent(
        offerDetails.shipping.default,
        parseFloat
      );
      break;
    case "goal":
      offerDetails.amount_greater_than.amount = parseIfPresent(
        offerDetails.amount_greater_than.amount,
        parseFloat
      );
      break;
    case "gift":
      offerDetails.cart_amount_greater_than.amount = parseIfPresent(
        offerDetails.cart_amount_greater_than.amount,
        parseFloat
      );
      break;
  }

  //parsing 'quantity' values on Volume and Bundle offers
  if (offerDetails?.tiers) {
    parseRecursively(offerDetails.tiers, "quantity", parseInt);
  }
  if (offerDetails?.bundles) {
    parseRecursively(offerDetails.bundles, "quantity", parseInt);
  }

  return offerDetails;
};

export const prepareFormData = (formData, moneyFormat) => {
  const offerType = getTypeSlug(formData.type);

  if (formData.applicability) {
    formData.applicability = prepareApplicabilityParams(formData.applicability);
  }

  if (formData.details) {
    formData.details = appendPriceTitles(
      formData.details,
      offerType,
      moneyFormat
    );

    //numeric inputs on the offer form need to be parsed before written to the database
    formData.details = parseOfferInputsToNumerics(formData.details, offerType);

    // some offers with a frontend popup need a link to the collections page
    formData.details = addSingleCollectionLink(formData.details);
  }

  if (offerType) {
    formData.priority = getPriority(formData.details, offerType);
  }

  return formData;
};
