// @flow

import axios from "./authedAxios";
import { API_ORIGIN } from "lib/constants";
import { reportError } from "lib/errorHelpers";

import AppPlan from "types/app-plan";
import PROMOS from "types/constants/promos";

export const RECURRING_CHARGES_API_PATH = "/api/recurring-charges";
export const RECURRING_CHARGES_API_BASE = `${API_ORIGIN}${RECURRING_CHARGES_API_PATH}`;

type RecurringChargesResponse =
  | {
      error: Error
    }
  | {
      data: { redirectUrl: string }
    };

type Payload = { promoCode?: PROMOS.THIRTY_DAYS_FREE.CODE };

type DeleteRecurringChargesResponse =
  | { error: Error }
  | { data: { appPlan: AppPlan } };

export async function createRecurringCharge(
  payload: Payload = {}
): Promise<RecurringChargesResponse> {
  try {
    const { data } = await axios.post(RECURRING_CHARGES_API_BASE, payload);
    return { data };
  } catch (e) {
    const error = new Error(e);
    reportError(error);
    return { error };
  }
}

export async function deleteRecurringCharge(): Promise<DeleteRecurringChargesResponse> {
  try {
    const { data } = await axios.delete(RECURRING_CHARGES_API_BASE);
    return { data };
  } catch (e) {
    const error = new Error(e);
    reportError(error);
    return { error };
  }
}
