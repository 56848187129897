const badgeTag = ({
  badgeType,
  badgeSvg,
  zIndex,
  badgeText,
  handleOfferUUID,
  badgeUseDefault,
  badgePosition,
  badgeBorder
}) => {
  return `
  <div class="saso-badge-wrapper">
    <div class="saso-offer-badge saso-offer-badge-style-${
      badgeUseDefault ? "default" : handleOfferUUID
    } saso-offer-badge-position-${badgePosition} saso-offer-badge-type-${badgeType}${
    badgeBorder ? " saso-offer-badge-border" : ""
  }" style="z-index:${zIndex};" saso-offer-uuid="${handleOfferUUID}">
      ${badgeSvg || ""}
      <div class="saso-offer-badge-content">
        <div class="saso-offer-badge-text" data-badge-type="${badgeType}">${badgeText}</div>
      </div>
    </div>
  </div>
  `;
};

export default badgeTag;
