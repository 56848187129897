import React from "react";
import { useLocation } from "react-router-dom";

import EarlyActivation from "../EarlyActivation";
import MaxUsage from "./MaxUsage";
import TrialEnded from "./TrialEnded";
import TrialOngoing from "./TrialOngoing";
import TrialStart from "./TrialStart";
import Usage from "./Usage";

import { useShop } from "contexts/Shop";
import { SetShopEvent } from "types/shop";

interface Props {
  setEvent: SetShopEvent;
}

export default function PlanStatusContainer({ setEvent }: Props) {
  const { pathname } = useLocation();

  const {
    activeUltimatePlan,
    app_plan: appPlan,
    events,
    free_plan_usage: freePlanUsage,
    trial_days: trialDays,
    trialDaysRemaining
  } = useShop();

  if (
    activeUltimatePlan &&
    trialDaysRemaining > 0 &&
    pathname.startsWith("/account")
  ) {
    return <EarlyActivation trialDaysRemaining={trialDaysRemaining} />;
  }

  if (activeUltimatePlan) return null;

  if (trialDays === trialDaysRemaining) {
    return <TrialStart trialDaysRemaining={trialDaysRemaining} />;
  }

  if (trialDaysRemaining > 0) {
    return <TrialOngoing />;
  }

  if (
    (!freePlanUsage || freePlanUsage === 0) &&
    !events.free_plan_trial_ended_banner_dismissed
  ) {
    return <TrialEnded setEvent={setEvent} />;
  }

  if (freePlanUsage >= appPlan.freeOrders) {
    return <MaxUsage />;
  }

  if (freePlanUsage > 0) {
    return <Usage freePlanUsage={freePlanUsage} />;
  }

  return null;
}
