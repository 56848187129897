// @flow
import React, { useEffect, useState } from "react";
import { AppProvider } from "@shopify/polaris";
import { Provider, Toast } from "@shopify/app-bridge-react";
import createApp from "@shopify/app-bridge";
import translations from "@shopify/polaris/locales/en.json";

import { shopActions } from "store/shopStore";
import { storeProvide, storeConnect } from "appStore";
import makeRequestProps from "store/makeRequestProps";
import { IndexSkeletonPage } from "components/skeletons";
import { reportError } from "lib/errorHelpers";
import getAppAuth from "lib/getAppAuth";

import { STATUSES } from "lib/constants";
import ErrorBoundary from "components/ErrorBoundary";
import Routes from "./Routes";
import ErrorPage from "components/ErrorPage";
import SupportWidget from "components/SupportWidget";

import Navigation from "contexts/Navigation";
import { FlagsProvider } from "contexts/Flags";
import { I18nProvider } from "ui/src/providers/I18nProvider.tsx";
import GraphQLProvider from "graphql/provider";

import type { ShopRequest } from "appStore";
import type { Shop } from "types/flow-types/shop";

import "@shopify/polaris/build/esm/styles.css";
import "./App.css";

type Props = {
  shopRequest: ShopRequest,
  checkCompanionApps: () => void,
  clearToast: () => void,
  initShop: () => void,
  toast: { content: String, error?: boolean },
  shop: Shop
};

function AppWrapper(props) {
  const [authError, setAuthError] = useState(false);
  const [appAuth, setAppAuth] = useState(null);

  useEffect(() => {
    try {
      const appAuth = getAppAuth();
      window.host = appAuth.host;
      window.app = createApp(appAuth);
      setAppAuth(appAuth);
    } catch (error) {
      reportError(error);
      setAuthError(true);
    }
  }, []);

  if (authError) {
    return (
      <AppProvider i18n={translations}>
        <ErrorPage stateKey="appConfig" status={STATUSES.READING} />
      </AppProvider>
    );
  }

  if (appAuth) {
    return (
      <I18nProvider>
        <AppProvider i18n={translations}>
          <Provider config={appAuth}>
            <ErrorBoundary>
              <GraphQLProvider>
                <App {...props} />
              </GraphQLProvider>
            </ErrorBoundary>
          </Provider>
        </AppProvider>
      </I18nProvider>
    );
  }

  return (
    <AppProvider i18n={translations}>
      <IndexSkeletonPage />
    </AppProvider>
  );
}

export const App = (props: Props) => {
  useEffect(() => {
    const { initShop, checkCompanionApps } = props;

    /**
     * Sentry is supposed to handle these errors out of the box – perhaps the
     * iframe prevents them from bubbling up?
     **/
    window.addEventListener("unhandledrejection", error => {
      reportError(error.reason);
    });

    // Hide the Zendesk Web Widget until feature flags are available
    window.zE && window.zE("webWidget", "hide");

    initShop();
    checkCompanionApps();
  }, []);

  useEffect(() => {
    const { flags, settings } = props.shopRequest.shop;
    const { email, shop_owner: shopOwner } = settings;

    if (!flags.enableZendeskWidget || !window.zE) {
      return;
    }

    window.zE("webWidget", "show");
    window.zE("webWidget", "identify", { name: shopOwner, email: email });
    window.zE("webWidget", "prefill", {
      email: {
        value: email,
        readOnly: false // user can edit the value on the form
      }
    });
  }, [props.shopRequest.shop.flags, window.zE]);

  const { shopRequest, clearToast, toast } = props;
  const { error, loading, shop, status } = shopRequest;

  if (loading) {
    return <IndexSkeletonPage />;
  }

  if (error) {
    return <ErrorPage stateKey="appConfig" status={status} />;
  }

  const { flags } = shop;

  return (
    <>
      <Navigation>
        <FlagsProvider value={flags}>
          <Routes {...props} />
        </FlagsProvider>
      </Navigation>
      {toast && <Toast onDismiss={clearToast} {...toast} />}
      {!flags.enableZendeskWidget && <SupportWidget />}
    </>
  );
};

const mapStateToProps = state => {
  return {
    shopRequest: makeRequestProps(state, "shop"),
    toast: state.toast
  };
};

export default storeProvide(
  storeConnect(AppWrapper, mapStateToProps, shopActions)
);
