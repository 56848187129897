// @flow

import React, { useContext } from "react";
import { TitleBar } from "@shopify/app-bridge-react";
import { Page } from "@shopify/polaris";
import { ExternalMinor } from "@shopify/polaris-icons";

import { NavigationContext } from "contexts/Navigation";

import AccountPageMessage from "./AccountPageMessage";
import BillingTableContainer from "./BillingTableContainer";
import MaxChargeDisclaimer from "./MaxChargeDisclaimer";
import PlanCard from "./PlanCard";

import { getTrialDaysLeft } from "lib/planHelpers";
import onShopifyDevPlan from "lib/shop/on-shopify-dev-plan";

import type { Shop, ShopEventPayload } from "types/flow-types/shop";

import "./AccountPage.scss";
import DevPlanCard from "./DevPlanCard";

export default function AccountPage({
  appPlanName,
  displayBillingTable,
  setEvent,
  shop,
  shopifyChargeReturnError,
  ultimatePlanActivated
}: {
  appPlanName: string,
  displayBillingTable: boolean,
  setEvent: ShopEventPayload => void,
  shop: Shop,
  shopifyChargeReturnError: boolean,
  ultimatePlanActivated: boolean
}) {
  const navigation = useContext(NavigationContext);
  const trialDays = shop.trial_days;
  const createdDate = shop.created_date;
  const trialDaysRemaining = getTrialDaysLeft(createdDate, trialDays);
  const onDevPlan = onShopifyDevPlan(shop.settings.plan_name);

  return (
    <div className="Account-Page">
      <TitleBar title="Account" />
      <Page
        title="Account"
        breadcrumbs={[
          {
            content: "Dashboard",
            onAction: () => navigation.goTo("/")
          }
        ]}
        secondaryActions={[
          {
            content: "Learn more",
            external: true,
            icon: ExternalMinor,
            url: "https://launch.orbitapps.com/hc/en-us/articles/4402806613523"
          }
        ]}
      >
        {!onDevPlan && (
          <AccountPageMessage
            appPlanName={appPlanName}
            setEvent={setEvent}
            shop={shop}
            shopifyChargeReturnError={shopifyChargeReturnError}
            trialDaysRemaining={trialDaysRemaining}
          />
        )}
        {displayBillingTable && <BillingTableContainer />}
        {onDevPlan && <DevPlanCard />}
        <PlanCard
          planActivated={ultimatePlanActivated}
          setEvent={setEvent}
          shop={shop}
        />
        {!displayBillingTable && <MaxChargeDisclaimer />}
      </Page>
    </div>
  );
}
