// @flow

import React from "react";
import { Heading } from "@shopify/polaris";

import { periodDate } from "./account-helpers";

import type { CurrentBillingPeriodData } from "types/flow-types/billing";

export default function BillingPeriodDetails({
  billingData
}: {
  billingData: CurrentBillingPeriodData
}) {
  const { billingPeriodStart: startDate, billingPeriodEnd: endDate } =
    billingData;

  return (
    <Heading>
      <div className="Current-Billing-Subtitle">
        Current billing cycle: {periodDate(startDate)} to {periodDate(endDate)}
      </div>
    </Heading>
  );
}
