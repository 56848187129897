// @flow
import React, { useContext } from "react";
import { Button } from "@shopify/polaris";
import { getScopesOAuthURL } from "api/scopes";
import { hasRequiredScopes } from "lib/scopesHelpers";

import { NavigationContext } from "contexts/Navigation";

type Props = {
  scopes: Array<string>
};

async function redirectToAuth(navigation) {
  const authUrl = await getScopesOAuthURL();

  navigation.redirectAdmin(authUrl);
}

export default function AuthorizeScopesButton(props: Props) {
  const navigation = useContext(NavigationContext);
  const { scopes } = props;

  const disabled = hasRequiredScopes(scopes);

  return (
    <Button
      plain
      disabled={disabled}
      onClick={() => redirectToAuth(navigation)}
    >
      1. Confirm new app permissions
    </Button>
  );
}
