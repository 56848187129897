import React from "react";
import {
  ChoiceList,
  Card,
  FormLayout,
  TextStyle,
  TextContainer
} from "@shopify/polaris";

import AnnotatedSection from "components/AnnotatedSection";

import "./MultipleDiscountsSection.css";
import DiscountCodeUnsupportedBanner from "./DiscountCodeUnsupportedBanner";

const discountCodeChoices = [
  {
    label: `Let customers use a discount code with Ultimate Special Offers`,
    value: "allow_use_discount_code_cart",
    helpText: (
      <TextContainer spacing="tight">
        <p>
          Customers enter the discount code on the cart page. You manage
          Discount Codes via the Discounts section of your Shopify admin.
        </p>
        <p>
          At this time, Ultimate Special Offers is not compatible with
          Shopify&apos;s Automatic Discounts, Buy X Get Y discounts, or Free
          Shipping discounts.
        </p>
      </TextContainer>
    )
  },
  {
    label:
      "Let customers choose to use a discount code instead of Ultimate Special Offers",
    value: "allow_use_discount_code_instead"
  }
];

const discountCodeOptionProperties = discountCodeChoices.map(
  choice => choice.value
);

// options nested underneath the first cart method choice above
const cartMethodChoices = [
  {
    label: (
      <span>
        Apply discounts <strong>before</strong> special offers
      </span>
    ),
    value: "before",
    helpText:
      "If discount codes require a minimum purchase to be valid, the larger cart total before special offers is used."
  },
  {
    label: (
      <span>
        Apply discounts <strong>after</strong> special offers
      </span>
    ),
    value: "after",
    helpText:
      'If you use the Goal or Gift offer types, offers will still apply when the cart dips below the required amount when a discount code is used. (e.g. "Spend $50 and get a free gift." Customers will spend $50, qualify for the gift, enter a 10% discount code, reducing the total order value to $45, but they will still get the gift.'
  }
];

const multipleOffersChoices = [
  { value: "Discount", label: "Discount" },
  { value: "Volume", label: "Volume" },
  { value: "Spend Amount", label: "Goal" },
  { value: "Buy X Get Y", label: "BOGO" },
  { value: "Buy X For $", label: "Bulk Discount" },
  { value: "Bundle", label: "Bundle" }
];

export default function MultipleDiscountsSection(props) {
  const { values, setFieldValue } = props.form;

  const selectedMethods = discountCodeOptionProperties.filter(
    name => values[name]
  );

  function handleDiscountMethodChange(values) {
    discountCodeOptionProperties.forEach(property => {
      setFieldValue(property, values.includes(property));
    });
  }
  const CartMethodOptions = (
    <ChoiceList
      name="allow_use_discount_code_cart_method"
      selected={values.allow_use_discount_code_cart_method}
      choices={cartMethodChoices}
      onChange={([value]) => {
        setFieldValue("allow_use_discount_code_cart_method", value);
      }}
    />
  );

  return (
    <AnnotatedSection
      title="Multiple discounts"
      description="Specify how to handle scenarios with multiple discounts."
      linkUrl="https://launch.orbitapps.com/hc/en-us/articles/4402812996499"
    >
      <Card>
        <Card.Section title="Third party discounts">
          <DiscountCodeUnsupportedBanner />
          <FormLayout>
            <ChoiceList
              name="third_party_discounts"
              allowMultiple
              choices={[
                {
                  ...discountCodeChoices[0],
                  renderChildren: () => CartMethodOptions
                },
                discountCodeChoices[1]
              ]}
              selected={selectedMethods}
              onChange={handleDiscountMethodChange}
            />
          </FormLayout>
        </Card.Section>
        <Card.Section title="Multiple special offers">
          <FormLayout>
            <TextContainer>
              <TextStyle variation="subdued">
                Multiple offers can be applied to a product as long as they are
                different offer types. Select which offer types can be combined.
              </TextStyle>
            </TextContainer>
            <div className="MultipleOffers-Choices">
              <ChoiceList
                name="multiple_offers"
                choices={multipleOffersChoices}
                selected={values.multiple_offers}
                allowMultiple
                onChange={values => {
                  setFieldValue("multiple_offers", values);
                }}
              />
            </div>
          </FormLayout>
        </Card.Section>
      </Card>
    </AnnotatedSection>
  );
}
