import React from "react";
import { Tooltip } from "@shopify/polaris";
import { colorSky } from "@shopify/polaris-tokens";

import { getOfferType } from "lib/offerHelpers";

// These files are pretty small and we're going to use all of them in the offer
// edit screen so it's okay that we import them all in this one file
// If they were larger, or more of them, or only used in certain routes then
// we'd want to split this file out into multiple component files, one for each
// icon
// Also there's no way in CRA to import all images from a dir, sooo....
import bogoImg from "./img/icon-bogo.svg";
import bulkImg from "./img/icon-bulk.svg";
import bundleImg from "./img/icon-bundle.svg";
import creditImg from "./img/icon-credit.svg";
import discountImg from "./img/icon-discount.svg";
import giftImg from "./img/icon-gift.svg";
import goalImg from "./img/icon-goal.svg";
import upsellImg from "./img/icon-upsell.svg";
import volumeImg from "./img/icon-volume.svg";
import postPurchaseOfferImg from "./img/icon-post-purchase-offer.svg";

import postPurchaseOfferImgLocked from "./img/icon-post-purchase-offer-locked.svg";

// TODO: get final images for all sizes and populate this object with them
const images = {
  bogo: bogoImg,
  bulk: bulkImg,
  bundle: bundleImg,
  credit: creditImg,
  discount: discountImg,
  gift: giftImg,
  goal: goalImg,
  upsell: upsellImg,
  volume: volumeImg,
  postPurchaseOffer: postPurchaseOfferImg
};

const lockedimages = {
  postPurchaseOffer: postPurchaseOfferImgLocked
};

export default function offerIcon(type, props = {}) {
  const offerType = getOfferType(type);
  const { deactivated, faded, size } = props;

  const image = deactivated ? lockedimages[type] || images[type] : images[type];

  const style = {
    width: `${size}px`,
    minWidth: `${size}px`,
    height: `${size}px`,
    display: "inline-block",
    background: `url(${image}) no-repeat center center`,
    backgroundColor: faded || deactivated ? colorSky : offerType.color,
    backgroundSize: "auto 100%",
    borderRadius: "3px",
    verticalAlign: "middle",
    ...props.style
  };

  const tooltipText = (
    <span>
      {`To re-enable your ${offerType.title} offers, visit the Account Page from
      the main menu.`}
    </span>
  );

  return deactivated && type === "postPurchaseOffer" ? (
    <Tooltip preferredPosition="above" content={tooltipText}>
      <span style={style} />
    </Tooltip>
  ) : (
    <span style={style} />
  );
}
