import React, { Component } from "react";
import { Button, InlineError } from "@shopify/polaris";
import { fetchCollections } from "api/collections";
import { addAllCollections } from "lib/formHelpers";

class AddAllCollectionsButton extends Component {
  state = {
    fetching: false,
    error: null
  };

  errorMessage =
    "Something went wrong getting your collections. Please reload the page and try again, or contact support";

  onClick = async () => {
    this.setState({ fetching: true });

    const { data: collections, error } = await fetchCollections();

    if (error) {
      this.setState({ error: true, fetching: false });
    } else {
      const { form, idsFieldName, titlesFieldName, handlesFieldName } =
        this.props;
      addAllCollections(
        form,
        idsFieldName,
        titlesFieldName,
        handlesFieldName,
        collections
      );

      this.props.onAllCollectionFetch(collections);

      this.setState({ fetching: false });
    }
  };

  render() {
    if (this.props.type === "PostPurchaseOffer") return null;

    return (
      <React.Fragment>
        <Button plain onClick={this.onClick} loading={this.state.fetching}>
          Add all collections
        </Button>
        {this.state.error && (
          <div style={{ marginTop: `0.5em` }}>
            <InlineError message={this.errorMessage} />
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default AddAllCollectionsButton;
