// @flow

import React, { useContext } from "react";
import { Layout, Banner, ButtonGroup, Button } from "@shopify/polaris";
import { NavigationContext } from "contexts/Navigation";

import { getTrialDaysLeft, getTrialBannerState } from "lib/planHelpers";
import DevPlanBanner from "./DevPlanBanner";

import MailTo from "components/MailTo";

import { storeConnect } from "appStore";
import { shopActions } from "store/shopStore";
import onShopifyDevPlan from "lib/shop/on-shopify-dev-plan";
import type { Shop, ShopEventPayload } from "types/flow-types/shop";

const trialActiveText = (
  <p>
    Activate your subscription now to continue running offers after your trial
    has ended. You will not be charged for the remainder of your trial.
  </p>
);

const trialOverText = (
  <p>
    Activate your Ultimate Special Offers subscription to make your offers
    available to your customers.
  </p>
);

function buildMailtoLink(shop) {
  const subject = `Ultimate Special Offers app subscription`;
  const appDomain = `https://${shop.settings.myshopify_domain}/admin/apps/${shop.app_slug}`;

  const body = [
    "Hi there,",
    "We're using Ultimate Special Offers to manage sales and discounts in our Shopify store. Would you please start a paid subscription at the following link?",
    appDomain
  ].join("\n\n");

  return [
    `${shop.settings.email}`,
    `?subject=${encodeURIComponent(subject)}`,
    `&body=${encodeURIComponent(body)}`
  ].join("");
}

function ActivationButton() {
  const navigation = useContext(NavigationContext);
  return (
    <Button primary onClick={() => navigation.goTo("/account")}>
      View and activate subscription
    </Button>
  );
}

function TrialBannerSection({
  setEvent,
  shop
}: {
  setEvent: ShopEventPayload => void,
  shop: Shop
}) {
  const {
    created_date: createdDate,
    events,
    plans,
    settings,
    slug,
    subscription_activated_date: subscriptionActivatedDate,
    trial_days: trialDays
  } = shop;

  if (onShopifyDevPlan(settings.plan_name)) {
    return (
      <DevPlanBanner
        dismissed={events.dev_shop_trial_banner_dismissed}
        setEvent={setEvent}
      />
    );
  }

  if (!plans || (plans && subscriptionActivatedDate)) {
    return null;
  }

  const trialDaysLeft = getTrialDaysLeft(createdDate, trialDays);

  const { status, heading } = getTrialBannerState(trialDays, trialDaysLeft);
  const trialExpired = trialDaysLeft <= 0;
  const mailToLink = `groundcontrol@orbitapps.com?subject=Ultimate%20Special%20Offers%20trial%20extension%20request%20-%20${slug}`;

  return (
    <Layout.Section>
      <Banner title={heading} status={status}>
        {trialDaysLeft <= 0 ? trialOverText : trialActiveText}
        <div style={{ marginTop: "20px" }}>
          <ButtonGroup>
            <ActivationButton />
            <Button outline external url={buildMailtoLink(shop)}>
              Contact account owner
            </Button>
            {trialExpired && (
              <MailTo to={mailToLink} component={<Button outline external />}>
                Request trial extension
              </MailTo>
            )}
          </ButtonGroup>
        </div>
      </Banner>
    </Layout.Section>
  );
}

export default storeConnect(TrialBannerSection, "", shopActions);
