// events passed as "flash" cookie for frontend tracking
const COOKIE_EVENTS = {
  INSTALLED: "installed",
  CHARGE_ACTIVATED: "chargeActivated"
};

const CLIENT_EVENTS = {
  [COOKIE_EVENTS.INSTALLED]: "App Installed",
  [COOKIE_EVENTS.CHARGE_ACTIVATED]: "App Subscription Activated"
};

const EVENT_COOKIE_KEY = "routeEvent";

// server-side analytics events
const ANALYTICS_EVENTS = {
  CART_VIEWED: "USO Storefront Cart Viewed",
  ORDER_CREATED: "USO Storefront Order Created"
};

module.exports = {
  EVENT_COOKIE_KEY,
  COOKIE_EVENTS,
  CLIENT_EVENTS,
  ANALYTICS_EVENTS
};
