import React from "react";
import { TextField } from "@shopify/polaris";
import objectPath from "lib/objectPath";
import { uniqueIdForInput } from "lib/formHelpers";

// TODO: move <Field/> wrapping directly into component
export default function PolarisFormikTextField(props) {
  const {
    field,
    form,
    label,
    prefix,
    suffix,
    connectedLeft,
    labelHidden,
    type = "text",
    suppressErrorMessage = false,
    autoFocus,
    helpText
  } = props;
  const { name, value } = field;
  const id = props.id || uniqueIdForInput(name);

  const touched = objectPath.get(form.touched, name);
  const error = objectPath.get(form.errors, name);
  let errorMsg = touched && error ? error : null;

  if (suppressErrorMessage) {
    errorMsg = Boolean(errorMsg);
  }

  const placeholder = props.placeholder || "";

  const onChange =
    props.onChange ||
    function (value) {
      form.setFieldTouched(name);
      form.setFieldValue(name, value);
    };

  return (
    <TextField
      {...{
        id,
        label,
        value,
        type,
        error: errorMsg,
        prefix,
        suffix,
        connectedLeft,
        placeholder,
        onChange,
        labelHidden,
        autoFocus,
        helpText
      }}
    />
  );
}
