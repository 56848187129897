// @flow
import React, { Fragment, type Element } from "react";
import { Select, Stack, FormLayout, TextStyle } from "@shopify/polaris";
import objectPath from "lib/objectPath";

import { OFFER_PRICE_DISCOUNT_TYPES as PRICE_TYPES } from "types/constants/offers";
import { getTypeSlug } from "lib/offerHelpers";
import { getCurrencySymbol, stripTags } from "lib/currencyHelpers";

import PolarisFormikNumberField from "components/PolarisFormikNumberField";

import "./PolarisFormikDiscountSection.css";

import type { MulticurrencyWarningInCardType } from "components/banners/MulticurrencyWarningInCard";

type DiscountSectionProps = {
  form: Object,
  name: string,
  moneyFormat: string,
  label: string,
  discountTypes: Array<string>,
  multicurrencyWarningCard: Element<MulticurrencyWarningInCardType>
};

const discountTypeOptions = {
  flat: {
    label: "New price",
    value: PRICE_TYPES.FLAT
  },
  percent: {
    label: "Discount percentage",
    value: PRICE_TYPES.PERCENT
  },
  subtract: {
    label: "Discount amount",
    value: PRICE_TYPES.SUBTRACT
  }
};

const goalFlatOptions = {
  label: "Discount amount",
  value: PRICE_TYPES.FLAT
};

function getDiscountOptionsForType(type) {
  // goal offers get 'flat' and 'percent' options, but the 'flat' option has a
  // label that we use for the 'subtract' option for other offer types. For some
  // reason, the logic is just inconsistent
  // https://github.com/pixelunion-apps/ultimate-special-offers/blob/7936e83dfd4f244575a8f3206702f626a4f77b1c/api/src/lib/offers/spend-amount/spend-amount.js#L151-L156
  if (type === "goal") {
    return [goalFlatOptions, discountTypeOptions.percent];
  }

  if (type === "bulk") {
    return [discountTypeOptions.flat, discountTypeOptions.percent];
  }

  if (type === "postPurchaseOffer") {
    return [discountTypeOptions.subtract, discountTypeOptions.percent];
  }

  return Object.values(discountTypeOptions);
}

export function getSymbolPrefixOrSuffix(
  value: string,
  moneyFormat: string
): {
  positionKey: string,
  symbol: string
} {
  if (value === PRICE_TYPES.PERCENT) {
    return {
      positionKey: "suffix",
      symbol: "%"
    };
  }

  const symbol = getCurrencySymbol(moneyFormat);
  const positionKey =
    stripTags(moneyFormat).indexOf(symbol) === 0 ? "prefix" : "suffix";

  return {
    positionKey,
    symbol
  };
}

export default function PolarisFormikDiscountSection(
  props: DiscountSectionProps
) {
  const { form, name, moneyFormat, multicurrencyWarningCard } = props;
  const offerType = getTypeSlug(form.values.type);
  const typePath = `${name}.type`;
  const value = objectPath.get(form.values, typePath);
  const options = getDiscountOptionsForType(offerType);
  const { positionKey, symbol } = getSymbolPrefixOrSuffix(value, moneyFormat);

  const typeProps = {
    [positionKey]: <TextStyle variation="subdued">{symbol}</TextStyle>,
    max: value === PRICE_TYPES.PERCENT ? 100 : null
  };

  return (
    <Fragment>
      <div className="DiscountFields__Row">
        <Stack vertical spacing="extraTight">
          <FormLayout>
            <FormLayout.Group>
              <PolarisFormikNumberField
                {...typeProps}
                label="Choose discount"
                min={0}
                name={`${name}.amount`}
                allowFloat
                connectedLeft={
                  <Select
                    value={value}
                    onChange={value => {
                      form.setFieldValue(typePath, value);
                    }}
                    options={options}
                  />
                }
              />
            </FormLayout.Group>
          </FormLayout>
        </Stack>
      </div>
      {multicurrencyWarningCard}
    </Fragment>
  );
}
