// @flow
import React, { type Node } from "react";

type Props = {
  children: Node
};

export default function ChartWrapper(props: Props): Node {
  return <div className="ReportingChart__Wrapper">{props.children}</div>;
}
