import React, { useContext } from "react";
import { Banner, Layout } from "@shopify/polaris";
import { NavigationContext } from "contexts/Navigation";
import { useI18n } from "@shopify/react-i18n";
import useDiscountsAppEmbedNeedsEnabling from "hooks/use-discounts-app-embed-needs-enabling";

import AnimatedBanner from "./AnimatedBanner";
export default function AppEmbedBanner() {
  const navigation = useContext(NavigationContext);
  const [i18n] = useI18n();
  const { needsEnabling: discountsAppEmbedNeedsEnabling } =
    useDiscountsAppEmbedNeedsEnabling();
  const path = `/themes/current/editor?context=apps&activateAppId=${process.env.REACT_APP_SHOPIFY_DISCOUNTS_APP_EMBED_ID}/app-embed`;

  const bannerExpanded = discountsAppEmbedNeedsEnabling === true;

  return (
    <Layout.Section>
      <AnimatedBanner expanded={bannerExpanded}>
        <Banner
          status="info"
          title={i18n.translate("appEmbedBanner.title")}
          action={{
            content: i18n.translate("appEmbedBanner.callToActionContent"),
            onAction: () => navigation.redirectAdmin(path)
          }}
        >
          <p>{i18n.translate("appEmbedBanner.body")}</p>
        </Banner>
      </AnimatedBanner>
    </Layout.Section>
  );
}
