import React from "react";
import { Banner } from "@shopify/polaris";
import bannerAction from "./banner-action";
import copy from "content/copy.json";
import { Shop } from "types/shop";

interface Props {
  freePlanUsage: Shop["free_plan_usage"];
}

const { usageText, usageTitleEnd, usageTitleStart } = copy.freePlanBanners;

export default function Usage({ freePlanUsage }: Props) {
  return (
    <Banner
      status="info"
      title={`${usageTitleStart} ${freePlanUsage} ${usageTitleEnd}`}
      action={bannerAction()}
    >
      <p>{usageText}</p>
    </Banner>
  );
}
