import React from "react";
import { parseGid } from "@shopify/admin-graphql-api-utilities";
import { Banner } from "@shopify/polaris";

import objectPath from "lib/objectPath";
import unique from "lib/unique";

export function addInventoryItemsForVariants(form, fieldName, items) {
  const productVariants = items.selection.map(product => ({
    product_id: parseInt(parseGid(product.id)),
    handle: product.handle,
    title: product.title,
    variants: product.variants.map(variant => ({
      id: parseInt(parseGid(variant.id)),
      title: variant.title
    }))
  }));

  form.setFieldValue(fieldName, productVariants);
  form.setFieldTouched(fieldName, true);
}

// form - formik context
// fieldnames - formik style name
// items - [{id, title, handle}, {id, title, handle}, ...]
export function addInventoryItems(
  form,
  idsFieldName,
  titlesFieldName,
  handlesFieldName,
  items
) {
  const ids = getIds(items);
  const titles = getTitles(items);
  const handles = getHandles(items);

  form.setFieldValue(idsFieldName, ids);
  form.setFieldTouched(idsFieldName, true);
  form.setFieldValue(titlesFieldName, titles);
  form.setFieldTouched(titlesFieldName, true);
  form.setFieldValue(handlesFieldName, handles);
  form.setFieldTouched(handlesFieldName, true);
}

function getTitles(items) {
  return items.selection.map(item => item.title);
}

function getHandles(items) {
  return items.selection.map(item => item.handle);
}

function getIds(items) {
  return items.selection.map(item => parseInt(parseGid(item.id)));
}

export function addAllCollections(
  form,
  idsFieldName,
  titlesFieldName,
  handlesFieldName,
  items
) {
  const ids = unique([
    ...objectPath.get(form.values, idsFieldName),
    ...items.map(item => item.id)
  ]);
  const titles = unique([
    ...objectPath.get(form.values, titlesFieldName),
    ...items.map(item => item.title)
  ]);
  const handles = unique([
    ...(objectPath.get(form.values, handlesFieldName) || []),
    ...items.map(item => item.handle)
  ]);
  form.setFieldValue(idsFieldName, ids);
  form.setFieldTouched(idsFieldName, true);
  form.setFieldValue(titlesFieldName, titles);
  form.setFieldTouched(titlesFieldName, true);
  form.setFieldValue(handlesFieldName, handles);
  form.setFieldTouched(handlesFieldName, true);
}

// form - formik context
// fieldnames - formik style name
// item - { id, title }
export function removeInventoryItem(
  form,
  idsFieldName,
  titlesFieldName,
  handlesFieldName,
  item
) {
  const ids = objectPath.get(form.values, idsFieldName);
  const titles = objectPath.get(form.values, titlesFieldName);
  const handles = objectPath.get(form.values, handlesFieldName) || [];
  // Filter out the id and title
  const idsWithoutItem = ids.filter(x => x !== item.id);
  const titlesWithoutItem = titles.filter(x => x !== item.title);
  const handlesWithoutItem = handles.filter(x => x !== item.handle);
  // If lengths are different, then the field changed
  if (ids.length !== idsWithoutItem.length) {
    form.setFieldValue(idsFieldName, idsWithoutItem);
    form.setFieldTouched(idsFieldName, true);
  }
  if (titles.length !== titlesWithoutItem.length) {
    form.setFieldValue(titlesFieldName, titlesWithoutItem);
    form.setFieldTouched(titlesFieldName, true);
  }
  if (handles.length !== handlesWithoutItem.length) {
    form.setFieldValue(handlesFieldName, handlesWithoutItem);
    form.setFieldTouched(handlesFieldName, true);
  }
}

export function removeInventoryItemForVariant(form, variantsFieldName, item) {
  const productVariants = objectPath.get(form.values, variantsFieldName) || [];

  const productVariantsWithoutItem = productVariants.filter(
    product => product.product_id !== item.product_id
  );

  if (productVariants.length !== productVariantsWithoutItem.length) {
    form.setFieldValue(variantsFieldName, productVariantsWithoutItem);
    form.setFieldTouched(variantsFieldName, true);
  }
}

export function makeErrorsList(errors) {
  const errorsArray = [];
  for (const errorName in errors) {
    errorsArray.push(errors[errorName]);
  }
  return errorsArray;
}

export function makeErrorTitle(numberOfErrors, elementName) {
  const areOrIs = numberOfErrors > 1 ? "are" : "is";
  const errorsOrError = numberOfErrors > 1 ? "errors" : "error";
  return `There ${areOrIs} ${numberOfErrors} ${errorsOrError} with this ${elementName}:`;
}

export function showFormErrorBanner(errors, elementName) {
  const numberOfErrors = Object.keys(errors).length;
  if (numberOfErrors === 0) {
    return null;
  }

  return (
    <Banner
      title={makeErrorTitle(numberOfErrors, elementName)}
      status="critical"
    >
      <ul>
        <li>{makeErrorsList(errors)}</li>
      </ul>
    </Banner>
  );
}

// converting dot notation of nested form values (notitifcation.color) to valid
// ID string (notification_color)
export function uniqueIdForInput(inputName) {
  return inputName.replace(/\./g, "_");
}

// Pluck the Polaris-specific input props from a props pbject
// for letting polaris props "pass through" our formik wrapper.
// Only includes the props we're using at the moment
// https://polaris.shopify.com/components/forms/text-field
export function gatherFieldProps(props) {
  return [
    "id",
    "label",
    "type",
    "prefix",
    "suffix",
    "connectedLeft",
    "connectedRight",
    "placeholder",
    "labelHidden",
    "helpText",
    "min",
    "max"
  ].reduce((fieldProps, key) => {
    if (Object.prototype.hasOwnProperty.call(props, key)) {
      fieldProps[key] = props[key];
    }

    return fieldProps;
  }, {});
}
