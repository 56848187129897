import React from "react";
import { getIn } from "formik";
import { InlineError } from "@shopify/polaris";
import InventoryTags from "components/InventoryTags";
import AddAllCollectionsButton from "components/AddAllCollectionsButton";
import PolarisFormikNumberField from "components/PolarisFormikNumberField";
import PolarisFormikInventoryPickerButton from "./PolarisFormikInventoryPickerButton";
import CollectionPickerButton from "./CollectionPickerButton";
import useInventoryPickerSelections from "./useInventoryPickerSelections";
import ThreeButtonsTooltip from "./ThreeButtonsTooltip";

import "./PolarisFormikInventoryPicker.scss";

// empty inventory message takes precendence over quantity error
// showing both isn't especially helpful
export function getErrorForInventoryRow(form, name, qtyName) {
  const inventoryError = getIn(form.errors, name);
  if (inventoryError) return inventoryError;

  const quantityError =
    qtyName && getIn(form.touched, qtyName) && getIn(form.errors, qtyName);

  if (quantityError) return quantityError;

  return null;
}

// qtyName:
//  string for the name of the quantity field - if falsey then no quantity input
// name:
//  string of name field in values (ex. details.buy_from)
// extras:
//  other components to show alongside the product picker buttons
// form:
//  formik instance
export default function PolarisFormikInventoryPicker({
  qtyName,
  name,
  label,
  extras,
  form
}) {
  const {
    collectionSelectionIds,
    handleAllCollectionFetch,
    productSelectionIds,
    removeSelectionIds,
    setCollectionSelectionIds,
    setProductSelectionIds,
    setVariantSelectionIds,
    variantSelectionIds
  } = useInventoryPickerSelections();

  const { values } = form;

  const errorMessage = getErrorForInventoryRow(form, name, qtyName);

  const qtyField = qtyName && (
    <PolarisFormikNumberField
      name={qtyName}
      prefix="Quantity"
      min={0}
      suppressErrorMessage
    />
  );

  return (
    <div className="InventoryPicker-Row">
      <div className="InventoryPicker-Fields">
        {label && <div className="Polaris-Labelled__LabelWrapper">{label}</div>}
        {(qtyField || extras) && (
          <div className="FlexRow Quantity-Extras">
            {qtyField}
            {extras}
          </div>
        )}
        <div className="ThreeButtons FlexRow">
          <CollectionPickerButton
            form={form}
            name={name}
            type={values.type}
            selectionIds={collectionSelectionIds}
            setSelectionIds={setCollectionSelectionIds}
          />
          <PolarisFormikInventoryPickerButton
            idsFieldName={`${name}.product_ids`}
            titlesFieldName={`${name}.product_titles`}
            handlesFieldName={`${name}.product_handles`}
            target="Product"
            form={form}
            title="Choose products"
            selectionIds={productSelectionIds}
            setSelectionIds={setProductSelectionIds}
          />
          <PolarisFormikInventoryPickerButton
            variantsFieldName={`${name}.product_variants`}
            target="Product"
            form={form}
            title="Choose variants"
            showVariants={true}
            selectionIds={variantSelectionIds}
            setSelectionIds={setVariantSelectionIds}
          />
          <ThreeButtonsTooltip />
        </div>
      </div>
      {errorMessage && (
        <div style={{ margin: "1rem 0" }}>
          <InlineError message={errorMessage} />
        </div>
      )}
      <InventoryTags
        form={form}
        name={name}
        removeSelectionIds={removeSelectionIds}
      />
      <AddAllCollectionsButton
        form={form}
        idsFieldName={`${name}.collection_ids`}
        titlesFieldName={`${name}.collection_titles`}
        handlesFieldName={`${name}.collection_handles`}
        type={values.type}
        onAllCollectionFetch={handleAllCollectionFetch}
      />
    </div>
  );
}
