// @flow
import React from "react";

export default function MarketingText({
  appPlanName
}: {
  appPlanName: string
}) {
  let marketingText = `9 popular offer types — now including the power of the Post-Purchase
  Upsell at no additional cost.`;

  if (appPlanName === "fixedWithPPU") {
    marketingText = `Move to our usage-based Ultimate plan and get access to the Post-Purchase Upsell
    offer at no additional cost. Start with a lower base fee and pay for your orders as you go!`;
  }

  if (appPlanName === "volumetricWithPPU") {
    marketingText = `Post-Purchase Upsells are now included at no additional 
    charge in our Ultimate plan. This means your monthly fees are about to go down.`;
  }
  return <span>{marketingText}</span>;
}
