import React from "react";
import { Field } from "formik";
import { Checkbox } from "@shopify/polaris";
import { gatherFieldProps } from "../lib/formHelpers";

export default function PolarisFormikCheckbox(props) {
  const { name } = props;
  const fieldProps = gatherFieldProps(props);
  return (
    <Field name={name}>
      {({ form, field }) => (
        <Checkbox
          {...fieldProps}
          checked={field.value === true}
          onChange={value => {
            form.setFieldValue(name, value);
          }}
        />
      )}
    </Field>
  );
}
