const DEFAULT_SETTINGS = {
  allow_use_discount_code_cart_method: "before",
  allow_use_discount_code_cart: true,
  allow_use_discount_code_instead: false,
  enableMultiCurrency: false,
  exclude_order_discount_details: false,
  multiple_offers_free_gift: "one",
  multiple_offers: [],
  notifications: {
    position: "top",
    background_color: "#73170f",
    text_color: "#ffffff",
    opacity: 100
  },
  offers_method: "draft_order",
  tax_percent: 0,
  trim_order_discount_details: false,
  use_money_with_currency: false
};

const DEFAULT_CUSTOMIZATIONS = {
  css: {}
};

const ensureDefaults = function ensureDefaults(settings, customizations) {
  settings = typeof settings !== "undefined" ? settings : "";
  customizations = {...DEFAULT_CUSTOMIZATIONS, ...customizations};
  return {...DEFAULT_SETTINGS, ...settings, customizations};
};

module.exports = {
  ensureDefaults: ensureDefaults
};
