// @flow
import React, { Component } from "react";
import type { ChildrenArray, Element } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";

import history from "lib/history";

import HomeRoute from "routes/home/HomeRoute";
import WelcomeRoute from "routes/welcome/WelcomeRoute";
import OffersRoute from "routes/offers/OffersRoute";
import SettingsRoute from "routes/settings/SettingsRoute";
import AppearanceRoute from "routes/appearance/AppearanceRoute";
import AccountRoute from "routes/account/AccountRoute";
import FeatureRequestsRoute from "routes/featureRequests/FeatureRequestsRoute";
import ThirtyDaysFreePromo from "routes/ThirtyDaysFreePromo";

type ScrollToTopProps = {
  children: ChildrenArray<Element<typeof Route> | Element<typeof Switch>>,
  location: Object
};

class ScrollToTop extends Component<ScrollToTopProps> {
  componentDidUpdate(prevProps: ScrollToTopProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

const ScrollToTopWithRouter = withRouter(ScrollToTop);

export default function Routes() {
  return (
    <Router history={history}>
      <ScrollToTopWithRouter>
        <Route exact path="/" component={HomeRoute} />
        <Route exact path="/welcome" component={WelcomeRoute} />
        <Route path="/settings" component={SettingsRoute} />
        <Route path="/appearance" component={AppearanceRoute} />
        <Route path="/account" component={AccountRoute} />
        <Route path="/feature-requests" component={FeatureRequestsRoute} />
        <Route path="/thirty-days-free" component={ThirtyDaysFreePromo} />
        <Switch>
          <Route path="/offers/new" component={OffersRoute} />
          <Route
            path="/offers/:offerId"
            render={routeProps => (
              <OffersRoute
                offerId={parseInt(routeProps.match.params.offerId, 10)}
              />
            )}
          />
        </Switch>
      </ScrollToTopWithRouter>
    </Router>
  );
}
