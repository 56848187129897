// @flow
import React, { Component, type ElementRef } from "react";
import { Page } from "@shopify/polaris";
import { Modal, TitleBar } from "@shopify/app-bridge-react";

import { shopActions } from "store/shopStore";
import { storeConnect } from "appStore";
import makeRequestProps from "store/makeRequestProps";

import { hasDiscountScopes } from "lib/scopesHelpers";

import { NavigationContext } from "contexts/Navigation";
import type { ShopRequest } from "appStore";
import type { OffersSettings } from "types/flow-types/settings";

import SettingsFormWrapped from "./SettingsFormWrapped";

type Props = {
  companionAppsInstalled?: boolean,
  putSettings: (OffersSettings, () => void) => void,
  shopRequest: ShopRequest
};

type State = {
  cancelAlertOpen: boolean
};

type FormRef = { current: ?ElementRef<"form"> } | null;

export class SettingsRoute extends Component<Props, State> {
  static contextType = NavigationContext;

  formRef: ?FormRef;
  constructor(props: Props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      cancelAlertOpen: false
    };
  }

  navigateToRoot = () => {
    this.context.goTo("/");
  };

  handleBarSaveClick = () => {
    // $FlowDisableNextLine
    this.formRef.current.props.form.submitForm();
  };

  handleSubmit = (values: OffersSettings) => {
    this.props.putSettings(values, this.navigateToRoot);
  };

  handleCancel = () => {
    // $FlowDisableNextLine
    const { form } = this.formRef.current.props;

    if (form.dirty) {
      return this.setState({ cancelAlertOpen: true });
    }

    this.navigateToRoot();
  };

  render() {
    const {
      shopRequest: {
        shop: {
          app_settings: settings,
          customizations,
          productBoardToken,
          shopify_scopes: shopifyScopes
        }
      }
    } = this.props;

    const shopHasDiscountScopes = hasDiscountScopes(shopifyScopes);

    return (
      <Page>
        <TitleBar
          title="Settings"
          primaryAction={{ content: "Save", onAction: this.handleBarSaveClick }}
          secondaryActions={[
            { content: "Cancel", onAction: this.handleCancel }
          ]}
        />
        <SettingsFormWrapped
          settings={settings}
          companionAppsInstalled={this.props.companionAppsInstalled}
          customizations={customizations}
          formRef={this.formRef}
          hasDiscountScopes={shopHasDiscountScopes}
          onCancel={this.handleCancel}
          onSubmit={this.handleSubmit}
          productBoardToken={productBoardToken}
        />
        <Modal
          title="You have unsaved changes"
          open={this.state.cancelAlertOpen}
          message="If you leave this page, all your unsaved changes will be lost. Are you sure you want to discard these changes and leave this page?"
          primaryAction={{
            content: "Leave page",
            destructive: true,
            onAction: () => {
              this.setState({ cancelAlertOpen: false });
              this.context.goTo("/");
            }
          }}
          secondaryActions={[
            {
              content: "Cancel",
              onAction: () => this.setState({ cancelAlertOpen: false })
            }
          ]}
        />
      </Page>
    );
  }
}

const mapStateToProps = state => {
  return {
    companionAppsInstalled: state.companionAppsInstalled,
    shopRequest: makeRequestProps(state, "shop")
  };
};

export default storeConnect(SettingsRoute, mapStateToProps, shopActions);
