module.exports = [
  "read_customers",
  "read_discounts",
  "write_discounts",
  "read_draft_orders",
  "write_draft_orders",
  "read_orders",
  "write_orders",
  "read_price_rules",
  "write_price_rules",
  "read_products",
  "read_script_tags",
  "write_script_tags",
  "read_themes",
  "write_themes"
];
