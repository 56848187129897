import React from "react";

const mailtoUrl = function (address) {
  return `mailto:${address}`;
};

const handleClick = function (e, to) {
  e.preventDefault();
  window.top.location = mailtoUrl(to);
};

export default function MailTo(props) {
  const { children, component, to } = props;

  if (component) {
    return React.cloneElement(component, {
      url: mailtoUrl(to),
      onClick: e => handleClick(e, to),
      ...props
    });
  }

  return (
    <a
      href={mailtoUrl(to)}
      className="Polaris-Link"
      onClick={e => handleClick(e, to)}
    >
      {children}
    </a>
  );
}
