// @flow
import axios from "./authedAxios";

import { API_ORIGIN } from "lib/constants";

import { reportError } from "lib/errorHelpers";

import type { Customizations } from "types/flow-types/customizations";

export type MaybeCustomizationsResponse = {
  data?: Customizations,
  error?: Error
};

export const CUSTOMIZATIONS_API_PATH = "/api/shop/customizations";
export const CUSTOMIZATIONS_API_BASE = `${API_ORIGIN}${CUSTOMIZATIONS_API_PATH}`;

export const customizationsUrl = (path: ?string): string =>
  [CUSTOMIZATIONS_API_BASE, path].filter(Boolean).join("/");

export async function putCustomizations(
  customizations: Object
): Promise<MaybeCustomizationsResponse> {
  try {
    const { data } = await axios.put(customizationsUrl(), customizations);
    return { data };
  } catch (e) {
    const error = new Error(e);
    reportError(error);
    return { error };
  }
}
