// @flow
import React from "react";
import { Layout } from "@shopify/polaris";

import AppEmbedBanner from "components/banners/AppEmbedBanner";
import OffersSection from "./OffersSection";
import FaqSection from "./FaqSection";
import TrialBannerSection from "components/banners/TrialBannerSection";
import CartWarningBannerContainer from "components/banners/CartWarningBannerContainer";
import AdCardSection from "components/AdCardSection";
import FooterSection from "components/FooterSection";
import ReportingChartAccordion from "components/reporting/ReportingChartAccordion";
import MulticurrencyWarningBannerContainer from "components/banners/MulticurrencyWarningBannerContainer";
import DashboardPlanStatusSection from "./DashboardPlanStatusSection";

import type { Shop } from "types/flow-types/shop";
import type { OffersRequest } from "appStore";
import { nonPostPurchaseOffers } from "../../lib/offerHelpers";

type Props = {
  shop: Shop,
  createOffer: () => void,
  fetchOffers: () => void,
  offersRequest: OffersRequest
};

export default function DashboardLayout({
  shop,
  createOffer,
  fetchOffers,
  offersRequest
}: Props) {
  const hasNonPostPurchaseOffers =
    nonPostPurchaseOffers(offersRequest.offers).length > 0;

  return (
    <Layout>
      <AppEmbedBanner />
      {shop.flags.releaseEnableFreePlan ? (
        <DashboardPlanStatusSection shop={shop} />
      ) : (
        <TrialBannerSection shop={shop} />
      )}
      <MulticurrencyWarningBannerContainer page="dashboard" />
      <CartWarningBannerContainer />
      {hasNonPostPurchaseOffers && <ReportingChartAccordion />}
      <Layout.Section>
        <OffersSection
          offersRequest={offersRequest}
          fetchOffers={fetchOffers}
          onCreateOffer={createOffer}
        />
      </Layout.Section>
      <FaqSection />
      <AdCardSection />
      <FooterSection />
    </Layout>
  );
}
