// @flow

import {
  getAggregateTotalsForOffer,
  calculateConversionPercentage
} from "lib/reporting-helpers";

import type { OffersReportsRequest } from "store/reportsStore";
import type { OfferReportItem } from "types/flow-types/reports";
import type { SpanKey } from "components/reporting/chart-types";

export type OfferReportProp = {
  error?: Error,
  loading?: boolean,
  data?: OfferReportItem
};

export default function makeOfferReportingProp(
  offerId: number,
  reportsRequest: OffersReportsRequest,
  spanKey: SpanKey,
  axisTicks: Date[]
): OfferReportProp {
  const { error, loading, offersReports } = reportsRequest;

  const offerReport: OfferReportProp = { error, loading };

  if (offersReports) {
    const reportData = getAggregateTotalsForOffer(
      offersReports[offerId],
      spanKey,
      axisTicks
    );

    const reportDataWithConversions = {
      ...reportData,
      conversionRate: calculateConversionPercentage(
        reportData.orderCount,
        reportData.cartCount
      )
    };
    offerReport.data = reportDataWithConversions;
  }

  return offerReport;
}
