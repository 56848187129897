import React from "react";
import { Badge, Card, Heading, Stack } from "@shopify/polaris";
import { useShop } from "contexts/Shop";
import copy from "content/copy.json";
import singularOrPluralText from "lib/singular-or-plural-text";
import UsageFeesDataTable from "./UsageFeesDataTable";

function UsageFeesInactiveCard() {
  const { activeUltimatePlan, onDevPlan, trialDaysRemaining } = useShop();
  const {
    usageFeesCardTitle,
    billingBeginsTitle,
    billingDataLastUpdated,
    inactiveTitle,
    unsubscribedTitle,
    billingDaySingularText,
    billingDayPluralText,
    billingLastUpdateNotAvailable
  } = copy.account.percentageVolumetric.usageFeesSection;

  const dayOrDays = singularOrPluralText(
    trialDaysRemaining,
    billingDaySingularText,
    billingDayPluralText
  );
  const usageCardTitleForShopWithoutActivePlan = (
    <Stack spacing="tight">
      <Heading>{usageFeesCardTitle}</Heading>
      <Badge>{onDevPlan ? inactiveTitle : unsubscribedTitle}</Badge>
    </Stack>
  );
  const usageCardTitleForShopOnTrialWithActivePlan = `${usageFeesCardTitle} ${billingBeginsTitle} ${trialDaysRemaining} ${dayOrDays}`;
  const usageCardTitle = !activeUltimatePlan
    ? usageCardTitleForShopWithoutActivePlan
    : usageCardTitleForShopOnTrialWithActivePlan;
  const usageFeesTableFooterText = `${billingDataLastUpdated} ${billingLastUpdateNotAvailable}`;

  return (
    <Card title={usageCardTitle}>
      <UsageFeesDataTable
        chargesRowContent="-"
        discountOrdersRowContent="-"
        footerContent={usageFeesTableFooterText}
      />
    </Card>
  );
}

export default UsageFeesInactiveCard;
