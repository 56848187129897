import React from "react";
import { Layout, Link } from "@shopify/polaris";

export default function AnnotatedSection(props) {
  const {
    title,
    description,
    linkUrl,
    linkText = "Learn more",
    external = true,
    layoutDescription
  } = props;

  const defaultLayoutDescription = layoutDescription || (
    <span>
      {description ? `${description} ` : ""}
      <Link url={linkUrl} external={external}>
        {linkText}
      </Link>
      .
    </span>
  );

  return (
    <Layout.AnnotatedSection
      title={title}
      description={defaultLayoutDescription}
    >
      {props.children}
    </Layout.AnnotatedSection>
  );
}
