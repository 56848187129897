// @flow
import React, { type Node } from "react";
import { Card } from "@shopify/polaris";

export default function ChartFooterEmpty(): Node {
  return (
    <Card.Section subdued>
      <div style={{ height: "36px" }} />
    </Card.Section>
  );
}
