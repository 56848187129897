import * as Types from "../../types/generated/graphql";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type MainThemeHasEmbeddedInstallQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type MainThemeHasEmbeddedInstallQuery = {
  __typename?: "RootQueryType";
  mainTheme: {
    __typename?: "MainTheme";
    id: number;
    hasEmbeddedInstall: boolean;
  };
};

export const MainThemeHasEmbeddedInstallDocument = gql`
  query MainThemeHasEmbeddedInstall {
    mainTheme {
      id
      hasEmbeddedInstall
    }
  }
`;

/**
 * __useMainThemeHasEmbeddedInstallQuery__
 *
 * To run a query within a React component, call `useMainThemeHasEmbeddedInstallQuery` and pass it any options that fit your needs.
 * When your component renders, `useMainThemeHasEmbeddedInstallQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMainThemeHasEmbeddedInstallQuery({
 *   variables: {
 *   },
 * });
 */
export function useMainThemeHasEmbeddedInstallQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MainThemeHasEmbeddedInstallQuery,
    MainThemeHasEmbeddedInstallQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MainThemeHasEmbeddedInstallQuery,
    MainThemeHasEmbeddedInstallQueryVariables
  >(MainThemeHasEmbeddedInstallDocument, options);
}
export function useMainThemeHasEmbeddedInstallLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MainThemeHasEmbeddedInstallQuery,
    MainThemeHasEmbeddedInstallQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MainThemeHasEmbeddedInstallQuery,
    MainThemeHasEmbeddedInstallQueryVariables
  >(MainThemeHasEmbeddedInstallDocument, options);
}
export type MainThemeHasEmbeddedInstallQueryHookResult = ReturnType<
  typeof useMainThemeHasEmbeddedInstallQuery
>;
export type MainThemeHasEmbeddedInstallLazyQueryHookResult = ReturnType<
  typeof useMainThemeHasEmbeddedInstallLazyQuery
>;
export type MainThemeHasEmbeddedInstallQueryResult = Apollo.QueryResult<
  MainThemeHasEmbeddedInstallQuery,
  MainThemeHasEmbeddedInstallQueryVariables
>;
