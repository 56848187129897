// @flow
import axios from "./authedAxios";

import { API_ORIGIN } from "lib/constants";

import { reportError } from "lib/errorHelpers";

import type { OffersSettings } from "types/flow-types/settings";

export type MaybeOffersSettingsResponse = {
  data?: OffersSettings,
  error?: Error
};

export const SETTINGS_API_PATH = "/api/settings";
export const SETTINGS_API_BASE = `${API_ORIGIN}${SETTINGS_API_PATH}`;

export const settingsUrl = (path: ?string): string =>
  [SETTINGS_API_BASE, path].filter(Boolean).join("/");

// There is no fetchSettings because we fetch the settings via the shop API
export async function putSettings(
  settings: Object
): Promise<MaybeOffersSettingsResponse> {
  try {
    const { data } = await axios.put(settingsUrl(), settings);
    return { data };
  } catch (e) {
    const error = new Error(e);
    reportError(error);
    return { error };
  }
}
