import React from "react";
import { Layout } from "@shopify/polaris";

import AnimatedBanner from "components/banners/AnimatedBanner";
import DevPlanBanner from "components/banners/DevPlanBanner";
import PlanStatusContainer from "components/banners/free-plan/PlanStatusContainer";

import onShopifyDevPlan from "lib/shop/on-shopify-dev-plan";
import { ShopProvider } from "contexts/Shop";
import { Shop, ShopEventPayload } from "types/shop";
import { storeConnect } from "appStore";
import { shopActions } from "store/shopStore";

interface Props {
  shop: Shop;
  setEvent: (payload: ShopEventPayload) => void;
}

function DashboardPlanStatusSection({ setEvent, shop }: Props) {
  if (onShopifyDevPlan(shop.settings.plan_name)) {
    return (
      <DevPlanBanner
        dismissed={shop.events.dev_shop_trial_banner_dismissed}
        setEvent={setEvent}
      />
    );
  }

  return (
    <ShopProvider value={shop}>
      <BannerWithLayout setEvent={setEvent} />
    </ShopProvider>
  );
}

interface BannerWithLayoutProps {
  setEvent: (payload: ShopEventPayload) => void;
}
function BannerWithLayout({ setEvent }: BannerWithLayoutProps) {
  const banner = PlanStatusContainer({ setEvent });
  return (
    banner && (
      <Layout.Section>
        <AnimatedBanner expanded={true}>{banner}</AnimatedBanner>
      </Layout.Section>
    )
  );
}

export default storeConnect(DashboardPlanStatusSection, "", shopActions);
