import React from "react";
import { Field } from "formik";

import PolarisFormikSelect from "components/PolarisFormikSelect";

const BADGE_POSITIONS = [
  {
    label: "Top, Left",
    value: "topLeft"
  },
  {
    label: "Top, Right",
    value: "topRight"
  },
  {
    label: "Bottom, Left",
    value: "bottomLeft"
  },
  {
    label: "Bottom, Right",
    value: "bottomRight"
  }
];

const BadgePositionInput = () => (
  <Field
    component={PolarisFormikSelect}
    key="badge-position"
    label="Badge position"
    name="customizations.badge.position"
    options={BADGE_POSITIONS}
    placeholder="Select"
  />
);

export default BadgePositionInput;
