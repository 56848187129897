import React, { PureComponent } from "react";

// Polaris readonly input with select-on-focus behaviour.
// can't be a polaris component (and can't be an SFC) because we need access to refs.
class CopyHelperTextField extends PureComponent {
  constructor(props) {
    super(props);
    this.input = React.createRef();
  }

  selectInputValue = () => {
    // setTimeout addresses issue with Safari not selecting the text
    setTimeout(() => {
      this.input.current.select();
    });
  };

  render() {
    const { label, value, id, helpText } = this.props;

    return (
      <div>
        <div className="Polaris-Labelled__LabelWrapper">
          <div className="Polaris-Label">
            <label
              id={`${id}Label`}
              htmlFor={id}
              className="Polaris-Label__Text"
            >
              {label}
            </label>
          </div>
        </div>
        <div className="Polaris-TextField">
          <input
            readOnly
            ref={this.input}
            onFocus={this.selectInputValue}
            className="Polaris-TextField__Input"
            aria-label={label}
            aria-labelledby={`${id}Label`}
            aria-invalid={false}
            value={value}
          />
          <div className="Polaris-TextField__Backdrop" />
        </div>
        {helpText && (
          <div className="Polaris-Labelled__HelpText">{helpText}</div>
        )}
      </div>
    );
  }
}

export default CopyHelperTextField;
