// @flow
import React from "react";
import {
  DataTable,
  Card,
  SkeletonBodyText,
  SkeletonDisplayText
} from "@shopify/polaris";
import "./BillingTable.scss";

function BillingTable() {
  return (
    <div className="Billing-Table">
      <div className="Current-Billing-Subtitle">
        <SkeletonDisplayText size="small" />
      </div>
      <Card>
        <DataTable
          columnContentTypes={["text", "numeric", "numeric"]}
          headings={["", "Orders", "Charges"]}
          rows={[["Loading data ...", "", ""]]}
          totals={["", "", ""]}
        />
      </Card>
      <SkeletonBodyText />
    </div>
  );
}

export default BillingTable;
