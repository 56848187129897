const variantProductTitleWithCount = (title, variantCount) => {
  if (title === undefined || variantCount === undefined) {
    throw new Error("Needs both title and variant count");
  }

  const variantString = variantCount > 1 ? "variants" : "variant";

  return `${title} (${variantCount} ${variantString})`;
};

export default variantProductTitleWithCount;
