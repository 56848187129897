// @flow

import React, { Component } from "react";
import { Button, Caption, OptionList, Popover } from "@shopify/polaris";

import { getOfferType } from "lib/offerHelpers";

import type { OfferOptionList } from "../chart-types";

type Props = {
  offerOptions: OfferOptionList,
  selectedOptions: number[],
  handleSelectionChange: (offerIds: number[]) => void,
  hasPostPurchaseOffers: boolean
};

type State = {
  popoverOpen: boolean
};

function makeButtonLabel(options: number[]): string {
  switch (options.length) {
    case 0:
      return "All Offers";
    case 1:
      return "1 offer";
    default:
      return `${options.length} offers`;
  }
}

function LimitationsCaption({ hasPostPurchaseOffers }) {
  if (hasPostPurchaseOffers) {
    return (
      <div style={{ margin: "0 1em 1em 1em" }}>
        <Caption>
          {`* ${getOfferType("postPurchaseOffer").title} offers`}
          <br />
          are currently unavailable
          <br />
          in graphed analytics
        </Caption>
      </div>
    );
  }
  return null;
}

export default class ChartOffersPicker extends Component<Props, State> {
  state = {
    popoverOpen: false
  };

  togglePopover = () => {
    this.setState(({ popoverOpen }) => {
      return { popoverOpen: !popoverOpen };
    });
  };

  render() {
    const { handleSelectionChange, offerOptions, selectedOptions } = this.props;
    const label = makeButtonLabel(selectedOptions);

    const activator = (
      <Button onClick={this.togglePopover} disclosure>
        <span style={{ display: "inline-block", minWidth: "6rem" }}>
          {label}
        </span>
      </Button>
    );

    return (
      <Popover
        active={this.state.popoverOpen}
        activator={activator}
        onClose={this.togglePopover}
      >
        <OptionList
          allowMultiple
          title="Choose offers"
          onChange={handleSelectionChange}
          options={offerOptions}
          selected={selectedOptions}
        />
        <LimitationsCaption
          hasPostPurchaseOffers={this.props.hasPostPurchaseOffers}
        />
      </Popover>
    );
  }
}
