import {
  Button,
  ButtonGroup,
  Card,
  Stack,
  Subheading,
  TextContainer
} from "@shopify/polaris";
import React, { useContext, useState } from "react";
import copy from "content/copy.json";
import { createRecurringCharge } from "api/recurring-charges";
import { NavigationContext } from "contexts/Navigation";
import { Toast } from "@shopify/app-bridge-react";

type RecurringChargesResponse = {
  data: { redirectUrl: string };
  error: Error;
};

const {
  percentagePlanCardTerm1,
  percentagePlanCardTerm2,
  percentagePlanCardTerm3,
  percentagePlanCardAction,
  percentagePlanCardTitle
} = copy.account.percentageVolumetric;

function PlanCard() {
  const navigation = useContext(NavigationContext);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  async function activateRecurringCharge() {
    setLoading(true);
    const response =
      (await createRecurringCharge()) as RecurringChargesResponse;
    if (response.error) {
      setError("We're sorry, an error has occurred. Please try again.");
      setLoading(false);
    } else {
      navigation.redirect(response.data.redirectUrl);
    }
  }
  return (
    <Card title={percentagePlanCardTitle}>
      <Card.Section>
        <Stack spacing="loose" vertical>
          <TextContainer>
            <Subheading>{percentagePlanCardTerm1}</Subheading>
            <Subheading>{percentagePlanCardTerm2}</Subheading>
            <p>{percentagePlanCardTerm3}</p>
          </TextContainer>
          <Stack distribution="trailing">
            <ButtonGroup>
              <Button
                onClick={activateRecurringCharge}
                loading={loading}
                primary
              >
                {percentagePlanCardAction}
              </Button>
            </ButtonGroup>
          </Stack>
        </Stack>
        {error && (
          <Toast content={error} error onDismiss={() => setError(null)} />
        )}
      </Card.Section>
    </Card>
  );
}
export default PlanCard;
