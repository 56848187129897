// @flow
import React from "react";

import AuthorizeScopesButton from "./AuthorizeScopesButton";
import ActivateRecurringChargeLink from "./ActivateRecurringChargeLink";

type newScopesContentProps = {
  activateRecurringCharge: () => Promise<void>,
  scopes: any,
  updateScopes: boolean,
  setError: (errorMessage: string) => void
};

export default function newScopesContent({
  activateRecurringCharge,
  scopes,
  setError,
  updateScopes
}: newScopesContentProps) {
  return (
    <div className="Plan-Activation-Section">
      <div className="Activation-Instructions">
        To activate this plan you need to do the following:
      </div>
      <div className="Scopes-Activation-Links">
        <div className={updateScopes ? "" : "Strike-Out"}>
          <AuthorizeScopesButton scopes={scopes} />
        </div>
      </div>
      <ActivateRecurringChargeLink
        activateRecurringCharge={activateRecurringCharge}
        updateScopes={updateScopes}
        setError={setError}
      />
    </div>
  );
}
