import BogoSection from "./BogoSection";
import BulkSection from "./BulkSection";
import BundleSection from "./BundleSection";
import CreditSection from "./CreditSection";
import DiscountSection from "./DiscountSection";
import GiftSection from "./GiftSection";
import GoalSection from "./GoalSection";
import UpsellSection from "./UpsellSection";
import VolumeSection from "./VolumeSection";
import PostPurchaseOfferSection from "./PostPurchaseOfferSection";

export default {
  bogo: BogoSection,
  bulk: BulkSection,
  bundle: BundleSection,
  credit: CreditSection,
  discount: DiscountSection,
  gift: GiftSection,
  goal: GoalSection,
  upsell: UpsellSection,
  volume: VolumeSection,
  postPurchaseOffer: PostPurchaseOfferSection
};
