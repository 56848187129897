import React from "react";
import {
  Button,
  Card,
  FormLayout,
  InlineError,
  Select,
  TextStyle,
  ChoiceList
} from "@shopify/polaris";
import { CancelSmallMinor } from "@shopify/polaris-icons";
import { FieldArray } from "formik";

import { get } from "lib/objectPath";
import { DEFAULT_OFFER_FIELD_GROUPS } from "types/constants/offers";
import { uniqueIdForInput } from "lib/formHelpers";
import { isMulticurrencyEnabled } from "lib/multicurrency";

import { variantsSelected } from "lib/offerHelpers";
import offerPlaceholders from "content/offerPlaceholders";
import PolarisFormikNumberField from "components/PolarisFormikNumberField";
import PolarisFormikInventoryPicker from "components/PolarisFormikInventoryPicker";
import OfferMessageSection from "components/OfferMessageSection";
import OfferSummarySection from "components/OfferSummarySection";
import OfferConfigurationSection from "components/OfferConfigurationSection";
import MulticurrencyWarningInCard from "components/banners/MulticurrencyWarningInCard";
import { HelpDocsLink } from "components/links";

import "./VolumeSection.css";

// given a Formik form and the name of a tier, gather all errors associated with
// the three inputs inside and return them in an array
function gatherErrorsForPriceTier(form, name) {
  return [
    `${name}.quantity`,
    `${name}.price.amount`,
    `${name}.price.type`
  ].reduce((messageArray, field) => {
    const touched = get(form.touched, field);
    const error = get(form.errors, field);
    if (touched && error) {
      messageArray.push({
        error,
        fieldId: uniqueIdForInput(field)
      });
    }
    return messageArray;
  }, []);
}

function VolumePriceTierRow(props) {
  const { form, name, currency = "", tier, remove } = props;

  const discountOptions = [
    { label: `New price in ${currency}`, value: "flat" },
    { label: `Discount amount ${currency}`, value: "subtract" },
    { label: "Discount percentage", value: "percent" }
  ];

  const errors = gatherErrorsForPriceTier(form, name);

  return (
    <div className="VolumeTier-Row">
      <div className="VolumeTier-Column VolumeTier-Column__Quantity">
        <PolarisFormikNumberField
          suppressErrorMessage
          name={`${name}.quantity`}
        />
      </div>
      <div className="VolumeTier-Column VolumeTier-Column__Discount">
        <PolarisFormikNumberField
          name={`${name}.price.amount`}
          suppressErrorMessage
          allowFloat
          connectedLeft={
            <Select
              value={tier.price.type}
              onChange={value => {
                form.setFieldValue(`${name}.price.type`, value);
              }}
              options={discountOptions}
            />
          }
        />
      </div>
      <div className="VolumeTier-Column VolumeTier-Column__Action">
        <Button icon={CancelSmallMinor} onClick={remove} />
      </div>
      {errors.length > 0 && (
        <div className="VolumeTier-Errors">
          {errors.map(({ error, fieldId }) => (
            <InlineError key={fieldId} message={error} fieldId={fieldId} />
          ))}
        </div>
      )}
    </div>
  );
}

function renderVolumePriceTiers(form, currency, { remove, push }) {
  const { values } = form;
  const hasTiers = values.details.tiers.length > 0;

  return (
    <div className="VolumeTiers">
      {hasTiers && (
        <div className="VolumeTier-Row VolumeTier-Row__Header">
          <div className="VolumeTier-Column VolumeTier-Column__Quantity">
            Quantity
          </div>
          <div className="VolumeTier-Discount VolumeTier-Column__Discount">
            Discount
          </div>
        </div>
      )}
      {values.details.tiers.map((tier, index) => (
        <VolumePriceTierRow
          currency={currency}
          index={index}
          form={form}
          tier={tier}
          name={`details.tiers[${index}]`}
          remove={remove}
          key={index}
        />
      ))}
      <Button
        plain
        onClick={() => {
          push({
            quantity: 1,
            price: { ...DEFAULT_OFFER_FIELD_GROUPS.price }
          });
        }}
      >
        {hasTiers ? "+ Add another tier" : "Add a tier"}
      </Button>
    </div>
  );
}

export default function VolumeSection(props) {
  const {
    form,
    currency,
    moneyFormat,
    offerTypeSlug,
    enabledPresentmentCurrencies,
    redirectToAppearance
  } = props;
  const { values } = form;

  const variantPresence = variantsSelected(form);

  const placeholderType = variantPresence ? "variant" : "nonVariant";

  const discountTypeOptions = [
    {
      label: "Per product variant",
      value: "variant",
      helpText:
        "Each variant (e.g. Size: Medium) in cart is checked for minimums."
    },
    {
      label: "Per product",
      value: "product",
      helpText: (
        <span>
          All variants in cart will be grouped by product and summed up before
          checking minimums.
        </span>
      )
    },
    {
      label: "Per entire product selection",
      value: "collection",
      helpText: (
        <span>
          All variants and products in cart matching your selection will be
          grouped and summed up before checking minimums.
        </span>
      )
    }
  ];

  const hasMulticurrency = isMulticurrencyEnabled(enabledPresentmentCurrencies);

  return [
    <OfferConfigurationSection
      key="offer-configuration"
      offerTypeSlug={offerTypeSlug}
    >
      <Card>
        <Card.Section title="Details">
          <FormLayout>
            <TextStyle variation="subdued">
              Create multiple price tiers for products or collections that offer
              increasing discounts with increasing quantities purchased. Display
              these tiers on product pages to incentivize customers. Note that
              volume discounts apply to all items above a minimum quantity as
              opposed to bulk discounts, which apply only to fixed quantities.{" "}
              <HelpDocsLink helpDocsType={offerTypeSlug} />
            </TextStyle>
            <PolarisFormikInventoryPicker form={form} name="details.buy_from" />
          </FormLayout>
        </Card.Section>
        <Card.Section title="Price Tiers">
          <FormLayout>
            <FieldArray validateOnChange={false} name="details.tiers">
              {arrayHelpers =>
                renderVolumePriceTiers(form, currency, arrayHelpers)
              }
            </FieldArray>
          </FormLayout>
          {hasMulticurrency && <MulticurrencyWarningInCard />}
        </Card.Section>
        <Card.Section title="Volume Discount Type">
          <FormLayout>
            <ChoiceList
              choices={discountTypeOptions}
              selected={values.details.type}
              onChange={([value]) => {
                form.setFieldValue("details.type", value);
              }}
            />
            <OfferSummarySection form={form} moneyFormat={moneyFormat} />
          </FormLayout>
        </Card.Section>
      </Card>
    </OfferConfigurationSection>,
    <OfferMessageSection
      key="offer-message"
      offerTypeSlug={offerTypeSlug}
      redirectToAppearance={redirectToAppearance}
      variantPresence={variantPresence}
      fields={[
        {
          label: "Product page message",
          name: "notifications.product_message",
          placeholder: offerPlaceholders.volume.product[placeholderType]
        },
        {
          label: "Progress message",
          name: "notifications.upsell_message",
          placeholder: offerPlaceholders.volume.cart[placeholderType]
        },
        {
          label: "Success message",
          name: "notifications.message_after",
          placeholder: offerPlaceholders.volume.success
        }
      ]}
    />
  ];
}
