import React from "react";
import { FormLayout, Stack } from "@shopify/polaris";
import { getIn } from "formik";

import BadgePreview from "components/badges/BadgePreview";
import BadgeTextInput from "components/badges/BadgeTextInput";
import BadgeUseDefaultInput from "components/badges/BadgeUseDefaultInput";

export default function BadgePickerDefaultStyles({
  defaultBadgeStyles,
  defaultBadgeType,
  defaultBadgePosition,
  defaultBadgeBorder,
  form,
  onUseDefaultChange
}) {
  const badgeText = getIn(form, "values.customizations.badge.badge_text");
  const useDefault = getIn(form, "values.customizations.badge.use_default");

  return (
    <Stack distribution="fillEvenly">
      <BadgePreview
        badgeStyles={defaultBadgeStyles}
        badgeText={badgeText}
        badgeType={defaultBadgeType}
        badgePosition={defaultBadgePosition}
        badgeBorder={defaultBadgeBorder}
      />
      <FormLayout>
        <BadgeTextInput />
        <BadgeUseDefaultInput
          onChange={onUseDefaultChange}
          selected={[useDefault]}
        />
      </FormLayout>
    </Stack>
  );
}
