import React from "react";
import { Card } from "@shopify/polaris";

import ChartTabs from "../ChartTabs/ChartTabs";
import ChartLineChartEmpty from "../ChartLineChart/ChartLineChartEmpty";
import ChartFooterEmpty from "../ChartFooter/ChartFooterEmpty";
import ChartWrapper from "../ChartWrapper";

export default function ReportingChartEmpty(props) {
  return (
    <Card>
      <ChartTabs
        currentTab={0}
        valueFormatters={props.valueFormatters}
        onTabChange={() => {}}
      />
      <Card.Section>
        <ChartWrapper>
          <ChartLineChartEmpty />
        </ChartWrapper>
      </Card.Section>
      <ChartFooterEmpty />
    </Card>
  );
}
