const SHOPIFY_DEV_PLANS = [
  "partner_test",
  "affiliate",
  "staff",
  "plus_partner_sandbox"
];

export default function onShopifyDevPlan(planName) {
  return SHOPIFY_DEV_PLANS.includes(planName);
}
