import React from "react";
import { Banner, Card } from "@shopify/polaris";
import copy from "content/copy.json";
import singularOrPluralText from "lib/singular-or-plural-text";
import EarlyActivation from "components/banners/EarlyActivation";

interface Props {
  activeUltimatePlan: boolean;
  trialDaysRemaining: number;
}
function TrialCard(props: Props) {
  const { activeUltimatePlan, trialDaysRemaining } = props;
  const {
    trialActiveTitle,
    trialActiveDescription,
    trialExpiredTitle,
    trialExpiredDescription
  } = copy.account.percentageVolumetric;

  const { trialDaySingularText, trialDayPluralText } = copy.common;
  const dayOrDays = singularOrPluralText(
    trialDaysRemaining,
    trialDaySingularText,
    trialDayPluralText
  );

  const TrialMessage = () => {
    return (
      <Card>
        <Banner
          title={`${trialDaysRemaining} ${dayOrDays} ${trialActiveTitle}`}
        >
          {trialActiveDescription}
        </Banner>
      </Card>
    );
  };

  const TrialExpiredMessage = () => {
    return (
      <Card>
        <Banner title={trialExpiredTitle} status="critical">
          {trialExpiredDescription}
        </Banner>
      </Card>
    );
  };

  if (!activeUltimatePlan && trialDaysRemaining > 0) {
    return <TrialMessage />;
  }
  if (!activeUltimatePlan && trialDaysRemaining <= 0) {
    return <TrialExpiredMessage />;
  }

  if (activeUltimatePlan && trialDaysRemaining > 0) {
    return (
      <Card>
        <EarlyActivation trialDaysRemaining={trialDaysRemaining} />
      </Card>
    );
  }

  return null;
}

export default TrialCard;
