// @flow
import React from "react";
import { Banner, Link, TextStyle } from "@shopify/polaris";
import { storeConnect } from "appStore";
import { isMulticurrencyEnabled } from "lib/multicurrency";

import type { ShopifyMultiCurrencyCode } from "types/flow-types/shopify";

type Props = {
  appMultiCurrencyEnabled: boolean,
  companionAppsInstalled?: Boolean,
  enabledPresentmentCurrencies: ShopifyMultiCurrencyCode[],
  offersMethod: string
};

export function MulticurrencyWarningInCard({
  appMultiCurrencyEnabled,
  companionAppsInstalled,
  enabledPresentmentCurrencies,
  offersMethod
}: Props) {
  if (!companionAppsInstalled) return null;
  if (!isMulticurrencyEnabled(enabledPresentmentCurrencies)) return null;

  // only show the warning if they have MC setup correctly, other error banners
  // cover these other conditions
  if (!appMultiCurrencyEnabled) return null;
  if (offersMethod !== "discount_code") return null;

  return (
    <div
      style={{
        marginTop: "20px"
      }}
    >
      <Banner>
        <p>
          <TextStyle variation="strong">
            You have enabled multi-currency in your store.
          </TextStyle>{" "}
          At this time, Ultimate Special Offers is only able to show
          &quot;Discount percentage&quot; offers outside your store’s default
          currency. &quot;New price&quot; and &quot;Discount amount&quot; offers
          will only be applied to customers in your store&apos;s default
          currency.{" "}
          <Link
            url="https://launch.orbitapps.com/hc/en-us/articles/4402806418579"
            external
          >
            Learn more.
          </Link>
        </p>
      </Banner>
    </div>
  );
}
export default storeConnect(
  MulticurrencyWarningInCard,
  ({ companionAppsInstalled }) => ({ companionAppsInstalled })
);

export type MulticurrencyWarningInCardType = typeof MulticurrencyWarningInCard;
