import React from "react";
import {
  Card,
  DisplayText,
  Button,
  TextContainer,
  TextStyle
} from "@shopify/polaris";

import "./OffersListEmpty.css";

export default function OffersListEmpty(props) {
  return (
    <div className="OffersListEmpty__Wrapper">
      <Card.Section>
        <div className="OffersListEmpty__Content">
          <div>
            <TextContainer spacing="tight">
              <DisplayText size="small">
                You haven’t created any special offers
              </DisplayText>
              <p style={{ marginBottom: "10px" }}>
                <TextStyle variation="subdued">
                  Once you&rsquo;ve received some orders with your offers
                  applied, you&rsquo;ll be able to track their success here.
                </TextStyle>
              </p>
              <Button primary onClick={props.onCreateOffer}>
                Create special offer
              </Button>
            </TextContainer>
          </div>
        </div>
      </Card.Section>
    </div>
  );
}
