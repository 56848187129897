// @flow
import React from "react";
import { Card, Stack } from "@shopify/polaris";

import ChartOffersPicker from "../ChartOffersPicker/ChartOffersPicker";
import ChartLabel from "../ChartLabel";

import type { OfferOptionList, LineData, LineMode } from "../chart-types";
import type { Offer } from "types/flow-types/offers";

type ChartLegendProps = {
  selectedOffers: Array<Offer>,
  lineData: LineData,
  lineMode: LineMode
};

const ChartLegend = ({
  selectedOffers,
  lineData,
  lineMode
}: ChartLegendProps) => {
  return (
    <div className="ChartLegend">
      <Stack>
        {lineMode === "separate" ? (
          selectedOffers.map(offer => {
            const { id, name, type } = offer;
            const color = lineData[id].color;
            return <ChartLabel key={id} label={name || type} color={color} />;
          })
        ) : (
          <ChartLabel
            label={lineData[lineMode].label}
            color={lineData[lineMode].color}
          />
        )}
      </Stack>
    </div>
  );
};

type ChartFooterProps = {
  offerOptions: OfferOptionList,
  selectedOffers: Array<Offer>,
  lineData: LineData,
  lineMode: LineMode,
  onOfferSelectionChange: (offerIds: number[]) => void,
  hasPostPurchaseOffers: boolean
};

const ChartFooter = ({
  offerOptions,
  selectedOffers,
  lineData,
  lineMode,
  onOfferSelectionChange,
  hasPostPurchaseOffers
}: ChartFooterProps) => {
  const selectedOfferIds = selectedOffers.map(({ id }) => id);

  return (
    <Card.Section subdued>
      <Stack alignment="center">
        <ChartOffersPicker
          offerOptions={offerOptions}
          maxSelection={3}
          selectedOptions={selectedOfferIds}
          handleSelectionChange={onOfferSelectionChange}
          hasPostPurchaseOffers={hasPostPurchaseOffers}
        />
        <ChartLegend
          selectedOffers={selectedOffers}
          lineData={lineData}
          lineMode={lineMode}
        />
      </Stack>
    </Card.Section>
  );
};

export default ChartFooter;
