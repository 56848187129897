// @flow
import React, { useContext, type Node } from "react";
import { Link } from "@shopify/polaris";
import { storeConnect } from "appStore";
import axios from "api/authedAxios";

import { trackEvent } from "lib/analytics";
import { reportError } from "lib/errorHelpers";
import { NavigationContext } from "contexts/Navigation";
import {
  OFFER_PREVIEW_ID_KEY,
  OFFER_PREVIEW_NAME_KEY
} from "types/constants/storefront";
const { DEFAULT_API_VERSION } = require("types/constants/shopify");
import { useMainThemeIdOnlyQuery } from "graphql/queries/main-theme-id-only.generated";

function ThemeAssetLinkComponent({ asset, domain, children }) {
  const { data } = useMainThemeIdOnlyQuery();

  const url =
    domain && data?.mainTheme.id
      ? `https://${domain}/admin/themes/${data.mainTheme.id}?key=${asset}`
      : "";

  return (
    <Link url={url} external>
      {children || asset}
    </Link>
  );
}

const getPropsForThemeAssetLink = state => {
  const { shop } = state;

  return {
    domain: shop.data.settings.domain
  };
};

export const ThemeAssetLink = storeConnect(
  ThemeAssetLinkComponent,
  getPropsForThemeAssetLink
);

export function WelcomeVideoLink({ children }: { children: Node }) {
  return (
    <Link onClick={trackWelcomeVideo} external>
      {children}
    </Link>
  );
}

function trackWelcomeVideo() {
  trackEvent("Welcome video played");
  window.open(
    "https://www.youtube.com/watch?v=L1fIcd9_EA8&feature=youtu.be",
    "_blank"
  );
}

export function SettingsPageLink({ children }: { children: Node }) {
  const navigation = useContext(NavigationContext);
  return <Link onClick={() => navigation.goTo("/settings")}>{children}</Link>;
}

export async function openPreviewTab(offer: Object, domain: Object) {
  const previewUrl = await getPreviewUrl(offer, domain);

  previewUrl && window.open(previewUrl, "_blank");
}

export async function getPreviewUrl(offer: Object, domain: Object) {
  const { details, name, uuid } = offer;
  const {
    collection_ids,
    product_ids,
    product_variants = []
  } = details.buy_from || details.bundles[0].buy_from;

  let discountItem;
  let handle;
  let itemResponse;

  if (collection_ids.length > 0) {
    discountItem = {
      type: "collection",
      id: collection_ids[0],
      isVariant: false
    };
    handle = null;
  }
  if (product_ids.length > 0) {
    discountItem = { type: "product", id: product_ids[0], isVariant: false };
    handle = null;
  }
  if (product_variants.length > 0) {
    discountItem = {
      type: "product",
      id: product_variants[0].product_id,
      isVariant: true
    };
    handle = product_variants[0].handle;
  }
  if (!discountItem) {
    return;
  }

  if (!handle) {
    try {
      itemResponse = (
        await axios.get(
          `/shopify-api-wrapper/admin/api/${DEFAULT_API_VERSION}/${discountItem.type}s/${discountItem.id}.json`
        )
      ).data;
    } catch (e) {
      reportError(e);
      // TODO: let the user know this failed
      return;
    }

    if (itemResponse.length > 0) {
      const product = itemResponse[0];
      handle = product.handle;
    }

    if (!handle) {
      return;
    }
  }

  let itemUrl = `https://${domain}/${discountItem.type}s/${handle}`;
  const params = [];

  if (discountItem.isVariant) {
    params.push(`variant=${product_variants[0].variants[0].id}`);
  }

  trackEvent("Offer Previewed", { offer });

  params.push(
    `${OFFER_PREVIEW_ID_KEY}=${uuid}`,
    `${OFFER_PREVIEW_NAME_KEY}=${encodeURI(name)}`
  );

  itemUrl += `?${params.join("&")}`;

  return itemUrl;
}

export function helpDocsUrl(helpDocsType: string) {
  switch (helpDocsType) {
    case "bogo":
      return "https://launch.orbitapps.com/hc/en-us/articles/4402812509843";
    case "bulk":
      return "https://launch.orbitapps.com/hc/en-us/articles/4402812945427";
    case "bundle":
      return "https://launch.orbitapps.com/hc/en-us/articles/4402812791699";
    case "discount":
      return "https://launch.orbitapps.com/hc/en-us/articles/4402806318995";
    case "goal":
      return "https://launch.orbitapps.com/hc/en-us/articles/4402812877331";
    case "gift":
      return "https://launch.orbitapps.com/hc/en-us/articles/4402806240659";
    case "postPurchaseOffer":
      return "https://launch.orbitapps.com/hc/en-us/articles/4402805635859";
    case "salesBadges":
      return "https://launch.orbitapps.com/hc/en-us/articles/4412116062867";
    case "upsell":
      return "https://launch.orbitapps.com/hc/en-us/articles/4402812869651";
    case "volume":
      return "https://launch.orbitapps.com/hc/en-us/articles/4402805868563";
    case "helpCenter":
    default:
      return "https://launch.orbitapps.com/hc/en-us/categories/4402710708883";
  }
}

export function HelpDocsLink({
  helpDocsType,
  title = "Learn More"
}: {
  helpDocsType: string,
  title?: string
}) {
  return (
    <Link url={helpDocsUrl(helpDocsType)} external>
      {title}
    </Link>
  );
}

export function AppearanceLink({
  redirectToAppearance,
  sectionName,
  type
}: {
  redirectToAppearance: (e: string) => void,
  sectionName: string,
  type: string
}) {
  if (type === "postPurchaseOffer") return null;

  return (
    <p>
      You can make visual changes to your {sectionName} in{" "}
      <Link
        onClick={e => {
          redirectToAppearance(e);
        }}
      >
        Appearance
      </Link>
      .
    </p>
  );
}
