// @flow
import React from "react";

import ErrorsBannerSection from "components/banners/ErrorsBannerSection";
import PPUSettingsBanner from "components/banners/PPUSettingsBanner";
import PPUInfoBanner from "components/banners/PPUInfoBanner";
import AppEmbedBanner from "components/banners/AppEmbedBanner";

import type { AppPlanNames } from "types/flow-types/shop";

export default function OfferFormMessageContainer({
  appPlan,
  dismissPPUInfoMessage,
  dismissPPUSettingsMessage,
  errors,
  errorsRef,
  isNewOffer,
  offerType,
  ppuInfoMessageDismissed,
  ppuSettingsMessageDismissed,
  touched
}: {
  appPlan: AppPlanNames,
  dismissPPUInfoMessage: () => void,
  dismissPPUSettingsMessage: () => void,
  errors: any,
  errorsRef: { current: null | HTMLDivElement },
  isNewOffer: boolean,
  offerType: string,
  ppuInfoMessageDismissed: boolean,
  ppuSettingsMessageDismissed: Boolean,
  touched: boolean
}) {
  if (Object.keys(errors).length !== 0) {
    return (
      <ErrorsBannerSection errors={errors} touched={touched} ref={errorsRef} />
    );
  }

  if (!isNewOffer && offerType !== "PostPurchaseOffer") {
    return <AppEmbedBanner />;
  }

  if (appPlan === "volumetricWithoutPPU" && !offerType) {
    return ppuInfoMessageDismissed ? null : (
      <PPUInfoBanner appPlan={appPlan} dismissMessage={dismissPPUInfoMessage} />
    );
  }

  if (offerType !== "PostPurchaseOffer" || !appPlan) {
    return null;
  }

  if (
    appPlan === "ultimate" ||
    appPlan === "volumetricWithoutPPU" ||
    appPlan === "ultimateFeb2023" ||
    appPlan === "freeMay2023"
  ) {
    return ppuSettingsMessageDismissed ? null : (
      <PPUSettingsBanner
        dismissPPUSettingsMessage={dismissPPUSettingsMessage}
      />
    );
  }

  if (appPlan !== "volumetricWithoutPPU") {
    return ppuInfoMessageDismissed ? null : (
      <PPUInfoBanner appPlan={appPlan} dismissMessage={dismissPPUInfoMessage} />
    );
  }
}
