import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { createApp } from "@shopify/app-bridge";
import { authenticatedFetch } from "@shopify/app-bridge/utilities";
import getAppAuth from "lib/getAppAuth";

const appAuth = getAppAuth();
const app = createApp(appAuth);
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    credentials: "same-origin",
    fetch: authenticatedFetch(app),
    uri: process.env.REACT_APP_ELIXIR_GRAPHQL_URI
  })
});

export default client;
