import { isMobile, isShopifyEmbedded } from "@shopify/app-bridge/utilities";

export default function redirectUnlessIframe() {
  const storeListingUrl =
    process.env.REACT_APP_STORE_LISTING_URL ||
    "https://apps.shopify.com/special-offers";

  if (!(isMobile() || isShopifyEmbedded())) {
    window.location.href = storeListingUrl;
    return true;
  }
}
