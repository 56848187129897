import React from "react";
import { Layout } from "@shopify/polaris";

import AdCard from "components/AdCard";

import ads from "content/ads";

export default function AdCardSection() {
  const { pixelpop, wholesaleClub } = ads;

  return (
    <Layout.Section>
      <Layout>
        <Layout.Section secondary>
          <AdCard ad={pixelpop} />
        </Layout.Section>
        <Layout.Section secondary>
          <AdCard ad={wholesaleClub} />
        </Layout.Section>
      </Layout>
    </Layout.Section>
  );
}
