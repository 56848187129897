import React from "react";
import AccountPage from "./AccountPage";
import { getTrialDaysLeft } from "lib/planHelpers";

function FixedAndVolumetricContainer({ shop, setEvent }) {
  const {
    app_plan: appPlan,
    created_date: createdDate,
    events,
    subscription_activated_date: subscriptionActivatedDate,
    trial_days: trialDays
  } = shop;

  const shopifyChargeReturnError = events.billing_error || false;

  const trialDaysRemaining = getTrialDaysLeft(createdDate, trialDays);
  const displayBillingTable =
    appPlan.billingType === "volumetric" &&
    subscriptionActivatedDate &&
    trialDaysRemaining <= 0;
  const ultimatePlanActivated =
    !!subscriptionActivatedDate &&
    ["ultimate", "volumetricWithoutPPU"].includes(appPlan.name);

  return (
    <AccountPage
      appPlanName={appPlan.name}
      displayBillingTable={!!displayBillingTable}
      setEvent={setEvent}
      shop={shop}
      shopifyChargeReturnError={shopifyChargeReturnError}
      ultimatePlanActivated={ultimatePlanActivated}
    />
  );
}

export default FixedAndVolumetricContainer;
