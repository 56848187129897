// @flow
import React, { type Node } from "react";
import { DisplayText } from "@shopify/polaris";

import {
  getAggregateTotalsForOffer,
  getAggregateTotalsForMultipleOffers,
  calculateConversionPercentage
} from "../../../lib/reporting-helpers";

import type { ValueFormatMap } from "../reportingValueFormatters";
import type {
  NormalizedOffersReport,
  NormalizedSummaryReport
} from "store/reportsStore";
import type { SpanKey } from "../chart-types";

export type TabContentFactoryItem = {
  key: string,
  label: string
};

export type TabDescriptor = {
  accessibilityLabel?: string,
  content: string | Node,
  id: string
};

const makeChartTabTab = (label: string, value?: string): Node => (
  <div className="ChartTab__Tab">
    <div className="ChartTab__Label">{label}</div>
    <DisplayText size="small">{value || "-"}</DisplayText>
  </div>
);

function makeEmptyChartTabs(tabContents) {
  return tabContents.map(({ key, label }) => ({
    id: key,
    content: makeChartTabTab(label)
  }));
}

export default function chartTabsFactory(valueFormatters: ValueFormatMap) {
  const tabContents = [
    {
      key: "orderTotal",
      label: "Total sales"
    },
    {
      key: "orderCount",
      label: "Total orders"
    },
    {
      key: "conversionRate",
      label: "Conversion rate"
    }
  ];

  return function makeChartTabs(
    selectedOffers: number[],
    offersReports: NormalizedOffersReport,
    summaryReport: NormalizedSummaryReport,
    axisTicks: Date[],
    spanKey: SpanKey
  ): TabDescriptor[] {
    if (!offersReports) return makeEmptyChartTabs(tabContents);

    const values =
      selectedOffers.length > 0
        ? getAggregateTotalsForMultipleOffers(
            offersReports,
            spanKey,
            axisTicks,
            selectedOffers
          )
        : getAggregateTotalsForOffer(summaryReport, spanKey, axisTicks);

    const reportDataWithConversions = {
      ...values,
      conversionRate: calculateConversionPercentage(
        values.orderCount,
        values.cartCount
      )
    };

    return tabContents.map(tab => {
      const { key, label } = tab;
      const valueContent = valueFormatters[key](reportDataWithConversions[key]);

      return {
        id: key,
        accessibilityLabel: label,
        // $FlowDisableNextLine
        content: makeChartTabTab(label, valueContent)
      };
    });
  };
}
