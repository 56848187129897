// @flow

import React from "react";
import { TextStyle, Tooltip } from "@shopify/polaris";

import type { ReportingColumnItem } from "./OffersList";

import "./OffersListHeader.scss";

type Props = {
  reportingColumns: Array<ReportingColumnItem>
};

const ReportingColumnHeading = ({
  column
}: {
  column: ReportingColumnItem
}) => {
  const { title, titleTooltip } = column;
  return (
    <div
      className="OffersList__Column OffersList__Column--reporting"
      aria-hidden
    >
      <Tooltip preferredPosition="above" content={titleTooltip()}>
        <span>{title}</span>
      </Tooltip>
    </div>
  );
};

const OffersListHeader = ({ reportingColumns }: Props) => {
  return (
    <div className="OffersList__Header">
      <div className="OffersList__Row">
        <div className="OffersList__Column OffersList__Column--main">
          <span>
            Offers{" "}
            <TextStyle variation="subdued">
              (applied in the order shown)
            </TextStyle>
          </span>
        </div>
        <div className="OffersList__Column OffersList__Column--status">
          Status
        </div>
        {reportingColumns.map(column => (
          <ReportingColumnHeading key={column.key} column={column} />
        ))}
        <div className="OffersList__Column OffersList__Column--actions" />
      </div>
    </div>
  );
};

export default OffersListHeader;
