import React, { Component } from "react";
import {
  Banner,
  Button,
  Card,
  ChoiceList,
  FormLayout,
  Layout,
  List,
  TextStyle
} from "@shopify/polaris";

import PolarisFormikNumberField from "components/PolarisFormikNumberField";
import GroupedPageActions from "components/GroupedPageActions";
import ErrorsBannerSection from "components/banners/ErrorsBannerSection";
import MultipleDiscountsSection from "./MultipleDiscountsSection";
import OrderDiscountDetailsSection from "./OrderDiscountDetailsSection";

import "./SettingsForm.css";

export function CreditOfferSection(props) {
  const { values, setFieldValue } = props.form;
  return (
    <Layout.AnnotatedSection title="Gift offer">
      <Card>
        <Card.Section>
          <FormLayout>
            <ChoiceList
              name="multiple_offers_free_gift"
              selected={values.multiple_offers_free_gift}
              choices={[
                {
                  label: "Apply only one Gift offer (largest amount)",
                  value: "one"
                },
                {
                  label: "Apply all Gift offers",
                  value: "all"
                }
              ]}
              onChange={([value]) => {
                setFieldValue("multiple_offers_free_gift", value);
              }}
            />
            <TextStyle variation="subdued">
              Example: If a gift is offered for purchases over $100 and a second
              gift for purchases over $300, the first option would apply only
              the larger gift offer (for spending $300) whereas the second
              option would apply both offers.
            </TextStyle>
          </FormLayout>
        </Card.Section>
      </Card>
    </Layout.AnnotatedSection>
  );
}

export function FixedTaxPercentageSection() {
  return (
    <Layout.AnnotatedSection title="Fixed tax percentage">
      <Card>
        <Card.Section>
          <FormLayout>
            <div className="TaxPercent">
              <PolarisFormikNumberField
                name="tax_percent"
                suffix="%"
                min={0}
                max={100}
                helpText="Use when taxes are not included in prices, but your theme is coded
                to show prices with tax. For offers that use specific amounts
                (e.g. Gift) always specify the price without tax."
              />
            </div>
          </FormLayout>
        </Card.Section>
      </Card>
    </Layout.AnnotatedSection>
  );
}

export function ApplyingDiscountsSection(props) {
  const { form } = props;
  const { values } = form;

  return (
    <Layout.AnnotatedSection title="Applying discounts">
      <Card>
        <Card.Section>
          <FormLayout>
            <ChoiceList
              selected={values.offers_method}
              choices={[
                {
                  label: "Create draft orders for customers to apply discounts",
                  value: "draft_order"
                },
                {
                  label:
                    "Use a pre-filled discount code at checkout to apply discount",
                  value: "discount_code"
                }
              ]}
              onChange={([value]) => {
                form.setFieldValue("offers_method", value);
              }}
            />
          </FormLayout>
        </Card.Section>
      </Card>
    </Layout.AnnotatedSection>
  );
}

export function MultiCurrencySection(props) {
  const { values, setFieldValue } = props.form;

  const renderMulticurrencyWarnings = () => {
    if (!values.enableMultiCurrency) return null;

    return (
      <Banner status="info">
        Because of limitations in the Shopify multi-currency system, Ultimate
        Special Offers is only able to support multi-currency in certain
        situations:
        <List>
          <List.Item>
            The offer is configured to reward a percentage-based discount. New
            Price and Fixed Price discounts are not supported at this time
          </List.Item>
          <List.Item>
            {" "}
            The &quot;Applying discounts&quot; method selected for Ultimate
            Special Offers is &quot;Use a pre-filled discount code at checkout
            to apply discount&quot;
          </List.Item>
        </List>
      </Banner>
    );
  };

  return (
    <Layout.AnnotatedSection title="Multi-currency">
      <Card>
        <Card.Section>
          <FormLayout>
            <ChoiceList
              name="enableMultiCurrency"
              selected={values.enableMultiCurrency.toString()}
              choices={[
                {
                  label:
                    "Offers are only valid to customers using my default shop currency",
                  value: "false"
                },
                {
                  label:
                    "Percentage-based offers are valid to customers using all available currencies",
                  value: "true",
                  renderChildren: renderMulticurrencyWarnings
                }
              ]}
              onChange={([value]) => {
                setFieldValue("enableMultiCurrency", value === "true");
              }}
            />
          </FormLayout>
        </Card.Section>
      </Card>
    </Layout.AnnotatedSection>
  );
}

// Needs to be a component instance instead of a functional stateless component
// due to the fact that we're adding a ref when we mount it
export default class SettingsForm extends Component {
  constructor(props) {
    super(props);
    this.errorsSectionRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const wasSubmitting = prevProps.form.isSubmitting;
    const { isSubmitting, isValid } = this.props.form;
    if (wasSubmitting && !isSubmitting && !isValid) {
      this.errorsSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  render() {
    const { handleSubmit, errors, touched } = this.props.form;

    return (
      <form onSubmit={handleSubmit}>
        <Layout>
          <ErrorsBannerSection
            errors={errors}
            touched={touched}
            ref={this.errorsSectionRef}
          />
          <MultipleDiscountsSection {...this.props} />
          <CreditOfferSection {...this.props} />
          <OrderDiscountDetailsSection {...this.props} />
          <FixedTaxPercentageSection {...this.props} />
          {(this.props.companionAppsInstalled ||
            this.props.hasDiscountScopes) && (
            <>
              <MultiCurrencySection {...this.props} />
              <ApplyingDiscountsSection {...this.props} />
            </>
          )}
          <Layout.Section>
            <GroupedPageActions>
              <Button onClick={this.props.onCancel}>Cancel</Button>
              <Button primary onClick={this.props.form.submitForm}>
                Save
              </Button>
            </GroupedPageActions>
          </Layout.Section>
        </Layout>
      </form>
    );
  }
}
