// @flow
import requiredScopes from "types/constants/scopes";
import { type Shop } from "types/flow-types/shop";

export function hasRequiredScopes(shopScopes: Array<string>): boolean {
  if (shopScopes === []) return false;
  for (const scope of requiredScopes) {
    if (!shopScopes.includes(scope)) return false;
  }

  return true;
}

export function hasDiscountScopes(shopScopes: Array<string>): boolean {
  return (
    shopScopes.includes("write_discounts") &&
    shopScopes.includes("read_discounts")
  );
}

export function requiresDiscountScopesUpdate(shop: Shop) {
  return (
    shop.app_settings.offers_method === "discount_code" &&
    !hasDiscountScopes(shop.shopify_scopes)
  );
}
