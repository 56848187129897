import React from "react";
import { Card } from "@shopify/polaris";
import {
  formatCurrency,
  periodDate,
  timestamp
} from "routes/account/fixed-and-volumetric/account-helpers";
import copy from "content/copy.json";
import useFetchUsageChargesData from "../useFetchUsageChargesData";
import UsageFeesLoadingCard from "./UsageFeesLoadingCard";
import UsageFeesErrorCard from "./UsageFeesErrorCard";
import UsageFeesDataTable from "./UsageFeesDataTable";

function UsageFeesActiveCard() {
  const { usageFeesCardTitle, billingDataLastUpdated } =
    copy.account.percentageVolumetric.usageFeesSection;

  const { currentUsageCharges, error, loading } = useFetchUsageChargesData();

  if (loading) {
    return <UsageFeesLoadingCard />;
  }
  if (error) {
    return <UsageFeesErrorCard />;
  }

  const billingPeriodStart = periodDate(currentUsageCharges.billingPeriodStart);
  const billingPeriodEnd = periodDate(currentUsageCharges.billingPeriodEnd);
  const billingDataLastUpdate = timestamp(currentUsageCharges.lastUpdate);
  const usageFeesTotal = currentUsageCharges.totals;
  const discountedOrders = usageFeesTotal.totalOrders;
  const charges = formatCurrency(usageFeesTotal.totalCharged);

  const usageFeesTitle = `${usageFeesCardTitle} ${billingPeriodStart} to ${billingPeriodEnd}`;
  const usageFeesTableFooterText = `${billingDataLastUpdated} ${billingDataLastUpdate}`;

  return (
    <Card title={usageFeesTitle}>
      <UsageFeesDataTable
        chargesRowContent={charges}
        discountOrdersRowContent={discountedOrders}
        footerContent={usageFeesTableFooterText}
      />
    </Card>
  );
}

export default UsageFeesActiveCard;
