// @flow
import type { DateWithSkip } from "./chart-types";
import type { DateRanges } from "../../lib/reporting-helpers";
import { DATE_RANGES } from "../../lib/constants";
import { format, subDays, subSeconds, subHours, startOfDay } from "date-fns";

export function getTimeKeyForDate(date: Date): string {
  return format(date, "yyyy-MM-dd HH");
}

function makeHourlyDateArray(startDate: Date, endDate: Date): DateWithSkip[] {
  const dateArray = [];
  let currentDate = new Date(startDate);
  currentDate.setMinutes(0, 0, 0);

  while (currentDate <= endDate) {
    dateArray.push(new Date(currentDate));
    currentDate.setHours(currentDate.getHours() + 1);
  }

  return dateArray;
}

function makeDailyDateArray(startDate: Date, endDate: Date): DateWithSkip[] {
  const dateArray = [];
  let currentDate = new Date(startDate);
  currentDate.setHours(0, 0, 0, 0);

  while (currentDate <= endDate) {
    dateArray.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dateArray;
}

export function makeDateArray(
  startDate: Date,
  endDate: Date,
  increment: "daily" | "hourly"
): DateWithSkip[] {
  if (increment === "daily") {
    return makeDailyDateArray(startDate, endDate);
  }

  if (increment === "hourly") {
    return makeHourlyDateArray(startDate, endDate);
  }

  throw new Error("invalid span increment provided!");
}

type ReportingTimeSpanMap = {
  [timeSpan: DateRanges]: {
    spanKey: "daily" | "hourly",
    end: Date,
    start: Date
  }
};

const now = new Date();
const thisMorning = startOfDay(now);

const timeSpansForRange: ReportingTimeSpanMap = {
  [DATE_RANGES.PAST_30_DAYS]: {
    spanKey: "daily",
    end: now,
    start: subDays(now, 29)
  },
  [DATE_RANGES.PAST_7_DAYS]: {
    spanKey: "daily",
    end: now,
    start: subDays(now, 6)
  },
  [DATE_RANGES.YESTERDAY]: {
    spanKey: "hourly",
    end: subSeconds(thisMorning, 1),
    start: subHours(thisMorning, 23)
  },
  [DATE_RANGES.TODAY]: {
    spanKey: "hourly",
    end: now,
    start: thisMorning
  }
};

export function getRangeForSpan(timeSpan: DateRanges): {
  spanKey: "daily" | "hourly",
  axisTicks: Array<Date>
} {
  const range = timeSpansForRange[timeSpan];

  if (!range) {
    throw new Error(`invalid range: ${timeSpan}`);
  }

  const { spanKey, start, end } = range;

  return {
    spanKey,
    // $FlowDisableNextLine
    axisTicks: makeDateArray(start, end, spanKey)
  };
}
