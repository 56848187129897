//@flow
import { getTimeKeyForDate } from "../components/reporting/chart-date-helpers";
import { EMPTY_OFFER_REPORT } from "store/reportsStore";

import type { OfferReportItem } from "types/flow-types/reports";
import type {
  NormalizedSummaryReport,
  NormalizedOffersReport
} from "store/reportsStore";
import type { SpanKey } from "../components/reporting/chart-types";

export type DateRanges = "today" | "yesterday" | "past7days" | "past30days";

export function getAggregateTotalsForOffer(
  offerReport: NormalizedSummaryReport,
  spanKey: SpanKey,
  axisTicks: Date[]
): OfferReportItem {
  const totals = {
    ...EMPTY_OFFER_REPORT
  };

  if (!offerReport) return totals;

  return axisTicks.reduce((accumulator, date) => {
    const dateKey = getTimeKeyForDate(date);

    const reportAtDate = offerReport[spanKey][dateKey];

    if (!reportAtDate) {
      return accumulator;
    }

    const { orderTotal, orderCount, cartCount } = reportAtDate;

    accumulator.orderTotal += orderTotal;
    accumulator.orderCount += orderCount;
    accumulator.cartCount += cartCount;

    return accumulator;
  }, totals);
}

export function getAggregateTotalsForMultipleOffers(
  offersReports: NormalizedOffersReport,
  spanKey: SpanKey,
  axisTicks: Date[],
  offers: number[]
): OfferReportItem {
  const totals = {
    ...EMPTY_OFFER_REPORT
  };

  if (!offersReports) return totals;

  return offers.reduce((accumulator, offerId) => {
    const offer = offersReports[offerId];

    if (!offer) return accumulator;

    const { orderTotal, orderCount, cartCount } = getAggregateTotalsForOffer(
      offer,
      spanKey,
      axisTicks
    );

    accumulator.orderTotal += orderTotal;
    accumulator.orderCount += orderCount;
    accumulator.cartCount += cartCount;

    return accumulator;
  }, totals);
}

export function calculateConversionPercentage(
  orderCount: number,
  cartCount: number
) {
  if (orderCount === 0 && cartCount === 0) return 0;
  if (orderCount > 0 && cartCount === 0) {
    // eslint-disable-next-line no-console
    console.warn("Cart count is zero calculating conversion percentage");
    return 100;
  }
  return Math.min((orderCount / cartCount) * 100, 100);
}

type SurvicateProps = {
  axisTicks: Date[],
  shopDomain: string,
  spanKey: SpanKey,
  summaryReport: NormalizedSummaryReport
};

export function sendSurvicateDataToSegment({
  axisTicks,
  shopDomain,
  spanKey,
  summaryReport
}: SurvicateProps) {
  // send orderTotal to Segment for Survicate targeting
  if (window.analytics) {
    const { orderCount } = getAggregateTotalsForOffer(
      summaryReport,
      spanKey,
      axisTicks
    );

    window.analytics.identify(shopDomain, { orderCount });
  }
}
