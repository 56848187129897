import React, { useState } from "react";
import { Collapsible, Icon, Stack } from "@shopify/polaris";
import { CancelSmallMinor, ChevronDownMinor } from "@shopify/polaris-icons";
import "./AccordionItem.css";

function AccordionItem({ heading, children, index, id = "accordion" }) {
  const [open, setOpen] = useState(false);
  const icon = open ? CancelSmallMinor : ChevronDownMinor;

  return (
    <div className="AccordionItem__row">
      <button
        type="button"
        className="AccordionItem__heading"
        onClick={() => setOpen(!open)}
        aria-expanded={open}
      >
        <Stack wrap={false}>
          <Stack.Item fill>{heading}</Stack.Item>
          <Icon source={icon} color="base" />
        </Stack>
      </button>
      <Collapsible open={open} id={`${id}-item-${index}`}>
        {children}
      </Collapsible>
    </div>
  );
}

export default AccordionItem;
