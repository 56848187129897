import React from "react";
import { Field } from "formik";
import PolarisFormikSelect from "components/PolarisFormikSelect";

const BADGE_OPTIONS = [
  {
    label: "Circle",
    value: "circle"
  },
  {
    label: "Ribbon",
    value: "ribbon"
  },
  {
    label: "Corner",
    value: "corner"
  },
  {
    label: "Pennant",
    value: "pennant"
  },
  {
    label: "Banner",
    value: "banner"
  },
  {
    label: "Rectangle",
    value: "rectangle"
  },
  {
    label: "Oval",
    value: "oval"
  },
  {
    label: "Swallowtail",
    value: "swallowtail"
  }
];

export default function BadgeTypeInput() {
  return (
    <Field
      component={PolarisFormikSelect}
      key="badge-type"
      label="Badge type"
      name="customizations.badge.type"
      options={BADGE_OPTIONS}
      placeholder="Select"
    />
  );
}
