// @flow

import { format } from "date-fns";

import { getTimeKeyForDate } from "./chart-date-helpers";
import { drawName } from "../../lib/offerHelpers";

import { calculateConversionPercentage } from "../../lib/reporting-helpers";

import type {
  NormalizedOffersReport,
  NormalizedSummaryReport,
  NormalizedReportRange
} from "../../store/reportsStore";
import type { Offer } from "types/flow-types/offers";
import type {
  OfferOptionList,
  ChartPointArray,
  LineData,
  ChartView
} from "./chart-types";

import { BULK_COLOR } from "./chart-theme";

export function isChartEmpty(
  chartData: {
    data: Array<{ y: number, y: number }>
  }[]
): boolean {
  const allTicks = [].concat
    .apply(
      [],
      chartData.map(line => line.data)
    )
    .map(tick => tick.y);

  for (const value of allTicks) {
    if (value > 0) return false;
  }

  return true;
}

/**
 * Make an array of options for the offers picker, given an array of offers.
 */
export function makeOfferOptions(offers: Array<Offer>): OfferOptionList {
  return offers.map(offer => ({
    label: drawName(offer.name, offer.type),
    value: offer.id
  }));
}

export function setChosenOffersInOrder(
  currentIds: number[],
  newIds: number[]
): number[] {
  // removal: just pass new state
  if (currentIds.length > newIds.length) {
    return newIds;
  }

  const addedId = newIds.find(id => !currentIds.includes(id));

  if (addedId) {
    return currentIds.concat(addedId);
  }

  return currentIds;
}

/**
 * Get a sum of all orderCount/orderTotal values, for given offers, for a given date
 */
export function getOffersValuesForTick(
  offers: Array<Offer>,
  reports: NormalizedOffersReport,
  dateTick: Date,
  spanKey: string
): {
  orderCount: number,
  orderTotal: number,
  cartCount: number
} {
  const dateKey = getTimeKeyForDate(dateTick);
  const accumulator = {
    orderCount: 0,
    orderTotal: 0,
    cartCount: 0
  };

  return offers.reduce((totals, offer) => {
    const report = reports[offer.id];

    // skip if offer doesn't have any data
    if (!report || !report[spanKey]) return totals;

    const reportForDate = report[spanKey][dateKey] || {};
    const { orderCount = 0, orderTotal = 0, cartCount = 0 } = reportForDate;

    totals.orderCount = totals.orderCount + orderCount;
    totals.orderTotal = totals.orderTotal + orderTotal;
    totals.cartCount = totals.cartCount + cartCount;

    return totals;
  }, accumulator);
}

/**
 * get an array of x/y values for a given offer's report
 */
export function getLineValuesForOffer(
  report: NormalizedReportRange | {},
  axisTicks: Array<Date>
): {
  orderCount: ChartPointArray,
  orderTotal: ChartPointArray,
  conversionRate: ChartPointArray
} {
  const accumulator = {
    orderCount: [],
    orderTotal: [],
    conversionRate: []
  };

  return axisTicks.reduce((lineValues, dateTick) => {
    const dateKey = getTimeKeyForDate(dateTick);
    const reportStatsForTick = report[dateKey];

    lineValues.orderCount.push({
      x: format(dateTick, "yyyy-MM-dd HH"),
      y: reportStatsForTick ? reportStatsForTick.orderCount : 0
    });

    lineValues.orderTotal.push({
      x: format(dateTick, "yyyy-MM-dd HH"),
      y: reportStatsForTick ? reportStatsForTick.orderTotal : 0
    });

    lineValues.conversionRate.push({
      x: format(dateTick, "yyyy-MM-dd HH"),
      y: reportStatsForTick
        ? calculateConversionPercentage(
            reportStatsForTick.orderCount,
            reportStatsForTick.cartCount
          )
        : 0
    });

    return lineValues;
  }, accumulator);
}

/**
 * Make an array of chart lines, one for each offer
 */
export function makeOfferChartData(
  offers: Array<Offer>,
  offersReports: NormalizedOffersReport,
  axisTicks: Array<Date>,
  spanKey: string
): LineData {
  return offers.reduce((chartData, offer) => {
    // report may not exist for offer, and hourly data may not exist for report
    const offerReport = offersReports[offer.id] || {};
    const reportForSpan = offerReport[spanKey] || {};

    const { orderCount, orderTotal, conversionRate } = getLineValuesForOffer(
      reportForSpan,
      axisTicks
    );

    chartData[offer.id] = {
      label: drawName(offer.name, offer.type),
      id: offer.id,
      orderCount,
      orderTotal,
      conversionRate
    };

    return chartData;
  }, {});
}

/**
 * Make chart line, representing the total of all given offers
 */
export function makeAggregateChartData(
  offers: Array<Offer>,
  offersReports: NormalizedOffersReport,
  axisTicks: Array<Date>,
  spanKey: string
): LineData {
  const label = `${offers.length} offers`;

  const aggregator = {
    orderCount: [],
    orderTotal: [],
    conversionRate: []
  };

  const lineData = axisTicks.reduce((totals, dateTick) => {
    const { orderCount, orderTotal, cartCount } = getOffersValuesForTick(
      offers,
      offersReports,
      dateTick,
      spanKey
    );

    const x = format(dateTick, "yyyy-MM-dd HH");

    totals.orderCount.push({
      x,
      y: orderCount
    });

    totals.orderTotal.push({
      x,
      y: orderTotal
    });

    totals.conversionRate.push({
      x,
      y: calculateConversionPercentage(orderCount, cartCount)
    });

    return totals;
  }, aggregator);

  const aggregateLine = {
    label,
    color: BULK_COLOR,
    orderCount: lineData.orderCount,
    orderTotal: lineData.orderTotal,
    conversionRate: lineData.conversionRate
  };

  return { aggregate: aggregateLine };
}

/**
 * Make a single chart line for all offers (the summary response)
 */
export function makeSummaryChartData(
  totalOfferCount: number,
  summaryReport: NormalizedSummaryReport,
  axisTicks: Array<Date>,
  spanKey: string
): LineData {
  const label = `All ${totalOfferCount} offers`;
  const report = summaryReport[spanKey];
  const { orderCount, orderTotal, conversionRate } = getLineValuesForOffer(
    report,
    axisTicks
  );

  const summaryLine = {
    label,
    color: BULK_COLOR,
    orderCount,
    orderTotal,
    conversionRate
  };

  return { summary: summaryLine };
}

export function getChartView(
  selectedOffers: Array<Offer>,
  offersReports: NormalizedOffersReport,
  summaryReport: NormalizedSummaryReport,
  spanKey: string,
  axisTicks: Date[],
  totalOfferCount: number,
  maxOffers: number = 3
): ChartView {
  let lineMode = "";
  let lineData = {};

  if (selectedOffers.length === 0) {
    lineMode = "summary";
    lineData = makeSummaryChartData(
      totalOfferCount,
      summaryReport,
      axisTicks,
      spanKey
    );
  } else if (selectedOffers.length > 0 && selectedOffers.length <= maxOffers) {
    lineMode = "separate";
    lineData = makeOfferChartData(
      selectedOffers,
      offersReports,
      axisTicks,
      spanKey
    );
  } else {
    lineMode = "aggregate";
    lineData = makeAggregateChartData(
      selectedOffers,
      offersReports,
      axisTicks,
      spanKey
    );
  }

  return {
    lineMode,
    lineData
  };
}
